/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout {
    display: flex;
    flex: auto;
    flex-direction: column;
    /* fix firefox can't set height smaller than content on flex item */
    min-height: 0;
    background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
    box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
    flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: 0;
}
.ant-layout-header,
.ant-layout-footer {
    flex: 0 0 auto;
}
.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #001529;
}
.ant-layout-footer {
    padding: 24px 50px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background: #f0f2f5;
}
.ant-layout-content {
    flex: auto;
    /* fix firefox can't set height smaller than content on flex item */
    min-height: 0;
}
.ant-layout-sider {
    position: relative;
    /* fix firefox can't set width smaller than content on flex item */
    min-width: 0;
    background: #001529;
    transition: all 0.2s;
}
.ant-layout-sider-children {
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: auto;
}
.ant-layout-sider-has-trigger {
    padding-bottom: 48px;
}
.ant-layout-sider-right {
    order: 1;
}
.ant-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 48px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    background: #002140;
    cursor: pointer;
    transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
    overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: 64px;
    right: -36px;
    z-index: 1;
    width: 36px;
    height: 42px;
    color: #fff;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    background: #001529;
    border-radius: 0 2px 2px 0;
    cursor: pointer;
    transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    transition: all 0.3s;
    content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
    background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
    left: -36px;
    border-radius: 2px 0 0 2px;
}
.ant-layout-sider-light {
    background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
}
.ant-layout-rtl {
    direction: rtl;
}
nz-content {
    display: block;
}
nz-footer {
    display: block;
}
nz-header {
    display: block;
}
