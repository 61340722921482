@use 'variables' as var;
@import './grid/variables/main';

.k-dialog {
  border-radius: var.$border-radius;
  max-height: calc(100vh - 20px);
  .k-dialog-titlebar {
    background-color: var.$c-white;
    border-radius: var.$border-radius var.$border-radius 0 0;
    color: var.$c-black;
    font-weight: 500;
    .k-window-title {
      color: var.$c-black;
    }
  }
  .k-window-content {
    padding: 20px 20px 0 20px;
    background-color: var.$c-white !important;
  }
  kendo-dialog-actions {
    justify-content: center;
    margin: 8px 0;
    padding: 0;
    .k-button {
      margin: 10px !important;
      max-width: 250px;
      background-color: var.$c-white !important;
      color: var.$c-black !important;
      border: 2px solid var.$c-primary !important;
      box-shadow: none !important;
      border-radius: var.$border-radius !important;
      //height: auto !important;
      &:hover {
        background-color: darken(var.$c-white, 5%) !important;
      }
      &.k-primary {
        background-color: var.$c-primary !important;
        color: var.$c-white !important;
        &:hover {
          background-color: darken(var.$c-primary, 5%) !important;
        }
      }
    }
  }
}

.k-pager-info {
  display: inline-flex !important;
}

kendo-panelbar {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  kendo-panelbar-item {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    border-bottom: 1px solid var.$c-background-menu-active !important;
    &:nth-last-of-type(1) {
      border-bottom-width: 0 !important;
    }
    &.k-state-expanded {
      .k-link {
        color: var.$c-primary !important;
        .k-panelbar-item-text,
        .k-icon {
          color: var.$c-primary !important;
          text-transform: uppercase;
        }
      }
    }
    .k-link {
      background: var.$c-white !important;
      font-weight: bold !important;
      color: var.$c-black !important;
      display: flex;
      justify-content: space-between;
    }
    kendo-icon-wrapper .k-panelbar-collapse {
      color: var.$c-primary !important;
    }
  }
  .k-link {
    font-weight: bold !important;
    background: var.$c-white !important;
    color: var.$c-black !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }
  .k-content {
    padding: 0 10px;
  }
  .menu-item {
    justify-content: flex-start !important;
    text-align: left !important;
    background: var.$c-white !important;
    color: var.$c-black !important;
    box-shadow: none !important;
    &:not(.menu-item-selected):hover {
      background: darken(var.$c-white, 5%) !important;
    }
    .menu-icon-wrapper {
      position: relative;
      top: 2px;
    }
    &.menu-item-selected {
      background: var.$c-primary !important;
      color: var.$c-white !important;
      .menu-text {
        color: var.$c-white !important;
      }
      svg {
        color: var.$c-white !important;
      }
    }
  }
}

.k-i-filter {
  position: relative;
}

kendo-calendar-header {
  .k-button {
    color: var.$c-primary !important;
    &:hover {
      color: var.$c-white !important;
    }
  }
}

kendo-calendar * {
  font-size: 14px !important;
}

.k-checkbox:indeterminate::before,
.k-checkbox.k-state-indeterminate::before {
  background-color: var.$c-primary;
}

.k-form .k-form-field,
.k-form .k-form-buttons,
.k-form .k-daterangepicker .k-textbox-container {
  margin-top: 4px !important;
}

kendo-colorpicker {
  .k-selected-color {
    height: 100% !important;
  }
  display: grid !important;
  grid-auto-flow: column;
  justify-items: center;
  cursor: pointer;
  .k-input-button,
  .k-input-button:hover {
    box-shadow: 0px 1px 3px rgb(0 0 0 / 35%) !important;
    background-color: var.$c-white !important;
    border-radius: 0 5px 5px 0 !important;
    color: var.$c-black !important;
    padding: 4px;
    height: 100%;
  }
  .k-input-inner {
    width: 30px !important;
    height: 30px !important;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 35%) !important;
    background-color: var.$c-white !important;
    border-radius: 5px 0 0 5px !important;
    padding: 0;
    span {
      display: inline-flex;
      width: 30px !important;
      height: 30px !important;
      border-radius: 5px 0 0 5px !important;
    }
  }
}

.k-colorpicker-popup {
  overflow: hidden !important;
  .k-slider-wrap {
    height: 166px !important;
  }
}

.k-colorgradient-slider.k-slider-vertical .k-slider-track {
  width: 10px;
  border-radius: 10px;
  margin-left: -5px;
}

.k-slider-vertical .k-slider-track-wrap .k-slider-track {
  height: 100%;
}

.k-slider-transitions.k-slider-vertical .k-draghandle {
  touch-action: auto !important;
}

kendo-fileselect {
  .k-button {
    span {
      color: var.$c-white;
    }
  }
  .k-dropzone-hint {
    padding-left: 10px;
  }
}

.k-notification-error {
  padding: 10px;
  background: var.$c-txt-error;
  .k-notification-wrap * {
    color: var.$c-white;
  }
  .k-notification-content {
    color: var.$c-white;
  }
}

.k-notification-success {
  .k-notification-content {
    color: var.$c-white;
  }
}

.k-pager {
  .k-pager-nav.k-button {
    background: transparent !important;
    color: black !important;
    &:hover {
      background: rgba(0, 0, 0, 0.1) !important;
    }
    .k-button-text {
      color: var.$c-primary !important;
    }
    &.k-selected {
      background: var.$c-primary !important;
      cursor: initial;
      .k-button-text {
        color: var.$c-white !important;
      }
    }
  }
  .k-pager-sizes .k-dropdown {
    padding: 0;
  }
}

.k-grid {
  .k-input-button,
  .k-input-button:hover {
    background-color: var.$c-white !important;
    color: black !important;
  }
  .k-i-sort-asc-small,
  .k-i-sort-desc-small {
    color: var.$c-primary !important;
  }
  kendo-dropdownlist {
    width: 100%;
  }
}

.k-input {
  border-radius: 5px !important;
}

.k-numerictextbox {
  .k-input-inner {
    box-shadow: none !important;
    background: transparent;
  }
  .k-spinner-decrease:hover,
  .k-spinner-increase:hover {
    background-color: #f0f0f0 !important;
    color: var.$c-black !important;
  }
  .k-spinner-decrease,
  .k-spinner-increase {
    background-color: var.$c-white !important;
    color: var.$c-black !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0;
  }
}

.k-timepicker,
.k-datepicker {
  padding-right: 0 !important;
  border-radius: 5px !important;
  .k-input-inner,
  .k-input-solid,
  .k-input {
    box-shadow: none !important;
    background: transparent !important;
  }
  .k-input-button,
  .k-input-button:hover {
    color: var.$c-black !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    background: transparent !important;
  }
}

.k-calendar-header {
  display: flex;
  justify-content: space-between;
  .k-calendar-nav-today.k-button {
    background: transparent !important;
    color: var.$c-primary !important;
    padding: 0;
  }
  span {
    color: var.$c-primary !important;
    background: transparent !important;
  }
}

kendo-grid-filter-cell-operators,
kendo-treelist-filter-cell-operators {
  height: 28px;
}

.k-list-container {
  padding: 0 !important;
  kendo-list {
    ul {
      li {
        padding: 8px !important;
        &.k-item.k-state-selected {
          background: var.$c-primary;
          &:hover {
            background: darken(var.$c-primary, 10%);
          }
        }
      }
    }
  }
  .k-list-optionlabel {
    padding: 8px !important;
    &.k-state-selected {
      background: var.$c-primary;
      &:hover {
        background: darken(var.$c-primary, 10%);
      }
    }
  }
}

.k-tabstrip-items .k-item {
  color: var.$c-primary;
  border-radius: 10px 10px 0 0 !important;
  border: 0;
  transition: all 0.5s ease;
  &:hover {
    color: darken(var.$c-primary, 10%);
  }
  &:focus {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35) !important;
  }

  &.k-active {
    transition: none !important;
  }

  > .k-link {
    border-radius: 10px 10px 0 0;
  }
}

.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
  color: var.$c-primary;
  &:hover {
    color: darken(var.$c-primary, 10%);
  }
}

input[type='checkbox']:hover:not(:checked) {
  background-color: lighten(var.$c-primary, 50%) !important;
}
input[type='checkbox']:hover:checked {
  background-color: darken(var.$c-primary, 10%) !important;
}

.k-pager-refresh:hover,
.k-pager-nav.k-link:hover,
.k-state-hover.k-pager-refresh,
.k-state-hover.k-pager-nav.k-link {
  background: rgba(0, 0, 0, 0.1);
}

.k-pager-nav {
  border-radius: 5px;
  transition: all 0.2s ease;
}

kendo-dropdownlist.k-dropdown-operator {
  width: auto !important;
  display: inline-block !important;
  position: relative;
  top: -1px;
  .k-dropdown-wrap {
    display: flex;
    align-items: center;
  }
}

kendo-dropdownlist,
kendo-dropdowntree {
  display: flex;
  justify-items: center;
  cursor: pointer;
  border-radius: var.$border-radius;

  .k-input-button,
  .k-input-button:hover {
    box-shadow: 0px 1px 3px rgb(0 0 0 / 35%) !important;
    background-color: var.$c-white !important;
    border-radius: 0 5px 5px 0 !important;
    color: var.$c-black !important;
    padding: 4px;
  }

  .k-input-inner {
    box-shadow: 0px 1px 3px rgb(0 0 0 / 35%) !important;
    background-color: var.$c-white !important;
  }
}

.k-filtercell .k-filtercell-operator > .k-button {
  visibility: visible !important;
  padding: 0 !important;
  pointer-events: auto !important;
  top: -1px !important;
}

kendo-grid-filter-cell-operators,
kendo-treelist-filter-cell-operators {
  .k-input-inner {
    display: none;
  }
  .k-input-button,
  .k-input-button:hover {
    border-radius: 5px !important;
  }
}

.k-popup.k-popup-transparent {
  overflow: hidden;
}
kendo-popup {
  z-index: 101 !important;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    cursor: pointer;

    li:hover {
      background-color: #f0f0f0;
    }
    li[role='group'] {
      font-weight: bold;
      cursor: auto;
      border-top: 1px solid darken(#f0f0f0, 10%);
      &:hover {
        background-color: transparent;
      }
    }
    .k-checkbox {
      margin-right: 10px !important;
    }
    .k-selected {
      span {
        color: var.$c-white !important;
      }
      &:hover {
        background-color: darken(var.$c-primary, 5%);
      }
      background-color: var.$c-primary;
    }
  }
  .k-list-container kendo-list ul .k-selected {
    color: white !important;
  }

  .k-input-inner {
    box-shadow: none !important;
  }
  .k-list-filter {
    width: auto;
    position: sticky;
    top: 0px;
    background-color: var.$c-white;
  }
  .k-list-group-sticky-header {
    font-weight: bold;
    padding: 4px 8px;
    top: 46px;
    background-color: var.$c-white;
    border-bottom: 1px solid darken(#f0f0f0, 5%);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  }
  .k-list-content {
    height: 100% !important;
  }
  kendo-treeview {
    overflow-y: scroll !important;
    padding: 0;
    .k-checkbox {
      margin-right: 0 !important;
    }
    li:hover {
      background-color: transparent !important;
    }
  }
  li > ul,
  li > ul ul {
    margin: 0 0 0 1.8rem !important;
  }

  &.k-animation-container-shown {
    z-index: 10001 !important;
  }
}

kendo-grid-filter-cell-operators button {
  height: 30px;
  margin-left: 3px;
  display: inline-flex;
  align-items: center;
  padding: 0;
}

kendo-timeselector {
  kendo-virtualization {
    padding: 0 !important;
  }
}

.k-grid-header {
  .k-multiselect,
  kendo-multiselecttree {
    height: 30px !important;
    .k-input-values {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      width: calc(100% - 20px);
    }
    .k-input-inner {
      box-shadow: none !important;
    }
    .k-chip {
      //top: 6px;
      border: none;
      border-color: transparent;
      outline: none;
      border-radius: 5px !important;
      background-color: var.$c-primary;
      overflow: visible;
      &:not(:nth-last-of-type(1)) {
        margin-right: 2px;
      }
      span {
        color: var.$c-white !important;
        font-weight: 500;
        text-transform: uppercase;
      }
      .k-icon {
        color: var.$c-white !important;
        margin-right: 2px;
        top: -2px;
      }
      &:hover {
        background-color: darken(var.$c-primary, 5%) !important;
      }
      .k-chip-label {
        min-width: 100%;
      }
    }
    .k-chip-list {
      overflow-y: hidden !important;
    }
    &.k-disabled {
      background: var.$c-disabled-bg !important;
      span,
      button {
        background: var.$c-disabled-bg !important;
      }
      .k-chip {
        background: var.$c-disabled-bg !important;
      }
    }
    input,
    .k-input {
      background: transparent;
      border-radius: 0;
      box-shadow: none !important;
      color: var.$c-black;
      padding-right: 24px;
    }
    .k-chip-list {
      @media (hover: hover) and (pointer: fine) {
        &::-webkit-scrollbar-track {
          border-radius: 4px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar {
          height: 8px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #555;
        }
      }
    }
  }
}

.k-upload {
  .k-dropzone .k-dropzone-hint {
    text-align: right;
  }
  input {
    display: none;
  }
}

.k-treeview {
  ul:first-child {
    padding: 0;
    margin: 0;
  }
  ul {
    list-style-type: none;
  }
  .k-treeview-bot,
  .k-treeview-mid,
  .k-treeview-top {
    display: flex;
    align-items: center;
    gap: 2px;

    > span.k-treeview-leaf {
      display: block;
      padding: 4px 6px;
      cursor: pointer !important;
      width: 100%;
      &:not(.k-selected):hover {
        background-color: #f0f0f0;
      }
    }
  }
}

.k-notification {
  display: flex;
  flex-direction: row;

  .k-notification-status {
    margin-right: 0.5rem;
  }
}

nz-select {
  width: 100%;
  nz-select-top-control {
    border-radius: 5px !important;
    box-shadow: 0px 1px 3px rgb(0, 0, 0, 35%) !important;
    border: none !important;
    nz-select-search {
      width: 100%;
      input {
        box-shadow: none !important;
        height: 100% !important;
      }
    }
  }
}

input:disabled {
  background: var.$c-disabled-bg;
}

textarea:disabled {
  background: var.$c-disabled-bg;
}

.k-checkbox:not(:checked) {
  &:disabled {
    opacity: 1;
    filter: none;
    background: var.$c-disabled-bg;
  }
}

.k-radio:hover:not(:disabled) {
  filter: contrast(0.92);
}

.k-radio:checked,
.k-radio:checked:hover,
.k-radio:hover,
.k-radio.k-checked:hover {
  border-width: 4px;
}

.k-treeview-item {
  &.k-state-disabled {
    .k-checkbox {
      background: var.$c-disabled-bg !important;
    }
  }
}

.k-picker-wrap,
.k-dropdown-wrap {
  &.k-state-disabled {
    background: var.$c-disabled-bg !important;
    span {
      background: var.$c-disabled-bg;
    }
  }
}

.k-numerictextbox {
  &.k-disabled {
    background: var.$c-disabled-bg !important;
    .k-spin-button button {
      background: var.$c-disabled-bg !important;
    }
  }
}

.k-timepicker,
.k-datepicker,
.k-datetimepicker {
  &.k-disabled {
    background: var.$c-disabled-bg !important;
    span,
    button {
      background: var.$c-disabled-bg !important;
    }
  }
}

.k-dropdownlist {
  &.k-disabled {
    background: var.$c-disabled-bg !important;
    span,
    button {
      background: var.$c-disabled-bg !important;
    }
  }
}

.k-tabstrip-content,
.k-tabstrip > .k-content {
  display: inline;
  //top: -10px;
  //z-index: 100;
  height: calc(100vh - 170px);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
  border-top-right-radius: var.$c-border-radius-xl !important;
  border-bottom-right-radius: var.$c-border-radius-xl !important;
  border-bottom-left-radius: var.$c-border-radius-xl !important;
  background: var.$c-white;
  padding: 15px;
  font-weight: 500;
  @media #{$breakpoint-lg-down} {
    padding: 15px 10px;
  }
}

.hierarchyGrid {
  .k-hierarchy-col {
    width: 11px;
  }
}

.k-grid-md .k-edit-cell,
.k-grid-md .k-command-cell,
.k-grid-md .k-grid-edit-row td,
.k-grid-md .k-grid-edit-row .k-table-td {
  padding: 2px 4px;
}

.k-form .k-multiselect {
  > .k-input-values {
    overflow: auto;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
  }
}

.k-multiselect .k-chip {
  display: flex;
  flex-direction: row-reverse;
  gap: 0;
  padding-top: 5px;
  padding-bottom: 5px;

  .k-chip-actions {
    padding-right: 0;
  }

  .k-chip-content {
    padding-right: 4px;
  }
}
