@charset "UTF-8";
/* KendoUI variables */
.k-sr-only {
    position: absolute;
    left: -1px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.k-theme-test-class,
.k-common-test-class {
    opacity: 0;
}

.k-body {
    font-size: var(--kendo-font-size, inherit);
    font-family: var(--kendo-font-family, inherit);
    line-height: var(--kendo-line-height, normal);
    font-weight: var(--kendo-font-weight, normal);
    letter-spacing: var(--kendo-letter-spacing, normal);
    color: var(--kendo-body-text, initial);
    background-color: var(--kendo-body-bg, initial);
    margin: 0;
}

.k-hstack,
.k-hbox {
    display: flex;
    flex-flow: row nowrap;
}

.k-vstack,
.k-vbox {
    display: flex;
    flex-flow: column nowrap;
}

.k-spacer,
.k-flex {
    flex: 1 1 auto;
}

.k-spacer-sized {
    flex: none;
}

.k-float-wrap::after,
.k-floatwrap::after {
    content: '';
    display: block;
    clear: both;
}

.k-flex-layout {
    display: flex;
}

.k-grid-layout {
    display: grid;
}

.k-separator {
    width: auto;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: inherit;
    display: block;
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    align-self: stretch;
}

.k-separator-horizontal,
.k-vstack > .k-separator,
.k-vbox > .k-separator {
    width: auto;
    height: 0;
    border-width: 1px 0 0;
}

.k-separator-vertical,
.k-hstack > .k-separator,
.k-hbox > .k-separator {
    width: 0;
    height: auto;
    border-width: 0 0 0 1px;
}

hr.k-separator {
    margin: 0;
    padding: 0;
}

.k-hidden {
    display: none !important;
}

.k-rtl {
    direction: rtl;
}

[hidden] {
    display: none !important;
}

script {
    display: none !important;
}

.k-disabled,
.k-widget[disabled],
.k-disabled {
    outline: none;
    cursor: default;
    color: var(--kendo-disabled-text, inherit);
    border-color: var(--kendo-disabled-border, inherit);
    pointer-events: none;
    box-shadow: none;
}
.k-disabled .k-link,
.k-widget[disabled] .k-link,
.k-disabled .k-link {
    cursor: default;
    outline: 0;
}

.k-hr {
    margin-block: 16px;
    padding: 0;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: var(--kendo-component-border, inherit);
    display: block;
    float: none;
    clear: both;
}

.k-d-flex-row > .k-hr {
    margin: 0;
    width: 0;
    height: auto;
    border-width: 0 0 0 1px;
    flex: 0 0 auto;
}

.k-d-flex-col > .k-hr {
    margin: 0;
    flex: 0 0 auto;
}

.k-sprite {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    overflow: hidden;
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
    text-align: center;
}

.k-image {
    display: inline-block;
}

.k-reset {
    margin: 0;
    padding: 0;
    border-width: 0;
    outline: 0;
    text-decoration: none;
    font: inherit;
    list-style: none;
}

kendo-sortable {
    display: block;
}

.k-link,
.k-link:hover {
    color: inherit;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
}

.k-content {
    outline: 0;
}

.k-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.k-no-click {
    pointer-events: none;
}

.k-pdf-export-shadow {
    position: absolute;
    overflow: hidden;
    left: -15000px;
    width: 14400px;
}

.kendo-pdf-hide-pseudo-elements::before,
.kendo-pdf-hide-pseudo-elements::after {
    display: none !important;
}

.k-dirty {
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    border-width: 3px;
    border-style: solid;
    border-block-start-color: currentColor;
    border-block-end-color: transparent;
    border-inline-start-color: transparent;
    border-inline-end-color: currentColor;
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
}

.k-loading-mask,
.k-loading-image,
.k-loading-color {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
}
.k-loading-mask *,
.k-loading-mask *::before,
.k-loading-mask *::after,
.k-loading-mask::before,
.k-loading-mask::after,
.k-loading-image *,
.k-loading-image *::before,
.k-loading-image *::after,
.k-loading-image::before,
.k-loading-image::after,
.k-loading-color *,
.k-loading-color *::before,
.k-loading-color *::after,
.k-loading-color::before,
.k-loading-color::after {
    box-sizing: border-box;
}

.k-loading-mask {
    z-index: 100;
}
.k-loading-mask.k-opaque .k-loading-color {
    opacity: 1;
}

.k-loading-text {
    text-indent: -4000px;
    text-align: center;
    position: absolute;
    color: currentColor;
}

.k-loading-image {
    z-index: 2;
    color: currentColor;
}

.k-loading-color {
    background-color: #ffffff;
    opacity: 0.3;
}

.k-i-loading {
    width: 1em;
    height: 1em;
    line-height: 1;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    background-color: transparent;
    box-sizing: border-box;
    color: currentColor;
}
.k-i-loading::before,
.k-i-loading::after {
    box-sizing: border-box;
}

.k-i-loading::before,
.k-i-loading::after,
.k-loading-image::before,
.k-loading-image::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    content: '';
    box-sizing: inherit;
    border-radius: 50%;
    border-width: 0.05em;
    border-style: solid;
    border-color: currentColor;
    border-top-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
}

.k-icon.k-i-loading::before,
.k-icon.k-i-loading::after {
    content: '';
}

.k-i-loading::before,
.k-loading-image::before {
    margin-top: -0.5em;
    margin-left: -0.5em;
    width: 1em;
    height: 1em;
    animation: k-loading-animation 0.7s linear infinite;
}

.k-i-loading::after,
.k-loading-image::after {
    margin-top: -0.25em;
    margin-left: -0.25em;
    width: 0.5em;
    height: 0.5em;
    animation: k-loading-animation reverse 1.4s linear infinite;
}

.k-loading-image::before,
.k-loading-image::after {
    content: '';
    border-width: 1px;
    border-width: clamp(0.015em, 1px, 1px);
    font-size: 4em;
}

@keyframes k-loading-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.k-marquee {
    position: absolute;
    z-index: 100000;
}

.k-marquee-color,
.k-marquee-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.k-marquee-color {
    color: white;
    background-color: var(--kendo-primary-60, #4e1289);
    border-color: var(--kendo-primary-100, rgba(0, 0, 0, 0.08));
    opacity: 0.6;
}

.k-marquee-text {
    color: white;
}

:root {
    --kendo-elevation-1: 0 2px 3px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-2: 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-3: 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-4: 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-5: 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-6: 0 12px 14px rgba(0, 0, 0, 0.2), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-7: 0 14px 16px rgba(0, 0, 0, 0.24), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-8: 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12);
    --kendo-elevation-9: 0 32px 34px rgba(0, 0, 0, 0.32), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-ratio-auto {
    aspect-ratio: auto;
}

.\!k-ratio-auto {
    aspect-ratio: auto !important;
}

.k-ratio-1 {
    aspect-ratio: 1;
}

.\!k-ratio-1 {
    aspect-ratio: 1 !important;
}

.k-ratio-square {
    aspect-ratio: 1 / 1;
}

.\!k-ratio-square {
    aspect-ratio: 1 / 1 !important;
}

.k-ratio-video {
    aspect-ratio: 16 / 9;
}

.\!k-ratio-video {
    aspect-ratio: 16 / 9 !important;
}

.k-aspect-ratio-auto {
    aspect-ratio: auto;
}

.\!k-aspect-ratio-auto {
    aspect-ratio: auto !important;
}

.k-aspect-ratio-1 {
    aspect-ratio: 1;
}

.\!k-aspect-ratio-1 {
    aspect-ratio: 1 !important;
}

.k-aspect-ratio-square {
    aspect-ratio: 1 / 1;
}

.\!k-aspect-ratio-square {
    aspect-ratio: 1 / 1 !important;
}

.k-aspect-ratio-video {
    aspect-ratio: 16 / 9;
}

.\!k-aspect-ratio-video {
    aspect-ratio: 16 / 9 !important;
}

.k-box-sizing-border {
    box-sizing: border-box;
}

.\!k-box-sizing-border {
    box-sizing: border-box !important;
}

.k-box-sizing-content {
    box-sizing: content-box;
}

.\!k-box-sizing-content {
    box-sizing: content-box !important;
}

.k-clear-left {
    clear: left;
}

.\!k-clear-left {
    clear: left !important;
}

.k-clear-right {
    clear: right;
}

.\!k-clear-right {
    clear: right !important;
}

.k-clear-both {
    clear: both;
}

.\!k-clear-both {
    clear: both !important;
}

.k-clear-none {
    clear: none;
}

.\!k-clear-none {
    clear: none !important;
}

.k-columns-1 {
    columns: 1;
}

.\!k-columns-1 {
    columns: 1 !important;
}

.k-columns-2 {
    columns: 2;
}

.\!k-columns-2 {
    columns: 2 !important;
}

.k-columns-3 {
    columns: 3;
}

.\!k-columns-3 {
    columns: 3 !important;
}

.k-columns-4 {
    columns: 4;
}

.\!k-columns-4 {
    columns: 4 !important;
}

.k-columns-5 {
    columns: 5;
}

.\!k-columns-5 {
    columns: 5 !important;
}

.k-columns-6 {
    columns: 6;
}

.\!k-columns-6 {
    columns: 6 !important;
}

.k-columns-7 {
    columns: 7;
}

.\!k-columns-7 {
    columns: 7 !important;
}

.k-columns-8 {
    columns: 8;
}

.\!k-columns-8 {
    columns: 8 !important;
}

.k-columns-9 {
    columns: 9;
}

.\!k-columns-9 {
    columns: 9 !important;
}

.k-columns-10 {
    columns: 10;
}

.\!k-columns-10 {
    columns: 10 !important;
}

.k-columns-11 {
    columns: 11;
}

.\!k-columns-11 {
    columns: 11 !important;
}

.k-columns-12 {
    columns: 12;
}

.\!k-columns-12 {
    columns: 12 !important;
}

.k-columns-auto {
    columns: auto;
}

.\!k-columns-auto {
    columns: auto !important;
}

.k-d-none {
    display: none;
}

.\!k-d-none {
    display: none !important;
}

.k-d-contents {
    display: contents;
}

.\!k-d-contents {
    display: contents !important;
}

.k-d-block {
    display: block;
}

.\!k-d-block {
    display: block !important;
}

.k-d-inline {
    display: inline;
}

.\!k-d-inline {
    display: inline !important;
}

.k-d-inline-block {
    display: inline-block;
}

.\!k-d-inline-block {
    display: inline-block !important;
}

.k-d-flex,
.k-d-flex-col,
.k-d-flex-row {
    display: flex;
}

.\!k-d-flex,
.\!k-d-flex-col,
.\!k-d-flex-row {
    display: flex !important;
}

.k-d-inline-flex {
    display: inline-flex;
}

.\!k-d-inline-flex {
    display: inline-flex !important;
}

.k-d-grid {
    display: grid;
}

.\!k-d-grid {
    display: grid !important;
}

.k-d-inline-grid {
    display: inline-grid;
}

.\!k-d-inline-grid {
    display: inline-grid !important;
}

.k-d-table {
    display: table;
}

.\!k-d-table {
    display: table !important;
}

.k-d-inline-table {
    display: inline-table;
}

.\!k-d-inline-table {
    display: inline-table !important;
}

.k-d-table-row {
    display: table-row;
}

.\!k-d-table-row {
    display: table-row !important;
}

.k-d-table-cell {
    display: table-cell;
}

.\!k-d-table-cell {
    display: table-cell !important;
}

.k-d-list-item {
    display: list-item;
}

.\!k-d-list-item {
    display: list-item !important;
}

.k-display-none {
    display: none;
}

.\!k-display-none {
    display: none !important;
}

.k-display-contents {
    display: contents;
}

.\!k-display-contents {
    display: contents !important;
}

.k-display-block {
    display: block;
}

.\!k-display-block {
    display: block !important;
}

.k-display-inline {
    display: inline;
}

.\!k-display-inline {
    display: inline !important;
}

.k-display-inline-block {
    display: inline-block;
}

.\!k-display-inline-block {
    display: inline-block !important;
}

.k-display-flex {
    display: flex;
}

.\!k-display-flex {
    display: flex !important;
}

.k-display-inline-flex {
    display: inline-flex;
}

.\!k-display-inline-flex {
    display: inline-flex !important;
}

.k-display-grid {
    display: grid;
}

.\!k-display-grid {
    display: grid !important;
}

.k-display-inline-grid {
    display: inline-grid;
}

.\!k-display-inline-grid {
    display: inline-grid !important;
}

.k-display-table {
    display: table;
}

.\!k-display-table {
    display: table !important;
}

.k-display-inline-table {
    display: inline-table;
}

.\!k-display-inline-table {
    display: inline-table !important;
}

.k-display-table-row {
    display: table-row;
}

.\!k-display-table-row {
    display: table-row !important;
}

.k-display-table-cell {
    display: table-cell;
}

.\!k-display-table-cell {
    display: table-cell !important;
}

.k-display-list-item {
    display: list-item;
}

.\!k-display-list-item {
    display: list-item !important;
}

.k-float-left {
    float: left;
}

.\!k-float-left {
    float: left !important;
}

.k-float-right {
    float: right;
}

.\!k-float-right {
    float: right !important;
}

.k-float-none {
    float: none;
}

.\!k-float-none {
    float: none !important;
}

.k-object-fit-contain {
    object-fit: contain;
}

.\!k-object-fit-contain {
    object-fit: contain !important;
}

.k-object-fit-cover {
    object-fit: cover;
}

.\!k-object-fit-cover {
    object-fit: cover !important;
}

.k-object-fit-fill {
    object-fit: fill;
}

.\!k-object-fit-fill {
    object-fit: fill !important;
}

.k-object-fit-scale-down {
    object-fit: scale-down;
}

.\!k-object-fit-scale-down {
    object-fit: scale-down !important;
}

.k-object-fit-initial {
    object-fit: initial;
}

.\!k-object-fit-initial {
    object-fit: initial !important;
}

.k-object-fit-none {
    object-fit: none;
}

.\!k-object-fit-none {
    object-fit: none !important;
}

.k-object-position-center {
    object-position: center;
}

.\!k-object-position-center {
    object-position: center !important;
}

.k-object-position-top {
    object-position: top;
}

.\!k-object-position-top {
    object-position: top !important;
}

.k-object-position-right {
    object-position: right;
}

.\!k-object-position-right {
    object-position: right !important;
}

.k-object-position-bottom {
    object-position: bottom;
}

.\!k-object-position-bottom {
    object-position: bottom !important;
}

.k-object-position-left {
    object-position: left;
}

.\!k-object-position-left {
    object-position: left !important;
}

.k-object-position-top-left {
    object-position: top left;
}

.\!k-object-position-top-left {
    object-position: top left !important;
}

.k-object-position-top-right {
    object-position: top right;
}

.\!k-object-position-top-right {
    object-position: top right !important;
}

.k-object-position-bottom-left {
    object-position: bottom left;
}

.\!k-object-position-bottom-left {
    object-position: bottom left !important;
}

.k-object-position-bottom-right {
    object-position: bottom right;
}

.\!k-object-position-bottom-right {
    object-position: bottom right !important;
}

.k-overflow-auto {
    overflow: auto;
}

.\!k-overflow-auto {
    overflow: auto !important;
}

.k-overflow-hidden {
    overflow: hidden;
}

.\!k-overflow-hidden {
    overflow: hidden !important;
}

.k-overflow-visible {
    overflow: visible;
}

.\!k-overflow-visible {
    overflow: visible !important;
}

.k-overflow-scroll {
    overflow: scroll;
}

.\!k-overflow-scroll {
    overflow: scroll !important;
}

.k-overflow-clip {
    overflow: clip;
}

.\!k-overflow-clip {
    overflow: clip !important;
}

.k-overflow-x-auto {
    overflow-x: auto;
}

.\!k-overflow-x-auto {
    overflow-x: auto !important;
}

.k-overflow-x-hidden {
    overflow-x: hidden;
}

.\!k-overflow-x-hidden {
    overflow-x: hidden !important;
}

.k-overflow-x-visible {
    overflow-x: visible;
}

.\!k-overflow-x-visible {
    overflow-x: visible !important;
}

.k-overflow-x-scroll {
    overflow-x: scroll;
}

.\!k-overflow-x-scroll {
    overflow-x: scroll !important;
}

.k-overflow-x-clip {
    overflow-x: clip;
}

.\!k-overflow-x-clip {
    overflow-x: clip !important;
}

.k-overflow-y-auto {
    overflow-y: auto;
}

.\!k-overflow-y-auto {
    overflow-y: auto !important;
}

.k-overflow-y-hidden {
    overflow-y: hidden;
}

.\!k-overflow-y-hidden {
    overflow-y: hidden !important;
}

.k-overflow-y-visible {
    overflow-y: visible;
}

.\!k-overflow-y-visible {
    overflow-y: visible !important;
}

.k-overflow-y-scroll {
    overflow-y: scroll;
}

.\!k-overflow-y-scroll {
    overflow-y: scroll !important;
}

.k-overflow-y-clip {
    overflow-y: clip;
}

.\!k-overflow-y-clip {
    overflow-y: clip !important;
}

.k-top-0 {
    top: 0;
}

.\!k-top-0 {
    top: 0 !important;
}

.k-top-1px {
    top: 1px;
}

.\!k-top-1px {
    top: 1px !important;
}

.k-top-0\.5 {
    top: 2px;
}

.\!k-top-0\.5 {
    top: 2px !important;
}

.k-top-1 {
    top: 4px;
}

.\!k-top-1 {
    top: 4px !important;
}

.k-top-1\.5 {
    top: 6px;
}

.\!k-top-1\.5 {
    top: 6px !important;
}

.k-top-2 {
    top: 8px;
}

.\!k-top-2 {
    top: 8px !important;
}

.k-top-2\.5 {
    top: 10px;
}

.\!k-top-2\.5 {
    top: 10px !important;
}

.k-top-3 {
    top: 12px;
}

.\!k-top-3 {
    top: 12px !important;
}

.k-top-3\.5 {
    top: 14px;
}

.\!k-top-3\.5 {
    top: 14px !important;
}

.k-top-4 {
    top: 16px;
}

.\!k-top-4 {
    top: 16px !important;
}

.k-top-4\.5 {
    top: 18px;
}

.\!k-top-4\.5 {
    top: 18px !important;
}

.k-top-5 {
    top: 20px;
}

.\!k-top-5 {
    top: 20px !important;
}

.k-top-5\.5 {
    top: 22px;
}

.\!k-top-5\.5 {
    top: 22px !important;
}

.k-top-6 {
    top: 24px;
}

.\!k-top-6 {
    top: 24px !important;
}

.k-top-6\.5 {
    top: 26px;
}

.\!k-top-6\.5 {
    top: 26px !important;
}

.k-top-7 {
    top: 28px;
}

.\!k-top-7 {
    top: 28px !important;
}

.k-top-7\.5 {
    top: 30px;
}

.\!k-top-7\.5 {
    top: 30px !important;
}

.k-top-8 {
    top: 32px;
}

.\!k-top-8 {
    top: 32px !important;
}

.k-top-9 {
    top: 36px;
}

.\!k-top-9 {
    top: 36px !important;
}

.k-top-10 {
    top: 40px;
}

.\!k-top-10 {
    top: 40px !important;
}

.k-top-11 {
    top: 44px;
}

.\!k-top-11 {
    top: 44px !important;
}

.k-top-12 {
    top: 48px;
}

.\!k-top-12 {
    top: 48px !important;
}

.k-top-13 {
    top: 52px;
}

.\!k-top-13 {
    top: 52px !important;
}

.k-top-14 {
    top: 56px;
}

.\!k-top-14 {
    top: 56px !important;
}

.k-top-15 {
    top: 60px;
}

.\!k-top-15 {
    top: 60px !important;
}

.k-top-16 {
    top: 64px;
}

.\!k-top-16 {
    top: 64px !important;
}

.k-top-17 {
    top: 68px;
}

.\!k-top-17 {
    top: 68px !important;
}

.k-top-18 {
    top: 72px;
}

.\!k-top-18 {
    top: 72px !important;
}

.k-top-19 {
    top: 76px;
}

.\!k-top-19 {
    top: 76px !important;
}

.k-top-20 {
    top: 80px;
}

.\!k-top-20 {
    top: 80px !important;
}

.k-top-21 {
    top: 84px;
}

.\!k-top-21 {
    top: 84px !important;
}

.k-top-22 {
    top: 88px;
}

.\!k-top-22 {
    top: 88px !important;
}

.k-top-23 {
    top: 92px;
}

.\!k-top-23 {
    top: 92px !important;
}

.k-top-24 {
    top: 96px;
}

.\!k-top-24 {
    top: 96px !important;
}

.k-top--1 {
    top: -1px;
}

.\!k-top--1 {
    top: -1px !important;
}

.k-right-0 {
    right: 0;
}

.\!k-right-0 {
    right: 0 !important;
}

.k-right-1px {
    right: 1px;
}

.\!k-right-1px {
    right: 1px !important;
}

.k-right-0\.5 {
    right: 2px;
}

.\!k-right-0\.5 {
    right: 2px !important;
}

.k-right-1 {
    right: 4px;
}

.\!k-right-1 {
    right: 4px !important;
}

.k-right-1\.5 {
    right: 6px;
}

.\!k-right-1\.5 {
    right: 6px !important;
}

.k-right-2 {
    right: 8px;
}

.\!k-right-2 {
    right: 8px !important;
}

.k-right-2\.5 {
    right: 10px;
}

.\!k-right-2\.5 {
    right: 10px !important;
}

.k-right-3 {
    right: 12px;
}

.\!k-right-3 {
    right: 12px !important;
}

.k-right-3\.5 {
    right: 14px;
}

.\!k-right-3\.5 {
    right: 14px !important;
}

.k-right-4 {
    right: 16px;
}

.\!k-right-4 {
    right: 16px !important;
}

.k-right-4\.5 {
    right: 18px;
}

.\!k-right-4\.5 {
    right: 18px !important;
}

.k-right-5 {
    right: 20px;
}

.\!k-right-5 {
    right: 20px !important;
}

.k-right-5\.5 {
    right: 22px;
}

.\!k-right-5\.5 {
    right: 22px !important;
}

.k-right-6 {
    right: 24px;
}

.\!k-right-6 {
    right: 24px !important;
}

.k-right-6\.5 {
    right: 26px;
}

.\!k-right-6\.5 {
    right: 26px !important;
}

.k-right-7 {
    right: 28px;
}

.\!k-right-7 {
    right: 28px !important;
}

.k-right-7\.5 {
    right: 30px;
}

.\!k-right-7\.5 {
    right: 30px !important;
}

.k-right-8 {
    right: 32px;
}

.\!k-right-8 {
    right: 32px !important;
}

.k-right-9 {
    right: 36px;
}

.\!k-right-9 {
    right: 36px !important;
}

.k-right-10 {
    right: 40px;
}

.\!k-right-10 {
    right: 40px !important;
}

.k-right-11 {
    right: 44px;
}

.\!k-right-11 {
    right: 44px !important;
}

.k-right-12 {
    right: 48px;
}

.\!k-right-12 {
    right: 48px !important;
}

.k-right-13 {
    right: 52px;
}

.\!k-right-13 {
    right: 52px !important;
}

.k-right-14 {
    right: 56px;
}

.\!k-right-14 {
    right: 56px !important;
}

.k-right-15 {
    right: 60px;
}

.\!k-right-15 {
    right: 60px !important;
}

.k-right-16 {
    right: 64px;
}

.\!k-right-16 {
    right: 64px !important;
}

.k-right-17 {
    right: 68px;
}

.\!k-right-17 {
    right: 68px !important;
}

.k-right-18 {
    right: 72px;
}

.\!k-right-18 {
    right: 72px !important;
}

.k-right-19 {
    right: 76px;
}

.\!k-right-19 {
    right: 76px !important;
}

.k-right-20 {
    right: 80px;
}

.\!k-right-20 {
    right: 80px !important;
}

.k-right-21 {
    right: 84px;
}

.\!k-right-21 {
    right: 84px !important;
}

.k-right-22 {
    right: 88px;
}

.\!k-right-22 {
    right: 88px !important;
}

.k-right-23 {
    right: 92px;
}

.\!k-right-23 {
    right: 92px !important;
}

.k-right-24 {
    right: 96px;
}

.\!k-right-24 {
    right: 96px !important;
}

.k-right--1 {
    right: -1px;
}

.\!k-right--1 {
    right: -1px !important;
}

.k-bottom-0 {
    bottom: 0;
}

.\!k-bottom-0 {
    bottom: 0 !important;
}

.k-bottom-1px {
    bottom: 1px;
}

.\!k-bottom-1px {
    bottom: 1px !important;
}

.k-bottom-0\.5 {
    bottom: 2px;
}

.\!k-bottom-0\.5 {
    bottom: 2px !important;
}

.k-bottom-1 {
    bottom: 4px;
}

.\!k-bottom-1 {
    bottom: 4px !important;
}

.k-bottom-1\.5 {
    bottom: 6px;
}

.\!k-bottom-1\.5 {
    bottom: 6px !important;
}

.k-bottom-2 {
    bottom: 8px;
}

.\!k-bottom-2 {
    bottom: 8px !important;
}

.k-bottom-2\.5 {
    bottom: 10px;
}

.\!k-bottom-2\.5 {
    bottom: 10px !important;
}

.k-bottom-3 {
    bottom: 12px;
}

.\!k-bottom-3 {
    bottom: 12px !important;
}

.k-bottom-3\.5 {
    bottom: 14px;
}

.\!k-bottom-3\.5 {
    bottom: 14px !important;
}

.k-bottom-4 {
    bottom: 16px;
}

.\!k-bottom-4 {
    bottom: 16px !important;
}

.k-bottom-4\.5 {
    bottom: 18px;
}

.\!k-bottom-4\.5 {
    bottom: 18px !important;
}

.k-bottom-5 {
    bottom: 20px;
}

.\!k-bottom-5 {
    bottom: 20px !important;
}

.k-bottom-5\.5 {
    bottom: 22px;
}

.\!k-bottom-5\.5 {
    bottom: 22px !important;
}

.k-bottom-6 {
    bottom: 24px;
}

.\!k-bottom-6 {
    bottom: 24px !important;
}

.k-bottom-6\.5 {
    bottom: 26px;
}

.\!k-bottom-6\.5 {
    bottom: 26px !important;
}

.k-bottom-7 {
    bottom: 28px;
}

.\!k-bottom-7 {
    bottom: 28px !important;
}

.k-bottom-7\.5 {
    bottom: 30px;
}

.\!k-bottom-7\.5 {
    bottom: 30px !important;
}

.k-bottom-8 {
    bottom: 32px;
}

.\!k-bottom-8 {
    bottom: 32px !important;
}

.k-bottom-9 {
    bottom: 36px;
}

.\!k-bottom-9 {
    bottom: 36px !important;
}

.k-bottom-10 {
    bottom: 40px;
}

.\!k-bottom-10 {
    bottom: 40px !important;
}

.k-bottom-11 {
    bottom: 44px;
}

.\!k-bottom-11 {
    bottom: 44px !important;
}

.k-bottom-12 {
    bottom: 48px;
}

.\!k-bottom-12 {
    bottom: 48px !important;
}

.k-bottom-13 {
    bottom: 52px;
}

.\!k-bottom-13 {
    bottom: 52px !important;
}

.k-bottom-14 {
    bottom: 56px;
}

.\!k-bottom-14 {
    bottom: 56px !important;
}

.k-bottom-15 {
    bottom: 60px;
}

.\!k-bottom-15 {
    bottom: 60px !important;
}

.k-bottom-16 {
    bottom: 64px;
}

.\!k-bottom-16 {
    bottom: 64px !important;
}

.k-bottom-17 {
    bottom: 68px;
}

.\!k-bottom-17 {
    bottom: 68px !important;
}

.k-bottom-18 {
    bottom: 72px;
}

.\!k-bottom-18 {
    bottom: 72px !important;
}

.k-bottom-19 {
    bottom: 76px;
}

.\!k-bottom-19 {
    bottom: 76px !important;
}

.k-bottom-20 {
    bottom: 80px;
}

.\!k-bottom-20 {
    bottom: 80px !important;
}

.k-bottom-21 {
    bottom: 84px;
}

.\!k-bottom-21 {
    bottom: 84px !important;
}

.k-bottom-22 {
    bottom: 88px;
}

.\!k-bottom-22 {
    bottom: 88px !important;
}

.k-bottom-23 {
    bottom: 92px;
}

.\!k-bottom-23 {
    bottom: 92px !important;
}

.k-bottom-24 {
    bottom: 96px;
}

.\!k-bottom-24 {
    bottom: 96px !important;
}

.k-bottom--1 {
    bottom: -1px;
}

.\!k-bottom--1 {
    bottom: -1px !important;
}

.k-left-0 {
    left: 0;
}

.\!k-left-0 {
    left: 0 !important;
}

.k-left-1px {
    left: 1px;
}

.\!k-left-1px {
    left: 1px !important;
}

.k-left-0\.5 {
    left: 2px;
}

.\!k-left-0\.5 {
    left: 2px !important;
}

.k-left-1 {
    left: 4px;
}

.\!k-left-1 {
    left: 4px !important;
}

.k-left-1\.5 {
    left: 6px;
}

.\!k-left-1\.5 {
    left: 6px !important;
}

.k-left-2 {
    left: 8px;
}

.\!k-left-2 {
    left: 8px !important;
}

.k-left-2\.5 {
    left: 10px;
}

.\!k-left-2\.5 {
    left: 10px !important;
}

.k-left-3 {
    left: 12px;
}

.\!k-left-3 {
    left: 12px !important;
}

.k-left-3\.5 {
    left: 14px;
}

.\!k-left-3\.5 {
    left: 14px !important;
}

.k-left-4 {
    left: 16px;
}

.\!k-left-4 {
    left: 16px !important;
}

.k-left-4\.5 {
    left: 18px;
}

.\!k-left-4\.5 {
    left: 18px !important;
}

.k-left-5 {
    left: 20px;
}

.\!k-left-5 {
    left: 20px !important;
}

.k-left-5\.5 {
    left: 22px;
}

.\!k-left-5\.5 {
    left: 22px !important;
}

.k-left-6 {
    left: 24px;
}

.\!k-left-6 {
    left: 24px !important;
}

.k-left-6\.5 {
    left: 26px;
}

.\!k-left-6\.5 {
    left: 26px !important;
}

.k-left-7 {
    left: 28px;
}

.\!k-left-7 {
    left: 28px !important;
}

.k-left-7\.5 {
    left: 30px;
}

.\!k-left-7\.5 {
    left: 30px !important;
}

.k-left-8 {
    left: 32px;
}

.\!k-left-8 {
    left: 32px !important;
}

.k-left-9 {
    left: 36px;
}

.\!k-left-9 {
    left: 36px !important;
}

.k-left-10 {
    left: 40px;
}

.\!k-left-10 {
    left: 40px !important;
}

.k-left-11 {
    left: 44px;
}

.\!k-left-11 {
    left: 44px !important;
}

.k-left-12 {
    left: 48px;
}

.\!k-left-12 {
    left: 48px !important;
}

.k-left-13 {
    left: 52px;
}

.\!k-left-13 {
    left: 52px !important;
}

.k-left-14 {
    left: 56px;
}

.\!k-left-14 {
    left: 56px !important;
}

.k-left-15 {
    left: 60px;
}

.\!k-left-15 {
    left: 60px !important;
}

.k-left-16 {
    left: 64px;
}

.\!k-left-16 {
    left: 64px !important;
}

.k-left-17 {
    left: 68px;
}

.\!k-left-17 {
    left: 68px !important;
}

.k-left-18 {
    left: 72px;
}

.\!k-left-18 {
    left: 72px !important;
}

.k-left-19 {
    left: 76px;
}

.\!k-left-19 {
    left: 76px !important;
}

.k-left-20 {
    left: 80px;
}

.\!k-left-20 {
    left: 80px !important;
}

.k-left-21 {
    left: 84px;
}

.\!k-left-21 {
    left: 84px !important;
}

.k-left-22 {
    left: 88px;
}

.\!k-left-22 {
    left: 88px !important;
}

.k-left-23 {
    left: 92px;
}

.\!k-left-23 {
    left: 92px !important;
}

.k-left-24 {
    left: 96px;
}

.\!k-left-24 {
    left: 96px !important;
}

.k-left--1 {
    left: -1px;
}

.\!k-left--1 {
    left: -1px !important;
}

.k-top,
.k-pos-top {
    top: 0;
}

.k-right,
.k-pos-right {
    right: 0;
}

.k-bottom,
.k-pos-bottom {
    bottom: 0;
}

.k-left,
.k-pos-left {
    left: 0;
}

.k-inset-0 {
    inset: 0;
}

.\!k-inset-0 {
    inset: 0 !important;
}

.k-inset-1 {
    inset: 1px;
}

.\!k-inset-1 {
    inset: 1px !important;
}

.k-inset--1 {
    inset: -1px;
}

.\!k-inset--1 {
    inset: -1px !important;
}

.k-inset-x-0 {
    inset-inline: 0;
}

.\!k-inset-x-0 {
    inset-inline: 0 !important;
}

.k-inset-x-1 {
    inset-inline: 1px;
}

.\!k-inset-x-1 {
    inset-inline: 1px !important;
}

.k-inset-x--1 {
    inset-inline: -1px;
}

.\!k-inset-x--1 {
    inset-inline: -1px !important;
}

.k-inset-y-0 {
    inset-block: 0;
}

.\!k-inset-y-0 {
    inset-block: 0 !important;
}

.k-inset-y-1 {
    inset-block: 1px;
}

.\!k-inset-y-1 {
    inset-block: 1px !important;
}

.k-inset-y--1 {
    inset-block: -1px;
}

.\!k-inset-y--1 {
    inset-block: -1px !important;
}

.k-top-left-0 {
    top: 0;
    left: 0;
}

.\!k-top-left-0 {
    top: 0 !important;
    left: 0 !important;
}

.k-top-left-1 {
    top: 1px;
    left: 1px;
}

.\!k-top-left-1 {
    top: 1px !important;
    left: 1px !important;
}

.k-top-left--1 {
    top: -1px;
    left: -1px;
}

.\!k-top-left--1 {
    top: -1px !important;
    left: -1px !important;
}

.k-top-right-0 {
    top: 0;
    right: 0;
}

.\!k-top-right-0 {
    top: 0 !important;
    right: 0 !important;
}

.k-top-right-1 {
    top: 1px;
    right: 1px;
}

.\!k-top-right-1 {
    top: 1px !important;
    right: 1px !important;
}

.k-top-right--1 {
    top: -1px;
    right: -1px;
}

.\!k-top-right--1 {
    top: -1px !important;
    right: -1px !important;
}

.k-bottom-left-0 {
    bottom: 0;
    left: 0;
}

.\!k-bottom-left-0 {
    bottom: 0 !important;
    left: 0 !important;
}

.k-bottom-left-1 {
    bottom: 1px;
    left: 1px;
}

.\!k-bottom-left-1 {
    bottom: 1px !important;
    left: 1px !important;
}

.k-bottom-left--1 {
    bottom: -1px;
    left: -1px;
}

.\!k-bottom-left--1 {
    bottom: -1px !important;
    left: -1px !important;
}

.k-bottom-right-0 {
    bottom: 0;
    right: 0;
}

.\!k-bottom-right-0 {
    bottom: 0 !important;
    right: 0 !important;
}

.k-bottom-right-1 {
    bottom: 1px;
    right: 1px;
}

.\!k-bottom-right-1 {
    bottom: 1px !important;
    right: 1px !important;
}

.k-bottom-right--1 {
    bottom: -1px;
    right: -1px;
}

.\!k-bottom-right--1 {
    bottom: -1px !important;
    right: -1px !important;
}

.k-top-start,
.k-pos-top-start {
    top: 0;
    inset-inline-start: 0;
}

.k-top-end,
.k-pos-top-end {
    top: 0;
    inset-inline-end: 0;
}

.k-bottom-start,
.k-pos-bottom-start {
    bottom: 0;
    inset-inline-start: 0;
}

.k-bottom-end,
.k-pos-bottom-end {
    bottom: 0;
    inset-inline-end: 0;
}

.k-top-center,
.k-pos-top-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.k-middle-left,
.k-middle-start,
.k-pos-middle-start {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.k-middle-center {
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
}

.k-middle-right,
.k-middle-end,
.k-pos-middle-end {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.k-bottom-center,
.k-pos-bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.k-pos-static,
.k-static {
    position: static;
}

.\!k-pos-static,
.\!k-static {
    position: static !important;
}

.k-pos-relative,
.k-relative {
    position: relative;
}

.\!k-pos-relative,
.\!k-relative {
    position: relative !important;
}

.k-pos-absolute,
.k-absolute {
    position: absolute;
}

.\!k-pos-absolute,
.\!k-absolute {
    position: absolute !important;
}

.k-pos-fixed,
.k-fixed {
    position: fixed;
}

.\!k-pos-fixed,
.\!k-fixed {
    position: fixed !important;
}

.k-pos-sticky,
.k-sticky {
    position: sticky;
}

.\!k-pos-sticky,
.\!k-sticky {
    position: sticky !important;
}

.k-visibility-visible,
.k-visible {
    visibility: visible;
}

.\!k-visibility-visible,
.\!k-visible {
    visibility: visible !important;
}

.k-visibility-invisible {
    visibility: hidden;
}

.\!k-visibility-invisible {
    visibility: hidden !important;
}

.k-visibility-collapse {
    visibility: collapse;
}

.\!k-visibility-collapse {
    visibility: collapse !important;
}

.k-z-0 {
    z-index: 0;
}

.\!k-z-0 {
    z-index: 0 !important;
}

.k-z-10 {
    z-index: 10;
}

.\!k-z-10 {
    z-index: 10 !important;
}

.k-z-20 {
    z-index: 20;
}

.\!k-z-20 {
    z-index: 20 !important;
}

.k-z-30 {
    z-index: 30;
}

.\!k-z-30 {
    z-index: 30 !important;
}

.k-z-40 {
    z-index: 40;
}

.\!k-z-40 {
    z-index: 40 !important;
}

.k-z-50 {
    z-index: 50;
}

.\!k-z-50 {
    z-index: 50 !important;
}

.k-z-auto {
    z-index: auto;
}

.\!k-z-auto {
    z-index: auto !important;
}

.k-align-content-normal {
    align-content: normal;
}

.\!k-align-content-normal {
    align-content: normal !important;
}

.k-align-content-stretch {
    align-content: stretch;
}

.\!k-align-content-stretch {
    align-content: stretch !important;
}

.k-align-content-center {
    align-content: center;
}

.\!k-align-content-center {
    align-content: center !important;
}

.k-align-content-start {
    align-content: start;
}

.\!k-align-content-start {
    align-content: start !important;
}

.k-align-content-end {
    align-content: end;
}

.\!k-align-content-end {
    align-content: end !important;
}

.k-align-content-flex-start {
    align-content: flex-start;
}

.\!k-align-content-flex-start {
    align-content: flex-start !important;
}

.k-align-content-flex-end {
    align-content: flex-end;
}

.\!k-align-content-flex-end {
    align-content: flex-end !important;
}

.k-align-content-baseline {
    align-content: baseline;
}

.\!k-align-content-baseline {
    align-content: baseline !important;
}

.k-align-content-first-baseline {
    align-content: first baseline;
}

.\!k-align-content-first-baseline {
    align-content: first baseline !important;
}

.k-align-content-last-baseline {
    align-content: last baseline;
}

.\!k-align-content-last-baseline {
    align-content: last baseline !important;
}

.k-align-content-between {
    align-content: space-between;
}

.\!k-align-content-between {
    align-content: space-between !important;
}

.k-align-content-around {
    align-content: space-around;
}

.\!k-align-content-around {
    align-content: space-around !important;
}

.k-align-content-evenly {
    align-content: space-evenly;
}

.\!k-align-content-evenly {
    align-content: space-evenly !important;
}

.k-align-items-normal {
    align-items: normal;
}

.\!k-align-items-normal {
    align-items: normal !important;
}

.k-align-items-stretch {
    align-items: stretch;
}

.\!k-align-items-stretch {
    align-items: stretch !important;
}

.k-align-items-center {
    align-items: center;
}

.\!k-align-items-center {
    align-items: center !important;
}

.k-align-items-start {
    align-items: start;
}

.\!k-align-items-start {
    align-items: start !important;
}

.k-align-items-end {
    align-items: end;
}

.\!k-align-items-end {
    align-items: end !important;
}

.k-align-items-flex-start {
    align-items: flex-start;
}

.\!k-align-items-flex-start {
    align-items: flex-start !important;
}

.k-align-items-flex-end {
    align-items: flex-end;
}

.\!k-align-items-flex-end {
    align-items: flex-end !important;
}

.k-align-items-baseline {
    align-items: baseline;
}

.\!k-align-items-baseline {
    align-items: baseline !important;
}

.k-align-items-first-baseline {
    align-items: first baseline;
}

.\!k-align-items-first-baseline {
    align-items: first baseline !important;
}

.k-align-items-last-baseline {
    align-items: last baseline;
}

.\!k-align-items-last-baseline {
    align-items: last baseline !important;
}

.k-align-items-self-start {
    align-items: self-start;
}

.\!k-align-items-self-start {
    align-items: self-start !important;
}

.k-align-items-self-end {
    align-items: self-end;
}

.\!k-align-items-self-end {
    align-items: self-end !important;
}

.k-align-self-auto {
    align-self: auto;
}

.\!k-align-self-auto {
    align-self: auto !important;
}

.k-align-self-normal {
    align-self: normal;
}

.\!k-align-self-normal {
    align-self: normal !important;
}

.k-align-self-stretch {
    align-self: stretch;
}

.\!k-align-self-stretch {
    align-self: stretch !important;
}

.k-align-self-center {
    align-self: center;
}

.\!k-align-self-center {
    align-self: center !important;
}

.k-align-self-start {
    align-self: start;
}

.\!k-align-self-start {
    align-self: start !important;
}

.k-align-self-end {
    align-self: end;
}

.\!k-align-self-end {
    align-self: end !important;
}

.k-align-self-flex-start {
    align-self: flex-start;
}

.\!k-align-self-flex-start {
    align-self: flex-start !important;
}

.k-align-self-flex-end {
    align-self: flex-end;
}

.\!k-align-self-flex-end {
    align-self: flex-end !important;
}

.k-align-self-baseline {
    align-self: baseline;
}

.\!k-align-self-baseline {
    align-self: baseline !important;
}

.k-align-self-first-baseline {
    align-self: first baseline;
}

.\!k-align-self-first-baseline {
    align-self: first baseline !important;
}

.k-align-self-last-baseline {
    align-self: last baseline;
}

.\!k-align-self-last-baseline {
    align-self: last baseline !important;
}

.k-align-self-self-start {
    align-self: self-start;
}

.\!k-align-self-self-start {
    align-self: self-start !important;
}

.k-align-self-self-end {
    align-self: self-end;
}

.\!k-align-self-self-end {
    align-self: self-end !important;
}

.k-basis-0 {
    flex-basis: 0;
}

.\!k-basis-0 {
    flex-basis: 0 !important;
}

.k-basis-1px {
    flex-basis: 1px;
}

.\!k-basis-1px {
    flex-basis: 1px !important;
}

.k-basis-0\.5 {
    flex-basis: 2px;
}

.\!k-basis-0\.5 {
    flex-basis: 2px !important;
}

.k-basis-1 {
    flex-basis: 4px;
}

.\!k-basis-1 {
    flex-basis: 4px !important;
}

.k-basis-1\.5 {
    flex-basis: 6px;
}

.\!k-basis-1\.5 {
    flex-basis: 6px !important;
}

.k-basis-2 {
    flex-basis: 8px;
}

.\!k-basis-2 {
    flex-basis: 8px !important;
}

.k-basis-2\.5 {
    flex-basis: 10px;
}

.\!k-basis-2\.5 {
    flex-basis: 10px !important;
}

.k-basis-3 {
    flex-basis: 12px;
}

.\!k-basis-3 {
    flex-basis: 12px !important;
}

.k-basis-3\.5 {
    flex-basis: 14px;
}

.\!k-basis-3\.5 {
    flex-basis: 14px !important;
}

.k-basis-4 {
    flex-basis: 16px;
}

.\!k-basis-4 {
    flex-basis: 16px !important;
}

.k-basis-4\.5 {
    flex-basis: 18px;
}

.\!k-basis-4\.5 {
    flex-basis: 18px !important;
}

.k-basis-5 {
    flex-basis: 20px;
}

.\!k-basis-5 {
    flex-basis: 20px !important;
}

.k-basis-5\.5 {
    flex-basis: 22px;
}

.\!k-basis-5\.5 {
    flex-basis: 22px !important;
}

.k-basis-6 {
    flex-basis: 24px;
}

.\!k-basis-6 {
    flex-basis: 24px !important;
}

.k-basis-6\.5 {
    flex-basis: 26px;
}

.\!k-basis-6\.5 {
    flex-basis: 26px !important;
}

.k-basis-7 {
    flex-basis: 28px;
}

.\!k-basis-7 {
    flex-basis: 28px !important;
}

.k-basis-7\.5 {
    flex-basis: 30px;
}

.\!k-basis-7\.5 {
    flex-basis: 30px !important;
}

.k-basis-8 {
    flex-basis: 32px;
}

.\!k-basis-8 {
    flex-basis: 32px !important;
}

.k-basis-9 {
    flex-basis: 36px;
}

.\!k-basis-9 {
    flex-basis: 36px !important;
}

.k-basis-10 {
    flex-basis: 40px;
}

.\!k-basis-10 {
    flex-basis: 40px !important;
}

.k-basis-11 {
    flex-basis: 44px;
}

.\!k-basis-11 {
    flex-basis: 44px !important;
}

.k-basis-12 {
    flex-basis: 48px;
}

.\!k-basis-12 {
    flex-basis: 48px !important;
}

.k-basis-13 {
    flex-basis: 52px;
}

.\!k-basis-13 {
    flex-basis: 52px !important;
}

.k-basis-14 {
    flex-basis: 56px;
}

.\!k-basis-14 {
    flex-basis: 56px !important;
}

.k-basis-15 {
    flex-basis: 60px;
}

.\!k-basis-15 {
    flex-basis: 60px !important;
}

.k-basis-16 {
    flex-basis: 64px;
}

.\!k-basis-16 {
    flex-basis: 64px !important;
}

.k-basis-17 {
    flex-basis: 68px;
}

.\!k-basis-17 {
    flex-basis: 68px !important;
}

.k-basis-18 {
    flex-basis: 72px;
}

.\!k-basis-18 {
    flex-basis: 72px !important;
}

.k-basis-19 {
    flex-basis: 76px;
}

.\!k-basis-19 {
    flex-basis: 76px !important;
}

.k-basis-20 {
    flex-basis: 80px;
}

.\!k-basis-20 {
    flex-basis: 80px !important;
}

.k-basis-21 {
    flex-basis: 84px;
}

.\!k-basis-21 {
    flex-basis: 84px !important;
}

.k-basis-22 {
    flex-basis: 88px;
}

.\!k-basis-22 {
    flex-basis: 88px !important;
}

.k-basis-23 {
    flex-basis: 92px;
}

.\!k-basis-23 {
    flex-basis: 92px !important;
}

.k-basis-24 {
    flex-basis: 96px;
}

.\!k-basis-24 {
    flex-basis: 96px !important;
}

.k-basis-auto {
    flex-basis: auto;
}

.\!k-basis-auto {
    flex-basis: auto !important;
}

.k-basis-1\/2 {
    flex-basis: 50%;
}

.\!k-basis-1\/2 {
    flex-basis: 50% !important;
}

.k-basis-1\/3 {
    flex-basis: 33.333333%;
}

.\!k-basis-1\/3 {
    flex-basis: 33.333333% !important;
}

.k-basis-2\/3 {
    flex-basis: 66.666667%;
}

.\!k-basis-2\/3 {
    flex-basis: 66.666667% !important;
}

.k-basis-1\/4 {
    flex-basis: 25%;
}

.\!k-basis-1\/4 {
    flex-basis: 25% !important;
}

.k-basis-2\/4 {
    flex-basis: 50%;
}

.\!k-basis-2\/4 {
    flex-basis: 50% !important;
}

.k-basis-3\/4 {
    flex-basis: 75%;
}

.\!k-basis-3\/4 {
    flex-basis: 75% !important;
}

.k-basis-1\/5 {
    flex-basis: 20%;
}

.\!k-basis-1\/5 {
    flex-basis: 20% !important;
}

.k-basis-2\/5 {
    flex-basis: 40%;
}

.\!k-basis-2\/5 {
    flex-basis: 40% !important;
}

.k-basis-3\/5 {
    flex-basis: 60%;
}

.\!k-basis-3\/5 {
    flex-basis: 60% !important;
}

.k-basis-4\/5 {
    flex-basis: 80%;
}

.\!k-basis-4\/5 {
    flex-basis: 80% !important;
}

.k-basis-1\/6 {
    flex-basis: 16.666667%;
}

.\!k-basis-1\/6 {
    flex-basis: 16.666667% !important;
}

.k-basis-2\/6 {
    flex-basis: 33.333333%;
}

.\!k-basis-2\/6 {
    flex-basis: 33.333333% !important;
}

.k-basis-3\/6 {
    flex-basis: 50%;
}

.\!k-basis-3\/6 {
    flex-basis: 50% !important;
}

.k-basis-4\/6 {
    flex-basis: 66.666667%;
}

.\!k-basis-4\/6 {
    flex-basis: 66.666667% !important;
}

.k-basis-5\/6 {
    flex-basis: 83.333333%;
}

.\!k-basis-5\/6 {
    flex-basis: 83.333333% !important;
}

.k-basis-1\/12 {
    flex-basis: 8.333333%;
}

.\!k-basis-1\/12 {
    flex-basis: 8.333333% !important;
}

.k-basis-2\/12 {
    flex-basis: 16.666667%;
}

.\!k-basis-2\/12 {
    flex-basis: 16.666667% !important;
}

.k-basis-3\/12 {
    flex-basis: 25%;
}

.\!k-basis-3\/12 {
    flex-basis: 25% !important;
}

.k-basis-4\/12 {
    flex-basis: 33.333333%;
}

.\!k-basis-4\/12 {
    flex-basis: 33.333333% !important;
}

.k-basis-5\/12 {
    flex-basis: 41.666667%;
}

.\!k-basis-5\/12 {
    flex-basis: 41.666667% !important;
}

.k-basis-6\/12 {
    flex-basis: 50%;
}

.\!k-basis-6\/12 {
    flex-basis: 50% !important;
}

.k-basis-7\/12 {
    flex-basis: 58.333333%;
}

.\!k-basis-7\/12 {
    flex-basis: 58.333333% !important;
}

.k-basis-8\/12 {
    flex-basis: 66.666667%;
}

.\!k-basis-8\/12 {
    flex-basis: 66.666667% !important;
}

.k-basis-9\/12 {
    flex-basis: 75%;
}

.\!k-basis-9\/12 {
    flex-basis: 75% !important;
}

.k-basis-10\/12 {
    flex-basis: 83.333333%;
}

.\!k-basis-10\/12 {
    flex-basis: 83.333333% !important;
}

.k-basis-11\/12 {
    flex-basis: 91.666667%;
}

.\!k-basis-11\/12 {
    flex-basis: 91.666667% !important;
}

.k-basis-full {
    flex-basis: 100%;
}

.\!k-basis-full {
    flex-basis: 100% !important;
}

.k-basis-min {
    flex-basis: min-content;
}

.\!k-basis-min {
    flex-basis: min-content !important;
}

.k-basis-max {
    flex-basis: max-content;
}

.\!k-basis-max {
    flex-basis: max-content !important;
}

.k-basis-fit {
    flex-basis: fit-content;
}

.\!k-basis-fit {
    flex-basis: fit-content !important;
}

.k-flex-basis-0 {
    flex-basis: 0;
}

.\!k-flex-basis-0 {
    flex-basis: 0 !important;
}

.k-flex-basis-1px {
    flex-basis: 1px;
}

.\!k-flex-basis-1px {
    flex-basis: 1px !important;
}

.k-flex-basis-0\.5 {
    flex-basis: 2px;
}

.\!k-flex-basis-0\.5 {
    flex-basis: 2px !important;
}

.k-flex-basis-1 {
    flex-basis: 4px;
}

.\!k-flex-basis-1 {
    flex-basis: 4px !important;
}

.k-flex-basis-1\.5 {
    flex-basis: 6px;
}

.\!k-flex-basis-1\.5 {
    flex-basis: 6px !important;
}

.k-flex-basis-2 {
    flex-basis: 8px;
}

.\!k-flex-basis-2 {
    flex-basis: 8px !important;
}

.k-flex-basis-2\.5 {
    flex-basis: 10px;
}

.\!k-flex-basis-2\.5 {
    flex-basis: 10px !important;
}

.k-flex-basis-3 {
    flex-basis: 12px;
}

.\!k-flex-basis-3 {
    flex-basis: 12px !important;
}

.k-flex-basis-3\.5 {
    flex-basis: 14px;
}

.\!k-flex-basis-3\.5 {
    flex-basis: 14px !important;
}

.k-flex-basis-4 {
    flex-basis: 16px;
}

.\!k-flex-basis-4 {
    flex-basis: 16px !important;
}

.k-flex-basis-4\.5 {
    flex-basis: 18px;
}

.\!k-flex-basis-4\.5 {
    flex-basis: 18px !important;
}

.k-flex-basis-5 {
    flex-basis: 20px;
}

.\!k-flex-basis-5 {
    flex-basis: 20px !important;
}

.k-flex-basis-5\.5 {
    flex-basis: 22px;
}

.\!k-flex-basis-5\.5 {
    flex-basis: 22px !important;
}

.k-flex-basis-6 {
    flex-basis: 24px;
}

.\!k-flex-basis-6 {
    flex-basis: 24px !important;
}

.k-flex-basis-6\.5 {
    flex-basis: 26px;
}

.\!k-flex-basis-6\.5 {
    flex-basis: 26px !important;
}

.k-flex-basis-7 {
    flex-basis: 28px;
}

.\!k-flex-basis-7 {
    flex-basis: 28px !important;
}

.k-flex-basis-7\.5 {
    flex-basis: 30px;
}

.\!k-flex-basis-7\.5 {
    flex-basis: 30px !important;
}

.k-flex-basis-8 {
    flex-basis: 32px;
}

.\!k-flex-basis-8 {
    flex-basis: 32px !important;
}

.k-flex-basis-9 {
    flex-basis: 36px;
}

.\!k-flex-basis-9 {
    flex-basis: 36px !important;
}

.k-flex-basis-10 {
    flex-basis: 40px;
}

.\!k-flex-basis-10 {
    flex-basis: 40px !important;
}

.k-flex-basis-11 {
    flex-basis: 44px;
}

.\!k-flex-basis-11 {
    flex-basis: 44px !important;
}

.k-flex-basis-12 {
    flex-basis: 48px;
}

.\!k-flex-basis-12 {
    flex-basis: 48px !important;
}

.k-flex-basis-13 {
    flex-basis: 52px;
}

.\!k-flex-basis-13 {
    flex-basis: 52px !important;
}

.k-flex-basis-14 {
    flex-basis: 56px;
}

.\!k-flex-basis-14 {
    flex-basis: 56px !important;
}

.k-flex-basis-15 {
    flex-basis: 60px;
}

.\!k-flex-basis-15 {
    flex-basis: 60px !important;
}

.k-flex-basis-16 {
    flex-basis: 64px;
}

.\!k-flex-basis-16 {
    flex-basis: 64px !important;
}

.k-flex-basis-17 {
    flex-basis: 68px;
}

.\!k-flex-basis-17 {
    flex-basis: 68px !important;
}

.k-flex-basis-18 {
    flex-basis: 72px;
}

.\!k-flex-basis-18 {
    flex-basis: 72px !important;
}

.k-flex-basis-19 {
    flex-basis: 76px;
}

.\!k-flex-basis-19 {
    flex-basis: 76px !important;
}

.k-flex-basis-20 {
    flex-basis: 80px;
}

.\!k-flex-basis-20 {
    flex-basis: 80px !important;
}

.k-flex-basis-21 {
    flex-basis: 84px;
}

.\!k-flex-basis-21 {
    flex-basis: 84px !important;
}

.k-flex-basis-22 {
    flex-basis: 88px;
}

.\!k-flex-basis-22 {
    flex-basis: 88px !important;
}

.k-flex-basis-23 {
    flex-basis: 92px;
}

.\!k-flex-basis-23 {
    flex-basis: 92px !important;
}

.k-flex-basis-24 {
    flex-basis: 96px;
}

.\!k-flex-basis-24 {
    flex-basis: 96px !important;
}

.k-flex-basis-auto {
    flex-basis: auto;
}

.\!k-flex-basis-auto {
    flex-basis: auto !important;
}

.k-flex-basis-1\/2 {
    flex-basis: 50%;
}

.\!k-flex-basis-1\/2 {
    flex-basis: 50% !important;
}

.k-flex-basis-1\/3 {
    flex-basis: 33.333333%;
}

.\!k-flex-basis-1\/3 {
    flex-basis: 33.333333% !important;
}

.k-flex-basis-2\/3 {
    flex-basis: 66.666667%;
}

.\!k-flex-basis-2\/3 {
    flex-basis: 66.666667% !important;
}

.k-flex-basis-1\/4 {
    flex-basis: 25%;
}

.\!k-flex-basis-1\/4 {
    flex-basis: 25% !important;
}

.k-flex-basis-2\/4 {
    flex-basis: 50%;
}

.\!k-flex-basis-2\/4 {
    flex-basis: 50% !important;
}

.k-flex-basis-3\/4 {
    flex-basis: 75%;
}

.\!k-flex-basis-3\/4 {
    flex-basis: 75% !important;
}

.k-flex-basis-1\/5 {
    flex-basis: 20%;
}

.\!k-flex-basis-1\/5 {
    flex-basis: 20% !important;
}

.k-flex-basis-2\/5 {
    flex-basis: 40%;
}

.\!k-flex-basis-2\/5 {
    flex-basis: 40% !important;
}

.k-flex-basis-3\/5 {
    flex-basis: 60%;
}

.\!k-flex-basis-3\/5 {
    flex-basis: 60% !important;
}

.k-flex-basis-4\/5 {
    flex-basis: 80%;
}

.\!k-flex-basis-4\/5 {
    flex-basis: 80% !important;
}

.k-flex-basis-1\/6 {
    flex-basis: 16.666667%;
}

.\!k-flex-basis-1\/6 {
    flex-basis: 16.666667% !important;
}

.k-flex-basis-2\/6 {
    flex-basis: 33.333333%;
}

.\!k-flex-basis-2\/6 {
    flex-basis: 33.333333% !important;
}

.k-flex-basis-3\/6 {
    flex-basis: 50%;
}

.\!k-flex-basis-3\/6 {
    flex-basis: 50% !important;
}

.k-flex-basis-4\/6 {
    flex-basis: 66.666667%;
}

.\!k-flex-basis-4\/6 {
    flex-basis: 66.666667% !important;
}

.k-flex-basis-5\/6 {
    flex-basis: 83.333333%;
}

.\!k-flex-basis-5\/6 {
    flex-basis: 83.333333% !important;
}

.k-flex-basis-1\/12 {
    flex-basis: 8.333333%;
}

.\!k-flex-basis-1\/12 {
    flex-basis: 8.333333% !important;
}

.k-flex-basis-2\/12 {
    flex-basis: 16.666667%;
}

.\!k-flex-basis-2\/12 {
    flex-basis: 16.666667% !important;
}

.k-flex-basis-3\/12 {
    flex-basis: 25%;
}

.\!k-flex-basis-3\/12 {
    flex-basis: 25% !important;
}

.k-flex-basis-4\/12 {
    flex-basis: 33.333333%;
}

.\!k-flex-basis-4\/12 {
    flex-basis: 33.333333% !important;
}

.k-flex-basis-5\/12 {
    flex-basis: 41.666667%;
}

.\!k-flex-basis-5\/12 {
    flex-basis: 41.666667% !important;
}

.k-flex-basis-6\/12 {
    flex-basis: 50%;
}

.\!k-flex-basis-6\/12 {
    flex-basis: 50% !important;
}

.k-flex-basis-7\/12 {
    flex-basis: 58.333333%;
}

.\!k-flex-basis-7\/12 {
    flex-basis: 58.333333% !important;
}

.k-flex-basis-8\/12 {
    flex-basis: 66.666667%;
}

.\!k-flex-basis-8\/12 {
    flex-basis: 66.666667% !important;
}

.k-flex-basis-9\/12 {
    flex-basis: 75%;
}

.\!k-flex-basis-9\/12 {
    flex-basis: 75% !important;
}

.k-flex-basis-10\/12 {
    flex-basis: 83.333333%;
}

.\!k-flex-basis-10\/12 {
    flex-basis: 83.333333% !important;
}

.k-flex-basis-11\/12 {
    flex-basis: 91.666667%;
}

.\!k-flex-basis-11\/12 {
    flex-basis: 91.666667% !important;
}

.k-flex-basis-full {
    flex-basis: 100%;
}

.\!k-flex-basis-full {
    flex-basis: 100% !important;
}

.k-flex-basis-min {
    flex-basis: min-content;
}

.\!k-flex-basis-min {
    flex-basis: min-content !important;
}

.k-flex-basis-max {
    flex-basis: max-content;
}

.\!k-flex-basis-max {
    flex-basis: max-content !important;
}

.k-flex-basis-fit {
    flex-basis: fit-content;
}

.\!k-flex-basis-fit {
    flex-basis: fit-content !important;
}

.k-flex-row,
.k-d-flex-row {
    flex-direction: row;
}

.\!k-flex-row,
.\!k-d-flex-row,
.\!k-d-flex-col {
    flex-direction: row !important;
}

.k-flex-row-reverse {
    flex-direction: row-reverse;
}

.\!k-flex-row-reverse {
    flex-direction: row-reverse !important;
}

.k-flex-col,
.k-flex-column,
.k-d-flex-col {
    flex-direction: column;
}

.\!k-flex-col {
    flex-direction: column !important;
}

.k-flex-col-reverse,
.k-flex-column-reverse {
    flex-direction: column-reverse;
}

.\!k-flex-col-reverse {
    flex-direction: column-reverse !important;
}

.k-grow {
    flex-grow: 1;
}

.\!k-grow {
    flex-grow: 1 !important;
}

.k-grow-0 {
    flex-grow: 0;
}

.\!k-grow-0 {
    flex-grow: 0 !important;
}

.k-flex-grow {
    flex-grow: 1;
}

.\!k-flex-grow {
    flex-grow: 1 !important;
}

.k-flex-grow-0 {
    flex-grow: 0;
}

.\!k-flex-grow-0 {
    flex-grow: 0 !important;
}

.k-shrink {
    flex-shrink: 1;
}

.\!k-shrink {
    flex-shrink: 1 !important;
}

.k-shrink-0 {
    flex-shrink: 0;
}

.\!k-shrink-0 {
    flex-shrink: 0 !important;
}

.k-flex-shrink {
    flex-shrink: 1;
}

.\!k-flex-shrink {
    flex-shrink: 1 !important;
}

.k-flex-shrink-0 {
    flex-shrink: 0;
}

.\!k-flex-shrink-0 {
    flex-shrink: 0 !important;
}

.k-flex-wrap {
    flex-wrap: wrap;
}

.\!k-flex-wrap {
    flex-wrap: wrap !important;
}

.k-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.\!k-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.k-flex-nowrap {
    flex-wrap: nowrap;
}

.\!k-flex-nowrap {
    flex-wrap: nowrap !important;
}

.k-flex-1 {
    flex: 1 1 0%;
}

.\!k-flex-1 {
    flex: 1 1 0% !important;
}

.k-flex-auto {
    flex: 1 1 auto;
}

.\!k-flex-auto {
    flex: 1 1 auto !important;
}

.k-flex-initial {
    flex: 0 1 auto;
}

.\!k-flex-initial {
    flex: 0 1 auto !important;
}

.k-flex-none {
    flex: none;
}

.\!k-flex-none {
    flex: none !important;
}

.k-gap-0 {
    gap: 0;
}

.\!k-gap-0 {
    gap: 0 !important;
}

.k-gap-1px {
    gap: 1px;
}

.\!k-gap-1px {
    gap: 1px !important;
}

.k-gap-0\.5 {
    gap: 2px;
}

.\!k-gap-0\.5 {
    gap: 2px !important;
}

.k-gap-1 {
    gap: 4px;
}

.\!k-gap-1 {
    gap: 4px !important;
}

.k-gap-1\.5 {
    gap: 6px;
}

.\!k-gap-1\.5 {
    gap: 6px !important;
}

.k-gap-2 {
    gap: 8px;
}

.\!k-gap-2 {
    gap: 8px !important;
}

.k-gap-2\.5 {
    gap: 10px;
}

.\!k-gap-2\.5 {
    gap: 10px !important;
}

.k-gap-3 {
    gap: 12px;
}

.\!k-gap-3 {
    gap: 12px !important;
}

.k-gap-3\.5 {
    gap: 14px;
}

.\!k-gap-3\.5 {
    gap: 14px !important;
}

.k-gap-4 {
    gap: 16px;
}

.\!k-gap-4 {
    gap: 16px !important;
}

.k-gap-4\.5 {
    gap: 18px;
}

.\!k-gap-4\.5 {
    gap: 18px !important;
}

.k-gap-5 {
    gap: 20px;
}

.\!k-gap-5 {
    gap: 20px !important;
}

.k-gap-5\.5 {
    gap: 22px;
}

.\!k-gap-5\.5 {
    gap: 22px !important;
}

.k-gap-6 {
    gap: 24px;
}

.\!k-gap-6 {
    gap: 24px !important;
}

.k-gap-6\.5 {
    gap: 26px;
}

.\!k-gap-6\.5 {
    gap: 26px !important;
}

.k-gap-7 {
    gap: 28px;
}

.\!k-gap-7 {
    gap: 28px !important;
}

.k-gap-7\.5 {
    gap: 30px;
}

.\!k-gap-7\.5 {
    gap: 30px !important;
}

.k-gap-8 {
    gap: 32px;
}

.\!k-gap-8 {
    gap: 32px !important;
}

.k-gap-9 {
    gap: 36px;
}

.\!k-gap-9 {
    gap: 36px !important;
}

.k-gap-10 {
    gap: 40px;
}

.\!k-gap-10 {
    gap: 40px !important;
}

.k-gap-11 {
    gap: 44px;
}

.\!k-gap-11 {
    gap: 44px !important;
}

.k-gap-12 {
    gap: 48px;
}

.\!k-gap-12 {
    gap: 48px !important;
}

.k-gap-13 {
    gap: 52px;
}

.\!k-gap-13 {
    gap: 52px !important;
}

.k-gap-14 {
    gap: 56px;
}

.\!k-gap-14 {
    gap: 56px !important;
}

.k-gap-15 {
    gap: 60px;
}

.\!k-gap-15 {
    gap: 60px !important;
}

.k-gap-16 {
    gap: 64px;
}

.\!k-gap-16 {
    gap: 64px !important;
}

.k-gap-17 {
    gap: 68px;
}

.\!k-gap-17 {
    gap: 68px !important;
}

.k-gap-18 {
    gap: 72px;
}

.\!k-gap-18 {
    gap: 72px !important;
}

.k-gap-19 {
    gap: 76px;
}

.\!k-gap-19 {
    gap: 76px !important;
}

.k-gap-20 {
    gap: 80px;
}

.\!k-gap-20 {
    gap: 80px !important;
}

.k-gap-21 {
    gap: 84px;
}

.\!k-gap-21 {
    gap: 84px !important;
}

.k-gap-22 {
    gap: 88px;
}

.\!k-gap-22 {
    gap: 88px !important;
}

.k-gap-23 {
    gap: 92px;
}

.\!k-gap-23 {
    gap: 92px !important;
}

.k-gap-24 {
    gap: 96px;
}

.\!k-gap-24 {
    gap: 96px !important;
}

.k-gap-xs {
    gap: 4px;
}

.\!k-gap-xs {
    gap: 4px !important;
}

.k-gap-sm {
    gap: 8px;
}

.\!k-gap-sm {
    gap: 8px !important;
}

.k-gap-md {
    gap: 12px;
}

.\!k-gap-md {
    gap: 12px !important;
}

.k-gap-lg {
    gap: 16px;
}

.\!k-gap-lg {
    gap: 16px !important;
}

.k-gap-xl {
    gap: 24px;
}

.\!k-gap-xl {
    gap: 24px !important;
}

.k-gap-thin {
    gap: 2px;
}

.\!k-gap-thin {
    gap: 2px !important;
}

.k-gap-hair {
    gap: 1px;
}

.\!k-gap-hair {
    gap: 1px !important;
}

.k-gap-x-0 {
    column-gap: 0;
}

.\!k-gap-x-0 {
    column-gap: 0 !important;
}

.k-gap-x-1px {
    column-gap: 1px;
}

.\!k-gap-x-1px {
    column-gap: 1px !important;
}

.k-gap-x-0\.5 {
    column-gap: 2px;
}

.\!k-gap-x-0\.5 {
    column-gap: 2px !important;
}

.k-gap-x-1 {
    column-gap: 4px;
}

.\!k-gap-x-1 {
    column-gap: 4px !important;
}

.k-gap-x-1\.5 {
    column-gap: 6px;
}

.\!k-gap-x-1\.5 {
    column-gap: 6px !important;
}

.k-gap-x-2 {
    column-gap: 8px;
}

.\!k-gap-x-2 {
    column-gap: 8px !important;
}

.k-gap-x-2\.5 {
    column-gap: 10px;
}

.\!k-gap-x-2\.5 {
    column-gap: 10px !important;
}

.k-gap-x-3 {
    column-gap: 12px;
}

.\!k-gap-x-3 {
    column-gap: 12px !important;
}

.k-gap-x-3\.5 {
    column-gap: 14px;
}

.\!k-gap-x-3\.5 {
    column-gap: 14px !important;
}

.k-gap-x-4 {
    column-gap: 16px;
}

.\!k-gap-x-4 {
    column-gap: 16px !important;
}

.k-gap-x-4\.5 {
    column-gap: 18px;
}

.\!k-gap-x-4\.5 {
    column-gap: 18px !important;
}

.k-gap-x-5 {
    column-gap: 20px;
}

.\!k-gap-x-5 {
    column-gap: 20px !important;
}

.k-gap-x-5\.5 {
    column-gap: 22px;
}

.\!k-gap-x-5\.5 {
    column-gap: 22px !important;
}

.k-gap-x-6 {
    column-gap: 24px;
}

.\!k-gap-x-6 {
    column-gap: 24px !important;
}

.k-gap-x-6\.5 {
    column-gap: 26px;
}

.\!k-gap-x-6\.5 {
    column-gap: 26px !important;
}

.k-gap-x-7 {
    column-gap: 28px;
}

.\!k-gap-x-7 {
    column-gap: 28px !important;
}

.k-gap-x-7\.5 {
    column-gap: 30px;
}

.\!k-gap-x-7\.5 {
    column-gap: 30px !important;
}

.k-gap-x-8 {
    column-gap: 32px;
}

.\!k-gap-x-8 {
    column-gap: 32px !important;
}

.k-gap-x-9 {
    column-gap: 36px;
}

.\!k-gap-x-9 {
    column-gap: 36px !important;
}

.k-gap-x-10 {
    column-gap: 40px;
}

.\!k-gap-x-10 {
    column-gap: 40px !important;
}

.k-gap-x-11 {
    column-gap: 44px;
}

.\!k-gap-x-11 {
    column-gap: 44px !important;
}

.k-gap-x-12 {
    column-gap: 48px;
}

.\!k-gap-x-12 {
    column-gap: 48px !important;
}

.k-gap-x-13 {
    column-gap: 52px;
}

.\!k-gap-x-13 {
    column-gap: 52px !important;
}

.k-gap-x-14 {
    column-gap: 56px;
}

.\!k-gap-x-14 {
    column-gap: 56px !important;
}

.k-gap-x-15 {
    column-gap: 60px;
}

.\!k-gap-x-15 {
    column-gap: 60px !important;
}

.k-gap-x-16 {
    column-gap: 64px;
}

.\!k-gap-x-16 {
    column-gap: 64px !important;
}

.k-gap-x-17 {
    column-gap: 68px;
}

.\!k-gap-x-17 {
    column-gap: 68px !important;
}

.k-gap-x-18 {
    column-gap: 72px;
}

.\!k-gap-x-18 {
    column-gap: 72px !important;
}

.k-gap-x-19 {
    column-gap: 76px;
}

.\!k-gap-x-19 {
    column-gap: 76px !important;
}

.k-gap-x-20 {
    column-gap: 80px;
}

.\!k-gap-x-20 {
    column-gap: 80px !important;
}

.k-gap-x-21 {
    column-gap: 84px;
}

.\!k-gap-x-21 {
    column-gap: 84px !important;
}

.k-gap-x-22 {
    column-gap: 88px;
}

.\!k-gap-x-22 {
    column-gap: 88px !important;
}

.k-gap-x-23 {
    column-gap: 92px;
}

.\!k-gap-x-23 {
    column-gap: 92px !important;
}

.k-gap-x-24 {
    column-gap: 96px;
}

.\!k-gap-x-24 {
    column-gap: 96px !important;
}

.k-gap-x-xs {
    column-gap: 4px;
}

.\!k-gap-x-xs {
    column-gap: 4px !important;
}

.k-gap-x-sm {
    column-gap: 8px;
}

.\!k-gap-x-sm {
    column-gap: 8px !important;
}

.k-gap-x-md {
    column-gap: 12px;
}

.\!k-gap-x-md {
    column-gap: 12px !important;
}

.k-gap-x-lg {
    column-gap: 16px;
}

.\!k-gap-x-lg {
    column-gap: 16px !important;
}

.k-gap-x-xl {
    column-gap: 24px;
}

.\!k-gap-x-xl {
    column-gap: 24px !important;
}

.k-gap-x-thin {
    column-gap: 2px;
}

.\!k-gap-x-thin {
    column-gap: 2px !important;
}

.k-gap-x-hair {
    column-gap: 1px;
}

.\!k-gap-x-hair {
    column-gap: 1px !important;
}

.k-gap-y-0 {
    row-gap: 0;
}

.\!k-gap-y-0 {
    row-gap: 0 !important;
}

.k-gap-y-1px {
    row-gap: 1px;
}

.\!k-gap-y-1px {
    row-gap: 1px !important;
}

.k-gap-y-0\.5 {
    row-gap: 2px;
}

.\!k-gap-y-0\.5 {
    row-gap: 2px !important;
}

.k-gap-y-1 {
    row-gap: 4px;
}

.\!k-gap-y-1 {
    row-gap: 4px !important;
}

.k-gap-y-1\.5 {
    row-gap: 6px;
}

.\!k-gap-y-1\.5 {
    row-gap: 6px !important;
}

.k-gap-y-2 {
    row-gap: 8px;
}

.\!k-gap-y-2 {
    row-gap: 8px !important;
}

.k-gap-y-2\.5 {
    row-gap: 10px;
}

.\!k-gap-y-2\.5 {
    row-gap: 10px !important;
}

.k-gap-y-3 {
    row-gap: 12px;
}

.\!k-gap-y-3 {
    row-gap: 12px !important;
}

.k-gap-y-3\.5 {
    row-gap: 14px;
}

.\!k-gap-y-3\.5 {
    row-gap: 14px !important;
}

.k-gap-y-4 {
    row-gap: 16px;
}

.\!k-gap-y-4 {
    row-gap: 16px !important;
}

.k-gap-y-4\.5 {
    row-gap: 18px;
}

.\!k-gap-y-4\.5 {
    row-gap: 18px !important;
}

.k-gap-y-5 {
    row-gap: 20px;
}

.\!k-gap-y-5 {
    row-gap: 20px !important;
}

.k-gap-y-5\.5 {
    row-gap: 22px;
}

.\!k-gap-y-5\.5 {
    row-gap: 22px !important;
}

.k-gap-y-6 {
    row-gap: 24px;
}

.\!k-gap-y-6 {
    row-gap: 24px !important;
}

.k-gap-y-6\.5 {
    row-gap: 26px;
}

.\!k-gap-y-6\.5 {
    row-gap: 26px !important;
}

.k-gap-y-7 {
    row-gap: 28px;
}

.\!k-gap-y-7 {
    row-gap: 28px !important;
}

.k-gap-y-7\.5 {
    row-gap: 30px;
}

.\!k-gap-y-7\.5 {
    row-gap: 30px !important;
}

.k-gap-y-8 {
    row-gap: 32px;
}

.\!k-gap-y-8 {
    row-gap: 32px !important;
}

.k-gap-y-9 {
    row-gap: 36px;
}

.\!k-gap-y-9 {
    row-gap: 36px !important;
}

.k-gap-y-10 {
    row-gap: 40px;
}

.\!k-gap-y-10 {
    row-gap: 40px !important;
}

.k-gap-y-11 {
    row-gap: 44px;
}

.\!k-gap-y-11 {
    row-gap: 44px !important;
}

.k-gap-y-12 {
    row-gap: 48px;
}

.\!k-gap-y-12 {
    row-gap: 48px !important;
}

.k-gap-y-13 {
    row-gap: 52px;
}

.\!k-gap-y-13 {
    row-gap: 52px !important;
}

.k-gap-y-14 {
    row-gap: 56px;
}

.\!k-gap-y-14 {
    row-gap: 56px !important;
}

.k-gap-y-15 {
    row-gap: 60px;
}

.\!k-gap-y-15 {
    row-gap: 60px !important;
}

.k-gap-y-16 {
    row-gap: 64px;
}

.\!k-gap-y-16 {
    row-gap: 64px !important;
}

.k-gap-y-17 {
    row-gap: 68px;
}

.\!k-gap-y-17 {
    row-gap: 68px !important;
}

.k-gap-y-18 {
    row-gap: 72px;
}

.\!k-gap-y-18 {
    row-gap: 72px !important;
}

.k-gap-y-19 {
    row-gap: 76px;
}

.\!k-gap-y-19 {
    row-gap: 76px !important;
}

.k-gap-y-20 {
    row-gap: 80px;
}

.\!k-gap-y-20 {
    row-gap: 80px !important;
}

.k-gap-y-21 {
    row-gap: 84px;
}

.\!k-gap-y-21 {
    row-gap: 84px !important;
}

.k-gap-y-22 {
    row-gap: 88px;
}

.\!k-gap-y-22 {
    row-gap: 88px !important;
}

.k-gap-y-23 {
    row-gap: 92px;
}

.\!k-gap-y-23 {
    row-gap: 92px !important;
}

.k-gap-y-24 {
    row-gap: 96px;
}

.\!k-gap-y-24 {
    row-gap: 96px !important;
}

.k-gap-y-xs {
    row-gap: 4px;
}

.\!k-gap-y-xs {
    row-gap: 4px !important;
}

.k-gap-y-sm {
    row-gap: 8px;
}

.\!k-gap-y-sm {
    row-gap: 8px !important;
}

.k-gap-y-md {
    row-gap: 12px;
}

.\!k-gap-y-md {
    row-gap: 12px !important;
}

.k-gap-y-lg {
    row-gap: 16px;
}

.\!k-gap-y-lg {
    row-gap: 16px !important;
}

.k-gap-y-xl {
    row-gap: 24px;
}

.\!k-gap-y-xl {
    row-gap: 24px !important;
}

.k-gap-y-thin {
    row-gap: 2px;
}

.\!k-gap-y-thin {
    row-gap: 2px !important;
}

.k-gap-y-hair {
    row-gap: 1px;
}

.\!k-gap-y-hair {
    row-gap: 1px !important;
}

.k-grid-auto-cols-auto {
    grid-auto-columns: auto;
}

.\!k-grid-auto-cols-auto {
    grid-auto-columns: auto !important;
}

.k-grid-auto-cols-min {
    grid-auto-columns: min-content;
}

.\!k-grid-auto-cols-min {
    grid-auto-columns: min-content !important;
}

.k-grid-auto-cols-max {
    grid-auto-columns: max-content;
}

.\!k-grid-auto-cols-max {
    grid-auto-columns: max-content !important;
}

.k-grid-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
}

.\!k-grid-auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important;
}

.k-grid-flow-row {
    grid-auto-flow: row;
}

.\!k-grid-flow-row {
    grid-auto-flow: row !important;
}

.k-grid-flow-col {
    grid-auto-flow: column;
}

.\!k-grid-flow-col {
    grid-auto-flow: column !important;
}

.k-grid-flow-dense {
    grid-auto-flow: dense;
}

.\!k-grid-flow-dense {
    grid-auto-flow: dense !important;
}

.k-grid-flow-row-dense {
    grid-auto-flow: row dense;
}

.\!k-grid-flow-row-dense {
    grid-auto-flow: row dense !important;
}

.k-grid-flow-col-dense {
    grid-auto-flow: column dense;
}

.\!k-grid-flow-col-dense {
    grid-auto-flow: column dense !important;
}

.k-grid-flow-unset {
    grid-auto-flow: unset;
}

.\!k-grid-flow-unset {
    grid-auto-flow: unset !important;
}

.k-grid-auto-rows-auto {
    grid-auto-rows: auto;
}

.\!k-grid-auto-rows-auto {
    grid-auto-rows: auto !important;
}

.k-grid-auto-rows-min {
    grid-auto-rows: min-content;
}

.\!k-grid-auto-rows-min {
    grid-auto-rows: min-content !important;
}

.k-grid-auto-rows-max {
    grid-auto-rows: max-content;
}

.\!k-grid-auto-rows-max {
    grid-auto-rows: max-content !important;
}

.k-grid-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
}

.\!k-grid-auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important;
}

.k-col-start-1 {
    grid-column-start: 1;
}

.\!k-col-start-1 {
    grid-column-start: 1 !important;
}

.k-col-start-2 {
    grid-column-start: 2;
}

.\!k-col-start-2 {
    grid-column-start: 2 !important;
}

.k-col-start-3 {
    grid-column-start: 3;
}

.\!k-col-start-3 {
    grid-column-start: 3 !important;
}

.k-col-start-4 {
    grid-column-start: 4;
}

.\!k-col-start-4 {
    grid-column-start: 4 !important;
}

.k-col-start-5 {
    grid-column-start: 5;
}

.\!k-col-start-5 {
    grid-column-start: 5 !important;
}

.k-col-start-6 {
    grid-column-start: 6;
}

.\!k-col-start-6 {
    grid-column-start: 6 !important;
}

.k-col-start-7 {
    grid-column-start: 7;
}

.\!k-col-start-7 {
    grid-column-start: 7 !important;
}

.k-col-start-8 {
    grid-column-start: 8;
}

.\!k-col-start-8 {
    grid-column-start: 8 !important;
}

.k-col-start-9 {
    grid-column-start: 9;
}

.\!k-col-start-9 {
    grid-column-start: 9 !important;
}

.k-col-start-10 {
    grid-column-start: 10;
}

.\!k-col-start-10 {
    grid-column-start: 10 !important;
}

.k-col-start-11 {
    grid-column-start: 11;
}

.\!k-col-start-11 {
    grid-column-start: 11 !important;
}

.k-col-start-12 {
    grid-column-start: 12;
}

.\!k-col-start-12 {
    grid-column-start: 12 !important;
}

.k-col-start-13 {
    grid-column-start: 13;
}

.\!k-col-start-13 {
    grid-column-start: 13 !important;
}

.k-col-start--1 {
    grid-column-start: -1;
}

.\!k-col-start--1 {
    grid-column-start: -1 !important;
}

.k-col-start--2 {
    grid-column-start: -2;
}

.\!k-col-start--2 {
    grid-column-start: -2 !important;
}

.k-col-start--3 {
    grid-column-start: -3;
}

.\!k-col-start--3 {
    grid-column-start: -3 !important;
}

.k-col-start--4 {
    grid-column-start: -4;
}

.\!k-col-start--4 {
    grid-column-start: -4 !important;
}

.k-col-start--5 {
    grid-column-start: -5;
}

.\!k-col-start--5 {
    grid-column-start: -5 !important;
}

.k-col-start--6 {
    grid-column-start: -6;
}

.\!k-col-start--6 {
    grid-column-start: -6 !important;
}

.k-col-start--7 {
    grid-column-start: -7;
}

.\!k-col-start--7 {
    grid-column-start: -7 !important;
}

.k-col-start--8 {
    grid-column-start: -8;
}

.\!k-col-start--8 {
    grid-column-start: -8 !important;
}

.k-col-start--9 {
    grid-column-start: -9;
}

.\!k-col-start--9 {
    grid-column-start: -9 !important;
}

.k-col-start--10 {
    grid-column-start: -10;
}

.\!k-col-start--10 {
    grid-column-start: -10 !important;
}

.k-col-start--11 {
    grid-column-start: -11;
}

.\!k-col-start--11 {
    grid-column-start: -11 !important;
}

.k-col-start--12 {
    grid-column-start: -12;
}

.\!k-col-start--12 {
    grid-column-start: -12 !important;
}

.k-col-start--13 {
    grid-column-start: -13;
}

.\!k-col-start--13 {
    grid-column-start: -13 !important;
}

.k-col-start-auto {
    grid-column-start: auto;
}

.\!k-col-start-auto {
    grid-column-start: auto !important;
}

.k-col-end-1 {
    grid-column-end: 1;
}

.\!k-col-end-1 {
    grid-column-end: 1 !important;
}

.k-col-end-2 {
    grid-column-end: 2;
}

.\!k-col-end-2 {
    grid-column-end: 2 !important;
}

.k-col-end-3 {
    grid-column-end: 3;
}

.\!k-col-end-3 {
    grid-column-end: 3 !important;
}

.k-col-end-4 {
    grid-column-end: 4;
}

.\!k-col-end-4 {
    grid-column-end: 4 !important;
}

.k-col-end-5 {
    grid-column-end: 5;
}

.\!k-col-end-5 {
    grid-column-end: 5 !important;
}

.k-col-end-6 {
    grid-column-end: 6;
}

.\!k-col-end-6 {
    grid-column-end: 6 !important;
}

.k-col-end-7 {
    grid-column-end: 7;
}

.\!k-col-end-7 {
    grid-column-end: 7 !important;
}

.k-col-end-8 {
    grid-column-end: 8;
}

.\!k-col-end-8 {
    grid-column-end: 8 !important;
}

.k-col-end-9 {
    grid-column-end: 9;
}

.\!k-col-end-9 {
    grid-column-end: 9 !important;
}

.k-col-end-10 {
    grid-column-end: 10;
}

.\!k-col-end-10 {
    grid-column-end: 10 !important;
}

.k-col-end-11 {
    grid-column-end: 11;
}

.\!k-col-end-11 {
    grid-column-end: 11 !important;
}

.k-col-end-12 {
    grid-column-end: 12;
}

.\!k-col-end-12 {
    grid-column-end: 12 !important;
}

.k-col-end-13 {
    grid-column-end: 13;
}

.\!k-col-end-13 {
    grid-column-end: 13 !important;
}

.k-col-end--1 {
    grid-column-end: -1;
}

.\!k-col-end--1 {
    grid-column-end: -1 !important;
}

.k-col-end--2 {
    grid-column-end: -2;
}

.\!k-col-end--2 {
    grid-column-end: -2 !important;
}

.k-col-end--3 {
    grid-column-end: -3;
}

.\!k-col-end--3 {
    grid-column-end: -3 !important;
}

.k-col-end--4 {
    grid-column-end: -4;
}

.\!k-col-end--4 {
    grid-column-end: -4 !important;
}

.k-col-end--5 {
    grid-column-end: -5;
}

.\!k-col-end--5 {
    grid-column-end: -5 !important;
}

.k-col-end--6 {
    grid-column-end: -6;
}

.\!k-col-end--6 {
    grid-column-end: -6 !important;
}

.k-col-end--7 {
    grid-column-end: -7;
}

.\!k-col-end--7 {
    grid-column-end: -7 !important;
}

.k-col-end--8 {
    grid-column-end: -8;
}

.\!k-col-end--8 {
    grid-column-end: -8 !important;
}

.k-col-end--9 {
    grid-column-end: -9;
}

.\!k-col-end--9 {
    grid-column-end: -9 !important;
}

.k-col-end--10 {
    grid-column-end: -10;
}

.\!k-col-end--10 {
    grid-column-end: -10 !important;
}

.k-col-end--11 {
    grid-column-end: -11;
}

.\!k-col-end--11 {
    grid-column-end: -11 !important;
}

.k-col-end--12 {
    grid-column-end: -12;
}

.\!k-col-end--12 {
    grid-column-end: -12 !important;
}

.k-col-end--13 {
    grid-column-end: -13;
}

.\!k-col-end--13 {
    grid-column-end: -13 !important;
}

.k-col-end-auto {
    grid-column-end: auto;
}

.\!k-col-end-auto {
    grid-column-end: auto !important;
}

.k-col-span-1 {
    grid-column: span 1 / span 1;
}

.\!k-col-span-1 {
    grid-column: span 1 / span 1 !important;
}

.k-col-span-2 {
    grid-column: span 2 / span 2;
}

.\!k-col-span-2 {
    grid-column: span 2 / span 2 !important;
}

.k-col-span-3 {
    grid-column: span 3 / span 3;
}

.\!k-col-span-3 {
    grid-column: span 3 / span 3 !important;
}

.k-col-span-4 {
    grid-column: span 4 / span 4;
}

.\!k-col-span-4 {
    grid-column: span 4 / span 4 !important;
}

.k-col-span-5 {
    grid-column: span 5 / span 5;
}

.\!k-col-span-5 {
    grid-column: span 5 / span 5 !important;
}

.k-col-span-6 {
    grid-column: span 6 / span 6;
}

.\!k-col-span-6 {
    grid-column: span 6 / span 6 !important;
}

.k-col-span-7 {
    grid-column: span 7 / span 7;
}

.\!k-col-span-7 {
    grid-column: span 7 / span 7 !important;
}

.k-col-span-8 {
    grid-column: span 8 / span 8;
}

.\!k-col-span-8 {
    grid-column: span 8 / span 8 !important;
}

.k-col-span-9 {
    grid-column: span 9 / span 9;
}

.\!k-col-span-9 {
    grid-column: span 9 / span 9 !important;
}

.k-col-span-10 {
    grid-column: span 10 / span 10;
}

.\!k-col-span-10 {
    grid-column: span 10 / span 10 !important;
}

.k-col-span-11 {
    grid-column: span 11 / span 11;
}

.\!k-col-span-11 {
    grid-column: span 11 / span 11 !important;
}

.k-col-span-12 {
    grid-column: span 12 / span 12;
}

.\!k-col-span-12 {
    grid-column: span 12 / span 12 !important;
}

.k-col-span-full {
    grid-column: 1 / -1;
}

.\!k-col-span-full {
    grid-column: 1 / -1 !important;
}

.k-col-span-auto {
    grid-column: auto;
}

.\!k-col-span-auto {
    grid-column: auto !important;
}

.k-colspan-1 {
    grid-column: span 1 / span 1;
}

.\!k-colspan-1 {
    grid-column: span 1 / span 1 !important;
}

.k-colspan-2 {
    grid-column: span 2 / span 2;
}

.\!k-colspan-2 {
    grid-column: span 2 / span 2 !important;
}

.k-colspan-3 {
    grid-column: span 3 / span 3;
}

.\!k-colspan-3 {
    grid-column: span 3 / span 3 !important;
}

.k-colspan-4 {
    grid-column: span 4 / span 4;
}

.\!k-colspan-4 {
    grid-column: span 4 / span 4 !important;
}

.k-colspan-5 {
    grid-column: span 5 / span 5;
}

.\!k-colspan-5 {
    grid-column: span 5 / span 5 !important;
}

.k-colspan-6 {
    grid-column: span 6 / span 6;
}

.\!k-colspan-6 {
    grid-column: span 6 / span 6 !important;
}

.k-colspan-7 {
    grid-column: span 7 / span 7;
}

.\!k-colspan-7 {
    grid-column: span 7 / span 7 !important;
}

.k-colspan-8 {
    grid-column: span 8 / span 8;
}

.\!k-colspan-8 {
    grid-column: span 8 / span 8 !important;
}

.k-colspan-9 {
    grid-column: span 9 / span 9;
}

.\!k-colspan-9 {
    grid-column: span 9 / span 9 !important;
}

.k-colspan-10 {
    grid-column: span 10 / span 10;
}

.\!k-colspan-10 {
    grid-column: span 10 / span 10 !important;
}

.k-colspan-11 {
    grid-column: span 11 / span 11;
}

.\!k-colspan-11 {
    grid-column: span 11 / span 11 !important;
}

.k-colspan-12 {
    grid-column: span 12 / span 12;
}

.\!k-colspan-12 {
    grid-column: span 12 / span 12 !important;
}

.k-colspan-full,
.k-colspan-all {
    grid-column: 1 / -1;
}

.\!k-colspan-full,
.\!k-colspan-all {
    grid-column: 1 / -1 !important;
}

.k-colspan-auto {
    grid-column: auto;
}

.\!k-colspan-auto {
    grid-column: auto !important;
}

.k-row-start-1 {
    grid-row-start: 1;
}

.\!k-row-start-1 {
    grid-row-start: 1 !important;
}

.k-row-start-2 {
    grid-row-start: 2;
}

.\!k-row-start-2 {
    grid-row-start: 2 !important;
}

.k-row-start-3 {
    grid-row-start: 3;
}

.\!k-row-start-3 {
    grid-row-start: 3 !important;
}

.k-row-start-4 {
    grid-row-start: 4;
}

.\!k-row-start-4 {
    grid-row-start: 4 !important;
}

.k-row-start-5 {
    grid-row-start: 5;
}

.\!k-row-start-5 {
    grid-row-start: 5 !important;
}

.k-row-start-6 {
    grid-row-start: 6;
}

.\!k-row-start-6 {
    grid-row-start: 6 !important;
}

.k-row-start-7 {
    grid-row-start: 7;
}

.\!k-row-start-7 {
    grid-row-start: 7 !important;
}

.k-row-start-8 {
    grid-row-start: 8;
}

.\!k-row-start-8 {
    grid-row-start: 8 !important;
}

.k-row-start-9 {
    grid-row-start: 9;
}

.\!k-row-start-9 {
    grid-row-start: 9 !important;
}

.k-row-start-10 {
    grid-row-start: 10;
}

.\!k-row-start-10 {
    grid-row-start: 10 !important;
}

.k-row-start-11 {
    grid-row-start: 11;
}

.\!k-row-start-11 {
    grid-row-start: 11 !important;
}

.k-row-start-12 {
    grid-row-start: 12;
}

.\!k-row-start-12 {
    grid-row-start: 12 !important;
}

.k-row-start-13 {
    grid-row-start: 13;
}

.\!k-row-start-13 {
    grid-row-start: 13 !important;
}

.k-row-start--1 {
    grid-row-start: -1;
}

.\!k-row-start--1 {
    grid-row-start: -1 !important;
}

.k-row-start--2 {
    grid-row-start: -2;
}

.\!k-row-start--2 {
    grid-row-start: -2 !important;
}

.k-row-start--3 {
    grid-row-start: -3;
}

.\!k-row-start--3 {
    grid-row-start: -3 !important;
}

.k-row-start--4 {
    grid-row-start: -4;
}

.\!k-row-start--4 {
    grid-row-start: -4 !important;
}

.k-row-start--5 {
    grid-row-start: -5;
}

.\!k-row-start--5 {
    grid-row-start: -5 !important;
}

.k-row-start--6 {
    grid-row-start: -6;
}

.\!k-row-start--6 {
    grid-row-start: -6 !important;
}

.k-row-start--7 {
    grid-row-start: -7;
}

.\!k-row-start--7 {
    grid-row-start: -7 !important;
}

.k-row-start--8 {
    grid-row-start: -8;
}

.\!k-row-start--8 {
    grid-row-start: -8 !important;
}

.k-row-start--9 {
    grid-row-start: -9;
}

.\!k-row-start--9 {
    grid-row-start: -9 !important;
}

.k-row-start--10 {
    grid-row-start: -10;
}

.\!k-row-start--10 {
    grid-row-start: -10 !important;
}

.k-row-start--11 {
    grid-row-start: -11;
}

.\!k-row-start--11 {
    grid-row-start: -11 !important;
}

.k-row-start--12 {
    grid-row-start: -12;
}

.\!k-row-start--12 {
    grid-row-start: -12 !important;
}

.k-row-start--13 {
    grid-row-start: -13;
}

.\!k-row-start--13 {
    grid-row-start: -13 !important;
}

.k-row-start-auto {
    grid-row-start: auto;
}

.\!k-row-start-auto {
    grid-row-start: auto !important;
}

.k-row-end-1 {
    grid-row-end: 1;
}

.\!k-row-end-1 {
    grid-row-end: 1 !important;
}

.k-row-end-2 {
    grid-row-end: 2;
}

.\!k-row-end-2 {
    grid-row-end: 2 !important;
}

.k-row-end-3 {
    grid-row-end: 3;
}

.\!k-row-end-3 {
    grid-row-end: 3 !important;
}

.k-row-end-4 {
    grid-row-end: 4;
}

.\!k-row-end-4 {
    grid-row-end: 4 !important;
}

.k-row-end-5 {
    grid-row-end: 5;
}

.\!k-row-end-5 {
    grid-row-end: 5 !important;
}

.k-row-end-6 {
    grid-row-end: 6;
}

.\!k-row-end-6 {
    grid-row-end: 6 !important;
}

.k-row-end-7 {
    grid-row-end: 7;
}

.\!k-row-end-7 {
    grid-row-end: 7 !important;
}

.k-row-end-8 {
    grid-row-end: 8;
}

.\!k-row-end-8 {
    grid-row-end: 8 !important;
}

.k-row-end-9 {
    grid-row-end: 9;
}

.\!k-row-end-9 {
    grid-row-end: 9 !important;
}

.k-row-end-10 {
    grid-row-end: 10;
}

.\!k-row-end-10 {
    grid-row-end: 10 !important;
}

.k-row-end-11 {
    grid-row-end: 11;
}

.\!k-row-end-11 {
    grid-row-end: 11 !important;
}

.k-row-end-12 {
    grid-row-end: 12;
}

.\!k-row-end-12 {
    grid-row-end: 12 !important;
}

.k-row-end-13 {
    grid-row-end: 13;
}

.\!k-row-end-13 {
    grid-row-end: 13 !important;
}

.k-row-end--1 {
    grid-row-end: -1;
}

.\!k-row-end--1 {
    grid-row-end: -1 !important;
}

.k-row-end--2 {
    grid-row-end: -2;
}

.\!k-row-end--2 {
    grid-row-end: -2 !important;
}

.k-row-end--3 {
    grid-row-end: -3;
}

.\!k-row-end--3 {
    grid-row-end: -3 !important;
}

.k-row-end--4 {
    grid-row-end: -4;
}

.\!k-row-end--4 {
    grid-row-end: -4 !important;
}

.k-row-end--5 {
    grid-row-end: -5;
}

.\!k-row-end--5 {
    grid-row-end: -5 !important;
}

.k-row-end--6 {
    grid-row-end: -6;
}

.\!k-row-end--6 {
    grid-row-end: -6 !important;
}

.k-row-end--7 {
    grid-row-end: -7;
}

.\!k-row-end--7 {
    grid-row-end: -7 !important;
}

.k-row-end--8 {
    grid-row-end: -8;
}

.\!k-row-end--8 {
    grid-row-end: -8 !important;
}

.k-row-end--9 {
    grid-row-end: -9;
}

.\!k-row-end--9 {
    grid-row-end: -9 !important;
}

.k-row-end--10 {
    grid-row-end: -10;
}

.\!k-row-end--10 {
    grid-row-end: -10 !important;
}

.k-row-end--11 {
    grid-row-end: -11;
}

.\!k-row-end--11 {
    grid-row-end: -11 !important;
}

.k-row-end--12 {
    grid-row-end: -12;
}

.\!k-row-end--12 {
    grid-row-end: -12 !important;
}

.k-row-end--13 {
    grid-row-end: -13;
}

.\!k-row-end--13 {
    grid-row-end: -13 !important;
}

.k-row-end-auto {
    grid-row-end: auto;
}

.\!k-row-end-auto {
    grid-row-end: auto !important;
}

.k-row-span-1 {
    grid-row: span 1 / span 1;
}

.\!k-row-span-1 {
    grid-row: span 1 / span 1 !important;
}

.k-row-span-2 {
    grid-row: span 2 / span 2;
}

.\!k-row-span-2 {
    grid-row: span 2 / span 2 !important;
}

.k-row-span-3 {
    grid-row: span 3 / span 3;
}

.\!k-row-span-3 {
    grid-row: span 3 / span 3 !important;
}

.k-row-span-4 {
    grid-row: span 4 / span 4;
}

.\!k-row-span-4 {
    grid-row: span 4 / span 4 !important;
}

.k-row-span-5 {
    grid-row: span 5 / span 5;
}

.\!k-row-span-5 {
    grid-row: span 5 / span 5 !important;
}

.k-row-span-6 {
    grid-row: span 6 / span 6;
}

.\!k-row-span-6 {
    grid-row: span 6 / span 6 !important;
}

.k-row-span-7 {
    grid-row: span 7 / span 7;
}

.\!k-row-span-7 {
    grid-row: span 7 / span 7 !important;
}

.k-row-span-8 {
    grid-row: span 8 / span 8;
}

.\!k-row-span-8 {
    grid-row: span 8 / span 8 !important;
}

.k-row-span-9 {
    grid-row: span 9 / span 9;
}

.\!k-row-span-9 {
    grid-row: span 9 / span 9 !important;
}

.k-row-span-10 {
    grid-row: span 10 / span 10;
}

.\!k-row-span-10 {
    grid-row: span 10 / span 10 !important;
}

.k-row-span-11 {
    grid-row: span 11 / span 11;
}

.\!k-row-span-11 {
    grid-row: span 11 / span 11 !important;
}

.k-row-span-12 {
    grid-row: span 12 / span 12;
}

.\!k-row-span-12 {
    grid-row: span 12 / span 12 !important;
}

.k-row-span-full {
    grid-row: span 1 / span -1;
}

.\!k-row-span-full {
    grid-row: span 1 / span -1 !important;
}

.k-row-span-auto {
    grid-row: auto;
}

.\!k-row-span-auto {
    grid-row: auto !important;
}

.k-rowspan-1 {
    grid-row: span 1 / span 1;
}

.\!k-rowspan-1 {
    grid-row: span 1 / span 1 !important;
}

.k-rowspan-2 {
    grid-row: span 2 / span 2;
}

.\!k-rowspan-2 {
    grid-row: span 2 / span 2 !important;
}

.k-rowspan-3 {
    grid-row: span 3 / span 3;
}

.\!k-rowspan-3 {
    grid-row: span 3 / span 3 !important;
}

.k-rowspan-4 {
    grid-row: span 4 / span 4;
}

.\!k-rowspan-4 {
    grid-row: span 4 / span 4 !important;
}

.k-rowspan-5 {
    grid-row: span 5 / span 5;
}

.\!k-rowspan-5 {
    grid-row: span 5 / span 5 !important;
}

.k-rowspan-6 {
    grid-row: span 6 / span 6;
}

.\!k-rowspan-6 {
    grid-row: span 6 / span 6 !important;
}

.k-rowspan-7 {
    grid-row: span 7 / span 7;
}

.\!k-rowspan-7 {
    grid-row: span 7 / span 7 !important;
}

.k-rowspan-8 {
    grid-row: span 8 / span 8;
}

.\!k-rowspan-8 {
    grid-row: span 8 / span 8 !important;
}

.k-rowspan-9 {
    grid-row: span 9 / span 9;
}

.\!k-rowspan-9 {
    grid-row: span 9 / span 9 !important;
}

.k-rowspan-10 {
    grid-row: span 10 / span 10;
}

.\!k-rowspan-10 {
    grid-row: span 10 / span 10 !important;
}

.k-rowspan-11 {
    grid-row: span 11 / span 11;
}

.\!k-rowspan-11 {
    grid-row: span 11 / span 11 !important;
}

.k-rowspan-12 {
    grid-row: span 12 / span 12;
}

.\!k-rowspan-12 {
    grid-row: span 12 / span 12 !important;
}

.k-rowspan-full,
.k-rowspan-all {
    grid-row: span 1 / span -1;
}

.\!k-rowspan-full,
.\!k-rowspan-all {
    grid-row: span 1 / span -1 !important;
}

.k-rowspan-auto {
    grid-row: auto;
}

.\!k-rowspan-auto {
    grid-row: auto !important;
}

.k-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.\!k-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.k-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.\!k-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.k-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.\!k-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.k-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.\!k-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.k-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.\!k-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.k-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.\!k-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.k-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
}

.\!k-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.k-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
}

.\!k-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.k-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
}

.\!k-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.k-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
}

.\!k-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.k-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
}

.\!k-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.k-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.\!k-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.k-grid-cols-none {
    grid-template-columns: none;
}

.\!k-grid-cols-none {
    grid-template-columns: none !important;
}

.k-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
}

.\!k-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
}

.k-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
}

.\!k-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
}

.k-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
}

.\!k-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
}

.k-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
}

.\!k-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
}

.k-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
}

.\!k-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}

.k-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
}

.\!k-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
}

.k-grid-rows-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
}

.\!k-grid-rows-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr)) !important;
}

.k-grid-rows-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
}

.\!k-grid-rows-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr)) !important;
}

.k-grid-rows-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
}

.\!k-grid-rows-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr)) !important;
}

.k-grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr));
}

.\!k-grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr)) !important;
}

.k-grid-rows-11 {
    grid-template-rows: repeat(11, minmax(0, 1fr));
}

.\!k-grid-rows-11 {
    grid-template-rows: repeat(11, minmax(0, 1fr)) !important;
}

.k-grid-rows-12 {
    grid-template-rows: repeat(12, minmax(0, 1fr));
}

.\!k-grid-rows-12 {
    grid-template-rows: repeat(12, minmax(0, 1fr)) !important;
}

.k-grid-rows-none {
    grid-template-rows: none;
}

.\!k-grid-rows-none {
    grid-template-rows: none !important;
}

.k-justify-content-normal {
    justify-content: normal;
}

.\!k-justify-content-normal {
    justify-content: normal !important;
}

.k-justify-content-stretch {
    justify-content: stretch;
}

.\!k-justify-content-stretch {
    justify-content: stretch !important;
}

.k-justify-content-center {
    justify-content: center;
}

.\!k-justify-content-center {
    justify-content: center !important;
}

.k-justify-content-start {
    justify-content: start;
}

.\!k-justify-content-start {
    justify-content: start !important;
}

.k-justify-content-end {
    justify-content: end;
}

.\!k-justify-content-end {
    justify-content: end !important;
}

.k-justify-content-flex-start {
    justify-content: flex-start;
}

.\!k-justify-content-flex-start {
    justify-content: flex-start !important;
}

.k-justify-content-flex-end {
    justify-content: flex-end;
}

.\!k-justify-content-flex-end {
    justify-content: flex-end !important;
}

.k-justify-content-left {
    justify-content: left;
}

.\!k-justify-content-left {
    justify-content: left !important;
}

.k-justify-content-right {
    justify-content: right;
}

.\!k-justify-content-right {
    justify-content: right !important;
}

.k-justify-content-baseline {
    justify-content: baseline;
}

.\!k-justify-content-baseline {
    justify-content: baseline !important;
}

.k-justify-content-first-baseline {
    justify-content: first baseline;
}

.\!k-justify-content-first-baseline {
    justify-content: first baseline !important;
}

.k-justify-content-last-baseline {
    justify-content: last baseline;
}

.\!k-justify-content-last-baseline {
    justify-content: last baseline !important;
}

.k-justify-content-between {
    justify-content: space-between;
}

.\!k-justify-content-between {
    justify-content: space-between !important;
}

.k-justify-content-around {
    justify-content: space-around;
}

.\!k-justify-content-around {
    justify-content: space-around !important;
}

.k-justify-content-evenly {
    justify-content: space-evenly;
}

.\!k-justify-content-evenly {
    justify-content: space-evenly !important;
}

.k-justify-content-stretch > * {
    flex: 1 0 0%;
}

.\!.k-justify-content-stretch > * {
    flex: 1 0 0% !important;
}

.k-justify-items-normal {
    justify-items: normal;
}

.\!k-justify-items-normal {
    justify-items: normal !important;
}

.k-justify-items-stretch {
    justify-items: stretch;
}

.\!k-justify-items-stretch {
    justify-items: stretch !important;
}

.k-justify-items-center {
    justify-items: center;
}

.\!k-justify-items-center {
    justify-items: center !important;
}

.k-justify-items-start {
    justify-items: start;
}

.\!k-justify-items-start {
    justify-items: start !important;
}

.k-justify-items-end {
    justify-items: end;
}

.\!k-justify-items-end {
    justify-items: end !important;
}

.k-justify-items-flex-start {
    justify-items: flex-start;
}

.\!k-justify-items-flex-start {
    justify-items: flex-start !important;
}

.k-justify-items-flex-end {
    justify-items: flex-end;
}

.\!k-justify-items-flex-end {
    justify-items: flex-end !important;
}

.k-justify-items-self-start {
    justify-items: self-start;
}

.\!k-justify-items-self-start {
    justify-items: self-start !important;
}

.k-justify-items-self-end {
    justify-items: self-end;
}

.\!k-justify-items-self-end {
    justify-items: self-end !important;
}

.k-justify-items-left {
    justify-items: left;
}

.\!k-justify-items-left {
    justify-items: left !important;
}

.k-justify-items-right {
    justify-items: right;
}

.\!k-justify-items-right {
    justify-items: right !important;
}

.k-justify-items-baseline {
    justify-items: baseline;
}

.\!k-justify-items-baseline {
    justify-items: baseline !important;
}

.k-justify-items-first-baseline {
    justify-items: first baseline;
}

.\!k-justify-items-first-baseline {
    justify-items: first baseline !important;
}

.k-justify-items-last-baseline {
    justify-items: last baseline;
}

.\!k-justify-items-last-baseline {
    justify-items: last baseline !important;
}

.k-justify-self-auto {
    justify-self: auto;
}

.\!k-justify-self-auto {
    justify-self: auto !important;
}

.k-justify-self-normal {
    justify-self: normal;
}

.\!k-justify-self-normal {
    justify-self: normal !important;
}

.k-justify-self-stretch {
    justify-self: stretch;
}

.\!k-justify-self-stretch {
    justify-self: stretch !important;
}

.k-justify-self-center {
    justify-self: center;
}

.\!k-justify-self-center {
    justify-self: center !important;
}

.k-justify-self-start {
    justify-self: start;
}

.\!k-justify-self-start {
    justify-self: start !important;
}

.k-justify-self-end {
    justify-self: end;
}

.\!k-justify-self-end {
    justify-self: end !important;
}

.k-justify-self-flex-start {
    justify-self: flex-start;
}

.\!k-justify-self-flex-start {
    justify-self: flex-start !important;
}

.k-justify-self-flex-end {
    justify-self: flex-end;
}

.\!k-justify-self-flex-end {
    justify-self: flex-end !important;
}

.k-justify-self-self-start {
    justify-self: self-start;
}

.\!k-justify-self-self-start {
    justify-self: self-start !important;
}

.k-justify-self-self-end {
    justify-self: self-end;
}

.\!k-justify-self-self-end {
    justify-self: self-end !important;
}

.k-justify-self-baseline {
    justify-self: baseline;
}

.\!k-justify-self-baseline {
    justify-self: baseline !important;
}

.k-justify-self-first-baseline {
    justify-self: first baseline;
}

.\!k-justify-self-first-baseline {
    justify-self: first baseline !important;
}

.k-justify-self-last-baseline {
    justify-self: last baseline;
}

.\!k-justify-self-last-baseline {
    justify-self: last baseline !important;
}

.k-order-1 {
    order: 1;
}

.\!k-order-1 {
    order: 1 !important;
}

.k-order-2 {
    order: 2;
}

.\!k-order-2 {
    order: 2 !important;
}

.k-order-3 {
    order: 3;
}

.\!k-order-3 {
    order: 3 !important;
}

.k-order-4 {
    order: 4;
}

.\!k-order-4 {
    order: 4 !important;
}

.k-order-5 {
    order: 5;
}

.\!k-order-5 {
    order: 5 !important;
}

.k-order-6 {
    order: 6;
}

.\!k-order-6 {
    order: 6 !important;
}

.k-order-7 {
    order: 7;
}

.\!k-order-7 {
    order: 7 !important;
}

.k-order-8 {
    order: 8;
}

.\!k-order-8 {
    order: 8 !important;
}

.k-order-9 {
    order: 9;
}

.\!k-order-9 {
    order: 9 !important;
}

.k-order-10 {
    order: 10;
}

.\!k-order-10 {
    order: 10 !important;
}

.k-order-11 {
    order: 11;
}

.\!k-order-11 {
    order: 11 !important;
}

.k-order-12 {
    order: 12;
}

.\!k-order-12 {
    order: 12 !important;
}

.k-order-first {
    order: -9999;
}

.\!k-order-first {
    order: -9999 !important;
}

.k-order-last {
    order: 9999;
}

.\!k-order-last {
    order: 9999 !important;
}

.k-order-none {
    order: 0;
}

.\!k-order-none {
    order: 0 !important;
}

.k-place-content-normal {
    place-content: normal;
}

.\!k-place-content-normal {
    place-content: normal !important;
}

.k-place-content-stretch {
    place-content: stretch;
}

.\!k-place-content-stretch {
    place-content: stretch !important;
}

.k-place-content-center {
    place-content: center;
}

.\!k-place-content-center {
    place-content: center !important;
}

.k-place-content-start {
    place-content: start;
}

.\!k-place-content-start {
    place-content: start !important;
}

.k-place-content-end {
    place-content: end;
}

.\!k-place-content-end {
    place-content: end !important;
}

.k-place-content-flex-start {
    place-content: flex-start;
}

.\!k-place-content-flex-start {
    place-content: flex-start !important;
}

.k-place-content-flex-end {
    place-content: flex-end;
}

.\!k-place-content-flex-end {
    place-content: flex-end !important;
}

.k-place-content-baseline {
    place-content: baseline;
}

.\!k-place-content-baseline {
    place-content: baseline !important;
}

.k-place-content-between {
    place-content: space-between;
}

.\!k-place-content-between {
    place-content: space-between !important;
}

.k-place-content-around {
    place-content: space-around;
}

.\!k-place-content-around {
    place-content: space-around !important;
}

.k-place-content-evenly {
    place-content: space-evenly;
}

.\!k-place-content-evenly {
    place-content: space-evenly !important;
}

.k-place-items-normal {
    place-items: normal;
}

.\!k-place-items-normal {
    place-items: normal !important;
}

.k-place-items-stretch {
    place-items: stretch;
}

.\!k-place-items-stretch {
    place-items: stretch !important;
}

.k-place-items-center {
    place-items: center;
}

.\!k-place-items-center {
    place-items: center !important;
}

.k-place-items-start {
    place-items: start;
}

.\!k-place-items-start {
    place-items: start !important;
}

.k-place-items-end {
    place-items: end;
}

.\!k-place-items-end {
    place-items: end !important;
}

.k-place-items-flex-start {
    place-items: flex-start;
}

.\!k-place-items-flex-start {
    place-items: flex-start !important;
}

.k-place-items-flex-end {
    place-items: flex-end;
}

.\!k-place-items-flex-end {
    place-items: flex-end !important;
}

.k-place-items-self-start {
    place-items: self-start;
}

.\!k-place-items-self-start {
    place-items: self-start !important;
}

.k-place-items-self-end {
    place-items: self-end;
}

.\!k-place-items-self-end {
    place-items: self-end !important;
}

.k-place-items-baseline {
    place-items: baseline;
}

.\!k-place-items-baseline {
    place-items: baseline !important;
}

.k-place-self-auto {
    place-self: auto;
}

.\!k-place-self-auto {
    place-self: auto !important;
}

.k-place-self-normal {
    place-self: normal;
}

.\!k-place-self-normal {
    place-self: normal !important;
}

.k-place-self-stretch {
    place-self: stretch;
}

.\!k-place-self-stretch {
    place-self: stretch !important;
}

.k-place-self-center {
    place-self: center;
}

.\!k-place-self-center {
    place-self: center !important;
}

.k-place-self-start {
    place-self: start;
}

.\!k-place-self-start {
    place-self: start !important;
}

.k-place-self-end {
    place-self: end;
}

.\!k-place-self-end {
    place-self: end !important;
}

.k-place-self-flex-start {
    place-self: flex-start;
}

.\!k-place-self-flex-start {
    place-self: flex-start !important;
}

.k-place-self-flex-end {
    place-self: flex-end;
}

.\!k-place-self-flex-end {
    place-self: flex-end !important;
}

.k-place-self-self-start {
    place-self: self-start;
}

.\!k-place-self-self-start {
    place-self: self-start !important;
}

.k-place-self-self-end {
    place-self: self-end;
}

.\!k-place-self-self-end {
    place-self: self-end !important;
}

.k-place-self-baseline {
    place-self: baseline;
}

.\!k-place-self-baseline {
    place-self: baseline !important;
}

.k-m-0 {
    margin: 0;
}

.\!k-m-0 {
    margin: 0 !important;
}

.k-m-1px {
    margin: 1px;
}

.\!k-m-1px {
    margin: 1px !important;
}

.k-m-0\.5 {
    margin: 2px;
}

.\!k-m-0\.5 {
    margin: 2px !important;
}

.k-m-1 {
    margin: 4px;
}

.\!k-m-1 {
    margin: 4px !important;
}

.k-m-1\.5 {
    margin: 6px;
}

.\!k-m-1\.5 {
    margin: 6px !important;
}

.k-m-2 {
    margin: 8px;
}

.\!k-m-2 {
    margin: 8px !important;
}

.k-m-2\.5 {
    margin: 10px;
}

.\!k-m-2\.5 {
    margin: 10px !important;
}

.k-m-3 {
    margin: 12px;
}

.\!k-m-3 {
    margin: 12px !important;
}

.k-m-3\.5 {
    margin: 14px;
}

.\!k-m-3\.5 {
    margin: 14px !important;
}

.k-m-4 {
    margin: 16px;
}

.\!k-m-4 {
    margin: 16px !important;
}

.k-m-4\.5 {
    margin: 18px;
}

.\!k-m-4\.5 {
    margin: 18px !important;
}

.k-m-5 {
    margin: 20px;
}

.\!k-m-5 {
    margin: 20px !important;
}

.k-m-5\.5 {
    margin: 22px;
}

.\!k-m-5\.5 {
    margin: 22px !important;
}

.k-m-6 {
    margin: 24px;
}

.\!k-m-6 {
    margin: 24px !important;
}

.k-m-6\.5 {
    margin: 26px;
}

.\!k-m-6\.5 {
    margin: 26px !important;
}

.k-m-7 {
    margin: 28px;
}

.\!k-m-7 {
    margin: 28px !important;
}

.k-m-7\.5 {
    margin: 30px;
}

.\!k-m-7\.5 {
    margin: 30px !important;
}

.k-m-8 {
    margin: 32px;
}

.\!k-m-8 {
    margin: 32px !important;
}

.k-m-9 {
    margin: 36px;
}

.\!k-m-9 {
    margin: 36px !important;
}

.k-m-10 {
    margin: 40px;
}

.\!k-m-10 {
    margin: 40px !important;
}

.k-m-11 {
    margin: 44px;
}

.\!k-m-11 {
    margin: 44px !important;
}

.k-m-12 {
    margin: 48px;
}

.\!k-m-12 {
    margin: 48px !important;
}

.k-m-13 {
    margin: 52px;
}

.\!k-m-13 {
    margin: 52px !important;
}

.k-m-14 {
    margin: 56px;
}

.\!k-m-14 {
    margin: 56px !important;
}

.k-m-15 {
    margin: 60px;
}

.\!k-m-15 {
    margin: 60px !important;
}

.k-m-16 {
    margin: 64px;
}

.\!k-m-16 {
    margin: 64px !important;
}

.k-m-17 {
    margin: 68px;
}

.\!k-m-17 {
    margin: 68px !important;
}

.k-m-18 {
    margin: 72px;
}

.\!k-m-18 {
    margin: 72px !important;
}

.k-m-19 {
    margin: 76px;
}

.\!k-m-19 {
    margin: 76px !important;
}

.k-m-20 {
    margin: 80px;
}

.\!k-m-20 {
    margin: 80px !important;
}

.k-m-21 {
    margin: 84px;
}

.\!k-m-21 {
    margin: 84px !important;
}

.k-m-22 {
    margin: 88px;
}

.\!k-m-22 {
    margin: 88px !important;
}

.k-m-23 {
    margin: 92px;
}

.\!k-m-23 {
    margin: 92px !important;
}

.k-m-24 {
    margin: 96px;
}

.\!k-m-24 {
    margin: 96px !important;
}

.k-m--1px {
    margin: -1px;
}

.\!k-m--1px {
    margin: -1px !important;
}

.k-m--0\.5 {
    margin: -2px;
}

.\!k-m--0\.5 {
    margin: -2px !important;
}

.k-m--1 {
    margin: -4px;
}

.\!k-m--1 {
    margin: -4px !important;
}

.k-m--1\.5 {
    margin: -6px;
}

.\!k-m--1\.5 {
    margin: -6px !important;
}

.k-m--2 {
    margin: -8px;
}

.\!k-m--2 {
    margin: -8px !important;
}

.k-m--2\.5 {
    margin: -10px;
}

.\!k-m--2\.5 {
    margin: -10px !important;
}

.k-m--3 {
    margin: -12px;
}

.\!k-m--3 {
    margin: -12px !important;
}

.k-m--3\.5 {
    margin: -14px;
}

.\!k-m--3\.5 {
    margin: -14px !important;
}

.k-m--4 {
    margin: -16px;
}

.\!k-m--4 {
    margin: -16px !important;
}

.k-m--4\.5 {
    margin: -18px;
}

.\!k-m--4\.5 {
    margin: -18px !important;
}

.k-m--5 {
    margin: -20px;
}

.\!k-m--5 {
    margin: -20px !important;
}

.k-m--5\.5 {
    margin: -22px;
}

.\!k-m--5\.5 {
    margin: -22px !important;
}

.k-m--6 {
    margin: -24px;
}

.\!k-m--6 {
    margin: -24px !important;
}

.k-m--6\.5 {
    margin: -26px;
}

.\!k-m--6\.5 {
    margin: -26px !important;
}

.k-m--7 {
    margin: -28px;
}

.\!k-m--7 {
    margin: -28px !important;
}

.k-m--7\.5 {
    margin: -30px;
}

.\!k-m--7\.5 {
    margin: -30px !important;
}

.k-m--8 {
    margin: -32px;
}

.\!k-m--8 {
    margin: -32px !important;
}

.k-m--9 {
    margin: -36px;
}

.\!k-m--9 {
    margin: -36px !important;
}

.k-m--10 {
    margin: -40px;
}

.\!k-m--10 {
    margin: -40px !important;
}

.k-m--11 {
    margin: -44px;
}

.\!k-m--11 {
    margin: -44px !important;
}

.k-m--12 {
    margin: -48px;
}

.\!k-m--12 {
    margin: -48px !important;
}

.k-m--13 {
    margin: -52px;
}

.\!k-m--13 {
    margin: -52px !important;
}

.k-m--14 {
    margin: -56px;
}

.\!k-m--14 {
    margin: -56px !important;
}

.k-m--15 {
    margin: -60px;
}

.\!k-m--15 {
    margin: -60px !important;
}

.k-m--16 {
    margin: -64px;
}

.\!k-m--16 {
    margin: -64px !important;
}

.k-m--17 {
    margin: -68px;
}

.\!k-m--17 {
    margin: -68px !important;
}

.k-m--18 {
    margin: -72px;
}

.\!k-m--18 {
    margin: -72px !important;
}

.k-m--19 {
    margin: -76px;
}

.\!k-m--19 {
    margin: -76px !important;
}

.k-m--20 {
    margin: -80px;
}

.\!k-m--20 {
    margin: -80px !important;
}

.k-m--21 {
    margin: -84px;
}

.\!k-m--21 {
    margin: -84px !important;
}

.k-m--22 {
    margin: -88px;
}

.\!k-m--22 {
    margin: -88px !important;
}

.k-m--23 {
    margin: -92px;
}

.\!k-m--23 {
    margin: -92px !important;
}

.k-m--24 {
    margin: -96px;
}

.\!k-m--24 {
    margin: -96px !important;
}

.k-m-xs {
    margin: 4px;
}

.\!k-m-xs {
    margin: 4px !important;
}

.k-m-sm {
    margin: 8px;
}

.\!k-m-sm {
    margin: 8px !important;
}

.k-m-md {
    margin: 12px;
}

.\!k-m-md {
    margin: 12px !important;
}

.k-m-lg {
    margin: 16px;
}

.\!k-m-lg {
    margin: 16px !important;
}

.k-m-xl {
    margin: 24px;
}

.\!k-m-xl {
    margin: 24px !important;
}

.k-m-thin {
    margin: 2px;
}

.\!k-m-thin {
    margin: 2px !important;
}

.k-m-hair {
    margin: 1px;
}

.\!k-m-hair {
    margin: 1px !important;
}

.k-m-auto {
    margin: auto;
}

.\!k-m-auto {
    margin: auto !important;
}

.k-mt-0 {
    margin-top: 0;
}

.\!k-mt-0 {
    margin-top: 0 !important;
}

.k-mt-1px {
    margin-top: 1px;
}

.\!k-mt-1px {
    margin-top: 1px !important;
}

.k-mt-0\.5 {
    margin-top: 2px;
}

.\!k-mt-0\.5 {
    margin-top: 2px !important;
}

.k-mt-1 {
    margin-top: 4px;
}

.\!k-mt-1 {
    margin-top: 4px !important;
}

.k-mt-1\.5 {
    margin-top: 6px;
}

.\!k-mt-1\.5 {
    margin-top: 6px !important;
}

.k-mt-2 {
    margin-top: 8px;
}

.\!k-mt-2 {
    margin-top: 8px !important;
}

.k-mt-2\.5 {
    margin-top: 10px;
}

.\!k-mt-2\.5 {
    margin-top: 10px !important;
}

.k-mt-3 {
    margin-top: 12px;
}

.\!k-mt-3 {
    margin-top: 12px !important;
}

.k-mt-3\.5 {
    margin-top: 14px;
}

.\!k-mt-3\.5 {
    margin-top: 14px !important;
}

.k-mt-4 {
    margin-top: 16px;
}

.\!k-mt-4 {
    margin-top: 16px !important;
}

.k-mt-4\.5 {
    margin-top: 18px;
}

.\!k-mt-4\.5 {
    margin-top: 18px !important;
}

.k-mt-5 {
    margin-top: 20px;
}

.\!k-mt-5 {
    margin-top: 20px !important;
}

.k-mt-5\.5 {
    margin-top: 22px;
}

.\!k-mt-5\.5 {
    margin-top: 22px !important;
}

.k-mt-6 {
    margin-top: 24px;
}

.\!k-mt-6 {
    margin-top: 24px !important;
}

.k-mt-6\.5 {
    margin-top: 26px;
}

.\!k-mt-6\.5 {
    margin-top: 26px !important;
}

.k-mt-7 {
    margin-top: 28px;
}

.\!k-mt-7 {
    margin-top: 28px !important;
}

.k-mt-7\.5 {
    margin-top: 30px;
}

.\!k-mt-7\.5 {
    margin-top: 30px !important;
}

.k-mt-8 {
    margin-top: 32px;
}

.\!k-mt-8 {
    margin-top: 32px !important;
}

.k-mt-9 {
    margin-top: 36px;
}

.\!k-mt-9 {
    margin-top: 36px !important;
}

.k-mt-10 {
    margin-top: 40px;
}

.\!k-mt-10 {
    margin-top: 40px !important;
}

.k-mt-11 {
    margin-top: 44px;
}

.\!k-mt-11 {
    margin-top: 44px !important;
}

.k-mt-12 {
    margin-top: 48px;
}

.\!k-mt-12 {
    margin-top: 48px !important;
}

.k-mt-13 {
    margin-top: 52px;
}

.\!k-mt-13 {
    margin-top: 52px !important;
}

.k-mt-14 {
    margin-top: 56px;
}

.\!k-mt-14 {
    margin-top: 56px !important;
}

.k-mt-15 {
    margin-top: 60px;
}

.\!k-mt-15 {
    margin-top: 60px !important;
}

.k-mt-16 {
    margin-top: 64px;
}

.\!k-mt-16 {
    margin-top: 64px !important;
}

.k-mt-17 {
    margin-top: 68px;
}

.\!k-mt-17 {
    margin-top: 68px !important;
}

.k-mt-18 {
    margin-top: 72px;
}

.\!k-mt-18 {
    margin-top: 72px !important;
}

.k-mt-19 {
    margin-top: 76px;
}

.\!k-mt-19 {
    margin-top: 76px !important;
}

.k-mt-20 {
    margin-top: 80px;
}

.\!k-mt-20 {
    margin-top: 80px !important;
}

.k-mt-21 {
    margin-top: 84px;
}

.\!k-mt-21 {
    margin-top: 84px !important;
}

.k-mt-22 {
    margin-top: 88px;
}

.\!k-mt-22 {
    margin-top: 88px !important;
}

.k-mt-23 {
    margin-top: 92px;
}

.\!k-mt-23 {
    margin-top: 92px !important;
}

.k-mt-24 {
    margin-top: 96px;
}

.\!k-mt-24 {
    margin-top: 96px !important;
}

.k-mt--1px {
    margin-top: -1px;
}

.\!k-mt--1px {
    margin-top: -1px !important;
}

.k-mt--0\.5 {
    margin-top: -2px;
}

.\!k-mt--0\.5 {
    margin-top: -2px !important;
}

.k-mt--1 {
    margin-top: -4px;
}

.\!k-mt--1 {
    margin-top: -4px !important;
}

.k-mt--1\.5 {
    margin-top: -6px;
}

.\!k-mt--1\.5 {
    margin-top: -6px !important;
}

.k-mt--2 {
    margin-top: -8px;
}

.\!k-mt--2 {
    margin-top: -8px !important;
}

.k-mt--2\.5 {
    margin-top: -10px;
}

.\!k-mt--2\.5 {
    margin-top: -10px !important;
}

.k-mt--3 {
    margin-top: -12px;
}

.\!k-mt--3 {
    margin-top: -12px !important;
}

.k-mt--3\.5 {
    margin-top: -14px;
}

.\!k-mt--3\.5 {
    margin-top: -14px !important;
}

.k-mt--4 {
    margin-top: -16px;
}

.\!k-mt--4 {
    margin-top: -16px !important;
}

.k-mt--4\.5 {
    margin-top: -18px;
}

.\!k-mt--4\.5 {
    margin-top: -18px !important;
}

.k-mt--5 {
    margin-top: -20px;
}

.\!k-mt--5 {
    margin-top: -20px !important;
}

.k-mt--5\.5 {
    margin-top: -22px;
}

.\!k-mt--5\.5 {
    margin-top: -22px !important;
}

.k-mt--6 {
    margin-top: -24px;
}

.\!k-mt--6 {
    margin-top: -24px !important;
}

.k-mt--6\.5 {
    margin-top: -26px;
}

.\!k-mt--6\.5 {
    margin-top: -26px !important;
}

.k-mt--7 {
    margin-top: -28px;
}

.\!k-mt--7 {
    margin-top: -28px !important;
}

.k-mt--7\.5 {
    margin-top: -30px;
}

.\!k-mt--7\.5 {
    margin-top: -30px !important;
}

.k-mt--8 {
    margin-top: -32px;
}

.\!k-mt--8 {
    margin-top: -32px !important;
}

.k-mt--9 {
    margin-top: -36px;
}

.\!k-mt--9 {
    margin-top: -36px !important;
}

.k-mt--10 {
    margin-top: -40px;
}

.\!k-mt--10 {
    margin-top: -40px !important;
}

.k-mt--11 {
    margin-top: -44px;
}

.\!k-mt--11 {
    margin-top: -44px !important;
}

.k-mt--12 {
    margin-top: -48px;
}

.\!k-mt--12 {
    margin-top: -48px !important;
}

.k-mt--13 {
    margin-top: -52px;
}

.\!k-mt--13 {
    margin-top: -52px !important;
}

.k-mt--14 {
    margin-top: -56px;
}

.\!k-mt--14 {
    margin-top: -56px !important;
}

.k-mt--15 {
    margin-top: -60px;
}

.\!k-mt--15 {
    margin-top: -60px !important;
}

.k-mt--16 {
    margin-top: -64px;
}

.\!k-mt--16 {
    margin-top: -64px !important;
}

.k-mt--17 {
    margin-top: -68px;
}

.\!k-mt--17 {
    margin-top: -68px !important;
}

.k-mt--18 {
    margin-top: -72px;
}

.\!k-mt--18 {
    margin-top: -72px !important;
}

.k-mt--19 {
    margin-top: -76px;
}

.\!k-mt--19 {
    margin-top: -76px !important;
}

.k-mt--20 {
    margin-top: -80px;
}

.\!k-mt--20 {
    margin-top: -80px !important;
}

.k-mt--21 {
    margin-top: -84px;
}

.\!k-mt--21 {
    margin-top: -84px !important;
}

.k-mt--22 {
    margin-top: -88px;
}

.\!k-mt--22 {
    margin-top: -88px !important;
}

.k-mt--23 {
    margin-top: -92px;
}

.\!k-mt--23 {
    margin-top: -92px !important;
}

.k-mt--24 {
    margin-top: -96px;
}

.\!k-mt--24 {
    margin-top: -96px !important;
}

.k-mt-xs {
    margin-top: 4px;
}

.\!k-mt-xs {
    margin-top: 4px !important;
}

.k-mt-sm {
    margin-top: 8px;
}

.\!k-mt-sm {
    margin-top: 8px !important;
}

.k-mt-md {
    margin-top: 12px;
}

.\!k-mt-md {
    margin-top: 12px !important;
}

.k-mt-lg {
    margin-top: 16px;
}

.\!k-mt-lg {
    margin-top: 16px !important;
}

.k-mt-xl {
    margin-top: 24px;
}

.\!k-mt-xl {
    margin-top: 24px !important;
}

.k-mt-thin {
    margin-top: 2px;
}

.\!k-mt-thin {
    margin-top: 2px !important;
}

.k-mt-hair {
    margin-top: 1px;
}

.\!k-mt-hair {
    margin-top: 1px !important;
}

.k-mt-auto {
    margin-top: auto;
}

.\!k-mt-auto {
    margin-top: auto !important;
}

.k-mr-0 {
    margin-right: 0;
}

.\!k-mr-0 {
    margin-right: 0 !important;
}

.k-mr-1px {
    margin-right: 1px;
}

.\!k-mr-1px {
    margin-right: 1px !important;
}

.k-mr-0\.5 {
    margin-right: 2px;
}

.\!k-mr-0\.5 {
    margin-right: 2px !important;
}

.k-mr-1 {
    margin-right: 4px;
}

.\!k-mr-1 {
    margin-right: 4px !important;
}

.k-mr-1\.5 {
    margin-right: 6px;
}

.\!k-mr-1\.5 {
    margin-right: 6px !important;
}

.k-mr-2 {
    margin-right: 8px;
}

.\!k-mr-2 {
    margin-right: 8px !important;
}

.k-mr-2\.5 {
    margin-right: 10px;
}

.\!k-mr-2\.5 {
    margin-right: 10px !important;
}

.k-mr-3 {
    margin-right: 12px;
}

.\!k-mr-3 {
    margin-right: 12px !important;
}

.k-mr-3\.5 {
    margin-right: 14px;
}

.\!k-mr-3\.5 {
    margin-right: 14px !important;
}

.k-mr-4 {
    margin-right: 16px;
}

.\!k-mr-4 {
    margin-right: 16px !important;
}

.k-mr-4\.5 {
    margin-right: 18px;
}

.\!k-mr-4\.5 {
    margin-right: 18px !important;
}

.k-mr-5 {
    margin-right: 20px;
}

.\!k-mr-5 {
    margin-right: 20px !important;
}

.k-mr-5\.5 {
    margin-right: 22px;
}

.\!k-mr-5\.5 {
    margin-right: 22px !important;
}

.k-mr-6 {
    margin-right: 24px;
}

.\!k-mr-6 {
    margin-right: 24px !important;
}

.k-mr-6\.5 {
    margin-right: 26px;
}

.\!k-mr-6\.5 {
    margin-right: 26px !important;
}

.k-mr-7 {
    margin-right: 28px;
}

.\!k-mr-7 {
    margin-right: 28px !important;
}

.k-mr-7\.5 {
    margin-right: 30px;
}

.\!k-mr-7\.5 {
    margin-right: 30px !important;
}

.k-mr-8 {
    margin-right: 32px;
}

.\!k-mr-8 {
    margin-right: 32px !important;
}

.k-mr-9 {
    margin-right: 36px;
}

.\!k-mr-9 {
    margin-right: 36px !important;
}

.k-mr-10 {
    margin-right: 40px;
}

.\!k-mr-10 {
    margin-right: 40px !important;
}

.k-mr-11 {
    margin-right: 44px;
}

.\!k-mr-11 {
    margin-right: 44px !important;
}

.k-mr-12 {
    margin-right: 48px;
}

.\!k-mr-12 {
    margin-right: 48px !important;
}

.k-mr-13 {
    margin-right: 52px;
}

.\!k-mr-13 {
    margin-right: 52px !important;
}

.k-mr-14 {
    margin-right: 56px;
}

.\!k-mr-14 {
    margin-right: 56px !important;
}

.k-mr-15 {
    margin-right: 60px;
}

.\!k-mr-15 {
    margin-right: 60px !important;
}

.k-mr-16 {
    margin-right: 64px;
}

.\!k-mr-16 {
    margin-right: 64px !important;
}

.k-mr-17 {
    margin-right: 68px;
}

.\!k-mr-17 {
    margin-right: 68px !important;
}

.k-mr-18 {
    margin-right: 72px;
}

.\!k-mr-18 {
    margin-right: 72px !important;
}

.k-mr-19 {
    margin-right: 76px;
}

.\!k-mr-19 {
    margin-right: 76px !important;
}

.k-mr-20 {
    margin-right: 80px;
}

.\!k-mr-20 {
    margin-right: 80px !important;
}

.k-mr-21 {
    margin-right: 84px;
}

.\!k-mr-21 {
    margin-right: 84px !important;
}

.k-mr-22 {
    margin-right: 88px;
}

.\!k-mr-22 {
    margin-right: 88px !important;
}

.k-mr-23 {
    margin-right: 92px;
}

.\!k-mr-23 {
    margin-right: 92px !important;
}

.k-mr-24 {
    margin-right: 96px;
}

.\!k-mr-24 {
    margin-right: 96px !important;
}

.k-mr--1px {
    margin-right: -1px;
}

.\!k-mr--1px {
    margin-right: -1px !important;
}

.k-mr--0\.5 {
    margin-right: -2px;
}

.\!k-mr--0\.5 {
    margin-right: -2px !important;
}

.k-mr--1 {
    margin-right: -4px;
}

.\!k-mr--1 {
    margin-right: -4px !important;
}

.k-mr--1\.5 {
    margin-right: -6px;
}

.\!k-mr--1\.5 {
    margin-right: -6px !important;
}

.k-mr--2 {
    margin-right: -8px;
}

.\!k-mr--2 {
    margin-right: -8px !important;
}

.k-mr--2\.5 {
    margin-right: -10px;
}

.\!k-mr--2\.5 {
    margin-right: -10px !important;
}

.k-mr--3 {
    margin-right: -12px;
}

.\!k-mr--3 {
    margin-right: -12px !important;
}

.k-mr--3\.5 {
    margin-right: -14px;
}

.\!k-mr--3\.5 {
    margin-right: -14px !important;
}

.k-mr--4 {
    margin-right: -16px;
}

.\!k-mr--4 {
    margin-right: -16px !important;
}

.k-mr--4\.5 {
    margin-right: -18px;
}

.\!k-mr--4\.5 {
    margin-right: -18px !important;
}

.k-mr--5 {
    margin-right: -20px;
}

.\!k-mr--5 {
    margin-right: -20px !important;
}

.k-mr--5\.5 {
    margin-right: -22px;
}

.\!k-mr--5\.5 {
    margin-right: -22px !important;
}

.k-mr--6 {
    margin-right: -24px;
}

.\!k-mr--6 {
    margin-right: -24px !important;
}

.k-mr--6\.5 {
    margin-right: -26px;
}

.\!k-mr--6\.5 {
    margin-right: -26px !important;
}

.k-mr--7 {
    margin-right: -28px;
}

.\!k-mr--7 {
    margin-right: -28px !important;
}

.k-mr--7\.5 {
    margin-right: -30px;
}

.\!k-mr--7\.5 {
    margin-right: -30px !important;
}

.k-mr--8 {
    margin-right: -32px;
}

.\!k-mr--8 {
    margin-right: -32px !important;
}

.k-mr--9 {
    margin-right: -36px;
}

.\!k-mr--9 {
    margin-right: -36px !important;
}

.k-mr--10 {
    margin-right: -40px;
}

.\!k-mr--10 {
    margin-right: -40px !important;
}

.k-mr--11 {
    margin-right: -44px;
}

.\!k-mr--11 {
    margin-right: -44px !important;
}

.k-mr--12 {
    margin-right: -48px;
}

.\!k-mr--12 {
    margin-right: -48px !important;
}

.k-mr--13 {
    margin-right: -52px;
}

.\!k-mr--13 {
    margin-right: -52px !important;
}

.k-mr--14 {
    margin-right: -56px;
}

.\!k-mr--14 {
    margin-right: -56px !important;
}

.k-mr--15 {
    margin-right: -60px;
}

.\!k-mr--15 {
    margin-right: -60px !important;
}

.k-mr--16 {
    margin-right: -64px;
}

.\!k-mr--16 {
    margin-right: -64px !important;
}

.k-mr--17 {
    margin-right: -68px;
}

.\!k-mr--17 {
    margin-right: -68px !important;
}

.k-mr--18 {
    margin-right: -72px;
}

.\!k-mr--18 {
    margin-right: -72px !important;
}

.k-mr--19 {
    margin-right: -76px;
}

.\!k-mr--19 {
    margin-right: -76px !important;
}

.k-mr--20 {
    margin-right: -80px;
}

.\!k-mr--20 {
    margin-right: -80px !important;
}

.k-mr--21 {
    margin-right: -84px;
}

.\!k-mr--21 {
    margin-right: -84px !important;
}

.k-mr--22 {
    margin-right: -88px;
}

.\!k-mr--22 {
    margin-right: -88px !important;
}

.k-mr--23 {
    margin-right: -92px;
}

.\!k-mr--23 {
    margin-right: -92px !important;
}

.k-mr--24 {
    margin-right: -96px;
}

.\!k-mr--24 {
    margin-right: -96px !important;
}

.k-mr-xs {
    margin-right: 4px;
}

.\!k-mr-xs {
    margin-right: 4px !important;
}

.k-mr-sm {
    margin-right: 8px;
}

.\!k-mr-sm {
    margin-right: 8px !important;
}

.k-mr-md {
    margin-right: 12px;
}

.\!k-mr-md {
    margin-right: 12px !important;
}

.k-mr-lg {
    margin-right: 16px;
}

.\!k-mr-lg {
    margin-right: 16px !important;
}

.k-mr-xl {
    margin-right: 24px;
}

.\!k-mr-xl {
    margin-right: 24px !important;
}

.k-mr-thin {
    margin-right: 2px;
}

.\!k-mr-thin {
    margin-right: 2px !important;
}

.k-mr-hair {
    margin-right: 1px;
}

.\!k-mr-hair {
    margin-right: 1px !important;
}

.k-mr-auto {
    margin-right: auto;
}

.\!k-mr-auto {
    margin-right: auto !important;
}

.k-mb-0 {
    margin-bottom: 0;
}

.\!k-mb-0 {
    margin-bottom: 0 !important;
}

.k-mb-1px {
    margin-bottom: 1px;
}

.\!k-mb-1px {
    margin-bottom: 1px !important;
}

.k-mb-0\.5 {
    margin-bottom: 2px;
}

.\!k-mb-0\.5 {
    margin-bottom: 2px !important;
}

.k-mb-1 {
    margin-bottom: 4px;
}

.\!k-mb-1 {
    margin-bottom: 4px !important;
}

.k-mb-1\.5 {
    margin-bottom: 6px;
}

.\!k-mb-1\.5 {
    margin-bottom: 6px !important;
}

.k-mb-2 {
    margin-bottom: 8px;
}

.\!k-mb-2 {
    margin-bottom: 8px !important;
}

.k-mb-2\.5 {
    margin-bottom: 10px;
}

.\!k-mb-2\.5 {
    margin-bottom: 10px !important;
}

.k-mb-3 {
    margin-bottom: 12px;
}

.\!k-mb-3 {
    margin-bottom: 12px !important;
}

.k-mb-3\.5 {
    margin-bottom: 14px;
}

.\!k-mb-3\.5 {
    margin-bottom: 14px !important;
}

.k-mb-4 {
    margin-bottom: 16px;
}

.\!k-mb-4 {
    margin-bottom: 16px !important;
}

.k-mb-4\.5 {
    margin-bottom: 18px;
}

.\!k-mb-4\.5 {
    margin-bottom: 18px !important;
}

.k-mb-5 {
    margin-bottom: 20px;
}

.\!k-mb-5 {
    margin-bottom: 20px !important;
}

.k-mb-5\.5 {
    margin-bottom: 22px;
}

.\!k-mb-5\.5 {
    margin-bottom: 22px !important;
}

.k-mb-6 {
    margin-bottom: 24px;
}

.\!k-mb-6 {
    margin-bottom: 24px !important;
}

.k-mb-6\.5 {
    margin-bottom: 26px;
}

.\!k-mb-6\.5 {
    margin-bottom: 26px !important;
}

.k-mb-7 {
    margin-bottom: 28px;
}

.\!k-mb-7 {
    margin-bottom: 28px !important;
}

.k-mb-7\.5 {
    margin-bottom: 30px;
}

.\!k-mb-7\.5 {
    margin-bottom: 30px !important;
}

.k-mb-8 {
    margin-bottom: 32px;
}

.\!k-mb-8 {
    margin-bottom: 32px !important;
}

.k-mb-9 {
    margin-bottom: 36px;
}

.\!k-mb-9 {
    margin-bottom: 36px !important;
}

.k-mb-10 {
    margin-bottom: 40px;
}

.\!k-mb-10 {
    margin-bottom: 40px !important;
}

.k-mb-11 {
    margin-bottom: 44px;
}

.\!k-mb-11 {
    margin-bottom: 44px !important;
}

.k-mb-12 {
    margin-bottom: 48px;
}

.\!k-mb-12 {
    margin-bottom: 48px !important;
}

.k-mb-13 {
    margin-bottom: 52px;
}

.\!k-mb-13 {
    margin-bottom: 52px !important;
}

.k-mb-14 {
    margin-bottom: 56px;
}

.\!k-mb-14 {
    margin-bottom: 56px !important;
}

.k-mb-15 {
    margin-bottom: 60px;
}

.\!k-mb-15 {
    margin-bottom: 60px !important;
}

.k-mb-16 {
    margin-bottom: 64px;
}

.\!k-mb-16 {
    margin-bottom: 64px !important;
}

.k-mb-17 {
    margin-bottom: 68px;
}

.\!k-mb-17 {
    margin-bottom: 68px !important;
}

.k-mb-18 {
    margin-bottom: 72px;
}

.\!k-mb-18 {
    margin-bottom: 72px !important;
}

.k-mb-19 {
    margin-bottom: 76px;
}

.\!k-mb-19 {
    margin-bottom: 76px !important;
}

.k-mb-20 {
    margin-bottom: 80px;
}

.\!k-mb-20 {
    margin-bottom: 80px !important;
}

.k-mb-21 {
    margin-bottom: 84px;
}

.\!k-mb-21 {
    margin-bottom: 84px !important;
}

.k-mb-22 {
    margin-bottom: 88px;
}

.\!k-mb-22 {
    margin-bottom: 88px !important;
}

.k-mb-23 {
    margin-bottom: 92px;
}

.\!k-mb-23 {
    margin-bottom: 92px !important;
}

.k-mb-24 {
    margin-bottom: 96px;
}

.\!k-mb-24 {
    margin-bottom: 96px !important;
}

.k-mb--1px {
    margin-bottom: -1px;
}

.\!k-mb--1px {
    margin-bottom: -1px !important;
}

.k-mb--0\.5 {
    margin-bottom: -2px;
}

.\!k-mb--0\.5 {
    margin-bottom: -2px !important;
}

.k-mb--1 {
    margin-bottom: -4px;
}

.\!k-mb--1 {
    margin-bottom: -4px !important;
}

.k-mb--1\.5 {
    margin-bottom: -6px;
}

.\!k-mb--1\.5 {
    margin-bottom: -6px !important;
}

.k-mb--2 {
    margin-bottom: -8px;
}

.\!k-mb--2 {
    margin-bottom: -8px !important;
}

.k-mb--2\.5 {
    margin-bottom: -10px;
}

.\!k-mb--2\.5 {
    margin-bottom: -10px !important;
}

.k-mb--3 {
    margin-bottom: -12px;
}

.\!k-mb--3 {
    margin-bottom: -12px !important;
}

.k-mb--3\.5 {
    margin-bottom: -14px;
}

.\!k-mb--3\.5 {
    margin-bottom: -14px !important;
}

.k-mb--4 {
    margin-bottom: -16px;
}

.\!k-mb--4 {
    margin-bottom: -16px !important;
}

.k-mb--4\.5 {
    margin-bottom: -18px;
}

.\!k-mb--4\.5 {
    margin-bottom: -18px !important;
}

.k-mb--5 {
    margin-bottom: -20px;
}

.\!k-mb--5 {
    margin-bottom: -20px !important;
}

.k-mb--5\.5 {
    margin-bottom: -22px;
}

.\!k-mb--5\.5 {
    margin-bottom: -22px !important;
}

.k-mb--6 {
    margin-bottom: -24px;
}

.\!k-mb--6 {
    margin-bottom: -24px !important;
}

.k-mb--6\.5 {
    margin-bottom: -26px;
}

.\!k-mb--6\.5 {
    margin-bottom: -26px !important;
}

.k-mb--7 {
    margin-bottom: -28px;
}

.\!k-mb--7 {
    margin-bottom: -28px !important;
}

.k-mb--7\.5 {
    margin-bottom: -30px;
}

.\!k-mb--7\.5 {
    margin-bottom: -30px !important;
}

.k-mb--8 {
    margin-bottom: -32px;
}

.\!k-mb--8 {
    margin-bottom: -32px !important;
}

.k-mb--9 {
    margin-bottom: -36px;
}

.\!k-mb--9 {
    margin-bottom: -36px !important;
}

.k-mb--10 {
    margin-bottom: -40px;
}

.\!k-mb--10 {
    margin-bottom: -40px !important;
}

.k-mb--11 {
    margin-bottom: -44px;
}

.\!k-mb--11 {
    margin-bottom: -44px !important;
}

.k-mb--12 {
    margin-bottom: -48px;
}

.\!k-mb--12 {
    margin-bottom: -48px !important;
}

.k-mb--13 {
    margin-bottom: -52px;
}

.\!k-mb--13 {
    margin-bottom: -52px !important;
}

.k-mb--14 {
    margin-bottom: -56px;
}

.\!k-mb--14 {
    margin-bottom: -56px !important;
}

.k-mb--15 {
    margin-bottom: -60px;
}

.\!k-mb--15 {
    margin-bottom: -60px !important;
}

.k-mb--16 {
    margin-bottom: -64px;
}

.\!k-mb--16 {
    margin-bottom: -64px !important;
}

.k-mb--17 {
    margin-bottom: -68px;
}

.\!k-mb--17 {
    margin-bottom: -68px !important;
}

.k-mb--18 {
    margin-bottom: -72px;
}

.\!k-mb--18 {
    margin-bottom: -72px !important;
}

.k-mb--19 {
    margin-bottom: -76px;
}

.\!k-mb--19 {
    margin-bottom: -76px !important;
}

.k-mb--20 {
    margin-bottom: -80px;
}

.\!k-mb--20 {
    margin-bottom: -80px !important;
}

.k-mb--21 {
    margin-bottom: -84px;
}

.\!k-mb--21 {
    margin-bottom: -84px !important;
}

.k-mb--22 {
    margin-bottom: -88px;
}

.\!k-mb--22 {
    margin-bottom: -88px !important;
}

.k-mb--23 {
    margin-bottom: -92px;
}

.\!k-mb--23 {
    margin-bottom: -92px !important;
}

.k-mb--24 {
    margin-bottom: -96px;
}

.\!k-mb--24 {
    margin-bottom: -96px !important;
}

.k-mb-xs {
    margin-bottom: 4px;
}

.\!k-mb-xs {
    margin-bottom: 4px !important;
}

.k-mb-sm {
    margin-bottom: 8px;
}

.\!k-mb-sm {
    margin-bottom: 8px !important;
}

.k-mb-md {
    margin-bottom: 12px;
}

.\!k-mb-md {
    margin-bottom: 12px !important;
}

.k-mb-lg {
    margin-bottom: 16px;
}

.\!k-mb-lg {
    margin-bottom: 16px !important;
}

.k-mb-xl {
    margin-bottom: 24px;
}

.\!k-mb-xl {
    margin-bottom: 24px !important;
}

.k-mb-thin {
    margin-bottom: 2px;
}

.\!k-mb-thin {
    margin-bottom: 2px !important;
}

.k-mb-hair {
    margin-bottom: 1px;
}

.\!k-mb-hair {
    margin-bottom: 1px !important;
}

.k-mb-auto {
    margin-bottom: auto;
}

.\!k-mb-auto {
    margin-bottom: auto !important;
}

.k-ml-0 {
    margin-left: 0;
}

.\!k-ml-0 {
    margin-left: 0 !important;
}

.k-ml-1px {
    margin-left: 1px;
}

.\!k-ml-1px {
    margin-left: 1px !important;
}

.k-ml-0\.5 {
    margin-left: 2px;
}

.\!k-ml-0\.5 {
    margin-left: 2px !important;
}

.k-ml-1 {
    margin-left: 4px;
}

.\!k-ml-1 {
    margin-left: 4px !important;
}

.k-ml-1\.5 {
    margin-left: 6px;
}

.\!k-ml-1\.5 {
    margin-left: 6px !important;
}

.k-ml-2 {
    margin-left: 8px;
}

.\!k-ml-2 {
    margin-left: 8px !important;
}

.k-ml-2\.5 {
    margin-left: 10px;
}

.\!k-ml-2\.5 {
    margin-left: 10px !important;
}

.k-ml-3 {
    margin-left: 12px;
}

.\!k-ml-3 {
    margin-left: 12px !important;
}

.k-ml-3\.5 {
    margin-left: 14px;
}

.\!k-ml-3\.5 {
    margin-left: 14px !important;
}

.k-ml-4 {
    margin-left: 16px;
}

.\!k-ml-4 {
    margin-left: 16px !important;
}

.k-ml-4\.5 {
    margin-left: 18px;
}

.\!k-ml-4\.5 {
    margin-left: 18px !important;
}

.k-ml-5 {
    margin-left: 20px;
}

.\!k-ml-5 {
    margin-left: 20px !important;
}

.k-ml-5\.5 {
    margin-left: 22px;
}

.\!k-ml-5\.5 {
    margin-left: 22px !important;
}

.k-ml-6 {
    margin-left: 24px;
}

.\!k-ml-6 {
    margin-left: 24px !important;
}

.k-ml-6\.5 {
    margin-left: 26px;
}

.\!k-ml-6\.5 {
    margin-left: 26px !important;
}

.k-ml-7 {
    margin-left: 28px;
}

.\!k-ml-7 {
    margin-left: 28px !important;
}

.k-ml-7\.5 {
    margin-left: 30px;
}

.\!k-ml-7\.5 {
    margin-left: 30px !important;
}

.k-ml-8 {
    margin-left: 32px;
}

.\!k-ml-8 {
    margin-left: 32px !important;
}

.k-ml-9 {
    margin-left: 36px;
}

.\!k-ml-9 {
    margin-left: 36px !important;
}

.k-ml-10 {
    margin-left: 40px;
}

.\!k-ml-10 {
    margin-left: 40px !important;
}

.k-ml-11 {
    margin-left: 44px;
}

.\!k-ml-11 {
    margin-left: 44px !important;
}

.k-ml-12 {
    margin-left: 48px;
}

.\!k-ml-12 {
    margin-left: 48px !important;
}

.k-ml-13 {
    margin-left: 52px;
}

.\!k-ml-13 {
    margin-left: 52px !important;
}

.k-ml-14 {
    margin-left: 56px;
}

.\!k-ml-14 {
    margin-left: 56px !important;
}

.k-ml-15 {
    margin-left: 60px;
}

.\!k-ml-15 {
    margin-left: 60px !important;
}

.k-ml-16 {
    margin-left: 64px;
}

.\!k-ml-16 {
    margin-left: 64px !important;
}

.k-ml-17 {
    margin-left: 68px;
}

.\!k-ml-17 {
    margin-left: 68px !important;
}

.k-ml-18 {
    margin-left: 72px;
}

.\!k-ml-18 {
    margin-left: 72px !important;
}

.k-ml-19 {
    margin-left: 76px;
}

.\!k-ml-19 {
    margin-left: 76px !important;
}

.k-ml-20 {
    margin-left: 80px;
}

.\!k-ml-20 {
    margin-left: 80px !important;
}

.k-ml-21 {
    margin-left: 84px;
}

.\!k-ml-21 {
    margin-left: 84px !important;
}

.k-ml-22 {
    margin-left: 88px;
}

.\!k-ml-22 {
    margin-left: 88px !important;
}

.k-ml-23 {
    margin-left: 92px;
}

.\!k-ml-23 {
    margin-left: 92px !important;
}

.k-ml-24 {
    margin-left: 96px;
}

.\!k-ml-24 {
    margin-left: 96px !important;
}

.k-ml--1px {
    margin-left: -1px;
}

.\!k-ml--1px {
    margin-left: -1px !important;
}

.k-ml--0\.5 {
    margin-left: -2px;
}

.\!k-ml--0\.5 {
    margin-left: -2px !important;
}

.k-ml--1 {
    margin-left: -4px;
}

.\!k-ml--1 {
    margin-left: -4px !important;
}

.k-ml--1\.5 {
    margin-left: -6px;
}

.\!k-ml--1\.5 {
    margin-left: -6px !important;
}

.k-ml--2 {
    margin-left: -8px;
}

.\!k-ml--2 {
    margin-left: -8px !important;
}

.k-ml--2\.5 {
    margin-left: -10px;
}

.\!k-ml--2\.5 {
    margin-left: -10px !important;
}

.k-ml--3 {
    margin-left: -12px;
}

.\!k-ml--3 {
    margin-left: -12px !important;
}

.k-ml--3\.5 {
    margin-left: -14px;
}

.\!k-ml--3\.5 {
    margin-left: -14px !important;
}

.k-ml--4 {
    margin-left: -16px;
}

.\!k-ml--4 {
    margin-left: -16px !important;
}

.k-ml--4\.5 {
    margin-left: -18px;
}

.\!k-ml--4\.5 {
    margin-left: -18px !important;
}

.k-ml--5 {
    margin-left: -20px;
}

.\!k-ml--5 {
    margin-left: -20px !important;
}

.k-ml--5\.5 {
    margin-left: -22px;
}

.\!k-ml--5\.5 {
    margin-left: -22px !important;
}

.k-ml--6 {
    margin-left: -24px;
}

.\!k-ml--6 {
    margin-left: -24px !important;
}

.k-ml--6\.5 {
    margin-left: -26px;
}

.\!k-ml--6\.5 {
    margin-left: -26px !important;
}

.k-ml--7 {
    margin-left: -28px;
}

.\!k-ml--7 {
    margin-left: -28px !important;
}

.k-ml--7\.5 {
    margin-left: -30px;
}

.\!k-ml--7\.5 {
    margin-left: -30px !important;
}

.k-ml--8 {
    margin-left: -32px;
}

.\!k-ml--8 {
    margin-left: -32px !important;
}

.k-ml--9 {
    margin-left: -36px;
}

.\!k-ml--9 {
    margin-left: -36px !important;
}

.k-ml--10 {
    margin-left: -40px;
}

.\!k-ml--10 {
    margin-left: -40px !important;
}

.k-ml--11 {
    margin-left: -44px;
}

.\!k-ml--11 {
    margin-left: -44px !important;
}

.k-ml--12 {
    margin-left: -48px;
}

.\!k-ml--12 {
    margin-left: -48px !important;
}

.k-ml--13 {
    margin-left: -52px;
}

.\!k-ml--13 {
    margin-left: -52px !important;
}

.k-ml--14 {
    margin-left: -56px;
}

.\!k-ml--14 {
    margin-left: -56px !important;
}

.k-ml--15 {
    margin-left: -60px;
}

.\!k-ml--15 {
    margin-left: -60px !important;
}

.k-ml--16 {
    margin-left: -64px;
}

.\!k-ml--16 {
    margin-left: -64px !important;
}

.k-ml--17 {
    margin-left: -68px;
}

.\!k-ml--17 {
    margin-left: -68px !important;
}

.k-ml--18 {
    margin-left: -72px;
}

.\!k-ml--18 {
    margin-left: -72px !important;
}

.k-ml--19 {
    margin-left: -76px;
}

.\!k-ml--19 {
    margin-left: -76px !important;
}

.k-ml--20 {
    margin-left: -80px;
}

.\!k-ml--20 {
    margin-left: -80px !important;
}

.k-ml--21 {
    margin-left: -84px;
}

.\!k-ml--21 {
    margin-left: -84px !important;
}

.k-ml--22 {
    margin-left: -88px;
}

.\!k-ml--22 {
    margin-left: -88px !important;
}

.k-ml--23 {
    margin-left: -92px;
}

.\!k-ml--23 {
    margin-left: -92px !important;
}

.k-ml--24 {
    margin-left: -96px;
}

.\!k-ml--24 {
    margin-left: -96px !important;
}

.k-ml-xs {
    margin-left: 4px;
}

.\!k-ml-xs {
    margin-left: 4px !important;
}

.k-ml-sm {
    margin-left: 8px;
}

.\!k-ml-sm {
    margin-left: 8px !important;
}

.k-ml-md {
    margin-left: 12px;
}

.\!k-ml-md {
    margin-left: 12px !important;
}

.k-ml-lg {
    margin-left: 16px;
}

.\!k-ml-lg {
    margin-left: 16px !important;
}

.k-ml-xl {
    margin-left: 24px;
}

.\!k-ml-xl {
    margin-left: 24px !important;
}

.k-ml-thin {
    margin-left: 2px;
}

.\!k-ml-thin {
    margin-left: 2px !important;
}

.k-ml-hair {
    margin-left: 1px;
}

.\!k-ml-hair {
    margin-left: 1px !important;
}

.k-ml-auto {
    margin-left: auto;
}

.\!k-ml-auto {
    margin-left: auto !important;
}

.k-mx-0 {
    margin-inline: 0;
}

.\!k-mx-0 {
    margin-inline: 0 !important;
}

.k-mx-1px {
    margin-inline: 1px;
}

.\!k-mx-1px {
    margin-inline: 1px !important;
}

.k-mx-0\.5 {
    margin-inline: 2px;
}

.\!k-mx-0\.5 {
    margin-inline: 2px !important;
}

.k-mx-1 {
    margin-inline: 4px;
}

.\!k-mx-1 {
    margin-inline: 4px !important;
}

.k-mx-1\.5 {
    margin-inline: 6px;
}

.\!k-mx-1\.5 {
    margin-inline: 6px !important;
}

.k-mx-2 {
    margin-inline: 8px;
}

.\!k-mx-2 {
    margin-inline: 8px !important;
}

.k-mx-2\.5 {
    margin-inline: 10px;
}

.\!k-mx-2\.5 {
    margin-inline: 10px !important;
}

.k-mx-3 {
    margin-inline: 12px;
}

.\!k-mx-3 {
    margin-inline: 12px !important;
}

.k-mx-3\.5 {
    margin-inline: 14px;
}

.\!k-mx-3\.5 {
    margin-inline: 14px !important;
}

.k-mx-4 {
    margin-inline: 16px;
}

.\!k-mx-4 {
    margin-inline: 16px !important;
}

.k-mx-4\.5 {
    margin-inline: 18px;
}

.\!k-mx-4\.5 {
    margin-inline: 18px !important;
}

.k-mx-5 {
    margin-inline: 20px;
}

.\!k-mx-5 {
    margin-inline: 20px !important;
}

.k-mx-5\.5 {
    margin-inline: 22px;
}

.\!k-mx-5\.5 {
    margin-inline: 22px !important;
}

.k-mx-6 {
    margin-inline: 24px;
}

.\!k-mx-6 {
    margin-inline: 24px !important;
}

.k-mx-6\.5 {
    margin-inline: 26px;
}

.\!k-mx-6\.5 {
    margin-inline: 26px !important;
}

.k-mx-7 {
    margin-inline: 28px;
}

.\!k-mx-7 {
    margin-inline: 28px !important;
}

.k-mx-7\.5 {
    margin-inline: 30px;
}

.\!k-mx-7\.5 {
    margin-inline: 30px !important;
}

.k-mx-8 {
    margin-inline: 32px;
}

.\!k-mx-8 {
    margin-inline: 32px !important;
}

.k-mx-9 {
    margin-inline: 36px;
}

.\!k-mx-9 {
    margin-inline: 36px !important;
}

.k-mx-10 {
    margin-inline: 40px;
}

.\!k-mx-10 {
    margin-inline: 40px !important;
}

.k-mx-11 {
    margin-inline: 44px;
}

.\!k-mx-11 {
    margin-inline: 44px !important;
}

.k-mx-12 {
    margin-inline: 48px;
}

.\!k-mx-12 {
    margin-inline: 48px !important;
}

.k-mx-13 {
    margin-inline: 52px;
}

.\!k-mx-13 {
    margin-inline: 52px !important;
}

.k-mx-14 {
    margin-inline: 56px;
}

.\!k-mx-14 {
    margin-inline: 56px !important;
}

.k-mx-15 {
    margin-inline: 60px;
}

.\!k-mx-15 {
    margin-inline: 60px !important;
}

.k-mx-16 {
    margin-inline: 64px;
}

.\!k-mx-16 {
    margin-inline: 64px !important;
}

.k-mx-17 {
    margin-inline: 68px;
}

.\!k-mx-17 {
    margin-inline: 68px !important;
}

.k-mx-18 {
    margin-inline: 72px;
}

.\!k-mx-18 {
    margin-inline: 72px !important;
}

.k-mx-19 {
    margin-inline: 76px;
}

.\!k-mx-19 {
    margin-inline: 76px !important;
}

.k-mx-20 {
    margin-inline: 80px;
}

.\!k-mx-20 {
    margin-inline: 80px !important;
}

.k-mx-21 {
    margin-inline: 84px;
}

.\!k-mx-21 {
    margin-inline: 84px !important;
}

.k-mx-22 {
    margin-inline: 88px;
}

.\!k-mx-22 {
    margin-inline: 88px !important;
}

.k-mx-23 {
    margin-inline: 92px;
}

.\!k-mx-23 {
    margin-inline: 92px !important;
}

.k-mx-24 {
    margin-inline: 96px;
}

.\!k-mx-24 {
    margin-inline: 96px !important;
}

.k-mx--1px {
    margin-inline: -1px;
}

.\!k-mx--1px {
    margin-inline: -1px !important;
}

.k-mx--0\.5 {
    margin-inline: -2px;
}

.\!k-mx--0\.5 {
    margin-inline: -2px !important;
}

.k-mx--1 {
    margin-inline: -4px;
}

.\!k-mx--1 {
    margin-inline: -4px !important;
}

.k-mx--1\.5 {
    margin-inline: -6px;
}

.\!k-mx--1\.5 {
    margin-inline: -6px !important;
}

.k-mx--2 {
    margin-inline: -8px;
}

.\!k-mx--2 {
    margin-inline: -8px !important;
}

.k-mx--2\.5 {
    margin-inline: -10px;
}

.\!k-mx--2\.5 {
    margin-inline: -10px !important;
}

.k-mx--3 {
    margin-inline: -12px;
}

.\!k-mx--3 {
    margin-inline: -12px !important;
}

.k-mx--3\.5 {
    margin-inline: -14px;
}

.\!k-mx--3\.5 {
    margin-inline: -14px !important;
}

.k-mx--4 {
    margin-inline: -16px;
}

.\!k-mx--4 {
    margin-inline: -16px !important;
}

.k-mx--4\.5 {
    margin-inline: -18px;
}

.\!k-mx--4\.5 {
    margin-inline: -18px !important;
}

.k-mx--5 {
    margin-inline: -20px;
}

.\!k-mx--5 {
    margin-inline: -20px !important;
}

.k-mx--5\.5 {
    margin-inline: -22px;
}

.\!k-mx--5\.5 {
    margin-inline: -22px !important;
}

.k-mx--6 {
    margin-inline: -24px;
}

.\!k-mx--6 {
    margin-inline: -24px !important;
}

.k-mx--6\.5 {
    margin-inline: -26px;
}

.\!k-mx--6\.5 {
    margin-inline: -26px !important;
}

.k-mx--7 {
    margin-inline: -28px;
}

.\!k-mx--7 {
    margin-inline: -28px !important;
}

.k-mx--7\.5 {
    margin-inline: -30px;
}

.\!k-mx--7\.5 {
    margin-inline: -30px !important;
}

.k-mx--8 {
    margin-inline: -32px;
}

.\!k-mx--8 {
    margin-inline: -32px !important;
}

.k-mx--9 {
    margin-inline: -36px;
}

.\!k-mx--9 {
    margin-inline: -36px !important;
}

.k-mx--10 {
    margin-inline: -40px;
}

.\!k-mx--10 {
    margin-inline: -40px !important;
}

.k-mx--11 {
    margin-inline: -44px;
}

.\!k-mx--11 {
    margin-inline: -44px !important;
}

.k-mx--12 {
    margin-inline: -48px;
}

.\!k-mx--12 {
    margin-inline: -48px !important;
}

.k-mx--13 {
    margin-inline: -52px;
}

.\!k-mx--13 {
    margin-inline: -52px !important;
}

.k-mx--14 {
    margin-inline: -56px;
}

.\!k-mx--14 {
    margin-inline: -56px !important;
}

.k-mx--15 {
    margin-inline: -60px;
}

.\!k-mx--15 {
    margin-inline: -60px !important;
}

.k-mx--16 {
    margin-inline: -64px;
}

.\!k-mx--16 {
    margin-inline: -64px !important;
}

.k-mx--17 {
    margin-inline: -68px;
}

.\!k-mx--17 {
    margin-inline: -68px !important;
}

.k-mx--18 {
    margin-inline: -72px;
}

.\!k-mx--18 {
    margin-inline: -72px !important;
}

.k-mx--19 {
    margin-inline: -76px;
}

.\!k-mx--19 {
    margin-inline: -76px !important;
}

.k-mx--20 {
    margin-inline: -80px;
}

.\!k-mx--20 {
    margin-inline: -80px !important;
}

.k-mx--21 {
    margin-inline: -84px;
}

.\!k-mx--21 {
    margin-inline: -84px !important;
}

.k-mx--22 {
    margin-inline: -88px;
}

.\!k-mx--22 {
    margin-inline: -88px !important;
}

.k-mx--23 {
    margin-inline: -92px;
}

.\!k-mx--23 {
    margin-inline: -92px !important;
}

.k-mx--24 {
    margin-inline: -96px;
}

.\!k-mx--24 {
    margin-inline: -96px !important;
}

.k-mx-xs {
    margin-inline: 4px;
}

.\!k-mx-xs {
    margin-inline: 4px !important;
}

.k-mx-sm {
    margin-inline: 8px;
}

.\!k-mx-sm {
    margin-inline: 8px !important;
}

.k-mx-md {
    margin-inline: 12px;
}

.\!k-mx-md {
    margin-inline: 12px !important;
}

.k-mx-lg {
    margin-inline: 16px;
}

.\!k-mx-lg {
    margin-inline: 16px !important;
}

.k-mx-xl {
    margin-inline: 24px;
}

.\!k-mx-xl {
    margin-inline: 24px !important;
}

.k-mx-thin {
    margin-inline: 2px;
}

.\!k-mx-thin {
    margin-inline: 2px !important;
}

.k-mx-hair {
    margin-inline: 1px;
}

.\!k-mx-hair {
    margin-inline: 1px !important;
}

.k-mx-auto {
    margin-inline: auto;
}

.\!k-mx-auto {
    margin-inline: auto !important;
}

.k-my-0 {
    margin-block: 0;
}

.\!k-my-0 {
    margin-block: 0 !important;
}

.k-my-1px {
    margin-block: 1px;
}

.\!k-my-1px {
    margin-block: 1px !important;
}

.k-my-0\.5 {
    margin-block: 2px;
}

.\!k-my-0\.5 {
    margin-block: 2px !important;
}

.k-my-1 {
    margin-block: 4px;
}

.\!k-my-1 {
    margin-block: 4px !important;
}

.k-my-1\.5 {
    margin-block: 6px;
}

.\!k-my-1\.5 {
    margin-block: 6px !important;
}

.k-my-2 {
    margin-block: 8px;
}

.\!k-my-2 {
    margin-block: 8px !important;
}

.k-my-2\.5 {
    margin-block: 10px;
}

.\!k-my-2\.5 {
    margin-block: 10px !important;
}

.k-my-3 {
    margin-block: 12px;
}

.\!k-my-3 {
    margin-block: 12px !important;
}

.k-my-3\.5 {
    margin-block: 14px;
}

.\!k-my-3\.5 {
    margin-block: 14px !important;
}

.k-my-4 {
    margin-block: 16px;
}

.\!k-my-4 {
    margin-block: 16px !important;
}

.k-my-4\.5 {
    margin-block: 18px;
}

.\!k-my-4\.5 {
    margin-block: 18px !important;
}

.k-my-5 {
    margin-block: 20px;
}

.\!k-my-5 {
    margin-block: 20px !important;
}

.k-my-5\.5 {
    margin-block: 22px;
}

.\!k-my-5\.5 {
    margin-block: 22px !important;
}

.k-my-6 {
    margin-block: 24px;
}

.\!k-my-6 {
    margin-block: 24px !important;
}

.k-my-6\.5 {
    margin-block: 26px;
}

.\!k-my-6\.5 {
    margin-block: 26px !important;
}

.k-my-7 {
    margin-block: 28px;
}

.\!k-my-7 {
    margin-block: 28px !important;
}

.k-my-7\.5 {
    margin-block: 30px;
}

.\!k-my-7\.5 {
    margin-block: 30px !important;
}

.k-my-8 {
    margin-block: 32px;
}

.\!k-my-8 {
    margin-block: 32px !important;
}

.k-my-9 {
    margin-block: 36px;
}

.\!k-my-9 {
    margin-block: 36px !important;
}

.k-my-10 {
    margin-block: 40px;
}

.\!k-my-10 {
    margin-block: 40px !important;
}

.k-my-11 {
    margin-block: 44px;
}

.\!k-my-11 {
    margin-block: 44px !important;
}

.k-my-12 {
    margin-block: 48px;
}

.\!k-my-12 {
    margin-block: 48px !important;
}

.k-my-13 {
    margin-block: 52px;
}

.\!k-my-13 {
    margin-block: 52px !important;
}

.k-my-14 {
    margin-block: 56px;
}

.\!k-my-14 {
    margin-block: 56px !important;
}

.k-my-15 {
    margin-block: 60px;
}

.\!k-my-15 {
    margin-block: 60px !important;
}

.k-my-16 {
    margin-block: 64px;
}

.\!k-my-16 {
    margin-block: 64px !important;
}

.k-my-17 {
    margin-block: 68px;
}

.\!k-my-17 {
    margin-block: 68px !important;
}

.k-my-18 {
    margin-block: 72px;
}

.\!k-my-18 {
    margin-block: 72px !important;
}

.k-my-19 {
    margin-block: 76px;
}

.\!k-my-19 {
    margin-block: 76px !important;
}

.k-my-20 {
    margin-block: 80px;
}

.\!k-my-20 {
    margin-block: 80px !important;
}

.k-my-21 {
    margin-block: 84px;
}

.\!k-my-21 {
    margin-block: 84px !important;
}

.k-my-22 {
    margin-block: 88px;
}

.\!k-my-22 {
    margin-block: 88px !important;
}

.k-my-23 {
    margin-block: 92px;
}

.\!k-my-23 {
    margin-block: 92px !important;
}

.k-my-24 {
    margin-block: 96px;
}

.\!k-my-24 {
    margin-block: 96px !important;
}

.k-my--1px {
    margin-block: -1px;
}

.\!k-my--1px {
    margin-block: -1px !important;
}

.k-my--0\.5 {
    margin-block: -2px;
}

.\!k-my--0\.5 {
    margin-block: -2px !important;
}

.k-my--1 {
    margin-block: -4px;
}

.\!k-my--1 {
    margin-block: -4px !important;
}

.k-my--1\.5 {
    margin-block: -6px;
}

.\!k-my--1\.5 {
    margin-block: -6px !important;
}

.k-my--2 {
    margin-block: -8px;
}

.\!k-my--2 {
    margin-block: -8px !important;
}

.k-my--2\.5 {
    margin-block: -10px;
}

.\!k-my--2\.5 {
    margin-block: -10px !important;
}

.k-my--3 {
    margin-block: -12px;
}

.\!k-my--3 {
    margin-block: -12px !important;
}

.k-my--3\.5 {
    margin-block: -14px;
}

.\!k-my--3\.5 {
    margin-block: -14px !important;
}

.k-my--4 {
    margin-block: -16px;
}

.\!k-my--4 {
    margin-block: -16px !important;
}

.k-my--4\.5 {
    margin-block: -18px;
}

.\!k-my--4\.5 {
    margin-block: -18px !important;
}

.k-my--5 {
    margin-block: -20px;
}

.\!k-my--5 {
    margin-block: -20px !important;
}

.k-my--5\.5 {
    margin-block: -22px;
}

.\!k-my--5\.5 {
    margin-block: -22px !important;
}

.k-my--6 {
    margin-block: -24px;
}

.\!k-my--6 {
    margin-block: -24px !important;
}

.k-my--6\.5 {
    margin-block: -26px;
}

.\!k-my--6\.5 {
    margin-block: -26px !important;
}

.k-my--7 {
    margin-block: -28px;
}

.\!k-my--7 {
    margin-block: -28px !important;
}

.k-my--7\.5 {
    margin-block: -30px;
}

.\!k-my--7\.5 {
    margin-block: -30px !important;
}

.k-my--8 {
    margin-block: -32px;
}

.\!k-my--8 {
    margin-block: -32px !important;
}

.k-my--9 {
    margin-block: -36px;
}

.\!k-my--9 {
    margin-block: -36px !important;
}

.k-my--10 {
    margin-block: -40px;
}

.\!k-my--10 {
    margin-block: -40px !important;
}

.k-my--11 {
    margin-block: -44px;
}

.\!k-my--11 {
    margin-block: -44px !important;
}

.k-my--12 {
    margin-block: -48px;
}

.\!k-my--12 {
    margin-block: -48px !important;
}

.k-my--13 {
    margin-block: -52px;
}

.\!k-my--13 {
    margin-block: -52px !important;
}

.k-my--14 {
    margin-block: -56px;
}

.\!k-my--14 {
    margin-block: -56px !important;
}

.k-my--15 {
    margin-block: -60px;
}

.\!k-my--15 {
    margin-block: -60px !important;
}

.k-my--16 {
    margin-block: -64px;
}

.\!k-my--16 {
    margin-block: -64px !important;
}

.k-my--17 {
    margin-block: -68px;
}

.\!k-my--17 {
    margin-block: -68px !important;
}

.k-my--18 {
    margin-block: -72px;
}

.\!k-my--18 {
    margin-block: -72px !important;
}

.k-my--19 {
    margin-block: -76px;
}

.\!k-my--19 {
    margin-block: -76px !important;
}

.k-my--20 {
    margin-block: -80px;
}

.\!k-my--20 {
    margin-block: -80px !important;
}

.k-my--21 {
    margin-block: -84px;
}

.\!k-my--21 {
    margin-block: -84px !important;
}

.k-my--22 {
    margin-block: -88px;
}

.\!k-my--22 {
    margin-block: -88px !important;
}

.k-my--23 {
    margin-block: -92px;
}

.\!k-my--23 {
    margin-block: -92px !important;
}

.k-my--24 {
    margin-block: -96px;
}

.\!k-my--24 {
    margin-block: -96px !important;
}

.k-my-xs {
    margin-block: 4px;
}

.\!k-my-xs {
    margin-block: 4px !important;
}

.k-my-sm {
    margin-block: 8px;
}

.\!k-my-sm {
    margin-block: 8px !important;
}

.k-my-md {
    margin-block: 12px;
}

.\!k-my-md {
    margin-block: 12px !important;
}

.k-my-lg {
    margin-block: 16px;
}

.\!k-my-lg {
    margin-block: 16px !important;
}

.k-my-xl {
    margin-block: 24px;
}

.\!k-my-xl {
    margin-block: 24px !important;
}

.k-my-thin {
    margin-block: 2px;
}

.\!k-my-thin {
    margin-block: 2px !important;
}

.k-my-hair {
    margin-block: 1px;
}

.\!k-my-hair {
    margin-block: 1px !important;
}

.k-my-auto {
    margin-block: auto;
}

.\!k-my-auto {
    margin-block: auto !important;
}

.k-p-0 {
    padding: 0;
}

.\!k-p-0 {
    padding: 0 !important;
}

.k-p-1px {
    padding: 1px;
}

.\!k-p-1px {
    padding: 1px !important;
}

.k-p-0\.5 {
    padding: 2px;
}

.\!k-p-0\.5 {
    padding: 2px !important;
}

.k-p-1 {
    padding: 4px;
}

.\!k-p-1 {
    padding: 4px !important;
}

.k-p-1\.5 {
    padding: 6px;
}

.\!k-p-1\.5 {
    padding: 6px !important;
}

.k-p-2 {
    padding: 8px;
}

.\!k-p-2 {
    padding: 8px !important;
}

.k-p-2\.5 {
    padding: 10px;
}

.\!k-p-2\.5 {
    padding: 10px !important;
}

.k-p-3 {
    padding: 12px;
}

.\!k-p-3 {
    padding: 12px !important;
}

.k-p-3\.5 {
    padding: 14px;
}

.\!k-p-3\.5 {
    padding: 14px !important;
}

.k-p-4 {
    padding: 16px;
}

.\!k-p-4 {
    padding: 16px !important;
}

.k-p-4\.5 {
    padding: 18px;
}

.\!k-p-4\.5 {
    padding: 18px !important;
}

.k-p-5 {
    padding: 20px;
}

.\!k-p-5 {
    padding: 20px !important;
}

.k-p-5\.5 {
    padding: 22px;
}

.\!k-p-5\.5 {
    padding: 22px !important;
}

.k-p-6 {
    padding: 24px;
}

.\!k-p-6 {
    padding: 24px !important;
}

.k-p-6\.5 {
    padding: 26px;
}

.\!k-p-6\.5 {
    padding: 26px !important;
}

.k-p-7 {
    padding: 28px;
}

.\!k-p-7 {
    padding: 28px !important;
}

.k-p-7\.5 {
    padding: 30px;
}

.\!k-p-7\.5 {
    padding: 30px !important;
}

.k-p-8 {
    padding: 32px;
}

.\!k-p-8 {
    padding: 32px !important;
}

.k-p-9 {
    padding: 36px;
}

.\!k-p-9 {
    padding: 36px !important;
}

.k-p-10 {
    padding: 40px;
}

.\!k-p-10 {
    padding: 40px !important;
}

.k-p-11 {
    padding: 44px;
}

.\!k-p-11 {
    padding: 44px !important;
}

.k-p-12 {
    padding: 48px;
}

.\!k-p-12 {
    padding: 48px !important;
}

.k-p-13 {
    padding: 52px;
}

.\!k-p-13 {
    padding: 52px !important;
}

.k-p-14 {
    padding: 56px;
}

.\!k-p-14 {
    padding: 56px !important;
}

.k-p-15 {
    padding: 60px;
}

.\!k-p-15 {
    padding: 60px !important;
}

.k-p-16 {
    padding: 64px;
}

.\!k-p-16 {
    padding: 64px !important;
}

.k-p-17 {
    padding: 68px;
}

.\!k-p-17 {
    padding: 68px !important;
}

.k-p-18 {
    padding: 72px;
}

.\!k-p-18 {
    padding: 72px !important;
}

.k-p-19 {
    padding: 76px;
}

.\!k-p-19 {
    padding: 76px !important;
}

.k-p-20 {
    padding: 80px;
}

.\!k-p-20 {
    padding: 80px !important;
}

.k-p-21 {
    padding: 84px;
}

.\!k-p-21 {
    padding: 84px !important;
}

.k-p-22 {
    padding: 88px;
}

.\!k-p-22 {
    padding: 88px !important;
}

.k-p-23 {
    padding: 92px;
}

.\!k-p-23 {
    padding: 92px !important;
}

.k-p-24 {
    padding: 96px;
}

.\!k-p-24 {
    padding: 96px !important;
}

.k-p-xs {
    padding: 4px;
}

.\!k-p-xs {
    padding: 4px !important;
}

.k-p-sm {
    padding: 8px;
}

.\!k-p-sm {
    padding: 8px !important;
}

.k-p-md {
    padding: 12px;
}

.\!k-p-md {
    padding: 12px !important;
}

.k-p-lg {
    padding: 16px;
}

.\!k-p-lg {
    padding: 16px !important;
}

.k-p-xl {
    padding: 24px;
}

.\!k-p-xl {
    padding: 24px !important;
}

.k-p-thin {
    padding: 2px;
}

.\!k-p-thin {
    padding: 2px !important;
}

.k-p-hair {
    padding: 1px;
}

.\!k-p-hair {
    padding: 1px !important;
}

.k-pt-0 {
    padding-top: 0;
}

.\!k-pt-0 {
    padding-top: 0 !important;
}

.k-pt-1px {
    padding-top: 1px;
}

.\!k-pt-1px {
    padding-top: 1px !important;
}

.k-pt-0\.5 {
    padding-top: 2px;
}

.\!k-pt-0\.5 {
    padding-top: 2px !important;
}

.k-pt-1 {
    padding-top: 4px;
}

.\!k-pt-1 {
    padding-top: 4px !important;
}

.k-pt-1\.5 {
    padding-top: 6px;
}

.\!k-pt-1\.5 {
    padding-top: 6px !important;
}

.k-pt-2 {
    padding-top: 8px;
}

.\!k-pt-2 {
    padding-top: 8px !important;
}

.k-pt-2\.5 {
    padding-top: 10px;
}

.\!k-pt-2\.5 {
    padding-top: 10px !important;
}

.k-pt-3 {
    padding-top: 12px;
}

.\!k-pt-3 {
    padding-top: 12px !important;
}

.k-pt-3\.5 {
    padding-top: 14px;
}

.\!k-pt-3\.5 {
    padding-top: 14px !important;
}

.k-pt-4 {
    padding-top: 16px;
}

.\!k-pt-4 {
    padding-top: 16px !important;
}

.k-pt-4\.5 {
    padding-top: 18px;
}

.\!k-pt-4\.5 {
    padding-top: 18px !important;
}

.k-pt-5 {
    padding-top: 20px;
}

.\!k-pt-5 {
    padding-top: 20px !important;
}

.k-pt-5\.5 {
    padding-top: 22px;
}

.\!k-pt-5\.5 {
    padding-top: 22px !important;
}

.k-pt-6 {
    padding-top: 24px;
}

.\!k-pt-6 {
    padding-top: 24px !important;
}

.k-pt-6\.5 {
    padding-top: 26px;
}

.\!k-pt-6\.5 {
    padding-top: 26px !important;
}

.k-pt-7 {
    padding-top: 28px;
}

.\!k-pt-7 {
    padding-top: 28px !important;
}

.k-pt-7\.5 {
    padding-top: 30px;
}

.\!k-pt-7\.5 {
    padding-top: 30px !important;
}

.k-pt-8 {
    padding-top: 32px;
}

.\!k-pt-8 {
    padding-top: 32px !important;
}

.k-pt-9 {
    padding-top: 36px;
}

.\!k-pt-9 {
    padding-top: 36px !important;
}

.k-pt-10 {
    padding-top: 40px;
}

.\!k-pt-10 {
    padding-top: 40px !important;
}

.k-pt-11 {
    padding-top: 44px;
}

.\!k-pt-11 {
    padding-top: 44px !important;
}

.k-pt-12 {
    padding-top: 48px;
}

.\!k-pt-12 {
    padding-top: 48px !important;
}

.k-pt-13 {
    padding-top: 52px;
}

.\!k-pt-13 {
    padding-top: 52px !important;
}

.k-pt-14 {
    padding-top: 56px;
}

.\!k-pt-14 {
    padding-top: 56px !important;
}

.k-pt-15 {
    padding-top: 60px;
}

.\!k-pt-15 {
    padding-top: 60px !important;
}

.k-pt-16 {
    padding-top: 64px;
}

.\!k-pt-16 {
    padding-top: 64px !important;
}

.k-pt-17 {
    padding-top: 68px;
}

.\!k-pt-17 {
    padding-top: 68px !important;
}

.k-pt-18 {
    padding-top: 72px;
}

.\!k-pt-18 {
    padding-top: 72px !important;
}

.k-pt-19 {
    padding-top: 76px;
}

.\!k-pt-19 {
    padding-top: 76px !important;
}

.k-pt-20 {
    padding-top: 80px;
}

.\!k-pt-20 {
    padding-top: 80px !important;
}

.k-pt-21 {
    padding-top: 84px;
}

.\!k-pt-21 {
    padding-top: 84px !important;
}

.k-pt-22 {
    padding-top: 88px;
}

.\!k-pt-22 {
    padding-top: 88px !important;
}

.k-pt-23 {
    padding-top: 92px;
}

.\!k-pt-23 {
    padding-top: 92px !important;
}

.k-pt-24 {
    padding-top: 96px;
}

.\!k-pt-24 {
    padding-top: 96px !important;
}

.k-pt-xs {
    padding-top: 4px;
}

.\!k-pt-xs {
    padding-top: 4px !important;
}

.k-pt-sm {
    padding-top: 8px;
}

.\!k-pt-sm {
    padding-top: 8px !important;
}

.k-pt-md {
    padding-top: 12px;
}

.\!k-pt-md {
    padding-top: 12px !important;
}

.k-pt-lg {
    padding-top: 16px;
}

.\!k-pt-lg {
    padding-top: 16px !important;
}

.k-pt-xl {
    padding-top: 24px;
}

.\!k-pt-xl {
    padding-top: 24px !important;
}

.k-pt-thin {
    padding-top: 2px;
}

.\!k-pt-thin {
    padding-top: 2px !important;
}

.k-pt-hair {
    padding-top: 1px;
}

.\!k-pt-hair {
    padding-top: 1px !important;
}

.k-pr-0 {
    padding-right: 0;
}

.\!k-pr-0 {
    padding-right: 0 !important;
}

.k-pr-1px {
    padding-right: 1px;
}

.\!k-pr-1px {
    padding-right: 1px !important;
}

.k-pr-0\.5 {
    padding-right: 2px;
}

.\!k-pr-0\.5 {
    padding-right: 2px !important;
}

.k-pr-1 {
    padding-right: 4px;
}

.\!k-pr-1 {
    padding-right: 4px !important;
}

.k-pr-1\.5 {
    padding-right: 6px;
}

.\!k-pr-1\.5 {
    padding-right: 6px !important;
}

.k-pr-2 {
    padding-right: 8px;
}

.\!k-pr-2 {
    padding-right: 8px !important;
}

.k-pr-2\.5 {
    padding-right: 10px;
}

.\!k-pr-2\.5 {
    padding-right: 10px !important;
}

.k-pr-3 {
    padding-right: 12px;
}

.\!k-pr-3 {
    padding-right: 12px !important;
}

.k-pr-3\.5 {
    padding-right: 14px;
}

.\!k-pr-3\.5 {
    padding-right: 14px !important;
}

.k-pr-4 {
    padding-right: 16px;
}

.\!k-pr-4 {
    padding-right: 16px !important;
}

.k-pr-4\.5 {
    padding-right: 18px;
}

.\!k-pr-4\.5 {
    padding-right: 18px !important;
}

.k-pr-5 {
    padding-right: 20px;
}

.\!k-pr-5 {
    padding-right: 20px !important;
}

.k-pr-5\.5 {
    padding-right: 22px;
}

.\!k-pr-5\.5 {
    padding-right: 22px !important;
}

.k-pr-6 {
    padding-right: 24px;
}

.\!k-pr-6 {
    padding-right: 24px !important;
}

.k-pr-6\.5 {
    padding-right: 26px;
}

.\!k-pr-6\.5 {
    padding-right: 26px !important;
}

.k-pr-7 {
    padding-right: 28px;
}

.\!k-pr-7 {
    padding-right: 28px !important;
}

.k-pr-7\.5 {
    padding-right: 30px;
}

.\!k-pr-7\.5 {
    padding-right: 30px !important;
}

.k-pr-8 {
    padding-right: 32px;
}

.\!k-pr-8 {
    padding-right: 32px !important;
}

.k-pr-9 {
    padding-right: 36px;
}

.\!k-pr-9 {
    padding-right: 36px !important;
}

.k-pr-10 {
    padding-right: 40px;
}

.\!k-pr-10 {
    padding-right: 40px !important;
}

.k-pr-11 {
    padding-right: 44px;
}

.\!k-pr-11 {
    padding-right: 44px !important;
}

.k-pr-12 {
    padding-right: 48px;
}

.\!k-pr-12 {
    padding-right: 48px !important;
}

.k-pr-13 {
    padding-right: 52px;
}

.\!k-pr-13 {
    padding-right: 52px !important;
}

.k-pr-14 {
    padding-right: 56px;
}

.\!k-pr-14 {
    padding-right: 56px !important;
}

.k-pr-15 {
    padding-right: 60px;
}

.\!k-pr-15 {
    padding-right: 60px !important;
}

.k-pr-16 {
    padding-right: 64px;
}

.\!k-pr-16 {
    padding-right: 64px !important;
}

.k-pr-17 {
    padding-right: 68px;
}

.\!k-pr-17 {
    padding-right: 68px !important;
}

.k-pr-18 {
    padding-right: 72px;
}

.\!k-pr-18 {
    padding-right: 72px !important;
}

.k-pr-19 {
    padding-right: 76px;
}

.\!k-pr-19 {
    padding-right: 76px !important;
}

.k-pr-20 {
    padding-right: 80px;
}

.\!k-pr-20 {
    padding-right: 80px !important;
}

.k-pr-21 {
    padding-right: 84px;
}

.\!k-pr-21 {
    padding-right: 84px !important;
}

.k-pr-22 {
    padding-right: 88px;
}

.\!k-pr-22 {
    padding-right: 88px !important;
}

.k-pr-23 {
    padding-right: 92px;
}

.\!k-pr-23 {
    padding-right: 92px !important;
}

.k-pr-24 {
    padding-right: 96px;
}

.\!k-pr-24 {
    padding-right: 96px !important;
}

.k-pr-xs {
    padding-right: 4px;
}

.\!k-pr-xs {
    padding-right: 4px !important;
}

.k-pr-sm {
    padding-right: 8px;
}

.\!k-pr-sm {
    padding-right: 8px !important;
}

.k-pr-md {
    padding-right: 12px;
}

.\!k-pr-md {
    padding-right: 12px !important;
}

.k-pr-lg {
    padding-right: 16px;
}

.\!k-pr-lg {
    padding-right: 16px !important;
}

.k-pr-xl {
    padding-right: 24px;
}

.\!k-pr-xl {
    padding-right: 24px !important;
}

.k-pr-thin {
    padding-right: 2px;
}

.\!k-pr-thin {
    padding-right: 2px !important;
}

.k-pr-hair {
    padding-right: 1px;
}

.\!k-pr-hair {
    padding-right: 1px !important;
}

.k-pb-0 {
    padding-bottom: 0;
}

.\!k-pb-0 {
    padding-bottom: 0 !important;
}

.k-pb-1px {
    padding-bottom: 1px;
}

.\!k-pb-1px {
    padding-bottom: 1px !important;
}

.k-pb-0\.5 {
    padding-bottom: 2px;
}

.\!k-pb-0\.5 {
    padding-bottom: 2px !important;
}

.k-pb-1 {
    padding-bottom: 4px;
}

.\!k-pb-1 {
    padding-bottom: 4px !important;
}

.k-pb-1\.5 {
    padding-bottom: 6px;
}

.\!k-pb-1\.5 {
    padding-bottom: 6px !important;
}

.k-pb-2 {
    padding-bottom: 8px;
}

.\!k-pb-2 {
    padding-bottom: 8px !important;
}

.k-pb-2\.5 {
    padding-bottom: 10px;
}

.\!k-pb-2\.5 {
    padding-bottom: 10px !important;
}

.k-pb-3 {
    padding-bottom: 12px;
}

.\!k-pb-3 {
    padding-bottom: 12px !important;
}

.k-pb-3\.5 {
    padding-bottom: 14px;
}

.\!k-pb-3\.5 {
    padding-bottom: 14px !important;
}

.k-pb-4 {
    padding-bottom: 16px;
}

.\!k-pb-4 {
    padding-bottom: 16px !important;
}

.k-pb-4\.5 {
    padding-bottom: 18px;
}

.\!k-pb-4\.5 {
    padding-bottom: 18px !important;
}

.k-pb-5 {
    padding-bottom: 20px;
}

.\!k-pb-5 {
    padding-bottom: 20px !important;
}

.k-pb-5\.5 {
    padding-bottom: 22px;
}

.\!k-pb-5\.5 {
    padding-bottom: 22px !important;
}

.k-pb-6 {
    padding-bottom: 24px;
}

.\!k-pb-6 {
    padding-bottom: 24px !important;
}

.k-pb-6\.5 {
    padding-bottom: 26px;
}

.\!k-pb-6\.5 {
    padding-bottom: 26px !important;
}

.k-pb-7 {
    padding-bottom: 28px;
}

.\!k-pb-7 {
    padding-bottom: 28px !important;
}

.k-pb-7\.5 {
    padding-bottom: 30px;
}

.\!k-pb-7\.5 {
    padding-bottom: 30px !important;
}

.k-pb-8 {
    padding-bottom: 32px;
}

.\!k-pb-8 {
    padding-bottom: 32px !important;
}

.k-pb-9 {
    padding-bottom: 36px;
}

.\!k-pb-9 {
    padding-bottom: 36px !important;
}

.k-pb-10 {
    padding-bottom: 40px;
}

.\!k-pb-10 {
    padding-bottom: 40px !important;
}

.k-pb-11 {
    padding-bottom: 44px;
}

.\!k-pb-11 {
    padding-bottom: 44px !important;
}

.k-pb-12 {
    padding-bottom: 48px;
}

.\!k-pb-12 {
    padding-bottom: 48px !important;
}

.k-pb-13 {
    padding-bottom: 52px;
}

.\!k-pb-13 {
    padding-bottom: 52px !important;
}

.k-pb-14 {
    padding-bottom: 56px;
}

.\!k-pb-14 {
    padding-bottom: 56px !important;
}

.k-pb-15 {
    padding-bottom: 60px;
}

.\!k-pb-15 {
    padding-bottom: 60px !important;
}

.k-pb-16 {
    padding-bottom: 64px;
}

.\!k-pb-16 {
    padding-bottom: 64px !important;
}

.k-pb-17 {
    padding-bottom: 68px;
}

.\!k-pb-17 {
    padding-bottom: 68px !important;
}

.k-pb-18 {
    padding-bottom: 72px;
}

.\!k-pb-18 {
    padding-bottom: 72px !important;
}

.k-pb-19 {
    padding-bottom: 76px;
}

.\!k-pb-19 {
    padding-bottom: 76px !important;
}

.k-pb-20 {
    padding-bottom: 80px;
}

.\!k-pb-20 {
    padding-bottom: 80px !important;
}

.k-pb-21 {
    padding-bottom: 84px;
}

.\!k-pb-21 {
    padding-bottom: 84px !important;
}

.k-pb-22 {
    padding-bottom: 88px;
}

.\!k-pb-22 {
    padding-bottom: 88px !important;
}

.k-pb-23 {
    padding-bottom: 92px;
}

.\!k-pb-23 {
    padding-bottom: 92px !important;
}

.k-pb-24 {
    padding-bottom: 96px;
}

.\!k-pb-24 {
    padding-bottom: 96px !important;
}

.k-pb-xs {
    padding-bottom: 4px;
}

.\!k-pb-xs {
    padding-bottom: 4px !important;
}

.k-pb-sm {
    padding-bottom: 8px;
}

.\!k-pb-sm {
    padding-bottom: 8px !important;
}

.k-pb-md {
    padding-bottom: 12px;
}

.\!k-pb-md {
    padding-bottom: 12px !important;
}

.k-pb-lg {
    padding-bottom: 16px;
}

.\!k-pb-lg {
    padding-bottom: 16px !important;
}

.k-pb-xl {
    padding-bottom: 24px;
}

.\!k-pb-xl {
    padding-bottom: 24px !important;
}

.k-pb-thin {
    padding-bottom: 2px;
}

.\!k-pb-thin {
    padding-bottom: 2px !important;
}

.k-pb-hair {
    padding-bottom: 1px;
}

.\!k-pb-hair {
    padding-bottom: 1px !important;
}

.k-pl-0 {
    padding-left: 0;
}

.\!k-pl-0 {
    padding-left: 0 !important;
}

.k-pl-1px {
    padding-left: 1px;
}

.\!k-pl-1px {
    padding-left: 1px !important;
}

.k-pl-0\.5 {
    padding-left: 2px;
}

.\!k-pl-0\.5 {
    padding-left: 2px !important;
}

.k-pl-1 {
    padding-left: 4px;
}

.\!k-pl-1 {
    padding-left: 4px !important;
}

.k-pl-1\.5 {
    padding-left: 6px;
}

.\!k-pl-1\.5 {
    padding-left: 6px !important;
}

.k-pl-2 {
    padding-left: 8px;
}

.\!k-pl-2 {
    padding-left: 8px !important;
}

.k-pl-2\.5 {
    padding-left: 10px;
}

.\!k-pl-2\.5 {
    padding-left: 10px !important;
}

.k-pl-3 {
    padding-left: 12px;
}

.\!k-pl-3 {
    padding-left: 12px !important;
}

.k-pl-3\.5 {
    padding-left: 14px;
}

.\!k-pl-3\.5 {
    padding-left: 14px !important;
}

.k-pl-4 {
    padding-left: 16px;
}

.\!k-pl-4 {
    padding-left: 16px !important;
}

.k-pl-4\.5 {
    padding-left: 18px;
}

.\!k-pl-4\.5 {
    padding-left: 18px !important;
}

.k-pl-5 {
    padding-left: 20px;
}

.\!k-pl-5 {
    padding-left: 20px !important;
}

.k-pl-5\.5 {
    padding-left: 22px;
}

.\!k-pl-5\.5 {
    padding-left: 22px !important;
}

.k-pl-6 {
    padding-left: 24px;
}

.\!k-pl-6 {
    padding-left: 24px !important;
}

.k-pl-6\.5 {
    padding-left: 26px;
}

.\!k-pl-6\.5 {
    padding-left: 26px !important;
}

.k-pl-7 {
    padding-left: 28px;
}

.\!k-pl-7 {
    padding-left: 28px !important;
}

.k-pl-7\.5 {
    padding-left: 30px;
}

.\!k-pl-7\.5 {
    padding-left: 30px !important;
}

.k-pl-8 {
    padding-left: 32px;
}

.\!k-pl-8 {
    padding-left: 32px !important;
}

.k-pl-9 {
    padding-left: 36px;
}

.\!k-pl-9 {
    padding-left: 36px !important;
}

.k-pl-10 {
    padding-left: 40px;
}

.\!k-pl-10 {
    padding-left: 40px !important;
}

.k-pl-11 {
    padding-left: 44px;
}

.\!k-pl-11 {
    padding-left: 44px !important;
}

.k-pl-12 {
    padding-left: 48px;
}

.\!k-pl-12 {
    padding-left: 48px !important;
}

.k-pl-13 {
    padding-left: 52px;
}

.\!k-pl-13 {
    padding-left: 52px !important;
}

.k-pl-14 {
    padding-left: 56px;
}

.\!k-pl-14 {
    padding-left: 56px !important;
}

.k-pl-15 {
    padding-left: 60px;
}

.\!k-pl-15 {
    padding-left: 60px !important;
}

.k-pl-16 {
    padding-left: 64px;
}

.\!k-pl-16 {
    padding-left: 64px !important;
}

.k-pl-17 {
    padding-left: 68px;
}

.\!k-pl-17 {
    padding-left: 68px !important;
}

.k-pl-18 {
    padding-left: 72px;
}

.\!k-pl-18 {
    padding-left: 72px !important;
}

.k-pl-19 {
    padding-left: 76px;
}

.\!k-pl-19 {
    padding-left: 76px !important;
}

.k-pl-20 {
    padding-left: 80px;
}

.\!k-pl-20 {
    padding-left: 80px !important;
}

.k-pl-21 {
    padding-left: 84px;
}

.\!k-pl-21 {
    padding-left: 84px !important;
}

.k-pl-22 {
    padding-left: 88px;
}

.\!k-pl-22 {
    padding-left: 88px !important;
}

.k-pl-23 {
    padding-left: 92px;
}

.\!k-pl-23 {
    padding-left: 92px !important;
}

.k-pl-24 {
    padding-left: 96px;
}

.\!k-pl-24 {
    padding-left: 96px !important;
}

.k-pl-xs {
    padding-left: 4px;
}

.\!k-pl-xs {
    padding-left: 4px !important;
}

.k-pl-sm {
    padding-left: 8px;
}

.\!k-pl-sm {
    padding-left: 8px !important;
}

.k-pl-md {
    padding-left: 12px;
}

.\!k-pl-md {
    padding-left: 12px !important;
}

.k-pl-lg {
    padding-left: 16px;
}

.\!k-pl-lg {
    padding-left: 16px !important;
}

.k-pl-xl {
    padding-left: 24px;
}

.\!k-pl-xl {
    padding-left: 24px !important;
}

.k-pl-thin {
    padding-left: 2px;
}

.\!k-pl-thin {
    padding-left: 2px !important;
}

.k-pl-hair {
    padding-left: 1px;
}

.\!k-pl-hair {
    padding-left: 1px !important;
}

.k-px-0 {
    padding-inline: 0;
}

.\!k-px-0 {
    padding-inline: 0 !important;
}

.k-px-1px {
    padding-inline: 1px;
}

.\!k-px-1px {
    padding-inline: 1px !important;
}

.k-px-0\.5 {
    padding-inline: 2px;
}

.\!k-px-0\.5 {
    padding-inline: 2px !important;
}

.k-px-1 {
    padding-inline: 4px;
}

.\!k-px-1 {
    padding-inline: 4px !important;
}

.k-px-1\.5 {
    padding-inline: 6px;
}

.\!k-px-1\.5 {
    padding-inline: 6px !important;
}

.k-px-2 {
    padding-inline: 8px;
}

.\!k-px-2 {
    padding-inline: 8px !important;
}

.k-px-2\.5 {
    padding-inline: 10px;
}

.\!k-px-2\.5 {
    padding-inline: 10px !important;
}

.k-px-3 {
    padding-inline: 12px;
}

.\!k-px-3 {
    padding-inline: 12px !important;
}

.k-px-3\.5 {
    padding-inline: 14px;
}

.\!k-px-3\.5 {
    padding-inline: 14px !important;
}

.k-px-4 {
    padding-inline: 16px;
}

.\!k-px-4 {
    padding-inline: 16px !important;
}

.k-px-4\.5 {
    padding-inline: 18px;
}

.\!k-px-4\.5 {
    padding-inline: 18px !important;
}

.k-px-5 {
    padding-inline: 20px;
}

.\!k-px-5 {
    padding-inline: 20px !important;
}

.k-px-5\.5 {
    padding-inline: 22px;
}

.\!k-px-5\.5 {
    padding-inline: 22px !important;
}

.k-px-6 {
    padding-inline: 24px;
}

.\!k-px-6 {
    padding-inline: 24px !important;
}

.k-px-6\.5 {
    padding-inline: 26px;
}

.\!k-px-6\.5 {
    padding-inline: 26px !important;
}

.k-px-7 {
    padding-inline: 28px;
}

.\!k-px-7 {
    padding-inline: 28px !important;
}

.k-px-7\.5 {
    padding-inline: 30px;
}

.\!k-px-7\.5 {
    padding-inline: 30px !important;
}

.k-px-8 {
    padding-inline: 32px;
}

.\!k-px-8 {
    padding-inline: 32px !important;
}

.k-px-9 {
    padding-inline: 36px;
}

.\!k-px-9 {
    padding-inline: 36px !important;
}

.k-px-10 {
    padding-inline: 40px;
}

.\!k-px-10 {
    padding-inline: 40px !important;
}

.k-px-11 {
    padding-inline: 44px;
}

.\!k-px-11 {
    padding-inline: 44px !important;
}

.k-px-12 {
    padding-inline: 48px;
}

.\!k-px-12 {
    padding-inline: 48px !important;
}

.k-px-13 {
    padding-inline: 52px;
}

.\!k-px-13 {
    padding-inline: 52px !important;
}

.k-px-14 {
    padding-inline: 56px;
}

.\!k-px-14 {
    padding-inline: 56px !important;
}

.k-px-15 {
    padding-inline: 60px;
}

.\!k-px-15 {
    padding-inline: 60px !important;
}

.k-px-16 {
    padding-inline: 64px;
}

.\!k-px-16 {
    padding-inline: 64px !important;
}

.k-px-17 {
    padding-inline: 68px;
}

.\!k-px-17 {
    padding-inline: 68px !important;
}

.k-px-18 {
    padding-inline: 72px;
}

.\!k-px-18 {
    padding-inline: 72px !important;
}

.k-px-19 {
    padding-inline: 76px;
}

.\!k-px-19 {
    padding-inline: 76px !important;
}

.k-px-20 {
    padding-inline: 80px;
}

.\!k-px-20 {
    padding-inline: 80px !important;
}

.k-px-21 {
    padding-inline: 84px;
}

.\!k-px-21 {
    padding-inline: 84px !important;
}

.k-px-22 {
    padding-inline: 88px;
}

.\!k-px-22 {
    padding-inline: 88px !important;
}

.k-px-23 {
    padding-inline: 92px;
}

.\!k-px-23 {
    padding-inline: 92px !important;
}

.k-px-24 {
    padding-inline: 96px;
}

.\!k-px-24 {
    padding-inline: 96px !important;
}

.k-px-xs {
    padding-inline: 4px;
}

.\!k-px-xs {
    padding-inline: 4px !important;
}

.k-px-sm {
    padding-inline: 8px;
}

.\!k-px-sm {
    padding-inline: 8px !important;
}

.k-px-md {
    padding-inline: 12px;
}

.\!k-px-md {
    padding-inline: 12px !important;
}

.k-px-lg {
    padding-inline: 16px;
}

.\!k-px-lg {
    padding-inline: 16px !important;
}

.k-px-xl {
    padding-inline: 24px;
}

.\!k-px-xl {
    padding-inline: 24px !important;
}

.k-px-thin {
    padding-inline: 2px;
}

.\!k-px-thin {
    padding-inline: 2px !important;
}

.k-px-hair {
    padding-inline: 1px;
}

.\!k-px-hair {
    padding-inline: 1px !important;
}

.k-py-0 {
    padding-block: 0;
}

.\!k-py-0 {
    padding-block: 0 !important;
}

.k-py-1px {
    padding-block: 1px;
}

.\!k-py-1px {
    padding-block: 1px !important;
}

.k-py-0\.5 {
    padding-block: 2px;
}

.\!k-py-0\.5 {
    padding-block: 2px !important;
}

.k-py-1 {
    padding-block: 4px;
}

.\!k-py-1 {
    padding-block: 4px !important;
}

.k-py-1\.5 {
    padding-block: 6px;
}

.\!k-py-1\.5 {
    padding-block: 6px !important;
}

.k-py-2 {
    padding-block: 8px;
}

.\!k-py-2 {
    padding-block: 8px !important;
}

.k-py-2\.5 {
    padding-block: 10px;
}

.\!k-py-2\.5 {
    padding-block: 10px !important;
}

.k-py-3 {
    padding-block: 12px;
}

.\!k-py-3 {
    padding-block: 12px !important;
}

.k-py-3\.5 {
    padding-block: 14px;
}

.\!k-py-3\.5 {
    padding-block: 14px !important;
}

.k-py-4 {
    padding-block: 16px;
}

.\!k-py-4 {
    padding-block: 16px !important;
}

.k-py-4\.5 {
    padding-block: 18px;
}

.\!k-py-4\.5 {
    padding-block: 18px !important;
}

.k-py-5 {
    padding-block: 20px;
}

.\!k-py-5 {
    padding-block: 20px !important;
}

.k-py-5\.5 {
    padding-block: 22px;
}

.\!k-py-5\.5 {
    padding-block: 22px !important;
}

.k-py-6 {
    padding-block: 24px;
}

.\!k-py-6 {
    padding-block: 24px !important;
}

.k-py-6\.5 {
    padding-block: 26px;
}

.\!k-py-6\.5 {
    padding-block: 26px !important;
}

.k-py-7 {
    padding-block: 28px;
}

.\!k-py-7 {
    padding-block: 28px !important;
}

.k-py-7\.5 {
    padding-block: 30px;
}

.\!k-py-7\.5 {
    padding-block: 30px !important;
}

.k-py-8 {
    padding-block: 32px;
}

.\!k-py-8 {
    padding-block: 32px !important;
}

.k-py-9 {
    padding-block: 36px;
}

.\!k-py-9 {
    padding-block: 36px !important;
}

.k-py-10 {
    padding-block: 40px;
}

.\!k-py-10 {
    padding-block: 40px !important;
}

.k-py-11 {
    padding-block: 44px;
}

.\!k-py-11 {
    padding-block: 44px !important;
}

.k-py-12 {
    padding-block: 48px;
}

.\!k-py-12 {
    padding-block: 48px !important;
}

.k-py-13 {
    padding-block: 52px;
}

.\!k-py-13 {
    padding-block: 52px !important;
}

.k-py-14 {
    padding-block: 56px;
}

.\!k-py-14 {
    padding-block: 56px !important;
}

.k-py-15 {
    padding-block: 60px;
}

.\!k-py-15 {
    padding-block: 60px !important;
}

.k-py-16 {
    padding-block: 64px;
}

.\!k-py-16 {
    padding-block: 64px !important;
}

.k-py-17 {
    padding-block: 68px;
}

.\!k-py-17 {
    padding-block: 68px !important;
}

.k-py-18 {
    padding-block: 72px;
}

.\!k-py-18 {
    padding-block: 72px !important;
}

.k-py-19 {
    padding-block: 76px;
}

.\!k-py-19 {
    padding-block: 76px !important;
}

.k-py-20 {
    padding-block: 80px;
}

.\!k-py-20 {
    padding-block: 80px !important;
}

.k-py-21 {
    padding-block: 84px;
}

.\!k-py-21 {
    padding-block: 84px !important;
}

.k-py-22 {
    padding-block: 88px;
}

.\!k-py-22 {
    padding-block: 88px !important;
}

.k-py-23 {
    padding-block: 92px;
}

.\!k-py-23 {
    padding-block: 92px !important;
}

.k-py-24 {
    padding-block: 96px;
}

.\!k-py-24 {
    padding-block: 96px !important;
}

.k-py-xs {
    padding-block: 4px;
}

.\!k-py-xs {
    padding-block: 4px !important;
}

.k-py-sm {
    padding-block: 8px;
}

.\!k-py-sm {
    padding-block: 8px !important;
}

.k-py-md {
    padding-block: 12px;
}

.\!k-py-md {
    padding-block: 12px !important;
}

.k-py-lg {
    padding-block: 16px;
}

.\!k-py-lg {
    padding-block: 16px !important;
}

.k-py-xl {
    padding-block: 24px;
}

.\!k-py-xl {
    padding-block: 24px !important;
}

.k-py-thin {
    padding-block: 2px;
}

.\!k-py-thin {
    padding-block: 2px !important;
}

.k-py-hair {
    padding-block: 1px;
}

.\!k-py-hair {
    padding-block: 1px !important;
}

.k-h-0 {
    height: 0;
}

.\!k-h-0 {
    height: 0 !important;
}

.k-h-1px {
    height: 1px;
}

.\!k-h-1px {
    height: 1px !important;
}

.k-h-0\.5 {
    height: 2px;
}

.\!k-h-0\.5 {
    height: 2px !important;
}

.k-h-1 {
    height: 4px;
}

.\!k-h-1 {
    height: 4px !important;
}

.k-h-1\.5 {
    height: 6px;
}

.\!k-h-1\.5 {
    height: 6px !important;
}

.k-h-2 {
    height: 8px;
}

.\!k-h-2 {
    height: 8px !important;
}

.k-h-2\.5 {
    height: 10px;
}

.\!k-h-2\.5 {
    height: 10px !important;
}

.k-h-3 {
    height: 12px;
}

.\!k-h-3 {
    height: 12px !important;
}

.k-h-3\.5 {
    height: 14px;
}

.\!k-h-3\.5 {
    height: 14px !important;
}

.k-h-4 {
    height: 16px;
}

.\!k-h-4 {
    height: 16px !important;
}

.k-h-4\.5 {
    height: 18px;
}

.\!k-h-4\.5 {
    height: 18px !important;
}

.k-h-5 {
    height: 20px;
}

.\!k-h-5 {
    height: 20px !important;
}

.k-h-5\.5 {
    height: 22px;
}

.\!k-h-5\.5 {
    height: 22px !important;
}

.k-h-6 {
    height: 24px;
}

.\!k-h-6 {
    height: 24px !important;
}

.k-h-6\.5 {
    height: 26px;
}

.\!k-h-6\.5 {
    height: 26px !important;
}

.k-h-7 {
    height: 28px;
}

.\!k-h-7 {
    height: 28px !important;
}

.k-h-7\.5 {
    height: 30px;
}

.\!k-h-7\.5 {
    height: 30px !important;
}

.k-h-8 {
    height: 32px;
}

.\!k-h-8 {
    height: 32px !important;
}

.k-h-9 {
    height: 36px;
}

.\!k-h-9 {
    height: 36px !important;
}

.k-h-10 {
    height: 40px;
}

.\!k-h-10 {
    height: 40px !important;
}

.k-h-11 {
    height: 44px;
}

.\!k-h-11 {
    height: 44px !important;
}

.k-h-12 {
    height: 48px;
}

.\!k-h-12 {
    height: 48px !important;
}

.k-h-13 {
    height: 52px;
}

.\!k-h-13 {
    height: 52px !important;
}

.k-h-14 {
    height: 56px;
}

.\!k-h-14 {
    height: 56px !important;
}

.k-h-15 {
    height: 60px;
}

.\!k-h-15 {
    height: 60px !important;
}

.k-h-16 {
    height: 64px;
}

.\!k-h-16 {
    height: 64px !important;
}

.k-h-17 {
    height: 68px;
}

.\!k-h-17 {
    height: 68px !important;
}

.k-h-18 {
    height: 72px;
}

.\!k-h-18 {
    height: 72px !important;
}

.k-h-19 {
    height: 76px;
}

.\!k-h-19 {
    height: 76px !important;
}

.k-h-20 {
    height: 80px;
}

.\!k-h-20 {
    height: 80px !important;
}

.k-h-21 {
    height: 84px;
}

.\!k-h-21 {
    height: 84px !important;
}

.k-h-22 {
    height: 88px;
}

.\!k-h-22 {
    height: 88px !important;
}

.k-h-23 {
    height: 92px;
}

.\!k-h-23 {
    height: 92px !important;
}

.k-h-24 {
    height: 96px;
}

.\!k-h-24 {
    height: 96px !important;
}

.k-h-auto {
    height: auto;
}

.\!k-h-auto {
    height: auto !important;
}

.k-h-1\/2 {
    height: 50%;
}

.\!k-h-1\/2 {
    height: 50% !important;
}

.k-h-1\/3 {
    height: 33.333333%;
}

.\!k-h-1\/3 {
    height: 33.333333% !important;
}

.k-h-2\/3 {
    height: 66.666667%;
}

.\!k-h-2\/3 {
    height: 66.666667% !important;
}

.k-h-1\/4 {
    height: 25%;
}

.\!k-h-1\/4 {
    height: 25% !important;
}

.k-h-2\/4 {
    height: 50%;
}

.\!k-h-2\/4 {
    height: 50% !important;
}

.k-h-3\/4 {
    height: 75%;
}

.\!k-h-3\/4 {
    height: 75% !important;
}

.k-h-1\/5 {
    height: 20%;
}

.\!k-h-1\/5 {
    height: 20% !important;
}

.k-h-2\/5 {
    height: 40%;
}

.\!k-h-2\/5 {
    height: 40% !important;
}

.k-h-3\/5 {
    height: 60%;
}

.\!k-h-3\/5 {
    height: 60% !important;
}

.k-h-4\/5 {
    height: 80%;
}

.\!k-h-4\/5 {
    height: 80% !important;
}

.k-h-1\/6 {
    height: 16.666667%;
}

.\!k-h-1\/6 {
    height: 16.666667% !important;
}

.k-h-2\/6 {
    height: 33.333333%;
}

.\!k-h-2\/6 {
    height: 33.333333% !important;
}

.k-h-3\/6 {
    height: 50%;
}

.\!k-h-3\/6 {
    height: 50% !important;
}

.k-h-4\/6 {
    height: 66.666667%;
}

.\!k-h-4\/6 {
    height: 66.666667% !important;
}

.k-h-5\/6 {
    height: 83.333333%;
}

.\!k-h-5\/6 {
    height: 83.333333% !important;
}

.k-h-1\/12 {
    height: 8.333333%;
}

.\!k-h-1\/12 {
    height: 8.333333% !important;
}

.k-h-2\/12 {
    height: 16.666667%;
}

.\!k-h-2\/12 {
    height: 16.666667% !important;
}

.k-h-3\/12 {
    height: 25%;
}

.\!k-h-3\/12 {
    height: 25% !important;
}

.k-h-4\/12 {
    height: 33.333333%;
}

.\!k-h-4\/12 {
    height: 33.333333% !important;
}

.k-h-5\/12 {
    height: 41.666667%;
}

.\!k-h-5\/12 {
    height: 41.666667% !important;
}

.k-h-6\/12 {
    height: 50%;
}

.\!k-h-6\/12 {
    height: 50% !important;
}

.k-h-7\/12 {
    height: 58.333333%;
}

.\!k-h-7\/12 {
    height: 58.333333% !important;
}

.k-h-8\/12 {
    height: 66.666667%;
}

.\!k-h-8\/12 {
    height: 66.666667% !important;
}

.k-h-9\/12 {
    height: 75%;
}

.\!k-h-9\/12 {
    height: 75% !important;
}

.k-h-10\/12 {
    height: 83.333333%;
}

.\!k-h-10\/12 {
    height: 83.333333% !important;
}

.k-h-11\/12 {
    height: 91.666667%;
}

.\!k-h-11\/12 {
    height: 91.666667% !important;
}

.k-h-full {
    height: 100%;
}

.\!k-h-full {
    height: 100% !important;
}

.k-h-min {
    height: min-content;
}

.\!k-h-min {
    height: min-content !important;
}

.k-h-max {
    height: max-content;
}

.\!k-h-max {
    height: max-content !important;
}

.k-h-fit {
    height: fit-content;
}

.\!k-h-fit {
    height: fit-content !important;
}

.k-h-screen {
    height: 100vh;
}

.\!k-h-screen {
    height: 100vh !important;
}

.k-min-h-0 {
    min-height: 0;
}

.\!k-min-h-0 {
    min-height: 0 !important;
}

.k-min-h-full {
    min-height: 100%;
}

.\!k-min-h-full {
    min-height: 100% !important;
}

.k-min-h-screen {
    min-height: 100vh;
}

.\!k-min-h-screen {
    min-height: 100vh !important;
}

.k-min-h-min {
    min-height: min-content;
}

.\!k-min-h-min {
    min-height: min-content !important;
}

.k-min-h-max {
    min-height: max-content;
}

.\!k-min-h-max {
    min-height: max-content !important;
}

.k-min-h-fit {
    min-height: fit-content;
}

.\!k-min-h-fit {
    min-height: fit-content !important;
}

.k-max-h-none {
    max-height: none;
}

.\!k-max-h-none {
    max-height: none !important;
}

.k-max-h-0 {
    max-height: 0;
}

.\!k-max-h-0 {
    max-height: 0 !important;
}

.k-max-h-full {
    max-height: 100%;
}

.\!k-max-h-full {
    max-height: 100% !important;
}

.k-max-h-screen {
    max-height: 100vh;
}

.\!k-max-h-screen {
    max-height: 100vh !important;
}

.k-max-h-min {
    max-height: min-content;
}

.\!k-max-h-min {
    max-height: min-content !important;
}

.k-max-h-max {
    max-height: max-content;
}

.\!k-max-h-max {
    max-height: max-content !important;
}

.k-max-h-fit {
    max-height: fit-content;
}

.\!k-max-h-fit {
    max-height: fit-content !important;
}

.k-w-0 {
    width: 0;
}

.\!k-w-0 {
    width: 0 !important;
}

.k-w-1px {
    width: 1px;
}

.\!k-w-1px {
    width: 1px !important;
}

.k-w-0\.5 {
    width: 2px;
}

.\!k-w-0\.5 {
    width: 2px !important;
}

.k-w-1 {
    width: 4px;
}

.\!k-w-1 {
    width: 4px !important;
}

.k-w-1\.5 {
    width: 6px;
}

.\!k-w-1\.5 {
    width: 6px !important;
}

.k-w-2 {
    width: 8px;
}

.\!k-w-2 {
    width: 8px !important;
}

.k-w-2\.5 {
    width: 10px;
}

.\!k-w-2\.5 {
    width: 10px !important;
}

.k-w-3 {
    width: 12px;
}

.\!k-w-3 {
    width: 12px !important;
}

.k-w-3\.5 {
    width: 14px;
}

.\!k-w-3\.5 {
    width: 14px !important;
}

.k-w-4 {
    width: 16px;
}

.\!k-w-4 {
    width: 16px !important;
}

.k-w-4\.5 {
    width: 18px;
}

.\!k-w-4\.5 {
    width: 18px !important;
}

.k-w-5 {
    width: 20px;
}

.\!k-w-5 {
    width: 20px !important;
}

.k-w-5\.5 {
    width: 22px;
}

.\!k-w-5\.5 {
    width: 22px !important;
}

.k-w-6 {
    width: 24px;
}

.\!k-w-6 {
    width: 24px !important;
}

.k-w-6\.5 {
    width: 26px;
}

.\!k-w-6\.5 {
    width: 26px !important;
}

.k-w-7 {
    width: 28px;
}

.\!k-w-7 {
    width: 28px !important;
}

.k-w-7\.5 {
    width: 30px;
}

.\!k-w-7\.5 {
    width: 30px !important;
}

.k-w-8 {
    width: 32px;
}

.\!k-w-8 {
    width: 32px !important;
}

.k-w-9 {
    width: 36px;
}

.\!k-w-9 {
    width: 36px !important;
}

.k-w-10 {
    width: 40px;
}

.\!k-w-10 {
    width: 40px !important;
}

.k-w-11 {
    width: 44px;
}

.\!k-w-11 {
    width: 44px !important;
}

.k-w-12 {
    width: 48px;
}

.\!k-w-12 {
    width: 48px !important;
}

.k-w-13 {
    width: 52px;
}

.\!k-w-13 {
    width: 52px !important;
}

.k-w-14 {
    width: 56px;
}

.\!k-w-14 {
    width: 56px !important;
}

.k-w-15 {
    width: 60px;
}

.\!k-w-15 {
    width: 60px !important;
}

.k-w-16 {
    width: 64px;
}

.\!k-w-16 {
    width: 64px !important;
}

.k-w-17 {
    width: 68px;
}

.\!k-w-17 {
    width: 68px !important;
}

.k-w-18 {
    width: 72px;
}

.\!k-w-18 {
    width: 72px !important;
}

.k-w-19 {
    width: 76px;
}

.\!k-w-19 {
    width: 76px !important;
}

.k-w-20 {
    width: 80px;
}

.\!k-w-20 {
    width: 80px !important;
}

.k-w-21 {
    width: 84px;
}

.\!k-w-21 {
    width: 84px !important;
}

.k-w-22 {
    width: 88px;
}

.\!k-w-22 {
    width: 88px !important;
}

.k-w-23 {
    width: 92px;
}

.\!k-w-23 {
    width: 92px !important;
}

.k-w-24 {
    width: 96px;
}

.\!k-w-24 {
    width: 96px !important;
}

.k-w-auto {
    width: auto;
}

.\!k-w-auto {
    width: auto !important;
}

.k-w-1\/2 {
    width: 50%;
}

.\!k-w-1\/2 {
    width: 50% !important;
}

.k-w-1\/3 {
    width: 33.333333%;
}

.\!k-w-1\/3 {
    width: 33.333333% !important;
}

.k-w-2\/3 {
    width: 66.666667%;
}

.\!k-w-2\/3 {
    width: 66.666667% !important;
}

.k-w-1\/4 {
    width: 25%;
}

.\!k-w-1\/4 {
    width: 25% !important;
}

.k-w-2\/4 {
    width: 50%;
}

.\!k-w-2\/4 {
    width: 50% !important;
}

.k-w-3\/4 {
    width: 75%;
}

.\!k-w-3\/4 {
    width: 75% !important;
}

.k-w-1\/5 {
    width: 20%;
}

.\!k-w-1\/5 {
    width: 20% !important;
}

.k-w-2\/5 {
    width: 40%;
}

.\!k-w-2\/5 {
    width: 40% !important;
}

.k-w-3\/5 {
    width: 60%;
}

.\!k-w-3\/5 {
    width: 60% !important;
}

.k-w-4\/5 {
    width: 80%;
}

.\!k-w-4\/5 {
    width: 80% !important;
}

.k-w-1\/6 {
    width: 16.666667%;
}

.\!k-w-1\/6 {
    width: 16.666667% !important;
}

.k-w-2\/6 {
    width: 33.333333%;
}

.\!k-w-2\/6 {
    width: 33.333333% !important;
}

.k-w-3\/6 {
    width: 50%;
}

.\!k-w-3\/6 {
    width: 50% !important;
}

.k-w-4\/6 {
    width: 66.666667%;
}

.\!k-w-4\/6 {
    width: 66.666667% !important;
}

.k-w-5\/6 {
    width: 83.333333%;
}

.\!k-w-5\/6 {
    width: 83.333333% !important;
}

.k-w-1\/12 {
    width: 8.333333%;
}

.\!k-w-1\/12 {
    width: 8.333333% !important;
}

.k-w-2\/12 {
    width: 16.666667%;
}

.\!k-w-2\/12 {
    width: 16.666667% !important;
}

.k-w-3\/12 {
    width: 25%;
}

.\!k-w-3\/12 {
    width: 25% !important;
}

.k-w-4\/12 {
    width: 33.333333%;
}

.\!k-w-4\/12 {
    width: 33.333333% !important;
}

.k-w-5\/12 {
    width: 41.666667%;
}

.\!k-w-5\/12 {
    width: 41.666667% !important;
}

.k-w-6\/12 {
    width: 50%;
}

.\!k-w-6\/12 {
    width: 50% !important;
}

.k-w-7\/12 {
    width: 58.333333%;
}

.\!k-w-7\/12 {
    width: 58.333333% !important;
}

.k-w-8\/12 {
    width: 66.666667%;
}

.\!k-w-8\/12 {
    width: 66.666667% !important;
}

.k-w-9\/12 {
    width: 75%;
}

.\!k-w-9\/12 {
    width: 75% !important;
}

.k-w-10\/12 {
    width: 83.333333%;
}

.\!k-w-10\/12 {
    width: 83.333333% !important;
}

.k-w-11\/12 {
    width: 91.666667%;
}

.\!k-w-11\/12 {
    width: 91.666667% !important;
}

.k-w-full {
    width: 100%;
}

.\!k-w-full {
    width: 100% !important;
}

.k-w-min {
    width: min-content;
}

.\!k-w-min {
    width: min-content !important;
}

.k-w-max {
    width: max-content;
}

.\!k-w-max {
    width: max-content !important;
}

.k-w-fit {
    width: fit-content;
}

.\!k-w-fit {
    width: fit-content !important;
}

.k-w-screen {
    width: 100vw;
}

.\!k-w-screen {
    width: 100vw !important;
}

.k-min-w-0 {
    min-width: 0;
}

.\!k-min-w-0 {
    min-width: 0 !important;
}

.k-min-w-full {
    min-width: 100%;
}

.\!k-min-w-full {
    min-width: 100% !important;
}

.k-min-w-screen {
    min-width: 100vw;
}

.\!k-min-w-screen {
    min-width: 100vw !important;
}

.k-min-w-min {
    min-width: min-content;
}

.\!k-min-w-min {
    min-width: min-content !important;
}

.k-min-w-max {
    min-width: max-content;
}

.\!k-min-w-max {
    min-width: max-content !important;
}

.k-min-w-fit {
    min-width: fit-content;
}

.\!k-min-w-fit {
    min-width: fit-content !important;
}

.k-max-w-none {
    max-width: none;
}

.\!k-max-w-none {
    max-width: none !important;
}

.k-max-w-0 {
    max-width: 0;
}

.\!k-max-w-0 {
    max-width: 0 !important;
}

.k-max-w-full {
    max-width: 100%;
}

.\!k-max-w-full {
    max-width: 100% !important;
}

.k-max-w-screen {
    max-width: 100vw;
}

.\!k-max-w-screen {
    max-width: 100vw !important;
}

.k-max-w-min {
    max-width: min-content;
}

.\!k-max-w-min {
    max-width: min-content !important;
}

.k-max-w-max {
    max-width: max-content;
}

.\!k-max-w-max {
    max-width: max-content !important;
}

.k-max-w-fit {
    max-width: fit-content;
}

.\!k-max-w-fit {
    max-width: fit-content !important;
}

.k-font-size-xs {
    font-size: 10px;
}

.\!k-font-size-xs {
    font-size: 10px !important;
}

.k-font-size-sm {
    font-size: 12px;
}

.\!k-font-size-sm {
    font-size: 12px !important;
}

.k-font-size-md {
    font-size: 14px;
}

.\!k-font-size-md {
    font-size: 14px !important;
}

.k-font-size-lg {
    font-size: 16px;
}

.\!k-font-size-lg {
    font-size: 16px !important;
}

.k-font-size-xl {
    font-size: 20px;
}

.\!k-font-size-xl {
    font-size: 20px !important;
}

.k-fs-xs {
    font-size: 10px;
}

.\!k-fs-xs {
    font-size: 10px !important;
}

.k-fs-sm {
    font-size: 12px;
}

.\!k-fs-sm {
    font-size: 12px !important;
}

.k-fs-md {
    font-size: 14px;
}

.\!k-fs-md {
    font-size: 14px !important;
}

.k-fs-lg {
    font-size: 16px;
}

.\!k-fs-lg {
    font-size: 16px !important;
}

.k-fs-xl {
    font-size: 20px;
}

.\!k-fs-xl {
    font-size: 20px !important;
}

.k-font-italic {
    font-style: italic;
}

.\!k-font-italic {
    font-style: italic !important;
}

.k-font-non-italic {
    font-style: normal;
}

.\!k-font-non-italic {
    font-style: normal !important;
}

.k-font-thin {
    font-weight: 100;
}

.\!k-font-thin {
    font-weight: 100 !important;
}

.k-font-extralight {
    font-weight: 200;
}

.\!k-font-extralight {
    font-weight: 200 !important;
}

.k-font-light {
    font-weight: 300;
}

.\!k-font-light {
    font-weight: 300 !important;
}

.k-font-normal {
    font-weight: 400;
}

.\!k-font-normal {
    font-weight: 400 !important;
}

.k-font-medium {
    font-weight: 500;
}

.\!k-font-medium {
    font-weight: 500 !important;
}

.k-font-semibold {
    font-weight: 600;
}

.\!k-font-semibold {
    font-weight: 600 !important;
}

.k-font-bold {
    font-weight: 700;
}

.\!k-font-bold {
    font-weight: 700 !important;
}

.k-font-extrabold {
    font-weight: 800;
}

.\!k-font-extrabold {
    font-weight: 800 !important;
}

.k-font-black {
    font-weight: 900;
}

.\!k-font-black {
    font-weight: 900 !important;
}

.k-font-weight-thin {
    font-weight: 100;
}

.\!k-font-weight-thin {
    font-weight: 100 !important;
}

.k-font-weight-extralight {
    font-weight: 200;
}

.\!k-font-weight-extralight {
    font-weight: 200 !important;
}

.k-font-weight-light {
    font-weight: 300;
}

.\!k-font-weight-light {
    font-weight: 300 !important;
}

.k-font-weight-normal {
    font-weight: 400;
}

.\!k-font-weight-normal {
    font-weight: 400 !important;
}

.k-font-weight-medium {
    font-weight: 500;
}

.\!k-font-weight-medium {
    font-weight: 500 !important;
}

.k-font-weight-semibold {
    font-weight: 600;
}

.\!k-font-weight-semibold {
    font-weight: 600 !important;
}

.k-font-weight-bold {
    font-weight: 700;
}

.\!k-font-weight-bold {
    font-weight: 700 !important;
}

.k-font-weight-extrabold {
    font-weight: 800;
}

.\!k-font-weight-extrabold {
    font-weight: 800 !important;
}

.k-font-weight-black {
    font-weight: 900;
}

.\!k-font-weight-black {
    font-weight: 900 !important;
}

.k-list-none {
    list-style-type: none;
}

.\!k-list-none {
    list-style-type: none !important;
}

.k-list-disc {
    list-style-type: disc;
}

.\!k-list-disc {
    list-style-type: disc !important;
}

.k-list-decimal {
    list-style-type: decimal;
}

.\!k-list-decimal {
    list-style-type: decimal !important;
}

.k-text-left {
    text-align: left;
}

.\!k-text-left {
    text-align: left !important;
}

.k-text-right {
    text-align: right;
}

.\!k-text-right {
    text-align: right !important;
}

.k-text-center {
    text-align: center;
}

.\!k-text-center {
    text-align: center !important;
}

.k-text-justify {
    text-align: justify;
}

.\!k-text-justify {
    text-align: justify !important;
}

.k-text-start {
    text-align: start;
}

.\!k-text-start {
    text-align: start !important;
}

.k-text-end {
    text-align: end;
}

.\!k-text-end {
    text-align: end !important;
}

.k-text-primary {
    color: #4e1289;
}

.\!k-text-primary {
    color: #4e1289 !important;
}

.k-text-secondary {
    color: #666666;
}

.\!k-text-secondary {
    color: #666666 !important;
}

.k-text-tertiary {
    color: #03a9f4;
}

.\!k-text-tertiary {
    color: #03a9f4 !important;
}

.k-text-info {
    color: #0058e9;
}

.\!k-text-info {
    color: #0058e9 !important;
}

.k-text-success {
    color: #37b400;
}

.\!k-text-success {
    color: #37b400 !important;
}

.k-text-warning {
    color: #ffc000;
}

.\!k-text-warning {
    color: #ffc000 !important;
}

.k-text-error {
    color: #f31700;
}

.\!k-text-error {
    color: #f31700 !important;
}

.k-text-dark {
    color: #424242;
}

.\!k-text-dark {
    color: #424242 !important;
}

.k-text-light {
    color: #ebebeb;
}

.\!k-text-light {
    color: #ebebeb !important;
}

.k-text-inverse {
    color: #424242;
}

.\!k-text-inverse {
    color: #424242 !important;
}

.k-text-inherit {
    color: inherit;
}

.\!k-text-inherit {
    color: inherit !important;
}

.k-text-current {
    color: currentColor;
}

.\!k-text-current {
    color: currentColor !important;
}

.k-text-transparent {
    color: transparent;
}

.\!k-text-transparent {
    color: transparent !important;
}

.k-text-black {
    color: black;
}

.\!k-text-black {
    color: black !important;
}

.k-text-white {
    color: white;
}

.\!k-text-white {
    color: white !important;
}

.k-color-primary {
    color: #4e1289;
}

.\!k-color-primary {
    color: #4e1289 !important;
}

.k-color-secondary {
    color: #666666;
}

.\!k-color-secondary {
    color: #666666 !important;
}

.k-color-tertiary {
    color: #03a9f4;
}

.\!k-color-tertiary {
    color: #03a9f4 !important;
}

.k-color-info {
    color: #0058e9;
}

.\!k-color-info {
    color: #0058e9 !important;
}

.k-color-success {
    color: #37b400;
}

.\!k-color-success {
    color: #37b400 !important;
}

.k-color-warning {
    color: #ffc000;
}

.\!k-color-warning {
    color: #ffc000 !important;
}

.k-color-error {
    color: #f31700;
}

.\!k-color-error {
    color: #f31700 !important;
}

.k-color-dark {
    color: #424242;
}

.\!k-color-dark {
    color: #424242 !important;
}

.k-color-light {
    color: #ebebeb;
}

.\!k-color-light {
    color: #ebebeb !important;
}

.k-color-inverse {
    color: #424242;
}

.\!k-color-inverse {
    color: #424242 !important;
}

.k-color-inherit {
    color: inherit;
}

.\!k-color-inherit {
    color: inherit !important;
}

.k-color-current {
    color: currentColor;
}

.\!k-color-current {
    color: currentColor !important;
}

.k-color-transparent {
    color: transparent;
}

.\!k-color-transparent {
    color: transparent !important;
}

.k-color-black {
    color: black;
}

.\!k-color-black {
    color: black !important;
}

.k-color-white {
    color: white;
}

.\!k-color-white {
    color: white !important;
}

.k-text-underline {
    text-decoration: underline;
}

.\!k-text-underline {
    text-decoration: underline !important;
}

.k-text-overline {
    text-decoration: overline;
}

.\!k-text-overline {
    text-decoration: overline !important;
}

.k-text-line-through {
    text-decoration: line-through;
}

.\!k-text-line-through {
    text-decoration: line-through !important;
}

.k-text-no-underline {
    text-decoration: none;
}

.\!k-text-no-underline {
    text-decoration: none !important;
}

.k-text-clip {
    text-overflow: clip;
}

.\!k-text-clip {
    text-overflow: clip !important;
}

.k-text-ellipsis {
    text-overflow: ellipsis;
}

.\!k-text-ellipsis {
    text-overflow: ellipsis !important;
}

.k-text-truncate,
.k-text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.\!k-text-truncate,
.\!k-text-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.k-text-uppercase {
    text-transform: uppercase;
}

.\!k-text-uppercase {
    text-transform: uppercase !important;
}

.k-text-lowercase {
    text-transform: lowercase;
}

.\!k-text-lowercase {
    text-transform: lowercase !important;
}

.k-text-capitalize {
    text-transform: capitalize;
}

.\!k-text-capitalize {
    text-transform: capitalize !important;
}

.k-text-normal-case {
    text-transform: none;
}

.\!k-text-normal-case {
    text-transform: none !important;
}

.k-align-baseline {
    vertical-align: baseline;
}

.\!k-align-baseline {
    vertical-align: baseline !important;
}

.k-align-top {
    vertical-align: top;
}

.\!k-align-top {
    vertical-align: top !important;
}

.k-align-middle {
    vertical-align: middle;
}

.\!k-align-middle {
    vertical-align: middle !important;
}

.k-align-bottom {
    vertical-align: bottom;
}

.\!k-align-bottom {
    vertical-align: bottom !important;
}

.k-align-text-top {
    vertical-align: text-top;
}

.\!k-align-text-top {
    vertical-align: text-top !important;
}

.k-align-text-bottom {
    vertical-align: text-bottom;
}

.\!k-align-text-bottom {
    vertical-align: text-bottom !important;
}

.k-align-sub {
    vertical-align: sub;
}

.\!k-align-sub {
    vertical-align: sub !important;
}

.k-align-super {
    vertical-align: super;
}

.\!k-align-super {
    vertical-align: super !important;
}

.k-white-space-normal {
    white-space: normal;
}

.\!k-white-space-normal {
    white-space: normal !important;
}

.k-white-space-nowrap,
.k-text-nowrap {
    white-space: nowrap;
}

.\!k-white-space-nowrap {
    white-space: nowrap !important;
}

.k-white-space-pre {
    white-space: pre;
}

.\!k-white-space-pre {
    white-space: pre !important;
}

.k-white-space-pre-line {
    white-space: pre-line;
}

.\!k-white-space-pre-line {
    white-space: pre-line !important;
}

.k-white-space-pre-wrap {
    white-space: pre-wrap;
}

.\!k-white-space-pre-wrap {
    white-space: pre-wrap !important;
}

.k-white-space-break-spaces {
    white-space: break-spaces;
}

.\!k-white-space-break-spaces {
    white-space: break-spaces !important;
}

.k-whitespace-normal {
    white-space: normal;
}

.\!k-whitespace-normal {
    white-space: normal !important;
}

.k-whitespace-nowrap {
    white-space: nowrap;
}

.\!k-whitespace-nowrap {
    white-space: nowrap !important;
}

.k-whitespace-pre {
    white-space: pre;
}

.\!k-whitespace-pre {
    white-space: pre !important;
}

.k-whitespace-pre-line {
    white-space: pre-line;
}

.\!k-whitespace-pre-line {
    white-space: pre-line !important;
}

.k-whitespace-pre-wrap {
    white-space: pre-wrap;
}

.\!k-whitespace-pre-wrap {
    white-space: pre-wrap !important;
}

.k-whitespace-break-spaces {
    white-space: break-spaces;
}

.\!k-whitespace-break-spaces {
    white-space: break-spaces !important;
}

.k-bg-clip-border {
    background-clip: border-box;
}

.\!k-bg-clip-border {
    background-clip: border-box !important;
}

.k-bg-clip-padding {
    background-clip: padding-box;
}

.\!k-bg-clip-padding {
    background-clip: padding-box !important;
}

.k-bg-clip-content {
    background-clip: content-box;
}

.\!k-bg-clip-content {
    background-clip: content-box !important;
}

.k-bg-clip-text {
    background-clip: text;
}

.\!k-bg-clip-text {
    background-clip: text !important;
}

.k-bg-primary {
    background-color: #4e1289;
}

.\!k-bg-primary {
    background-color: #4e1289 !important;
}

.k-bg-secondary {
    background-color: #666666;
}

.\!k-bg-secondary {
    background-color: #666666 !important;
}

.k-bg-tertiary {
    background-color: #03a9f4;
}

.\!k-bg-tertiary {
    background-color: #03a9f4 !important;
}

.k-bg-info {
    background-color: #0058e9;
}

.\!k-bg-info {
    background-color: #0058e9 !important;
}

.k-bg-success {
    background-color: #37b400;
}

.\!k-bg-success {
    background-color: #37b400 !important;
}

.k-bg-warning {
    background-color: #ffc000;
}

.\!k-bg-warning {
    background-color: #ffc000 !important;
}

.k-bg-error {
    background-color: #f31700;
}

.\!k-bg-error {
    background-color: #f31700 !important;
}

.k-bg-dark {
    background-color: #424242;
}

.\!k-bg-dark {
    background-color: #424242 !important;
}

.k-bg-light {
    background-color: #ebebeb;
}

.\!k-bg-light {
    background-color: #ebebeb !important;
}

.k-bg-inverse {
    background-color: #424242;
}

.\!k-bg-inverse {
    background-color: #424242 !important;
}

.k-bg-inherit {
    background-color: inherit;
}

.\!k-bg-inherit {
    background-color: inherit !important;
}

.k-bg-transparent {
    background-color: transparent;
}

.\!k-bg-transparent {
    background-color: transparent !important;
}

.k-bg-black {
    background-color: black;
}

.\!k-bg-black {
    background-color: black !important;
}

.k-bg-white {
    background-color: white;
}

.\!k-bg-white {
    background-color: white !important;
}

.k-bg-center {
    background-position: center;
}

.\!k-bg-center {
    background-position: center !important;
}

.k-bg-top {
    background-position: top;
}

.\!k-bg-top {
    background-position: top !important;
}

.k-bg-right {
    background-position: right;
}

.\!k-bg-right {
    background-position: right !important;
}

.k-bg-bottom {
    background-position: bottom;
}

.\!k-bg-bottom {
    background-position: bottom !important;
}

.k-bg-left {
    background-position: left;
}

.\!k-bg-left {
    background-position: left !important;
}

.k-bg-top-left {
    background-position: top left;
}

.\!k-bg-top-left {
    background-position: top left !important;
}

.k-bg-top-right {
    background-position: top right;
}

.\!k-bg-top-right {
    background-position: top right !important;
}

.k-bg-bottom-left {
    background-position: bottom left;
}

.\!k-bg-bottom-left {
    background-position: bottom left !important;
}

.k-bg-bottom-right {
    background-position: bottom right;
}

.\!k-bg-bottom-right {
    background-position: bottom right !important;
}

.k-bg-auto {
    background-size: auto;
}

.\!k-bg-auto {
    background-size: auto !important;
}

.k-bg-cover {
    background-size: cover;
}

.\!k-bg-cover {
    background-size: cover !important;
}

.k-bg-contain {
    background-size: contain;
}

.\!k-bg-contain {
    background-size: contain !important;
}

.k-border-primary {
    border-color: #4e1289;
}

.\!k-border-primary {
    border-color: #4e1289 !important;
}

.k-border-secondary {
    border-color: #666666;
}

.\!k-border-secondary {
    border-color: #666666 !important;
}

.k-border-tertiary {
    border-color: #03a9f4;
}

.\!k-border-tertiary {
    border-color: #03a9f4 !important;
}

.k-border-info {
    border-color: #0058e9;
}

.\!k-border-info {
    border-color: #0058e9 !important;
}

.k-border-success {
    border-color: #37b400;
}

.\!k-border-success {
    border-color: #37b400 !important;
}

.k-border-warning {
    border-color: #ffc000;
}

.\!k-border-warning {
    border-color: #ffc000 !important;
}

.k-border-error {
    border-color: #f31700;
}

.\!k-border-error {
    border-color: #f31700 !important;
}

.k-border-dark {
    border-color: #424242;
}

.\!k-border-dark {
    border-color: #424242 !important;
}

.k-border-light {
    border-color: #ebebeb;
}

.\!k-border-light {
    border-color: #ebebeb !important;
}

.k-border-inverse {
    border-color: #424242;
}

.\!k-border-inverse {
    border-color: #424242 !important;
}

.k-border-inherit {
    border-color: inherit;
}

.\!k-border-inherit {
    border-color: inherit !important;
}

.k-border-current {
    border-color: currentColor;
}

.\!k-border-current {
    border-color: currentColor !important;
}

.k-border-transparent {
    border-color: transparent;
}

.\!k-border-transparent {
    border-color: transparent !important;
}

.k-border-black {
    border-color: black;
}

.\!k-border-black {
    border-color: black !important;
}

.k-border-white {
    border-color: white;
}

.\!k-border-white {
    border-color: white !important;
}

.k-border-t-primary {
    border-top-color: #4e1289;
}

.\!k-border-t-primary {
    border-top-color: #4e1289 !important;
}

.k-border-t-secondary {
    border-top-color: #666666;
}

.\!k-border-t-secondary {
    border-top-color: #666666 !important;
}

.k-border-t-tertiary {
    border-top-color: #03a9f4;
}

.\!k-border-t-tertiary {
    border-top-color: #03a9f4 !important;
}

.k-border-t-info {
    border-top-color: #0058e9;
}

.\!k-border-t-info {
    border-top-color: #0058e9 !important;
}

.k-border-t-success {
    border-top-color: #37b400;
}

.\!k-border-t-success {
    border-top-color: #37b400 !important;
}

.k-border-t-warning {
    border-top-color: #ffc000;
}

.\!k-border-t-warning {
    border-top-color: #ffc000 !important;
}

.k-border-t-error {
    border-top-color: #f31700;
}

.\!k-border-t-error {
    border-top-color: #f31700 !important;
}

.k-border-t-dark {
    border-top-color: #424242;
}

.\!k-border-t-dark {
    border-top-color: #424242 !important;
}

.k-border-t-light {
    border-top-color: #ebebeb;
}

.\!k-border-t-light {
    border-top-color: #ebebeb !important;
}

.k-border-t-inverse {
    border-top-color: #424242;
}

.\!k-border-t-inverse {
    border-top-color: #424242 !important;
}

.k-border-t-inherit {
    border-top-color: inherit;
}

.\!k-border-t-inherit {
    border-top-color: inherit !important;
}

.k-border-t-current {
    border-top-color: currentColor;
}

.\!k-border-t-current {
    border-top-color: currentColor !important;
}

.k-border-t-transparent {
    border-top-color: transparent;
}

.\!k-border-t-transparent {
    border-top-color: transparent !important;
}

.k-border-t-black {
    border-top-color: black;
}

.\!k-border-t-black {
    border-top-color: black !important;
}

.k-border-t-white {
    border-top-color: white;
}

.\!k-border-t-white {
    border-top-color: white !important;
}

.k-border-r-primary {
    border-right-color: #4e1289;
}

.\!k-border-r-primary {
    border-right-color: #4e1289 !important;
}

.k-border-r-secondary {
    border-right-color: #666666;
}

.\!k-border-r-secondary {
    border-right-color: #666666 !important;
}

.k-border-r-tertiary {
    border-right-color: #03a9f4;
}

.\!k-border-r-tertiary {
    border-right-color: #03a9f4 !important;
}

.k-border-r-info {
    border-right-color: #0058e9;
}

.\!k-border-r-info {
    border-right-color: #0058e9 !important;
}

.k-border-r-success {
    border-right-color: #37b400;
}

.\!k-border-r-success {
    border-right-color: #37b400 !important;
}

.k-border-r-warning {
    border-right-color: #ffc000;
}

.\!k-border-r-warning {
    border-right-color: #ffc000 !important;
}

.k-border-r-error {
    border-right-color: #f31700;
}

.\!k-border-r-error {
    border-right-color: #f31700 !important;
}

.k-border-r-dark {
    border-right-color: #424242;
}

.\!k-border-r-dark {
    border-right-color: #424242 !important;
}

.k-border-r-light {
    border-right-color: #ebebeb;
}

.\!k-border-r-light {
    border-right-color: #ebebeb !important;
}

.k-border-r-inverse {
    border-right-color: #424242;
}

.\!k-border-r-inverse {
    border-right-color: #424242 !important;
}

.k-border-r-inherit {
    border-right-color: inherit;
}

.\!k-border-r-inherit {
    border-right-color: inherit !important;
}

.k-border-r-current {
    border-right-color: currentColor;
}

.\!k-border-r-current {
    border-right-color: currentColor !important;
}

.k-border-r-transparent {
    border-right-color: transparent;
}

.\!k-border-r-transparent {
    border-right-color: transparent !important;
}

.k-border-r-black {
    border-right-color: black;
}

.\!k-border-r-black {
    border-right-color: black !important;
}

.k-border-r-white {
    border-right-color: white;
}

.\!k-border-r-white {
    border-right-color: white !important;
}

.k-border-b-primary {
    border-bottom-color: #4e1289;
}

.\!k-border-b-primary {
    border-bottom-color: #4e1289 !important;
}

.k-border-b-secondary {
    border-bottom-color: #666666;
}

.\!k-border-b-secondary {
    border-bottom-color: #666666 !important;
}

.k-border-b-tertiary {
    border-bottom-color: #03a9f4;
}

.\!k-border-b-tertiary {
    border-bottom-color: #03a9f4 !important;
}

.k-border-b-info {
    border-bottom-color: #0058e9;
}

.\!k-border-b-info {
    border-bottom-color: #0058e9 !important;
}

.k-border-b-success {
    border-bottom-color: #37b400;
}

.\!k-border-b-success {
    border-bottom-color: #37b400 !important;
}

.k-border-b-warning {
    border-bottom-color: #ffc000;
}

.\!k-border-b-warning {
    border-bottom-color: #ffc000 !important;
}

.k-border-b-error {
    border-bottom-color: #f31700;
}

.\!k-border-b-error {
    border-bottom-color: #f31700 !important;
}

.k-border-b-dark {
    border-bottom-color: #424242;
}

.\!k-border-b-dark {
    border-bottom-color: #424242 !important;
}

.k-border-b-light {
    border-bottom-color: #ebebeb;
}

.\!k-border-b-light {
    border-bottom-color: #ebebeb !important;
}

.k-border-b-inverse {
    border-bottom-color: #424242;
}

.\!k-border-b-inverse {
    border-bottom-color: #424242 !important;
}

.k-border-b-inherit {
    border-bottom-color: inherit;
}

.\!k-border-b-inherit {
    border-bottom-color: inherit !important;
}

.k-border-b-current {
    border-bottom-color: currentColor;
}

.\!k-border-b-current {
    border-bottom-color: currentColor !important;
}

.k-border-b-transparent {
    border-bottom-color: transparent;
}

.\!k-border-b-transparent {
    border-bottom-color: transparent !important;
}

.k-border-b-black {
    border-bottom-color: black;
}

.\!k-border-b-black {
    border-bottom-color: black !important;
}

.k-border-b-white {
    border-bottom-color: white;
}

.\!k-border-b-white {
    border-bottom-color: white !important;
}

.k-border-l-primary {
    border-left-color: #4e1289;
}

.\!k-border-l-primary {
    border-left-color: #4e1289 !important;
}

.k-border-l-secondary {
    border-left-color: #666666;
}

.\!k-border-l-secondary {
    border-left-color: #666666 !important;
}

.k-border-l-tertiary {
    border-left-color: #03a9f4;
}

.\!k-border-l-tertiary {
    border-left-color: #03a9f4 !important;
}

.k-border-l-info {
    border-left-color: #0058e9;
}

.\!k-border-l-info {
    border-left-color: #0058e9 !important;
}

.k-border-l-success {
    border-left-color: #37b400;
}

.\!k-border-l-success {
    border-left-color: #37b400 !important;
}

.k-border-l-warning {
    border-left-color: #ffc000;
}

.\!k-border-l-warning {
    border-left-color: #ffc000 !important;
}

.k-border-l-error {
    border-left-color: #f31700;
}

.\!k-border-l-error {
    border-left-color: #f31700 !important;
}

.k-border-l-dark {
    border-left-color: #424242;
}

.\!k-border-l-dark {
    border-left-color: #424242 !important;
}

.k-border-l-light {
    border-left-color: #ebebeb;
}

.\!k-border-l-light {
    border-left-color: #ebebeb !important;
}

.k-border-l-inverse {
    border-left-color: #424242;
}

.\!k-border-l-inverse {
    border-left-color: #424242 !important;
}

.k-border-l-inherit {
    border-left-color: inherit;
}

.\!k-border-l-inherit {
    border-left-color: inherit !important;
}

.k-border-l-current {
    border-left-color: currentColor;
}

.\!k-border-l-current {
    border-left-color: currentColor !important;
}

.k-border-l-transparent {
    border-left-color: transparent;
}

.\!k-border-l-transparent {
    border-left-color: transparent !important;
}

.k-border-l-black {
    border-left-color: black;
}

.\!k-border-l-black {
    border-left-color: black !important;
}

.k-border-l-white {
    border-left-color: white;
}

.\!k-border-l-white {
    border-left-color: white !important;
}

.k-border-x-primary {
    border-inline-color: #4e1289;
}

.\!k-border-x-primary {
    border-inline-color: #4e1289 !important;
}

.k-border-x-secondary {
    border-inline-color: #666666;
}

.\!k-border-x-secondary {
    border-inline-color: #666666 !important;
}

.k-border-x-tertiary {
    border-inline-color: #03a9f4;
}

.\!k-border-x-tertiary {
    border-inline-color: #03a9f4 !important;
}

.k-border-x-info {
    border-inline-color: #0058e9;
}

.\!k-border-x-info {
    border-inline-color: #0058e9 !important;
}

.k-border-x-success {
    border-inline-color: #37b400;
}

.\!k-border-x-success {
    border-inline-color: #37b400 !important;
}

.k-border-x-warning {
    border-inline-color: #ffc000;
}

.\!k-border-x-warning {
    border-inline-color: #ffc000 !important;
}

.k-border-x-error {
    border-inline-color: #f31700;
}

.\!k-border-x-error {
    border-inline-color: #f31700 !important;
}

.k-border-x-dark {
    border-inline-color: #424242;
}

.\!k-border-x-dark {
    border-inline-color: #424242 !important;
}

.k-border-x-light {
    border-inline-color: #ebebeb;
}

.\!k-border-x-light {
    border-inline-color: #ebebeb !important;
}

.k-border-x-inverse {
    border-inline-color: #424242;
}

.\!k-border-x-inverse {
    border-inline-color: #424242 !important;
}

.k-border-x-inherit {
    border-inline-color: inherit;
}

.\!k-border-x-inherit {
    border-inline-color: inherit !important;
}

.k-border-x-current {
    border-inline-color: currentColor;
}

.\!k-border-x-current {
    border-inline-color: currentColor !important;
}

.k-border-x-transparent {
    border-inline-color: transparent;
}

.\!k-border-x-transparent {
    border-inline-color: transparent !important;
}

.k-border-x-black {
    border-inline-color: black;
}

.\!k-border-x-black {
    border-inline-color: black !important;
}

.k-border-x-white {
    border-inline-color: white;
}

.\!k-border-x-white {
    border-inline-color: white !important;
}

.k-border-y-primary {
    border-block-color: #4e1289;
}

.\!k-border-y-primary {
    border-block-color: #4e1289 !important;
}

.k-border-y-secondary {
    border-block-color: #666666;
}

.\!k-border-y-secondary {
    border-block-color: #666666 !important;
}

.k-border-y-tertiary {
    border-block-color: #03a9f4;
}

.\!k-border-y-tertiary {
    border-block-color: #03a9f4 !important;
}

.k-border-y-info {
    border-block-color: #0058e9;
}

.\!k-border-y-info {
    border-block-color: #0058e9 !important;
}

.k-border-y-success {
    border-block-color: #37b400;
}

.\!k-border-y-success {
    border-block-color: #37b400 !important;
}

.k-border-y-warning {
    border-block-color: #ffc000;
}

.\!k-border-y-warning {
    border-block-color: #ffc000 !important;
}

.k-border-y-error {
    border-block-color: #f31700;
}

.\!k-border-y-error {
    border-block-color: #f31700 !important;
}

.k-border-y-dark {
    border-block-color: #424242;
}

.\!k-border-y-dark {
    border-block-color: #424242 !important;
}

.k-border-y-light {
    border-block-color: #ebebeb;
}

.\!k-border-y-light {
    border-block-color: #ebebeb !important;
}

.k-border-y-inverse {
    border-block-color: #424242;
}

.\!k-border-y-inverse {
    border-block-color: #424242 !important;
}

.k-border-y-inherit {
    border-block-color: inherit;
}

.\!k-border-y-inherit {
    border-block-color: inherit !important;
}

.k-border-y-current {
    border-block-color: currentColor;
}

.\!k-border-y-current {
    border-block-color: currentColor !important;
}

.k-border-y-transparent {
    border-block-color: transparent;
}

.\!k-border-y-transparent {
    border-block-color: transparent !important;
}

.k-border-y-black {
    border-block-color: black;
}

.\!k-border-y-black {
    border-block-color: black !important;
}

.k-border-y-white {
    border-block-color: white;
}

.\!k-border-y-white {
    border-block-color: white !important;
}

.k-rounded {
    border-radius: 4px;
}

.\!k-rounded {
    border-radius: 4px !important;
}

.k-rounded-0 {
    border-radius: 0;
}

.\!k-rounded-0 {
    border-radius: 0 !important;
}

.k-rounded-sm {
    border-radius: 2px;
}

.\!k-rounded-sm {
    border-radius: 2px !important;
}

.k-rounded-md {
    border-radius: 4px;
}

.\!k-rounded-md {
    border-radius: 4px !important;
}

.k-rounded-lg {
    border-radius: 6px;
}

.\!k-rounded-lg {
    border-radius: 6px !important;
}

.k-rounded-full {
    border-radius: 9999px;
}

.\!k-rounded-full {
    border-radius: 9999px !important;
}

.k-rounded-tl {
    border-top-left-radius: 4px;
}

.\!k-rounded-tl {
    border-top-left-radius: 4px !important;
}

.k-rounded-tl-0 {
    border-top-left-radius: 0;
}

.\!k-rounded-tl-0 {
    border-top-left-radius: 0 !important;
}

.k-rounded-tl-sm {
    border-top-left-radius: 2px;
}

.\!k-rounded-tl-sm {
    border-top-left-radius: 2px !important;
}

.k-rounded-tl-md {
    border-top-left-radius: 4px;
}

.\!k-rounded-tl-md {
    border-top-left-radius: 4px !important;
}

.k-rounded-tl-lg {
    border-top-left-radius: 6px;
}

.\!k-rounded-tl-lg {
    border-top-left-radius: 6px !important;
}

.k-rounded-tl-full {
    border-top-left-radius: 9999px;
}

.\!k-rounded-tl-full {
    border-top-left-radius: 9999px !important;
}

.k-rounded-tr {
    border-top-right-radius: 4px;
}

.\!k-rounded-tr {
    border-top-right-radius: 4px !important;
}

.k-rounded-tr-0 {
    border-top-right-radius: 0;
}

.\!k-rounded-tr-0 {
    border-top-right-radius: 0 !important;
}

.k-rounded-tr-sm {
    border-top-right-radius: 2px;
}

.\!k-rounded-tr-sm {
    border-top-right-radius: 2px !important;
}

.k-rounded-tr-md {
    border-top-right-radius: 4px;
}

.\!k-rounded-tr-md {
    border-top-right-radius: 4px !important;
}

.k-rounded-tr-lg {
    border-top-right-radius: 6px;
}

.\!k-rounded-tr-lg {
    border-top-right-radius: 6px !important;
}

.k-rounded-tr-full {
    border-top-right-radius: 9999px;
}

.\!k-rounded-tr-full {
    border-top-right-radius: 9999px !important;
}

.k-rounded-br {
    border-bottom-right-radius: 4px;
}

.\!k-rounded-br {
    border-bottom-right-radius: 4px !important;
}

.k-rounded-br-0 {
    border-bottom-right-radius: 0;
}

.\!k-rounded-br-0 {
    border-bottom-right-radius: 0 !important;
}

.k-rounded-br-sm {
    border-bottom-right-radius: 2px;
}

.\!k-rounded-br-sm {
    border-bottom-right-radius: 2px !important;
}

.k-rounded-br-md {
    border-bottom-right-radius: 4px;
}

.\!k-rounded-br-md {
    border-bottom-right-radius: 4px !important;
}

.k-rounded-br-lg {
    border-bottom-right-radius: 6px;
}

.\!k-rounded-br-lg {
    border-bottom-right-radius: 6px !important;
}

.k-rounded-br-full {
    border-bottom-right-radius: 9999px;
}

.\!k-rounded-br-full {
    border-bottom-right-radius: 9999px !important;
}

.k-rounded-bl {
    border-bottom-left-radius: 4px;
}

.\!k-rounded-bl {
    border-bottom-left-radius: 4px !important;
}

.k-rounded-bl-0 {
    border-bottom-left-radius: 0;
}

.\!k-rounded-bl-0 {
    border-bottom-left-radius: 0 !important;
}

.k-rounded-bl-sm {
    border-bottom-left-radius: 2px;
}

.\!k-rounded-bl-sm {
    border-bottom-left-radius: 2px !important;
}

.k-rounded-bl-md {
    border-bottom-left-radius: 4px;
}

.\!k-rounded-bl-md {
    border-bottom-left-radius: 4px !important;
}

.k-rounded-bl-lg {
    border-bottom-left-radius: 6px;
}

.\!k-rounded-bl-lg {
    border-bottom-left-radius: 6px !important;
}

.k-rounded-bl-full {
    border-bottom-left-radius: 9999px;
}

.\!k-rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
}

.k-rounded-t {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.\!k-rounded-t {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.k-rounded-t-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.\!k-rounded-t-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.k-rounded-t-sm {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.\!k-rounded-t-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
}

.k-rounded-t-md {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.\!k-rounded-t-md {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.k-rounded-t-lg {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.\!k-rounded-t-lg {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}

.k-rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
}

.\!k-rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
}

.k-rounded-r {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.\!k-rounded-r {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.k-rounded-r-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.\!k-rounded-r-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.k-rounded-r-sm {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.\!k-rounded-r-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

.k-rounded-r-md {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.\!k-rounded-r-md {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.k-rounded-r-lg {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.\!k-rounded-r-lg {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.k-rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}

.\!k-rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
}

.k-rounded-b {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.\!k-rounded-b {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.k-rounded-b-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.\!k-rounded-b-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.k-rounded-b-sm {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.\!k-rounded-b-sm {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

.k-rounded-b-md {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.\!k-rounded-b-md {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.k-rounded-b-lg {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.\!k-rounded-b-lg {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

.k-rounded-b-full {
    border-bottom-left-radius: 9999px;
    border-bottom-right-radius: 9999px;
}

.\!k-rounded-b-full {
    border-bottom-left-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
}

.k-rounded-l {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.\!k-rounded-l {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.k-rounded-l-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.\!k-rounded-l-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.k-rounded-l-sm {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.\!k-rounded-l-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
}

.k-rounded-l-md {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.\!k-rounded-l-md {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.k-rounded-l-lg {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.\!k-rounded-l-lg {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.k-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
}

.\!k-rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
}

.k-border-solid {
    border-style: solid;
}

.\!k-border-solid {
    border-style: solid !important;
}

.k-border-dashed {
    border-style: dashed;
}

.\!k-border-dashed {
    border-style: dashed !important;
}

.k-border-dotted {
    border-style: dotted;
}

.\!k-border-dotted {
    border-style: dotted !important;
}

.k-border-double {
    border-style: double;
}

.\!k-border-double {
    border-style: double !important;
}

.k-border-inset {
    border-style: inset;
}

.\!k-border-inset {
    border-style: inset !important;
}

.k-border-outset {
    border-style: outset;
}

.\!k-border-outset {
    border-style: outset !important;
}

.k-border-hidden {
    border-style: hidden;
}

.\!k-border-hidden {
    border-style: hidden !important;
}

.k-border-none {
    border-style: none;
}

.\!k-border-none {
    border-style: none !important;
}

.k-border-t-solid {
    border-top-style: solid;
}

.\!k-border-t-solid {
    border-top-style: solid !important;
}

.k-border-t-dashed {
    border-top-style: dashed;
}

.\!k-border-t-dashed {
    border-top-style: dashed !important;
}

.k-border-t-dotted {
    border-top-style: dotted;
}

.\!k-border-t-dotted {
    border-top-style: dotted !important;
}

.k-border-t-double {
    border-top-style: double;
}

.\!k-border-t-double {
    border-top-style: double !important;
}

.k-border-t-inset {
    border-top-style: inset;
}

.\!k-border-t-inset {
    border-top-style: inset !important;
}

.k-border-t-outset {
    border-top-style: outset;
}

.\!k-border-t-outset {
    border-top-style: outset !important;
}

.k-border-t-hidden {
    border-top-style: hidden;
}

.\!k-border-t-hidden {
    border-top-style: hidden !important;
}

.k-border-t-none {
    border-top-style: none;
}

.\!k-border-t-none {
    border-top-style: none !important;
}

.k-border-r-solid {
    border-right-style: solid;
}

.\!k-border-r-solid {
    border-right-style: solid !important;
}

.k-border-r-dashed {
    border-right-style: dashed;
}

.\!k-border-r-dashed {
    border-right-style: dashed !important;
}

.k-border-r-dotted {
    border-right-style: dotted;
}

.\!k-border-r-dotted {
    border-right-style: dotted !important;
}

.k-border-r-double {
    border-right-style: double;
}

.\!k-border-r-double {
    border-right-style: double !important;
}

.k-border-r-inset {
    border-right-style: inset;
}

.\!k-border-r-inset {
    border-right-style: inset !important;
}

.k-border-r-outset {
    border-right-style: outset;
}

.\!k-border-r-outset {
    border-right-style: outset !important;
}

.k-border-r-hidden {
    border-right-style: hidden;
}

.\!k-border-r-hidden {
    border-right-style: hidden !important;
}

.k-border-r-none {
    border-right-style: none;
}

.\!k-border-r-none {
    border-right-style: none !important;
}

.k-border-b-solid {
    border-bottom-style: solid;
}

.\!k-border-b-solid {
    border-bottom-style: solid !important;
}

.k-border-b-dashed {
    border-bottom-style: dashed;
}

.\!k-border-b-dashed {
    border-bottom-style: dashed !important;
}

.k-border-b-dotted {
    border-bottom-style: dotted;
}

.\!k-border-b-dotted {
    border-bottom-style: dotted !important;
}

.k-border-b-double {
    border-bottom-style: double;
}

.\!k-border-b-double {
    border-bottom-style: double !important;
}

.k-border-b-inset {
    border-bottom-style: inset;
}

.\!k-border-b-inset {
    border-bottom-style: inset !important;
}

.k-border-b-outset {
    border-bottom-style: outset;
}

.\!k-border-b-outset {
    border-bottom-style: outset !important;
}

.k-border-b-hidden {
    border-bottom-style: hidden;
}

.\!k-border-b-hidden {
    border-bottom-style: hidden !important;
}

.k-border-b-none {
    border-bottom-style: none;
}

.\!k-border-b-none {
    border-bottom-style: none !important;
}

.k-border-l-solid {
    border-left-style: solid;
}

.\!k-border-l-solid {
    border-left-style: solid !important;
}

.k-border-l-dashed {
    border-left-style: dashed;
}

.\!k-border-l-dashed {
    border-left-style: dashed !important;
}

.k-border-l-dotted {
    border-left-style: dotted;
}

.\!k-border-l-dotted {
    border-left-style: dotted !important;
}

.k-border-l-double {
    border-left-style: double;
}

.\!k-border-l-double {
    border-left-style: double !important;
}

.k-border-l-inset {
    border-left-style: inset;
}

.\!k-border-l-inset {
    border-left-style: inset !important;
}

.k-border-l-outset {
    border-left-style: outset;
}

.\!k-border-l-outset {
    border-left-style: outset !important;
}

.k-border-l-hidden {
    border-left-style: hidden;
}

.\!k-border-l-hidden {
    border-left-style: hidden !important;
}

.k-border-l-none {
    border-left-style: none;
}

.\!k-border-l-none {
    border-left-style: none !important;
}

.k-border-x-solid {
    border-inline-style: solid;
}

.\!k-border-x-solid {
    border-inline-style: solid !important;
}

.k-border-x-dashed {
    border-inline-style: dashed;
}

.\!k-border-x-dashed {
    border-inline-style: dashed !important;
}

.k-border-x-dotted {
    border-inline-style: dotted;
}

.\!k-border-x-dotted {
    border-inline-style: dotted !important;
}

.k-border-x-double {
    border-inline-style: double;
}

.\!k-border-x-double {
    border-inline-style: double !important;
}

.k-border-x-inset {
    border-inline-style: inset;
}

.\!k-border-x-inset {
    border-inline-style: inset !important;
}

.k-border-x-outset {
    border-inline-style: outset;
}

.\!k-border-x-outset {
    border-inline-style: outset !important;
}

.k-border-x-hidden {
    border-inline-style: hidden;
}

.\!k-border-x-hidden {
    border-inline-style: hidden !important;
}

.k-border-x-none {
    border-inline-style: none;
}

.\!k-border-x-none {
    border-inline-style: none !important;
}

.k-border-y-solid {
    border-block-style: solid;
}

.\!k-border-y-solid {
    border-block-style: solid !important;
}

.k-border-y-dashed {
    border-block-style: dashed;
}

.\!k-border-y-dashed {
    border-block-style: dashed !important;
}

.k-border-y-dotted {
    border-block-style: dotted;
}

.\!k-border-y-dotted {
    border-block-style: dotted !important;
}

.k-border-y-double {
    border-block-style: double;
}

.\!k-border-y-double {
    border-block-style: double !important;
}

.k-border-y-inset {
    border-block-style: inset;
}

.\!k-border-y-inset {
    border-block-style: inset !important;
}

.k-border-y-outset {
    border-block-style: outset;
}

.\!k-border-y-outset {
    border-block-style: outset !important;
}

.k-border-y-hidden {
    border-block-style: hidden;
}

.\!k-border-y-hidden {
    border-block-style: hidden !important;
}

.k-border-y-none {
    border-block-style: none;
}

.\!k-border-y-none {
    border-block-style: none !important;
}

.k-border {
    border-width: 1px;
}

.\!k-border {
    border-width: 1px !important;
}

.k-border-0 {
    border-width: 0;
}

.\!k-border-0 {
    border-width: 0 !important;
}

.k-border-2 {
    border-width: 2px;
}

.\!k-border-2 {
    border-width: 2px !important;
}

.k-border-4 {
    border-width: 4px;
}

.\!k-border-4 {
    border-width: 4px !important;
}

.k-border-8 {
    border-width: 8px;
}

.\!k-border-8 {
    border-width: 8px !important;
}

.k-border-t {
    border-top-width: 1px;
}

.\!k-border-t {
    border-top-width: 1px !important;
}

.k-border-t-0 {
    border-top-width: 0;
}

.\!k-border-t-0 {
    border-top-width: 0 !important;
}

.k-border-t-2 {
    border-top-width: 2px;
}

.\!k-border-t-2 {
    border-top-width: 2px !important;
}

.k-border-t-4 {
    border-top-width: 4px;
}

.\!k-border-t-4 {
    border-top-width: 4px !important;
}

.k-border-t-8 {
    border-top-width: 8px;
}

.\!k-border-t-8 {
    border-top-width: 8px !important;
}

.k-border-r {
    border-right-width: 1px;
}

.\!k-border-r {
    border-right-width: 1px !important;
}

.k-border-r-0 {
    border-right-width: 0;
}

.\!k-border-r-0 {
    border-right-width: 0 !important;
}

.k-border-r-2 {
    border-right-width: 2px;
}

.\!k-border-r-2 {
    border-right-width: 2px !important;
}

.k-border-r-4 {
    border-right-width: 4px;
}

.\!k-border-r-4 {
    border-right-width: 4px !important;
}

.k-border-r-8 {
    border-right-width: 8px;
}

.\!k-border-r-8 {
    border-right-width: 8px !important;
}

.k-border-b {
    border-bottom-width: 1px;
}

.\!k-border-b {
    border-bottom-width: 1px !important;
}

.k-border-b-0 {
    border-bottom-width: 0;
}

.\!k-border-b-0 {
    border-bottom-width: 0 !important;
}

.k-border-b-2 {
    border-bottom-width: 2px;
}

.\!k-border-b-2 {
    border-bottom-width: 2px !important;
}

.k-border-b-4 {
    border-bottom-width: 4px;
}

.\!k-border-b-4 {
    border-bottom-width: 4px !important;
}

.k-border-b-8 {
    border-bottom-width: 8px;
}

.\!k-border-b-8 {
    border-bottom-width: 8px !important;
}

.k-border-l {
    border-left-width: 1px;
}

.\!k-border-l {
    border-left-width: 1px !important;
}

.k-border-l-0 {
    border-left-width: 0;
}

.\!k-border-l-0 {
    border-left-width: 0 !important;
}

.k-border-l-2 {
    border-left-width: 2px;
}

.\!k-border-l-2 {
    border-left-width: 2px !important;
}

.k-border-l-4 {
    border-left-width: 4px;
}

.\!k-border-l-4 {
    border-left-width: 4px !important;
}

.k-border-l-8 {
    border-left-width: 8px;
}

.\!k-border-l-8 {
    border-left-width: 8px !important;
}

.k-border-x {
    border-inline-width: 1px;
}

.\!k-border-x {
    border-inline-width: 1px !important;
}

.k-border-x-0 {
    border-inline-width: 0;
}

.\!k-border-x-0 {
    border-inline-width: 0 !important;
}

.k-border-x-2 {
    border-inline-width: 2px;
}

.\!k-border-x-2 {
    border-inline-width: 2px !important;
}

.k-border-x-4 {
    border-inline-width: 4px;
}

.\!k-border-x-4 {
    border-inline-width: 4px !important;
}

.k-border-x-8 {
    border-inline-width: 8px;
}

.\!k-border-x-8 {
    border-inline-width: 8px !important;
}

.k-border-y {
    border-block-width: 1px;
}

.\!k-border-y {
    border-block-width: 1px !important;
}

.k-border-y-0 {
    border-block-width: 0;
}

.\!k-border-y-0 {
    border-block-width: 0 !important;
}

.k-border-y-2 {
    border-block-width: 2px;
}

.\!k-border-y-2 {
    border-block-width: 2px !important;
}

.k-border-y-4 {
    border-block-width: 4px;
}

.\!k-border-y-4 {
    border-block-width: 4px !important;
}

.k-border-y-8 {
    border-block-width: 8px;
}

.\!k-border-y-8 {
    border-block-width: 8px !important;
}

.k-border-top {
    border-top-width: 1px;
}

.\!k-border-top {
    border-top-width: 1px !important;
}

.k-border-top-0 {
    border-top-width: 0;
}

.\!k-border-top-0 {
    border-top-width: 0 !important;
}

.k-border-top-2 {
    border-top-width: 2px;
}

.\!k-border-top-2 {
    border-top-width: 2px !important;
}

.k-border-top-4 {
    border-top-width: 4px;
}

.\!k-border-top-4 {
    border-top-width: 4px !important;
}

.k-border-top-8 {
    border-top-width: 8px;
}

.\!k-border-top-8 {
    border-top-width: 8px !important;
}

.k-border-right {
    border-right-width: 1px;
}

.\!k-border-right {
    border-right-width: 1px !important;
}

.k-border-right-0 {
    border-right-width: 0;
}

.\!k-border-right-0 {
    border-right-width: 0 !important;
}

.k-border-right-2 {
    border-right-width: 2px;
}

.\!k-border-right-2 {
    border-right-width: 2px !important;
}

.k-border-right-4 {
    border-right-width: 4px;
}

.\!k-border-right-4 {
    border-right-width: 4px !important;
}

.k-border-right-8 {
    border-right-width: 8px;
}

.\!k-border-right-8 {
    border-right-width: 8px !important;
}

.k-border-bottom {
    border-bottom-width: 1px;
}

.\!k-border-bottom {
    border-bottom-width: 1px !important;
}

.k-border-bottom-0 {
    border-bottom-width: 0;
}

.\!k-border-bottom-0 {
    border-bottom-width: 0 !important;
}

.k-border-bottom-2 {
    border-bottom-width: 2px;
}

.\!k-border-bottom-2 {
    border-bottom-width: 2px !important;
}

.k-border-bottom-4 {
    border-bottom-width: 4px;
}

.\!k-border-bottom-4 {
    border-bottom-width: 4px !important;
}

.k-border-bottom-8 {
    border-bottom-width: 8px;
}

.\!k-border-bottom-8 {
    border-bottom-width: 8px !important;
}

.k-border-left {
    border-left-width: 1px;
}

.\!k-border-left {
    border-left-width: 1px !important;
}

.k-border-left-0 {
    border-left-width: 0;
}

.\!k-border-left-0 {
    border-left-width: 0 !important;
}

.k-border-left-2 {
    border-left-width: 2px;
}

.\!k-border-left-2 {
    border-left-width: 2px !important;
}

.k-border-left-4 {
    border-left-width: 4px;
}

.\!k-border-left-4 {
    border-left-width: 4px !important;
}

.k-border-left-8 {
    border-left-width: 8px;
}

.\!k-border-left-8 {
    border-left-width: 8px !important;
}

.k-outline-primary {
    outline-color: #4e1289;
}

.\!k-outline-primary {
    outline-color: #4e1289 !important;
}

.k-outline-secondary {
    outline-color: #666666;
}

.\!k-outline-secondary {
    outline-color: #666666 !important;
}

.k-outline-tertiary {
    outline-color: #03a9f4;
}

.\!k-outline-tertiary {
    outline-color: #03a9f4 !important;
}

.k-outline-info {
    outline-color: #0058e9;
}

.\!k-outline-info {
    outline-color: #0058e9 !important;
}

.k-outline-success {
    outline-color: #37b400;
}

.\!k-outline-success {
    outline-color: #37b400 !important;
}

.k-outline-warning {
    outline-color: #ffc000;
}

.\!k-outline-warning {
    outline-color: #ffc000 !important;
}

.k-outline-error {
    outline-color: #f31700;
}

.\!k-outline-error {
    outline-color: #f31700 !important;
}

.k-outline-dark {
    outline-color: #424242;
}

.\!k-outline-dark {
    outline-color: #424242 !important;
}

.k-outline-light {
    outline-color: #ebebeb;
}

.\!k-outline-light {
    outline-color: #ebebeb !important;
}

.k-outline-inverse {
    outline-color: #424242;
}

.\!k-outline-inverse {
    outline-color: #424242 !important;
}

.k-outline-inherit {
    outline-color: inherit;
}

.\!k-outline-inherit {
    outline-color: inherit !important;
}

.k-outline-current {
    outline-color: currentColor;
}

.\!k-outline-current {
    outline-color: currentColor !important;
}

.k-outline-transparent {
    outline-color: transparent;
}

.\!k-outline-transparent {
    outline-color: transparent !important;
}

.k-outline-black {
    outline-color: black;
}

.\!k-outline-black {
    outline-color: black !important;
}

.k-outline-white {
    outline-color: white;
}

.\!k-outline-white {
    outline-color: white !important;
}

.k-outline-offset-0 {
    outline-offset: 0;
}

.\!k-outline-offset-0 {
    outline-offset: 0 !important;
}

.k-outline-offset-1 {
    outline-offset: 1px;
}

.\!k-outline-offset-1 {
    outline-offset: 1px !important;
}

.k-outline-offset-2 {
    outline-offset: 2px;
}

.\!k-outline-offset-2 {
    outline-offset: 2px !important;
}

.k-outline-offset-4 {
    outline-offset: 4px;
}

.\!k-outline-offset-4 {
    outline-offset: 4px !important;
}

.k-outline-offset-8 {
    outline-offset: 8px;
}

.\!k-outline-offset-8 {
    outline-offset: 8px !important;
}

.k-outline-solid {
    outline-style: solid;
}

.\!k-outline-solid {
    outline-style: solid !important;
}

.k-outline-dashed {
    outline-style: dashed;
}

.\!k-outline-dashed {
    outline-style: dashed !important;
}

.k-outline-dotted {
    outline-style: dotted;
}

.\!k-outline-dotted {
    outline-style: dotted !important;
}

.k-outline-double {
    outline-style: double;
}

.\!k-outline-double {
    outline-style: double !important;
}

.k-outline-inset {
    outline-style: inset;
}

.\!k-outline-inset {
    outline-style: inset !important;
}

.k-outline-outset {
    outline-style: outset;
}

.\!k-outline-outset {
    outline-style: outset !important;
}

.k-outline-none {
    outline-style: none;
}

.\!k-outline-none {
    outline-style: none !important;
}

.k-outline {
    outline-width: 1px;
}

.\!k-outline {
    outline-width: 1px !important;
}

.k-outline-0 {
    outline-width: 0;
}

.\!k-outline-0 {
    outline-width: 0 !important;
}

.k-outline-2 {
    outline-width: 2px;
}

.\!k-outline-2 {
    outline-width: 2px !important;
}

.k-outline-4 {
    outline-width: 4px;
}

.\!k-outline-4 {
    outline-width: 4px !important;
}

.k-outline-8 {
    outline-width: 8px;
}

.\!k-outline-8 {
    outline-width: 8px !important;
}

.k-opacity-0 {
    opacity: 0;
}

.\!k-opacity-0 {
    opacity: 0 !important;
}

.k-opacity-5 {
    opacity: 0.05;
}

.\!k-opacity-5 {
    opacity: 0.05 !important;
}

.k-opacity-10 {
    opacity: 0.1;
}

.\!k-opacity-10 {
    opacity: 0.1 !important;
}

.k-opacity-20 {
    opacity: 0.2;
}

.\!k-opacity-20 {
    opacity: 0.2 !important;
}

.k-opacity-30 {
    opacity: 0.3;
}

.\!k-opacity-30 {
    opacity: 0.3 !important;
}

.k-opacity-40 {
    opacity: 0.4;
}

.\!k-opacity-40 {
    opacity: 0.4 !important;
}

.k-opacity-50 {
    opacity: 0.5;
}

.\!k-opacity-50 {
    opacity: 0.5 !important;
}

.k-opacity-60 {
    opacity: 0.6;
}

.\!k-opacity-60 {
    opacity: 0.6 !important;
}

.k-opacity-70 {
    opacity: 0.7;
}

.\!k-opacity-70 {
    opacity: 0.7 !important;
}

.k-opacity-80 {
    opacity: 0.8;
}

.\!k-opacity-80 {
    opacity: 0.8 !important;
}

.k-opacity-90 {
    opacity: 0.9;
}

.\!k-opacity-90 {
    opacity: 0.9 !important;
}

.k-opacity-100 {
    opacity: 1;
}

.\!k-opacity-100 {
    opacity: 1 !important;
}

.k-backdrop-blur {
    backdrop-filter: blur(8px);
}

.\!k-backdrop-blur {
    backdrop-filter: blur(8px) !important;
}

.k-backdrop-blur-none {
    backdrop-filter: blur(0);
}

.\!k-backdrop-blur-none {
    backdrop-filter: blur(0) !important;
}

.k-backdrop-blur-sm {
    backdrop-filter: blur(4px);
}

.\!k-backdrop-blur-sm {
    backdrop-filter: blur(4px) !important;
}

.k-backdrop-blur-md {
    backdrop-filter: blur(6px);
}

.\!k-backdrop-blur-md {
    backdrop-filter: blur(6px) !important;
}

.k-backdrop-blur-lg {
    backdrop-filter: blur(12px);
}

.\!k-backdrop-blur-lg {
    backdrop-filter: blur(12px) !important;
}

.k-border-collapse {
    border-collapse: collapse;
}

.\!k-border-collapse {
    border-collapse: collapse !important;
}

.k-border-separate {
    border-collapse: separate;
}

.\!k-border-separate {
    border-collapse: separate !important;
}

.k-table-auto {
    table-layout: auto;
}

.\!k-table-auto {
    table-layout: auto !important;
}

.k-table-fixed {
    table-layout: fixed;
}

.\!k-table-fixed {
    table-layout: fixed !important;
}

.k-table-layout-auto {
    table-layout: auto;
}

.\!k-table-layout-auto {
    table-layout: auto !important;
}

.k-table-layout-fixed {
    table-layout: fixed;
}

.\!k-table-layout-fixed {
    table-layout: fixed !important;
}

.k-flip-h,
.k-flip-x {
    transform: scaleX(-1);
}

.\!k-flip-h,
.\!k-flip-x {
    transform: scaleX(-1) !important;
}

.k-flip-v,
.k-flip-y {
    transform: scaleY(-1);
}

.\!k-flip-v,
.\!k-flip-y {
    transform: scaleY(-1) !important;
}

.k-flip-h.k-flip-v,
.k-flip-h.k-flip-y,
.k-flip-v.k-flip-x,
.k-flip-x.k-flip-y {
    transform: scale(-1, -1);
}

.\!k-flip-h.\!k-flip-v,
.\!k-flip-h.\!k-flip-y,
.\!k-flip-v.\!k-flip-x,
.\!k-flip-x.\!k-flip-y {
    transform: scale(-1, -1) !important;
}

.k-origin-center {
    transform-origin: center;
}

.\!k-origin-center {
    transform-origin: center !important;
}

.k-origin-top {
    transform-origin: top;
}

.\!k-origin-top {
    transform-origin: top !important;
}

.k-origin-right {
    transform-origin: right;
}

.\!k-origin-right {
    transform-origin: right !important;
}

.k-origin-bottom {
    transform-origin: bottom;
}

.\!k-origin-bottom {
    transform-origin: bottom !important;
}

.k-origin-left {
    transform-origin: left;
}

.\!k-origin-left {
    transform-origin: left !important;
}

.k-origin-top-left {
    transform-origin: top left;
}

.\!k-origin-top-left {
    transform-origin: top left !important;
}

.k-origin-top-right {
    transform-origin: top right;
}

.\!k-origin-top-right {
    transform-origin: top right !important;
}

.k-origin-bottom-left {
    transform-origin: bottom left;
}

.\!k-origin-bottom-left {
    transform-origin: bottom left !important;
}

.k-origin-bottom-right {
    transform-origin: bottom right;
}

.\!k-origin-bottom-right {
    transform-origin: bottom right !important;
}

.k-rotate-0 {
    transform: rotate(0deg);
}

.\!k-rotate-0 {
    transform: rotate(0deg) !important;
}

.k-rotate-45 {
    transform: rotate(45deg);
}

.\!k-rotate-45 {
    transform: rotate(45deg) !important;
}

.k-rotate-90 {
    transform: rotate(90deg);
}

.\!k-rotate-90 {
    transform: rotate(90deg) !important;
}

.k-rotate-135 {
    transform: rotate(135deg);
}

.\!k-rotate-135 {
    transform: rotate(135deg) !important;
}

.k-rotate-180 {
    transform: rotate(180deg);
}

.\!k-rotate-180 {
    transform: rotate(180deg) !important;
}

.k-rotate-225 {
    transform: rotate(225deg);
}

.\!k-rotate-225 {
    transform: rotate(225deg) !important;
}

.k-rotate-270 {
    transform: rotate(270deg);
}

.\!k-rotate-270 {
    transform: rotate(270deg) !important;
}

.k-rotate-315 {
    transform: rotate(315deg);
}

.\!k-rotate-315 {
    transform: rotate(315deg) !important;
}

.k-scale-0 {
    transform: scale(0);
}

.\!k-scale-0 {
    transform: scale(0) !important;
}

.k-scale-0\.25 {
    transform: scale(0.25);
}

.\!k-scale-0\.25 {
    transform: scale(0.25) !important;
}

.k-scale-0\.5 {
    transform: scale(0.5);
}

.\!k-scale-0\.5 {
    transform: scale(0.5) !important;
}

.k-scale-0\.75 {
    transform: scale(0.75);
}

.\!k-scale-0\.75 {
    transform: scale(0.75) !important;
}

.k-scale-1 {
    transform: scale(1);
}

.\!k-scale-1 {
    transform: scale(1) !important;
}

.k-scale-1\.25 {
    transform: scale(1.25);
}

.\!k-scale-1\.25 {
    transform: scale(1.25) !important;
}

.k-scale-1\.5 {
    transform: scale(1.5);
}

.\!k-scale-1\.5 {
    transform: scale(1.5) !important;
}

.k-scale-2 {
    transform: scale(2);
}

.\!k-scale-2 {
    transform: scale(2) !important;
}

.k-scale-x-0 {
    transform: scaleX(0);
}

.\!k-scale-x-0 {
    transform: scaleX(0) !important;
}

.k-scale-x-0\.25 {
    transform: scaleX(0.25);
}

.\!k-scale-x-0\.25 {
    transform: scaleX(0.25) !important;
}

.k-scale-x-0\.5 {
    transform: scaleX(0.5);
}

.\!k-scale-x-0\.5 {
    transform: scaleX(0.5) !important;
}

.k-scale-x-0\.75 {
    transform: scaleX(0.75);
}

.\!k-scale-x-0\.75 {
    transform: scaleX(0.75) !important;
}

.k-scale-x-1 {
    transform: scaleX(1);
}

.\!k-scale-x-1 {
    transform: scaleX(1) !important;
}

.k-scale-x-1\.25 {
    transform: scaleX(1.25);
}

.\!k-scale-x-1\.25 {
    transform: scaleX(1.25) !important;
}

.k-scale-x-1\.5 {
    transform: scaleX(1.5);
}

.\!k-scale-x-1\.5 {
    transform: scaleX(1.5) !important;
}

.k-scale-x-2 {
    transform: scaleX(2);
}

.\!k-scale-x-2 {
    transform: scaleX(2) !important;
}

.k-scale-y-0 {
    transform: scaleY(0);
}

.\!k-scale-y-0 {
    transform: scaleY(0) !important;
}

.k-scale-y-0\.25 {
    transform: scaleY(0.25);
}

.\!k-scale-y-0\.25 {
    transform: scaleY(0.25) !important;
}

.k-scale-y-0\.5 {
    transform: scaleY(0.5);
}

.\!k-scale-y-0\.5 {
    transform: scaleY(0.5) !important;
}

.k-scale-y-0\.75 {
    transform: scaleY(0.75);
}

.\!k-scale-y-0\.75 {
    transform: scaleY(0.75) !important;
}

.k-scale-y-1 {
    transform: scaleY(1);
}

.\!k-scale-y-1 {
    transform: scaleY(1) !important;
}

.k-scale-y-1\.25 {
    transform: scaleY(1.25);
}

.\!k-scale-y-1\.25 {
    transform: scaleY(1.25) !important;
}

.k-scale-y-1\.5 {
    transform: scaleY(1.5);
}

.\!k-scale-y-1\.5 {
    transform: scaleY(1.5) !important;
}

.k-scale-y-2 {
    transform: scaleY(2);
}

.\!k-scale-y-2 {
    transform: scaleY(2) !important;
}

.k-skew-x-0 {
    transform: skewX(0deg);
}

.\!k-skew-x-0 {
    transform: skewX(0deg) !important;
}

.k-skew-x-1 {
    transform: skewX(1deg);
}

.\!k-skew-x-1 {
    transform: skewX(1deg) !important;
}

.k-skew-x-2 {
    transform: skewX(2deg);
}

.\!k-skew-x-2 {
    transform: skewX(2deg) !important;
}

.k-skew-x-3 {
    transform: skewX(3deg);
}

.\!k-skew-x-3 {
    transform: skewX(3deg) !important;
}

.k-skew-x-4 {
    transform: skewX(4deg);
}

.\!k-skew-x-4 {
    transform: skewX(4deg) !important;
}

.k-skew-x-5 {
    transform: skewX(5deg);
}

.\!k-skew-x-5 {
    transform: skewX(5deg) !important;
}

.k-skew-x-6 {
    transform: skewX(6deg);
}

.\!k-skew-x-6 {
    transform: skewX(6deg) !important;
}

.k-skew-x-7 {
    transform: skewX(7deg);
}

.\!k-skew-x-7 {
    transform: skewX(7deg) !important;
}

.k-skew-x-8 {
    transform: skewX(8deg);
}

.\!k-skew-x-8 {
    transform: skewX(8deg) !important;
}

.k-skew-x-9 {
    transform: skewX(9deg);
}

.\!k-skew-x-9 {
    transform: skewX(9deg) !important;
}

.k-skew-x-10 {
    transform: skewX(10deg);
}

.\!k-skew-x-10 {
    transform: skewX(10deg) !important;
}

.k-skew-x-11 {
    transform: skewX(11deg);
}

.\!k-skew-x-11 {
    transform: skewX(11deg) !important;
}

.k-skew-x-12 {
    transform: skewX(12deg);
}

.\!k-skew-x-12 {
    transform: skewX(12deg) !important;
}

.k-skew-y-0 {
    transform: skewY(0deg);
}

.\!k-skew-y-0 {
    transform: skewY(0deg) !important;
}

.k-skew-y-1 {
    transform: skewY(1deg);
}

.\!k-skew-y-1 {
    transform: skewY(1deg) !important;
}

.k-skew-y-2 {
    transform: skewY(2deg);
}

.\!k-skew-y-2 {
    transform: skewY(2deg) !important;
}

.k-skew-y-3 {
    transform: skewY(3deg);
}

.\!k-skew-y-3 {
    transform: skewY(3deg) !important;
}

.k-skew-y-4 {
    transform: skewY(4deg);
}

.\!k-skew-y-4 {
    transform: skewY(4deg) !important;
}

.k-skew-y-5 {
    transform: skewY(5deg);
}

.\!k-skew-y-5 {
    transform: skewY(5deg) !important;
}

.k-skew-y-6 {
    transform: skewY(6deg);
}

.\!k-skew-y-6 {
    transform: skewY(6deg) !important;
}

.k-skew-y-7 {
    transform: skewY(7deg);
}

.\!k-skew-y-7 {
    transform: skewY(7deg) !important;
}

.k-skew-y-8 {
    transform: skewY(8deg);
}

.\!k-skew-y-8 {
    transform: skewY(8deg) !important;
}

.k-skew-y-9 {
    transform: skewY(9deg);
}

.\!k-skew-y-9 {
    transform: skewY(9deg) !important;
}

.k-skew-y-10 {
    transform: skewY(10deg);
}

.\!k-skew-y-10 {
    transform: skewY(10deg) !important;
}

.k-skew-y-11 {
    transform: skewY(11deg);
}

.\!k-skew-y-11 {
    transform: skewY(11deg) !important;
}

.k-skew-y-12 {
    transform: skewY(12deg);
}

.\!k-skew-y-12 {
    transform: skewY(12deg) !important;
}

.k-translate-0 {
    transform: translate(0, 0);
}

.\!k-translate-0 {
    transform: translate(0, 0) !important;
}

.k-translate-0-50 {
    transform: translate(0, 50%);
}

.\!k-translate-0-50 {
    transform: translate(0, 50%) !important;
}

.k-translate-0-100 {
    transform: translate(0, 100%);
}

.\!k-translate-0-100 {
    transform: translate(0, 100%) !important;
}

.k-translate-50-0 {
    transform: translate(50%, 0);
}

.\!k-translate-50-0 {
    transform: translate(50%, 0) !important;
}

.k-translate-50-50 {
    transform: translate(50%, 50%);
}

.\!k-translate-50-50 {
    transform: translate(50%, 50%) !important;
}

.k-translate-50-100 {
    transform: translate(50%, 100%);
}

.\!k-translate-50-100 {
    transform: translate(50%, 100%) !important;
}

.k-translate-100-0 {
    transform: translate(100%, 0);
}

.\!k-translate-100-0 {
    transform: translate(100%, 0) !important;
}

.k-translate-100-50 {
    transform: translate(100%, 50%);
}

.\!k-translate-100-50 {
    transform: translate(100%, 50%) !important;
}

.k-translate-100-100 {
    transform: translate(100%, 100%);
}

.\!k-translate-100-100 {
    transform: translate(100%, 100%) !important;
}

.k-translate-x-0 {
    transform: translateX(0);
}

.\!k-translate-x-0 {
    transform: translateX(0) !important;
}

.k-translate-x-1px {
    transform: translateX(1px);
}

.\!k-translate-x-1px {
    transform: translateX(1px) !important;
}

.k-translate-x-0\.5 {
    transform: translateX(2px);
}

.\!k-translate-x-0\.5 {
    transform: translateX(2px) !important;
}

.k-translate-x-1 {
    transform: translateX(4px);
}

.\!k-translate-x-1 {
    transform: translateX(4px) !important;
}

.k-translate-x-1\.5 {
    transform: translateX(6px);
}

.\!k-translate-x-1\.5 {
    transform: translateX(6px) !important;
}

.k-translate-x-2 {
    transform: translateX(8px);
}

.\!k-translate-x-2 {
    transform: translateX(8px) !important;
}

.k-translate-x-2\.5 {
    transform: translateX(10px);
}

.\!k-translate-x-2\.5 {
    transform: translateX(10px) !important;
}

.k-translate-x-3 {
    transform: translateX(12px);
}

.\!k-translate-x-3 {
    transform: translateX(12px) !important;
}

.k-translate-x-3\.5 {
    transform: translateX(14px);
}

.\!k-translate-x-3\.5 {
    transform: translateX(14px) !important;
}

.k-translate-x-4 {
    transform: translateX(16px);
}

.\!k-translate-x-4 {
    transform: translateX(16px) !important;
}

.k-translate-x-4\.5 {
    transform: translateX(18px);
}

.\!k-translate-x-4\.5 {
    transform: translateX(18px) !important;
}

.k-translate-x-5 {
    transform: translateX(20px);
}

.\!k-translate-x-5 {
    transform: translateX(20px) !important;
}

.k-translate-x-5\.5 {
    transform: translateX(22px);
}

.\!k-translate-x-5\.5 {
    transform: translateX(22px) !important;
}

.k-translate-x-6 {
    transform: translateX(24px);
}

.\!k-translate-x-6 {
    transform: translateX(24px) !important;
}

.k-translate-x-6\.5 {
    transform: translateX(26px);
}

.\!k-translate-x-6\.5 {
    transform: translateX(26px) !important;
}

.k-translate-x-7 {
    transform: translateX(28px);
}

.\!k-translate-x-7 {
    transform: translateX(28px) !important;
}

.k-translate-x-7\.5 {
    transform: translateX(30px);
}

.\!k-translate-x-7\.5 {
    transform: translateX(30px) !important;
}

.k-translate-x-8 {
    transform: translateX(32px);
}

.\!k-translate-x-8 {
    transform: translateX(32px) !important;
}

.k-translate-x-9 {
    transform: translateX(36px);
}

.\!k-translate-x-9 {
    transform: translateX(36px) !important;
}

.k-translate-x-10 {
    transform: translateX(40px);
}

.\!k-translate-x-10 {
    transform: translateX(40px) !important;
}

.k-translate-x-11 {
    transform: translateX(44px);
}

.\!k-translate-x-11 {
    transform: translateX(44px) !important;
}

.k-translate-x-12 {
    transform: translateX(48px);
}

.\!k-translate-x-12 {
    transform: translateX(48px) !important;
}

.k-translate-x-13 {
    transform: translateX(52px);
}

.\!k-translate-x-13 {
    transform: translateX(52px) !important;
}

.k-translate-x-14 {
    transform: translateX(56px);
}

.\!k-translate-x-14 {
    transform: translateX(56px) !important;
}

.k-translate-x-15 {
    transform: translateX(60px);
}

.\!k-translate-x-15 {
    transform: translateX(60px) !important;
}

.k-translate-x-16 {
    transform: translateX(64px);
}

.\!k-translate-x-16 {
    transform: translateX(64px) !important;
}

.k-translate-x-17 {
    transform: translateX(68px);
}

.\!k-translate-x-17 {
    transform: translateX(68px) !important;
}

.k-translate-x-18 {
    transform: translateX(72px);
}

.\!k-translate-x-18 {
    transform: translateX(72px) !important;
}

.k-translate-x-19 {
    transform: translateX(76px);
}

.\!k-translate-x-19 {
    transform: translateX(76px) !important;
}

.k-translate-x-20 {
    transform: translateX(80px);
}

.\!k-translate-x-20 {
    transform: translateX(80px) !important;
}

.k-translate-x-21 {
    transform: translateX(84px);
}

.\!k-translate-x-21 {
    transform: translateX(84px) !important;
}

.k-translate-x-22 {
    transform: translateX(88px);
}

.\!k-translate-x-22 {
    transform: translateX(88px) !important;
}

.k-translate-x-23 {
    transform: translateX(92px);
}

.\!k-translate-x-23 {
    transform: translateX(92px) !important;
}

.k-translate-x-24 {
    transform: translateX(96px);
}

.\!k-translate-x-24 {
    transform: translateX(96px) !important;
}

.k-translate-x-1\/2 {
    transform: translateX(50%);
}

.\!k-translate-x-1\/2 {
    transform: translateX(50%) !important;
}

.k-translate-x-1\/3 {
    transform: translateX(33.333333%);
}

.\!k-translate-x-1\/3 {
    transform: translateX(33.333333%) !important;
}

.k-translate-x-2\/3 {
    transform: translateX(66.666667%);
}

.\!k-translate-x-2\/3 {
    transform: translateX(66.666667%) !important;
}

.k-translate-x-1\/4 {
    transform: translateX(25%);
}

.\!k-translate-x-1\/4 {
    transform: translateX(25%) !important;
}

.k-translate-x-2\/4 {
    transform: translateX(50%);
}

.\!k-translate-x-2\/4 {
    transform: translateX(50%) !important;
}

.k-translate-x-3\/4 {
    transform: translateX(75%);
}

.\!k-translate-x-3\/4 {
    transform: translateX(75%) !important;
}

.k-translate-x-1\/5 {
    transform: translateX(20%);
}

.\!k-translate-x-1\/5 {
    transform: translateX(20%) !important;
}

.k-translate-x-2\/5 {
    transform: translateX(40%);
}

.\!k-translate-x-2\/5 {
    transform: translateX(40%) !important;
}

.k-translate-x-3\/5 {
    transform: translateX(60%);
}

.\!k-translate-x-3\/5 {
    transform: translateX(60%) !important;
}

.k-translate-x-4\/5 {
    transform: translateX(80%);
}

.\!k-translate-x-4\/5 {
    transform: translateX(80%) !important;
}

.k-translate-x-1\/6 {
    transform: translateX(16.666667%);
}

.\!k-translate-x-1\/6 {
    transform: translateX(16.666667%) !important;
}

.k-translate-x-2\/6 {
    transform: translateX(33.333333%);
}

.\!k-translate-x-2\/6 {
    transform: translateX(33.333333%) !important;
}

.k-translate-x-3\/6 {
    transform: translateX(50%);
}

.\!k-translate-x-3\/6 {
    transform: translateX(50%) !important;
}

.k-translate-x-4\/6 {
    transform: translateX(66.666667%);
}

.\!k-translate-x-4\/6 {
    transform: translateX(66.666667%) !important;
}

.k-translate-x-5\/6 {
    transform: translateX(83.333333%);
}

.\!k-translate-x-5\/6 {
    transform: translateX(83.333333%) !important;
}

.k-translate-x-1\/12 {
    transform: translateX(8.333333%);
}

.\!k-translate-x-1\/12 {
    transform: translateX(8.333333%) !important;
}

.k-translate-x-2\/12 {
    transform: translateX(16.666667%);
}

.\!k-translate-x-2\/12 {
    transform: translateX(16.666667%) !important;
}

.k-translate-x-3\/12 {
    transform: translateX(25%);
}

.\!k-translate-x-3\/12 {
    transform: translateX(25%) !important;
}

.k-translate-x-4\/12 {
    transform: translateX(33.333333%);
}

.\!k-translate-x-4\/12 {
    transform: translateX(33.333333%) !important;
}

.k-translate-x-5\/12 {
    transform: translateX(41.666667%);
}

.\!k-translate-x-5\/12 {
    transform: translateX(41.666667%) !important;
}

.k-translate-x-6\/12 {
    transform: translateX(50%);
}

.\!k-translate-x-6\/12 {
    transform: translateX(50%) !important;
}

.k-translate-x-7\/12 {
    transform: translateX(58.333333%);
}

.\!k-translate-x-7\/12 {
    transform: translateX(58.333333%) !important;
}

.k-translate-x-8\/12 {
    transform: translateX(66.666667%);
}

.\!k-translate-x-8\/12 {
    transform: translateX(66.666667%) !important;
}

.k-translate-x-9\/12 {
    transform: translateX(75%);
}

.\!k-translate-x-9\/12 {
    transform: translateX(75%) !important;
}

.k-translate-x-10\/12 {
    transform: translateX(83.333333%);
}

.\!k-translate-x-10\/12 {
    transform: translateX(83.333333%) !important;
}

.k-translate-x-11\/12 {
    transform: translateX(91.666667%);
}

.\!k-translate-x-11\/12 {
    transform: translateX(91.666667%) !important;
}

.k-translate-x-full {
    transform: translateX(100%);
}

.\!k-translate-x-full {
    transform: translateX(100%) !important;
}

.k-translate-y-0 {
    transform: translateY(0);
}

.\!k-translate-y-0 {
    transform: translateY(0) !important;
}

.k-translate-y-1px {
    transform: translateY(1px);
}

.\!k-translate-y-1px {
    transform: translateY(1px) !important;
}

.k-translate-y-0\.5 {
    transform: translateY(2px);
}

.\!k-translate-y-0\.5 {
    transform: translateY(2px) !important;
}

.k-translate-y-1 {
    transform: translateY(4px);
}

.\!k-translate-y-1 {
    transform: translateY(4px) !important;
}

.k-translate-y-1\.5 {
    transform: translateY(6px);
}

.\!k-translate-y-1\.5 {
    transform: translateY(6px) !important;
}

.k-translate-y-2 {
    transform: translateY(8px);
}

.\!k-translate-y-2 {
    transform: translateY(8px) !important;
}

.k-translate-y-2\.5 {
    transform: translateY(10px);
}

.\!k-translate-y-2\.5 {
    transform: translateY(10px) !important;
}

.k-translate-y-3 {
    transform: translateY(12px);
}

.\!k-translate-y-3 {
    transform: translateY(12px) !important;
}

.k-translate-y-3\.5 {
    transform: translateY(14px);
}

.\!k-translate-y-3\.5 {
    transform: translateY(14px) !important;
}

.k-translate-y-4 {
    transform: translateY(16px);
}

.\!k-translate-y-4 {
    transform: translateY(16px) !important;
}

.k-translate-y-4\.5 {
    transform: translateY(18px);
}

.\!k-translate-y-4\.5 {
    transform: translateY(18px) !important;
}

.k-translate-y-5 {
    transform: translateY(20px);
}

.\!k-translate-y-5 {
    transform: translateY(20px) !important;
}

.k-translate-y-5\.5 {
    transform: translateY(22px);
}

.\!k-translate-y-5\.5 {
    transform: translateY(22px) !important;
}

.k-translate-y-6 {
    transform: translateY(24px);
}

.\!k-translate-y-6 {
    transform: translateY(24px) !important;
}

.k-translate-y-6\.5 {
    transform: translateY(26px);
}

.\!k-translate-y-6\.5 {
    transform: translateY(26px) !important;
}

.k-translate-y-7 {
    transform: translateY(28px);
}

.\!k-translate-y-7 {
    transform: translateY(28px) !important;
}

.k-translate-y-7\.5 {
    transform: translateY(30px);
}

.\!k-translate-y-7\.5 {
    transform: translateY(30px) !important;
}

.k-translate-y-8 {
    transform: translateY(32px);
}

.\!k-translate-y-8 {
    transform: translateY(32px) !important;
}

.k-translate-y-9 {
    transform: translateY(36px);
}

.\!k-translate-y-9 {
    transform: translateY(36px) !important;
}

.k-translate-y-10 {
    transform: translateY(40px);
}

.\!k-translate-y-10 {
    transform: translateY(40px) !important;
}

.k-translate-y-11 {
    transform: translateY(44px);
}

.\!k-translate-y-11 {
    transform: translateY(44px) !important;
}

.k-translate-y-12 {
    transform: translateY(48px);
}

.\!k-translate-y-12 {
    transform: translateY(48px) !important;
}

.k-translate-y-13 {
    transform: translateY(52px);
}

.\!k-translate-y-13 {
    transform: translateY(52px) !important;
}

.k-translate-y-14 {
    transform: translateY(56px);
}

.\!k-translate-y-14 {
    transform: translateY(56px) !important;
}

.k-translate-y-15 {
    transform: translateY(60px);
}

.\!k-translate-y-15 {
    transform: translateY(60px) !important;
}

.k-translate-y-16 {
    transform: translateY(64px);
}

.\!k-translate-y-16 {
    transform: translateY(64px) !important;
}

.k-translate-y-17 {
    transform: translateY(68px);
}

.\!k-translate-y-17 {
    transform: translateY(68px) !important;
}

.k-translate-y-18 {
    transform: translateY(72px);
}

.\!k-translate-y-18 {
    transform: translateY(72px) !important;
}

.k-translate-y-19 {
    transform: translateY(76px);
}

.\!k-translate-y-19 {
    transform: translateY(76px) !important;
}

.k-translate-y-20 {
    transform: translateY(80px);
}

.\!k-translate-y-20 {
    transform: translateY(80px) !important;
}

.k-translate-y-21 {
    transform: translateY(84px);
}

.\!k-translate-y-21 {
    transform: translateY(84px) !important;
}

.k-translate-y-22 {
    transform: translateY(88px);
}

.\!k-translate-y-22 {
    transform: translateY(88px) !important;
}

.k-translate-y-23 {
    transform: translateY(92px);
}

.\!k-translate-y-23 {
    transform: translateY(92px) !important;
}

.k-translate-y-24 {
    transform: translateY(96px);
}

.\!k-translate-y-24 {
    transform: translateY(96px) !important;
}

.k-translate-y-1\/2 {
    transform: translateY(50%);
}

.\!k-translate-y-1\/2 {
    transform: translateY(50%) !important;
}

.k-translate-y-1\/3 {
    transform: translateY(33.333333%);
}

.\!k-translate-y-1\/3 {
    transform: translateY(33.333333%) !important;
}

.k-translate-y-2\/3 {
    transform: translateY(66.666667%);
}

.\!k-translate-y-2\/3 {
    transform: translateY(66.666667%) !important;
}

.k-translate-y-1\/4 {
    transform: translateY(25%);
}

.\!k-translate-y-1\/4 {
    transform: translateY(25%) !important;
}

.k-translate-y-2\/4 {
    transform: translateY(50%);
}

.\!k-translate-y-2\/4 {
    transform: translateY(50%) !important;
}

.k-translate-y-3\/4 {
    transform: translateY(75%);
}

.\!k-translate-y-3\/4 {
    transform: translateY(75%) !important;
}

.k-translate-y-1\/5 {
    transform: translateY(20%);
}

.\!k-translate-y-1\/5 {
    transform: translateY(20%) !important;
}

.k-translate-y-2\/5 {
    transform: translateY(40%);
}

.\!k-translate-y-2\/5 {
    transform: translateY(40%) !important;
}

.k-translate-y-3\/5 {
    transform: translateY(60%);
}

.\!k-translate-y-3\/5 {
    transform: translateY(60%) !important;
}

.k-translate-y-4\/5 {
    transform: translateY(80%);
}

.\!k-translate-y-4\/5 {
    transform: translateY(80%) !important;
}

.k-translate-y-1\/6 {
    transform: translateY(16.666667%);
}

.\!k-translate-y-1\/6 {
    transform: translateY(16.666667%) !important;
}

.k-translate-y-2\/6 {
    transform: translateY(33.333333%);
}

.\!k-translate-y-2\/6 {
    transform: translateY(33.333333%) !important;
}

.k-translate-y-3\/6 {
    transform: translateY(50%);
}

.\!k-translate-y-3\/6 {
    transform: translateY(50%) !important;
}

.k-translate-y-4\/6 {
    transform: translateY(66.666667%);
}

.\!k-translate-y-4\/6 {
    transform: translateY(66.666667%) !important;
}

.k-translate-y-5\/6 {
    transform: translateY(83.333333%);
}

.\!k-translate-y-5\/6 {
    transform: translateY(83.333333%) !important;
}

.k-translate-y-1\/12 {
    transform: translateY(8.333333%);
}

.\!k-translate-y-1\/12 {
    transform: translateY(8.333333%) !important;
}

.k-translate-y-2\/12 {
    transform: translateY(16.666667%);
}

.\!k-translate-y-2\/12 {
    transform: translateY(16.666667%) !important;
}

.k-translate-y-3\/12 {
    transform: translateY(25%);
}

.\!k-translate-y-3\/12 {
    transform: translateY(25%) !important;
}

.k-translate-y-4\/12 {
    transform: translateY(33.333333%);
}

.\!k-translate-y-4\/12 {
    transform: translateY(33.333333%) !important;
}

.k-translate-y-5\/12 {
    transform: translateY(41.666667%);
}

.\!k-translate-y-5\/12 {
    transform: translateY(41.666667%) !important;
}

.k-translate-y-6\/12 {
    transform: translateY(50%);
}

.\!k-translate-y-6\/12 {
    transform: translateY(50%) !important;
}

.k-translate-y-7\/12 {
    transform: translateY(58.333333%);
}

.\!k-translate-y-7\/12 {
    transform: translateY(58.333333%) !important;
}

.k-translate-y-8\/12 {
    transform: translateY(66.666667%);
}

.\!k-translate-y-8\/12 {
    transform: translateY(66.666667%) !important;
}

.k-translate-y-9\/12 {
    transform: translateY(75%);
}

.\!k-translate-y-9\/12 {
    transform: translateY(75%) !important;
}

.k-translate-y-10\/12 {
    transform: translateY(83.333333%);
}

.\!k-translate-y-10\/12 {
    transform: translateY(83.333333%) !important;
}

.k-translate-y-11\/12 {
    transform: translateY(91.666667%);
}

.\!k-translate-y-11\/12 {
    transform: translateY(91.666667%) !important;
}

.k-translate-y-full {
    transform: translateY(100%);
}

.\!k-translate-y-full {
    transform: translateY(100%) !important;
}

.k-appearance-none {
    appearance: none;
}

.\!k-appearance-none {
    appearance: none !important;
}

.k-appearance-auto {
    appearance: auto;
}

.\!k-appearance-auto {
    appearance: auto !important;
}

.k-cursor-none {
    cursor: none;
}

.\!k-cursor-none {
    cursor: none !important;
}

.k-cursor-auto {
    cursor: auto;
}

.\!k-cursor-auto {
    cursor: auto !important;
}

.k-cursor-default {
    cursor: default;
}

.\!k-cursor-default {
    cursor: default !important;
}

.k-cursor-context-menu {
    cursor: context-menu;
}

.\!k-cursor-context-menu {
    cursor: context-menu !important;
}

.k-cursor-help {
    cursor: help;
}

.\!k-cursor-help {
    cursor: help !important;
}

.k-cursor-pointer {
    cursor: pointer;
}

.\!k-cursor-pointer {
    cursor: pointer !important;
}

.k-cursor-progress {
    cursor: progress;
}

.\!k-cursor-progress {
    cursor: progress !important;
}

.k-cursor-wait {
    cursor: wait;
}

.\!k-cursor-wait {
    cursor: wait !important;
}

.k-cursor-cell {
    cursor: cell;
}

.\!k-cursor-cell {
    cursor: cell !important;
}

.k-cursor-crosshair {
    cursor: crosshair;
}

.\!k-cursor-crosshair {
    cursor: crosshair !important;
}

.k-cursor-text {
    cursor: text;
}

.\!k-cursor-text {
    cursor: text !important;
}

.k-cursor-vertical-text {
    cursor: vertical-text;
}

.\!k-cursor-vertical-text {
    cursor: vertical-text !important;
}

.k-cursor-alias {
    cursor: alias;
}

.\!k-cursor-alias {
    cursor: alias !important;
}

.k-cursor-copy {
    cursor: copy;
}

.\!k-cursor-copy {
    cursor: copy !important;
}

.k-cursor-move {
    cursor: move;
}

.\!k-cursor-move {
    cursor: move !important;
}

.k-cursor-no-drop {
    cursor: no-drop;
}

.\!k-cursor-no-drop {
    cursor: no-drop !important;
}

.k-cursor-not-allowed {
    cursor: not-allowed;
}

.\!k-cursor-not-allowed {
    cursor: not-allowed !important;
}

.k-cursor-e-resize {
    cursor: e-resize;
}

.\!k-cursor-e-resize {
    cursor: e-resize !important;
}

.k-cursor-n-resize {
    cursor: n-resize;
}

.\!k-cursor-n-resize {
    cursor: n-resize !important;
}

.k-cursor-ne-resize {
    cursor: ne-resize;
}

.\!k-cursor-ne-resize {
    cursor: ne-resize !important;
}

.k-cursor-nw-resize {
    cursor: nw-resize;
}

.\!k-cursor-nw-resize {
    cursor: nw-resize !important;
}

.k-cursor-s-resize {
    cursor: s-resize;
}

.\!k-cursor-s-resize {
    cursor: s-resize !important;
}

.k-cursor-se-resize {
    cursor: se-resize;
}

.\!k-cursor-se-resize {
    cursor: se-resize !important;
}

.k-cursor-sw-resize {
    cursor: sw-resize;
}

.\!k-cursor-sw-resize {
    cursor: sw-resize !important;
}

.k-cursor-w-resize {
    cursor: w-resize;
}

.\!k-cursor-w-resize {
    cursor: w-resize !important;
}

.k-cursor-ew-resize {
    cursor: ew-resize;
}

.\!k-cursor-ew-resize {
    cursor: ew-resize !important;
}

.k-cursor-ns-resize {
    cursor: ns-resize;
}

.\!k-cursor-ns-resize {
    cursor: ns-resize !important;
}

.k-cursor-nesw-resize {
    cursor: nesw-resize;
}

.\!k-cursor-nesw-resize {
    cursor: nesw-resize !important;
}

.k-cursor-nwse-resize {
    cursor: nwse-resize;
}

.\!k-cursor-nwse-resize {
    cursor: nwse-resize !important;
}

.k-cursor-col-resize {
    cursor: col-resize;
}

.\!k-cursor-col-resize {
    cursor: col-resize !important;
}

.k-cursor-row-resize {
    cursor: row-resize;
}

.\!k-cursor-row-resize {
    cursor: row-resize !important;
}

.k-cursor-all-scroll {
    cursor: all-scroll;
}

.\!k-cursor-all-scroll {
    cursor: all-scroll !important;
}

.k-cursor-zoom-in {
    cursor: zoom-in;
}

.\!k-cursor-zoom-in {
    cursor: zoom-in !important;
}

.k-cursor-zoom-out {
    cursor: zoom-out;
}

.\!k-cursor-zoom-out {
    cursor: zoom-out !important;
}

.k-cursor-grab {
    cursor: grab;
}

.\!k-cursor-grab {
    cursor: grab !important;
}

.k-cursor-grabbing {
    cursor: grabbing;
}

.\!k-cursor-grabbing {
    cursor: grabbing !important;
}

.k-pointer-events-none {
    pointer-events: none;
}

.\!k-pointer-events-none {
    pointer-events: none !important;
}

.k-pointer-events-auto {
    pointer-events: auto;
}

.\!k-pointer-events-auto {
    pointer-events: auto !important;
}

.k-resize {
    resize: both;
}

.\!k-resize {
    resize: both !important;
}

.k-resize-none {
    resize: none;
}

.\!k-resize-none {
    resize: none !important;
}

.k-resize-x {
    resize: horizontal;
}

.\!k-resize-x {
    resize: horizontal !important;
}

.k-resize-y {
    resize: vertical;
}

.\!k-resize-y {
    resize: vertical !important;
}

.k-scroll-auto {
    scroll-behavior: auto;
}

.\!k-scroll-auto {
    scroll-behavior: auto !important;
}

.k-scroll-smooth {
    scroll-behavior: smooth;
}

.\!k-scroll-smooth {
    scroll-behavior: smooth !important;
}

.k-scroll-snap-none {
    scroll-snap-type: none;
}

.\!k-scroll-snap-none {
    scroll-snap-type: none !important;
}

.k-scroll-snap-snap-x {
    scroll-snap-type: x var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-x {
    scroll-snap-type: x var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-snap-y {
    scroll-snap-type: y var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-y {
    scroll-snap-type: y var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-snap-both {
    scroll-snap-type: both var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-both {
    scroll-snap-type: both var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-mandatory {
    --k-scroll-snap-strictness: mandatory;
}

.k-scroll-snap-proximity {
    --k-scroll-snap-strictness: proximity;
}

.k-scroll-snap-stop-normal {
    scroll-snap-stop: normal;
}

.\!k-scroll-snap-stop-normal {
    scroll-snap-stop: normal !important;
}

.k-scroll-snap-stop-always {
    scroll-snap-stop: always;
}

.\!k-scroll-snap-stop-always {
    scroll-snap-stop: always !important;
}

.k-scroll-snap-align-start {
    scroll-snap-align: start;
}

.\!k-scroll-snap-align-start {
    scroll-snap-align: start !important;
}

.k-scroll-snap-align-end {
    scroll-snap-align: end;
}

.\!k-scroll-snap-align-end {
    scroll-snap-align: end !important;
}

.k-scroll-snap-align-center {
    scroll-snap-align: center;
}

.\!k-scroll-snap-align-center {
    scroll-snap-align: center !important;
}

.k-scroll-snap-align-none {
    scroll-snap-align: none;
}

.\!k-scroll-snap-align-none {
    scroll-snap-align: none !important;
}

.k-scroll-m-0 {
    scroll-margin: 0;
}

.\!k-scroll-m-0 {
    scroll-margin: 0 !important;
}

.k-scroll-m-1px {
    scroll-margin: 1px;
}

.\!k-scroll-m-1px {
    scroll-margin: 1px !important;
}

.k-scroll-m-0\.5 {
    scroll-margin: 2px;
}

.\!k-scroll-m-0\.5 {
    scroll-margin: 2px !important;
}

.k-scroll-m-1 {
    scroll-margin: 4px;
}

.\!k-scroll-m-1 {
    scroll-margin: 4px !important;
}

.k-scroll-m-1\.5 {
    scroll-margin: 6px;
}

.\!k-scroll-m-1\.5 {
    scroll-margin: 6px !important;
}

.k-scroll-m-2 {
    scroll-margin: 8px;
}

.\!k-scroll-m-2 {
    scroll-margin: 8px !important;
}

.k-scroll-m-2\.5 {
    scroll-margin: 10px;
}

.\!k-scroll-m-2\.5 {
    scroll-margin: 10px !important;
}

.k-scroll-m-3 {
    scroll-margin: 12px;
}

.\!k-scroll-m-3 {
    scroll-margin: 12px !important;
}

.k-scroll-m-3\.5 {
    scroll-margin: 14px;
}

.\!k-scroll-m-3\.5 {
    scroll-margin: 14px !important;
}

.k-scroll-m-4 {
    scroll-margin: 16px;
}

.\!k-scroll-m-4 {
    scroll-margin: 16px !important;
}

.k-scroll-m-4\.5 {
    scroll-margin: 18px;
}

.\!k-scroll-m-4\.5 {
    scroll-margin: 18px !important;
}

.k-scroll-m-5 {
    scroll-margin: 20px;
}

.\!k-scroll-m-5 {
    scroll-margin: 20px !important;
}

.k-scroll-m-5\.5 {
    scroll-margin: 22px;
}

.\!k-scroll-m-5\.5 {
    scroll-margin: 22px !important;
}

.k-scroll-m-6 {
    scroll-margin: 24px;
}

.\!k-scroll-m-6 {
    scroll-margin: 24px !important;
}

.k-scroll-m-6\.5 {
    scroll-margin: 26px;
}

.\!k-scroll-m-6\.5 {
    scroll-margin: 26px !important;
}

.k-scroll-m-7 {
    scroll-margin: 28px;
}

.\!k-scroll-m-7 {
    scroll-margin: 28px !important;
}

.k-scroll-m-7\.5 {
    scroll-margin: 30px;
}

.\!k-scroll-m-7\.5 {
    scroll-margin: 30px !important;
}

.k-scroll-m-8 {
    scroll-margin: 32px;
}

.\!k-scroll-m-8 {
    scroll-margin: 32px !important;
}

.k-scroll-m-9 {
    scroll-margin: 36px;
}

.\!k-scroll-m-9 {
    scroll-margin: 36px !important;
}

.k-scroll-m-10 {
    scroll-margin: 40px;
}

.\!k-scroll-m-10 {
    scroll-margin: 40px !important;
}

.k-scroll-m-11 {
    scroll-margin: 44px;
}

.\!k-scroll-m-11 {
    scroll-margin: 44px !important;
}

.k-scroll-m-12 {
    scroll-margin: 48px;
}

.\!k-scroll-m-12 {
    scroll-margin: 48px !important;
}

.k-scroll-m-13 {
    scroll-margin: 52px;
}

.\!k-scroll-m-13 {
    scroll-margin: 52px !important;
}

.k-scroll-m-14 {
    scroll-margin: 56px;
}

.\!k-scroll-m-14 {
    scroll-margin: 56px !important;
}

.k-scroll-m-15 {
    scroll-margin: 60px;
}

.\!k-scroll-m-15 {
    scroll-margin: 60px !important;
}

.k-scroll-m-16 {
    scroll-margin: 64px;
}

.\!k-scroll-m-16 {
    scroll-margin: 64px !important;
}

.k-scroll-m-17 {
    scroll-margin: 68px;
}

.\!k-scroll-m-17 {
    scroll-margin: 68px !important;
}

.k-scroll-m-18 {
    scroll-margin: 72px;
}

.\!k-scroll-m-18 {
    scroll-margin: 72px !important;
}

.k-scroll-m-19 {
    scroll-margin: 76px;
}

.\!k-scroll-m-19 {
    scroll-margin: 76px !important;
}

.k-scroll-m-20 {
    scroll-margin: 80px;
}

.\!k-scroll-m-20 {
    scroll-margin: 80px !important;
}

.k-scroll-m-21 {
    scroll-margin: 84px;
}

.\!k-scroll-m-21 {
    scroll-margin: 84px !important;
}

.k-scroll-m-22 {
    scroll-margin: 88px;
}

.\!k-scroll-m-22 {
    scroll-margin: 88px !important;
}

.k-scroll-m-23 {
    scroll-margin: 92px;
}

.\!k-scroll-m-23 {
    scroll-margin: 92px !important;
}

.k-scroll-m-24 {
    scroll-margin: 96px;
}

.\!k-scroll-m-24 {
    scroll-margin: 96px !important;
}

.k-scroll-mt-0 {
    scroll-margin-top: 0;
}

.\!k-scroll-mt-0 {
    scroll-margin-top: 0 !important;
}

.k-scroll-mt-1px {
    scroll-margin-top: 1px;
}

.\!k-scroll-mt-1px {
    scroll-margin-top: 1px !important;
}

.k-scroll-mt-0\.5 {
    scroll-margin-top: 2px;
}

.\!k-scroll-mt-0\.5 {
    scroll-margin-top: 2px !important;
}

.k-scroll-mt-1 {
    scroll-margin-top: 4px;
}

.\!k-scroll-mt-1 {
    scroll-margin-top: 4px !important;
}

.k-scroll-mt-1\.5 {
    scroll-margin-top: 6px;
}

.\!k-scroll-mt-1\.5 {
    scroll-margin-top: 6px !important;
}

.k-scroll-mt-2 {
    scroll-margin-top: 8px;
}

.\!k-scroll-mt-2 {
    scroll-margin-top: 8px !important;
}

.k-scroll-mt-2\.5 {
    scroll-margin-top: 10px;
}

.\!k-scroll-mt-2\.5 {
    scroll-margin-top: 10px !important;
}

.k-scroll-mt-3 {
    scroll-margin-top: 12px;
}

.\!k-scroll-mt-3 {
    scroll-margin-top: 12px !important;
}

.k-scroll-mt-3\.5 {
    scroll-margin-top: 14px;
}

.\!k-scroll-mt-3\.5 {
    scroll-margin-top: 14px !important;
}

.k-scroll-mt-4 {
    scroll-margin-top: 16px;
}

.\!k-scroll-mt-4 {
    scroll-margin-top: 16px !important;
}

.k-scroll-mt-4\.5 {
    scroll-margin-top: 18px;
}

.\!k-scroll-mt-4\.5 {
    scroll-margin-top: 18px !important;
}

.k-scroll-mt-5 {
    scroll-margin-top: 20px;
}

.\!k-scroll-mt-5 {
    scroll-margin-top: 20px !important;
}

.k-scroll-mt-5\.5 {
    scroll-margin-top: 22px;
}

.\!k-scroll-mt-5\.5 {
    scroll-margin-top: 22px !important;
}

.k-scroll-mt-6 {
    scroll-margin-top: 24px;
}

.\!k-scroll-mt-6 {
    scroll-margin-top: 24px !important;
}

.k-scroll-mt-6\.5 {
    scroll-margin-top: 26px;
}

.\!k-scroll-mt-6\.5 {
    scroll-margin-top: 26px !important;
}

.k-scroll-mt-7 {
    scroll-margin-top: 28px;
}

.\!k-scroll-mt-7 {
    scroll-margin-top: 28px !important;
}

.k-scroll-mt-7\.5 {
    scroll-margin-top: 30px;
}

.\!k-scroll-mt-7\.5 {
    scroll-margin-top: 30px !important;
}

.k-scroll-mt-8 {
    scroll-margin-top: 32px;
}

.\!k-scroll-mt-8 {
    scroll-margin-top: 32px !important;
}

.k-scroll-mt-9 {
    scroll-margin-top: 36px;
}

.\!k-scroll-mt-9 {
    scroll-margin-top: 36px !important;
}

.k-scroll-mt-10 {
    scroll-margin-top: 40px;
}

.\!k-scroll-mt-10 {
    scroll-margin-top: 40px !important;
}

.k-scroll-mt-11 {
    scroll-margin-top: 44px;
}

.\!k-scroll-mt-11 {
    scroll-margin-top: 44px !important;
}

.k-scroll-mt-12 {
    scroll-margin-top: 48px;
}

.\!k-scroll-mt-12 {
    scroll-margin-top: 48px !important;
}

.k-scroll-mt-13 {
    scroll-margin-top: 52px;
}

.\!k-scroll-mt-13 {
    scroll-margin-top: 52px !important;
}

.k-scroll-mt-14 {
    scroll-margin-top: 56px;
}

.\!k-scroll-mt-14 {
    scroll-margin-top: 56px !important;
}

.k-scroll-mt-15 {
    scroll-margin-top: 60px;
}

.\!k-scroll-mt-15 {
    scroll-margin-top: 60px !important;
}

.k-scroll-mt-16 {
    scroll-margin-top: 64px;
}

.\!k-scroll-mt-16 {
    scroll-margin-top: 64px !important;
}

.k-scroll-mt-17 {
    scroll-margin-top: 68px;
}

.\!k-scroll-mt-17 {
    scroll-margin-top: 68px !important;
}

.k-scroll-mt-18 {
    scroll-margin-top: 72px;
}

.\!k-scroll-mt-18 {
    scroll-margin-top: 72px !important;
}

.k-scroll-mt-19 {
    scroll-margin-top: 76px;
}

.\!k-scroll-mt-19 {
    scroll-margin-top: 76px !important;
}

.k-scroll-mt-20 {
    scroll-margin-top: 80px;
}

.\!k-scroll-mt-20 {
    scroll-margin-top: 80px !important;
}

.k-scroll-mt-21 {
    scroll-margin-top: 84px;
}

.\!k-scroll-mt-21 {
    scroll-margin-top: 84px !important;
}

.k-scroll-mt-22 {
    scroll-margin-top: 88px;
}

.\!k-scroll-mt-22 {
    scroll-margin-top: 88px !important;
}

.k-scroll-mt-23 {
    scroll-margin-top: 92px;
}

.\!k-scroll-mt-23 {
    scroll-margin-top: 92px !important;
}

.k-scroll-mt-24 {
    scroll-margin-top: 96px;
}

.\!k-scroll-mt-24 {
    scroll-margin-top: 96px !important;
}

.k-scroll-mr-0 {
    scroll-margin-right: 0;
}

.\!k-scroll-mr-0 {
    scroll-margin-right: 0 !important;
}

.k-scroll-mr-1px {
    scroll-margin-right: 1px;
}

.\!k-scroll-mr-1px {
    scroll-margin-right: 1px !important;
}

.k-scroll-mr-0\.5 {
    scroll-margin-right: 2px;
}

.\!k-scroll-mr-0\.5 {
    scroll-margin-right: 2px !important;
}

.k-scroll-mr-1 {
    scroll-margin-right: 4px;
}

.\!k-scroll-mr-1 {
    scroll-margin-right: 4px !important;
}

.k-scroll-mr-1\.5 {
    scroll-margin-right: 6px;
}

.\!k-scroll-mr-1\.5 {
    scroll-margin-right: 6px !important;
}

.k-scroll-mr-2 {
    scroll-margin-right: 8px;
}

.\!k-scroll-mr-2 {
    scroll-margin-right: 8px !important;
}

.k-scroll-mr-2\.5 {
    scroll-margin-right: 10px;
}

.\!k-scroll-mr-2\.5 {
    scroll-margin-right: 10px !important;
}

.k-scroll-mr-3 {
    scroll-margin-right: 12px;
}

.\!k-scroll-mr-3 {
    scroll-margin-right: 12px !important;
}

.k-scroll-mr-3\.5 {
    scroll-margin-right: 14px;
}

.\!k-scroll-mr-3\.5 {
    scroll-margin-right: 14px !important;
}

.k-scroll-mr-4 {
    scroll-margin-right: 16px;
}

.\!k-scroll-mr-4 {
    scroll-margin-right: 16px !important;
}

.k-scroll-mr-4\.5 {
    scroll-margin-right: 18px;
}

.\!k-scroll-mr-4\.5 {
    scroll-margin-right: 18px !important;
}

.k-scroll-mr-5 {
    scroll-margin-right: 20px;
}

.\!k-scroll-mr-5 {
    scroll-margin-right: 20px !important;
}

.k-scroll-mr-5\.5 {
    scroll-margin-right: 22px;
}

.\!k-scroll-mr-5\.5 {
    scroll-margin-right: 22px !important;
}

.k-scroll-mr-6 {
    scroll-margin-right: 24px;
}

.\!k-scroll-mr-6 {
    scroll-margin-right: 24px !important;
}

.k-scroll-mr-6\.5 {
    scroll-margin-right: 26px;
}

.\!k-scroll-mr-6\.5 {
    scroll-margin-right: 26px !important;
}

.k-scroll-mr-7 {
    scroll-margin-right: 28px;
}

.\!k-scroll-mr-7 {
    scroll-margin-right: 28px !important;
}

.k-scroll-mr-7\.5 {
    scroll-margin-right: 30px;
}

.\!k-scroll-mr-7\.5 {
    scroll-margin-right: 30px !important;
}

.k-scroll-mr-8 {
    scroll-margin-right: 32px;
}

.\!k-scroll-mr-8 {
    scroll-margin-right: 32px !important;
}

.k-scroll-mr-9 {
    scroll-margin-right: 36px;
}

.\!k-scroll-mr-9 {
    scroll-margin-right: 36px !important;
}

.k-scroll-mr-10 {
    scroll-margin-right: 40px;
}

.\!k-scroll-mr-10 {
    scroll-margin-right: 40px !important;
}

.k-scroll-mr-11 {
    scroll-margin-right: 44px;
}

.\!k-scroll-mr-11 {
    scroll-margin-right: 44px !important;
}

.k-scroll-mr-12 {
    scroll-margin-right: 48px;
}

.\!k-scroll-mr-12 {
    scroll-margin-right: 48px !important;
}

.k-scroll-mr-13 {
    scroll-margin-right: 52px;
}

.\!k-scroll-mr-13 {
    scroll-margin-right: 52px !important;
}

.k-scroll-mr-14 {
    scroll-margin-right: 56px;
}

.\!k-scroll-mr-14 {
    scroll-margin-right: 56px !important;
}

.k-scroll-mr-15 {
    scroll-margin-right: 60px;
}

.\!k-scroll-mr-15 {
    scroll-margin-right: 60px !important;
}

.k-scroll-mr-16 {
    scroll-margin-right: 64px;
}

.\!k-scroll-mr-16 {
    scroll-margin-right: 64px !important;
}

.k-scroll-mr-17 {
    scroll-margin-right: 68px;
}

.\!k-scroll-mr-17 {
    scroll-margin-right: 68px !important;
}

.k-scroll-mr-18 {
    scroll-margin-right: 72px;
}

.\!k-scroll-mr-18 {
    scroll-margin-right: 72px !important;
}

.k-scroll-mr-19 {
    scroll-margin-right: 76px;
}

.\!k-scroll-mr-19 {
    scroll-margin-right: 76px !important;
}

.k-scroll-mr-20 {
    scroll-margin-right: 80px;
}

.\!k-scroll-mr-20 {
    scroll-margin-right: 80px !important;
}

.k-scroll-mr-21 {
    scroll-margin-right: 84px;
}

.\!k-scroll-mr-21 {
    scroll-margin-right: 84px !important;
}

.k-scroll-mr-22 {
    scroll-margin-right: 88px;
}

.\!k-scroll-mr-22 {
    scroll-margin-right: 88px !important;
}

.k-scroll-mr-23 {
    scroll-margin-right: 92px;
}

.\!k-scroll-mr-23 {
    scroll-margin-right: 92px !important;
}

.k-scroll-mr-24 {
    scroll-margin-right: 96px;
}

.\!k-scroll-mr-24 {
    scroll-margin-right: 96px !important;
}

.k-scroll-mb-0 {
    scroll-margin-bottom: 0;
}

.\!k-scroll-mb-0 {
    scroll-margin-bottom: 0 !important;
}

.k-scroll-mb-1px {
    scroll-margin-bottom: 1px;
}

.\!k-scroll-mb-1px {
    scroll-margin-bottom: 1px !important;
}

.k-scroll-mb-0\.5 {
    scroll-margin-bottom: 2px;
}

.\!k-scroll-mb-0\.5 {
    scroll-margin-bottom: 2px !important;
}

.k-scroll-mb-1 {
    scroll-margin-bottom: 4px;
}

.\!k-scroll-mb-1 {
    scroll-margin-bottom: 4px !important;
}

.k-scroll-mb-1\.5 {
    scroll-margin-bottom: 6px;
}

.\!k-scroll-mb-1\.5 {
    scroll-margin-bottom: 6px !important;
}

.k-scroll-mb-2 {
    scroll-margin-bottom: 8px;
}

.\!k-scroll-mb-2 {
    scroll-margin-bottom: 8px !important;
}

.k-scroll-mb-2\.5 {
    scroll-margin-bottom: 10px;
}

.\!k-scroll-mb-2\.5 {
    scroll-margin-bottom: 10px !important;
}

.k-scroll-mb-3 {
    scroll-margin-bottom: 12px;
}

.\!k-scroll-mb-3 {
    scroll-margin-bottom: 12px !important;
}

.k-scroll-mb-3\.5 {
    scroll-margin-bottom: 14px;
}

.\!k-scroll-mb-3\.5 {
    scroll-margin-bottom: 14px !important;
}

.k-scroll-mb-4 {
    scroll-margin-bottom: 16px;
}

.\!k-scroll-mb-4 {
    scroll-margin-bottom: 16px !important;
}

.k-scroll-mb-4\.5 {
    scroll-margin-bottom: 18px;
}

.\!k-scroll-mb-4\.5 {
    scroll-margin-bottom: 18px !important;
}

.k-scroll-mb-5 {
    scroll-margin-bottom: 20px;
}

.\!k-scroll-mb-5 {
    scroll-margin-bottom: 20px !important;
}

.k-scroll-mb-5\.5 {
    scroll-margin-bottom: 22px;
}

.\!k-scroll-mb-5\.5 {
    scroll-margin-bottom: 22px !important;
}

.k-scroll-mb-6 {
    scroll-margin-bottom: 24px;
}

.\!k-scroll-mb-6 {
    scroll-margin-bottom: 24px !important;
}

.k-scroll-mb-6\.5 {
    scroll-margin-bottom: 26px;
}

.\!k-scroll-mb-6\.5 {
    scroll-margin-bottom: 26px !important;
}

.k-scroll-mb-7 {
    scroll-margin-bottom: 28px;
}

.\!k-scroll-mb-7 {
    scroll-margin-bottom: 28px !important;
}

.k-scroll-mb-7\.5 {
    scroll-margin-bottom: 30px;
}

.\!k-scroll-mb-7\.5 {
    scroll-margin-bottom: 30px !important;
}

.k-scroll-mb-8 {
    scroll-margin-bottom: 32px;
}

.\!k-scroll-mb-8 {
    scroll-margin-bottom: 32px !important;
}

.k-scroll-mb-9 {
    scroll-margin-bottom: 36px;
}

.\!k-scroll-mb-9 {
    scroll-margin-bottom: 36px !important;
}

.k-scroll-mb-10 {
    scroll-margin-bottom: 40px;
}

.\!k-scroll-mb-10 {
    scroll-margin-bottom: 40px !important;
}

.k-scroll-mb-11 {
    scroll-margin-bottom: 44px;
}

.\!k-scroll-mb-11 {
    scroll-margin-bottom: 44px !important;
}

.k-scroll-mb-12 {
    scroll-margin-bottom: 48px;
}

.\!k-scroll-mb-12 {
    scroll-margin-bottom: 48px !important;
}

.k-scroll-mb-13 {
    scroll-margin-bottom: 52px;
}

.\!k-scroll-mb-13 {
    scroll-margin-bottom: 52px !important;
}

.k-scroll-mb-14 {
    scroll-margin-bottom: 56px;
}

.\!k-scroll-mb-14 {
    scroll-margin-bottom: 56px !important;
}

.k-scroll-mb-15 {
    scroll-margin-bottom: 60px;
}

.\!k-scroll-mb-15 {
    scroll-margin-bottom: 60px !important;
}

.k-scroll-mb-16 {
    scroll-margin-bottom: 64px;
}

.\!k-scroll-mb-16 {
    scroll-margin-bottom: 64px !important;
}

.k-scroll-mb-17 {
    scroll-margin-bottom: 68px;
}

.\!k-scroll-mb-17 {
    scroll-margin-bottom: 68px !important;
}

.k-scroll-mb-18 {
    scroll-margin-bottom: 72px;
}

.\!k-scroll-mb-18 {
    scroll-margin-bottom: 72px !important;
}

.k-scroll-mb-19 {
    scroll-margin-bottom: 76px;
}

.\!k-scroll-mb-19 {
    scroll-margin-bottom: 76px !important;
}

.k-scroll-mb-20 {
    scroll-margin-bottom: 80px;
}

.\!k-scroll-mb-20 {
    scroll-margin-bottom: 80px !important;
}

.k-scroll-mb-21 {
    scroll-margin-bottom: 84px;
}

.\!k-scroll-mb-21 {
    scroll-margin-bottom: 84px !important;
}

.k-scroll-mb-22 {
    scroll-margin-bottom: 88px;
}

.\!k-scroll-mb-22 {
    scroll-margin-bottom: 88px !important;
}

.k-scroll-mb-23 {
    scroll-margin-bottom: 92px;
}

.\!k-scroll-mb-23 {
    scroll-margin-bottom: 92px !important;
}

.k-scroll-mb-24 {
    scroll-margin-bottom: 96px;
}

.\!k-scroll-mb-24 {
    scroll-margin-bottom: 96px !important;
}

.k-scroll-ml-0 {
    scroll-margin-left: 0;
}

.\!k-scroll-ml-0 {
    scroll-margin-left: 0 !important;
}

.k-scroll-ml-1px {
    scroll-margin-left: 1px;
}

.\!k-scroll-ml-1px {
    scroll-margin-left: 1px !important;
}

.k-scroll-ml-0\.5 {
    scroll-margin-left: 2px;
}

.\!k-scroll-ml-0\.5 {
    scroll-margin-left: 2px !important;
}

.k-scroll-ml-1 {
    scroll-margin-left: 4px;
}

.\!k-scroll-ml-1 {
    scroll-margin-left: 4px !important;
}

.k-scroll-ml-1\.5 {
    scroll-margin-left: 6px;
}

.\!k-scroll-ml-1\.5 {
    scroll-margin-left: 6px !important;
}

.k-scroll-ml-2 {
    scroll-margin-left: 8px;
}

.\!k-scroll-ml-2 {
    scroll-margin-left: 8px !important;
}

.k-scroll-ml-2\.5 {
    scroll-margin-left: 10px;
}

.\!k-scroll-ml-2\.5 {
    scroll-margin-left: 10px !important;
}

.k-scroll-ml-3 {
    scroll-margin-left: 12px;
}

.\!k-scroll-ml-3 {
    scroll-margin-left: 12px !important;
}

.k-scroll-ml-3\.5 {
    scroll-margin-left: 14px;
}

.\!k-scroll-ml-3\.5 {
    scroll-margin-left: 14px !important;
}

.k-scroll-ml-4 {
    scroll-margin-left: 16px;
}

.\!k-scroll-ml-4 {
    scroll-margin-left: 16px !important;
}

.k-scroll-ml-4\.5 {
    scroll-margin-left: 18px;
}

.\!k-scroll-ml-4\.5 {
    scroll-margin-left: 18px !important;
}

.k-scroll-ml-5 {
    scroll-margin-left: 20px;
}

.\!k-scroll-ml-5 {
    scroll-margin-left: 20px !important;
}

.k-scroll-ml-5\.5 {
    scroll-margin-left: 22px;
}

.\!k-scroll-ml-5\.5 {
    scroll-margin-left: 22px !important;
}

.k-scroll-ml-6 {
    scroll-margin-left: 24px;
}

.\!k-scroll-ml-6 {
    scroll-margin-left: 24px !important;
}

.k-scroll-ml-6\.5 {
    scroll-margin-left: 26px;
}

.\!k-scroll-ml-6\.5 {
    scroll-margin-left: 26px !important;
}

.k-scroll-ml-7 {
    scroll-margin-left: 28px;
}

.\!k-scroll-ml-7 {
    scroll-margin-left: 28px !important;
}

.k-scroll-ml-7\.5 {
    scroll-margin-left: 30px;
}

.\!k-scroll-ml-7\.5 {
    scroll-margin-left: 30px !important;
}

.k-scroll-ml-8 {
    scroll-margin-left: 32px;
}

.\!k-scroll-ml-8 {
    scroll-margin-left: 32px !important;
}

.k-scroll-ml-9 {
    scroll-margin-left: 36px;
}

.\!k-scroll-ml-9 {
    scroll-margin-left: 36px !important;
}

.k-scroll-ml-10 {
    scroll-margin-left: 40px;
}

.\!k-scroll-ml-10 {
    scroll-margin-left: 40px !important;
}

.k-scroll-ml-11 {
    scroll-margin-left: 44px;
}

.\!k-scroll-ml-11 {
    scroll-margin-left: 44px !important;
}

.k-scroll-ml-12 {
    scroll-margin-left: 48px;
}

.\!k-scroll-ml-12 {
    scroll-margin-left: 48px !important;
}

.k-scroll-ml-13 {
    scroll-margin-left: 52px;
}

.\!k-scroll-ml-13 {
    scroll-margin-left: 52px !important;
}

.k-scroll-ml-14 {
    scroll-margin-left: 56px;
}

.\!k-scroll-ml-14 {
    scroll-margin-left: 56px !important;
}

.k-scroll-ml-15 {
    scroll-margin-left: 60px;
}

.\!k-scroll-ml-15 {
    scroll-margin-left: 60px !important;
}

.k-scroll-ml-16 {
    scroll-margin-left: 64px;
}

.\!k-scroll-ml-16 {
    scroll-margin-left: 64px !important;
}

.k-scroll-ml-17 {
    scroll-margin-left: 68px;
}

.\!k-scroll-ml-17 {
    scroll-margin-left: 68px !important;
}

.k-scroll-ml-18 {
    scroll-margin-left: 72px;
}

.\!k-scroll-ml-18 {
    scroll-margin-left: 72px !important;
}

.k-scroll-ml-19 {
    scroll-margin-left: 76px;
}

.\!k-scroll-ml-19 {
    scroll-margin-left: 76px !important;
}

.k-scroll-ml-20 {
    scroll-margin-left: 80px;
}

.\!k-scroll-ml-20 {
    scroll-margin-left: 80px !important;
}

.k-scroll-ml-21 {
    scroll-margin-left: 84px;
}

.\!k-scroll-ml-21 {
    scroll-margin-left: 84px !important;
}

.k-scroll-ml-22 {
    scroll-margin-left: 88px;
}

.\!k-scroll-ml-22 {
    scroll-margin-left: 88px !important;
}

.k-scroll-ml-23 {
    scroll-margin-left: 92px;
}

.\!k-scroll-ml-23 {
    scroll-margin-left: 92px !important;
}

.k-scroll-ml-24 {
    scroll-margin-left: 96px;
}

.\!k-scroll-ml-24 {
    scroll-margin-left: 96px !important;
}

.k-scroll-mx-0 {
    scroll-margin-inline: 0;
}

.\!k-scroll-mx-0 {
    scroll-margin-inline: 0 !important;
}

.k-scroll-mx-1px {
    scroll-margin-inline: 1px;
}

.\!k-scroll-mx-1px {
    scroll-margin-inline: 1px !important;
}

.k-scroll-mx-0\.5 {
    scroll-margin-inline: 2px;
}

.\!k-scroll-mx-0\.5 {
    scroll-margin-inline: 2px !important;
}

.k-scroll-mx-1 {
    scroll-margin-inline: 4px;
}

.\!k-scroll-mx-1 {
    scroll-margin-inline: 4px !important;
}

.k-scroll-mx-1\.5 {
    scroll-margin-inline: 6px;
}

.\!k-scroll-mx-1\.5 {
    scroll-margin-inline: 6px !important;
}

.k-scroll-mx-2 {
    scroll-margin-inline: 8px;
}

.\!k-scroll-mx-2 {
    scroll-margin-inline: 8px !important;
}

.k-scroll-mx-2\.5 {
    scroll-margin-inline: 10px;
}

.\!k-scroll-mx-2\.5 {
    scroll-margin-inline: 10px !important;
}

.k-scroll-mx-3 {
    scroll-margin-inline: 12px;
}

.\!k-scroll-mx-3 {
    scroll-margin-inline: 12px !important;
}

.k-scroll-mx-3\.5 {
    scroll-margin-inline: 14px;
}

.\!k-scroll-mx-3\.5 {
    scroll-margin-inline: 14px !important;
}

.k-scroll-mx-4 {
    scroll-margin-inline: 16px;
}

.\!k-scroll-mx-4 {
    scroll-margin-inline: 16px !important;
}

.k-scroll-mx-4\.5 {
    scroll-margin-inline: 18px;
}

.\!k-scroll-mx-4\.5 {
    scroll-margin-inline: 18px !important;
}

.k-scroll-mx-5 {
    scroll-margin-inline: 20px;
}

.\!k-scroll-mx-5 {
    scroll-margin-inline: 20px !important;
}

.k-scroll-mx-5\.5 {
    scroll-margin-inline: 22px;
}

.\!k-scroll-mx-5\.5 {
    scroll-margin-inline: 22px !important;
}

.k-scroll-mx-6 {
    scroll-margin-inline: 24px;
}

.\!k-scroll-mx-6 {
    scroll-margin-inline: 24px !important;
}

.k-scroll-mx-6\.5 {
    scroll-margin-inline: 26px;
}

.\!k-scroll-mx-6\.5 {
    scroll-margin-inline: 26px !important;
}

.k-scroll-mx-7 {
    scroll-margin-inline: 28px;
}

.\!k-scroll-mx-7 {
    scroll-margin-inline: 28px !important;
}

.k-scroll-mx-7\.5 {
    scroll-margin-inline: 30px;
}

.\!k-scroll-mx-7\.5 {
    scroll-margin-inline: 30px !important;
}

.k-scroll-mx-8 {
    scroll-margin-inline: 32px;
}

.\!k-scroll-mx-8 {
    scroll-margin-inline: 32px !important;
}

.k-scroll-mx-9 {
    scroll-margin-inline: 36px;
}

.\!k-scroll-mx-9 {
    scroll-margin-inline: 36px !important;
}

.k-scroll-mx-10 {
    scroll-margin-inline: 40px;
}

.\!k-scroll-mx-10 {
    scroll-margin-inline: 40px !important;
}

.k-scroll-mx-11 {
    scroll-margin-inline: 44px;
}

.\!k-scroll-mx-11 {
    scroll-margin-inline: 44px !important;
}

.k-scroll-mx-12 {
    scroll-margin-inline: 48px;
}

.\!k-scroll-mx-12 {
    scroll-margin-inline: 48px !important;
}

.k-scroll-mx-13 {
    scroll-margin-inline: 52px;
}

.\!k-scroll-mx-13 {
    scroll-margin-inline: 52px !important;
}

.k-scroll-mx-14 {
    scroll-margin-inline: 56px;
}

.\!k-scroll-mx-14 {
    scroll-margin-inline: 56px !important;
}

.k-scroll-mx-15 {
    scroll-margin-inline: 60px;
}

.\!k-scroll-mx-15 {
    scroll-margin-inline: 60px !important;
}

.k-scroll-mx-16 {
    scroll-margin-inline: 64px;
}

.\!k-scroll-mx-16 {
    scroll-margin-inline: 64px !important;
}

.k-scroll-mx-17 {
    scroll-margin-inline: 68px;
}

.\!k-scroll-mx-17 {
    scroll-margin-inline: 68px !important;
}

.k-scroll-mx-18 {
    scroll-margin-inline: 72px;
}

.\!k-scroll-mx-18 {
    scroll-margin-inline: 72px !important;
}

.k-scroll-mx-19 {
    scroll-margin-inline: 76px;
}

.\!k-scroll-mx-19 {
    scroll-margin-inline: 76px !important;
}

.k-scroll-mx-20 {
    scroll-margin-inline: 80px;
}

.\!k-scroll-mx-20 {
    scroll-margin-inline: 80px !important;
}

.k-scroll-mx-21 {
    scroll-margin-inline: 84px;
}

.\!k-scroll-mx-21 {
    scroll-margin-inline: 84px !important;
}

.k-scroll-mx-22 {
    scroll-margin-inline: 88px;
}

.\!k-scroll-mx-22 {
    scroll-margin-inline: 88px !important;
}

.k-scroll-mx-23 {
    scroll-margin-inline: 92px;
}

.\!k-scroll-mx-23 {
    scroll-margin-inline: 92px !important;
}

.k-scroll-mx-24 {
    scroll-margin-inline: 96px;
}

.\!k-scroll-mx-24 {
    scroll-margin-inline: 96px !important;
}

.k-scroll-my-0 {
    scroll-margin-block: 0;
}

.\!k-scroll-my-0 {
    scroll-margin-block: 0 !important;
}

.k-scroll-my-1px {
    scroll-margin-block: 1px;
}

.\!k-scroll-my-1px {
    scroll-margin-block: 1px !important;
}

.k-scroll-my-0\.5 {
    scroll-margin-block: 2px;
}

.\!k-scroll-my-0\.5 {
    scroll-margin-block: 2px !important;
}

.k-scroll-my-1 {
    scroll-margin-block: 4px;
}

.\!k-scroll-my-1 {
    scroll-margin-block: 4px !important;
}

.k-scroll-my-1\.5 {
    scroll-margin-block: 6px;
}

.\!k-scroll-my-1\.5 {
    scroll-margin-block: 6px !important;
}

.k-scroll-my-2 {
    scroll-margin-block: 8px;
}

.\!k-scroll-my-2 {
    scroll-margin-block: 8px !important;
}

.k-scroll-my-2\.5 {
    scroll-margin-block: 10px;
}

.\!k-scroll-my-2\.5 {
    scroll-margin-block: 10px !important;
}

.k-scroll-my-3 {
    scroll-margin-block: 12px;
}

.\!k-scroll-my-3 {
    scroll-margin-block: 12px !important;
}

.k-scroll-my-3\.5 {
    scroll-margin-block: 14px;
}

.\!k-scroll-my-3\.5 {
    scroll-margin-block: 14px !important;
}

.k-scroll-my-4 {
    scroll-margin-block: 16px;
}

.\!k-scroll-my-4 {
    scroll-margin-block: 16px !important;
}

.k-scroll-my-4\.5 {
    scroll-margin-block: 18px;
}

.\!k-scroll-my-4\.5 {
    scroll-margin-block: 18px !important;
}

.k-scroll-my-5 {
    scroll-margin-block: 20px;
}

.\!k-scroll-my-5 {
    scroll-margin-block: 20px !important;
}

.k-scroll-my-5\.5 {
    scroll-margin-block: 22px;
}

.\!k-scroll-my-5\.5 {
    scroll-margin-block: 22px !important;
}

.k-scroll-my-6 {
    scroll-margin-block: 24px;
}

.\!k-scroll-my-6 {
    scroll-margin-block: 24px !important;
}

.k-scroll-my-6\.5 {
    scroll-margin-block: 26px;
}

.\!k-scroll-my-6\.5 {
    scroll-margin-block: 26px !important;
}

.k-scroll-my-7 {
    scroll-margin-block: 28px;
}

.\!k-scroll-my-7 {
    scroll-margin-block: 28px !important;
}

.k-scroll-my-7\.5 {
    scroll-margin-block: 30px;
}

.\!k-scroll-my-7\.5 {
    scroll-margin-block: 30px !important;
}

.k-scroll-my-8 {
    scroll-margin-block: 32px;
}

.\!k-scroll-my-8 {
    scroll-margin-block: 32px !important;
}

.k-scroll-my-9 {
    scroll-margin-block: 36px;
}

.\!k-scroll-my-9 {
    scroll-margin-block: 36px !important;
}

.k-scroll-my-10 {
    scroll-margin-block: 40px;
}

.\!k-scroll-my-10 {
    scroll-margin-block: 40px !important;
}

.k-scroll-my-11 {
    scroll-margin-block: 44px;
}

.\!k-scroll-my-11 {
    scroll-margin-block: 44px !important;
}

.k-scroll-my-12 {
    scroll-margin-block: 48px;
}

.\!k-scroll-my-12 {
    scroll-margin-block: 48px !important;
}

.k-scroll-my-13 {
    scroll-margin-block: 52px;
}

.\!k-scroll-my-13 {
    scroll-margin-block: 52px !important;
}

.k-scroll-my-14 {
    scroll-margin-block: 56px;
}

.\!k-scroll-my-14 {
    scroll-margin-block: 56px !important;
}

.k-scroll-my-15 {
    scroll-margin-block: 60px;
}

.\!k-scroll-my-15 {
    scroll-margin-block: 60px !important;
}

.k-scroll-my-16 {
    scroll-margin-block: 64px;
}

.\!k-scroll-my-16 {
    scroll-margin-block: 64px !important;
}

.k-scroll-my-17 {
    scroll-margin-block: 68px;
}

.\!k-scroll-my-17 {
    scroll-margin-block: 68px !important;
}

.k-scroll-my-18 {
    scroll-margin-block: 72px;
}

.\!k-scroll-my-18 {
    scroll-margin-block: 72px !important;
}

.k-scroll-my-19 {
    scroll-margin-block: 76px;
}

.\!k-scroll-my-19 {
    scroll-margin-block: 76px !important;
}

.k-scroll-my-20 {
    scroll-margin-block: 80px;
}

.\!k-scroll-my-20 {
    scroll-margin-block: 80px !important;
}

.k-scroll-my-21 {
    scroll-margin-block: 84px;
}

.\!k-scroll-my-21 {
    scroll-margin-block: 84px !important;
}

.k-scroll-my-22 {
    scroll-margin-block: 88px;
}

.\!k-scroll-my-22 {
    scroll-margin-block: 88px !important;
}

.k-scroll-my-23 {
    scroll-margin-block: 92px;
}

.\!k-scroll-my-23 {
    scroll-margin-block: 92px !important;
}

.k-scroll-my-24 {
    scroll-margin-block: 96px;
}

.\!k-scroll-my-24 {
    scroll-margin-block: 96px !important;
}

.k-scroll-p-0 {
    scroll-padding: 0;
}

.\!k-scroll-p-0 {
    scroll-padding: 0 !important;
}

.k-scroll-p-1px {
    scroll-padding: 1px;
}

.\!k-scroll-p-1px {
    scroll-padding: 1px !important;
}

.k-scroll-p-0\.5 {
    scroll-padding: 2px;
}

.\!k-scroll-p-0\.5 {
    scroll-padding: 2px !important;
}

.k-scroll-p-1 {
    scroll-padding: 4px;
}

.\!k-scroll-p-1 {
    scroll-padding: 4px !important;
}

.k-scroll-p-1\.5 {
    scroll-padding: 6px;
}

.\!k-scroll-p-1\.5 {
    scroll-padding: 6px !important;
}

.k-scroll-p-2 {
    scroll-padding: 8px;
}

.\!k-scroll-p-2 {
    scroll-padding: 8px !important;
}

.k-scroll-p-2\.5 {
    scroll-padding: 10px;
}

.\!k-scroll-p-2\.5 {
    scroll-padding: 10px !important;
}

.k-scroll-p-3 {
    scroll-padding: 12px;
}

.\!k-scroll-p-3 {
    scroll-padding: 12px !important;
}

.k-scroll-p-3\.5 {
    scroll-padding: 14px;
}

.\!k-scroll-p-3\.5 {
    scroll-padding: 14px !important;
}

.k-scroll-p-4 {
    scroll-padding: 16px;
}

.\!k-scroll-p-4 {
    scroll-padding: 16px !important;
}

.k-scroll-p-4\.5 {
    scroll-padding: 18px;
}

.\!k-scroll-p-4\.5 {
    scroll-padding: 18px !important;
}

.k-scroll-p-5 {
    scroll-padding: 20px;
}

.\!k-scroll-p-5 {
    scroll-padding: 20px !important;
}

.k-scroll-p-5\.5 {
    scroll-padding: 22px;
}

.\!k-scroll-p-5\.5 {
    scroll-padding: 22px !important;
}

.k-scroll-p-6 {
    scroll-padding: 24px;
}

.\!k-scroll-p-6 {
    scroll-padding: 24px !important;
}

.k-scroll-p-6\.5 {
    scroll-padding: 26px;
}

.\!k-scroll-p-6\.5 {
    scroll-padding: 26px !important;
}

.k-scroll-p-7 {
    scroll-padding: 28px;
}

.\!k-scroll-p-7 {
    scroll-padding: 28px !important;
}

.k-scroll-p-7\.5 {
    scroll-padding: 30px;
}

.\!k-scroll-p-7\.5 {
    scroll-padding: 30px !important;
}

.k-scroll-p-8 {
    scroll-padding: 32px;
}

.\!k-scroll-p-8 {
    scroll-padding: 32px !important;
}

.k-scroll-p-9 {
    scroll-padding: 36px;
}

.\!k-scroll-p-9 {
    scroll-padding: 36px !important;
}

.k-scroll-p-10 {
    scroll-padding: 40px;
}

.\!k-scroll-p-10 {
    scroll-padding: 40px !important;
}

.k-scroll-p-11 {
    scroll-padding: 44px;
}

.\!k-scroll-p-11 {
    scroll-padding: 44px !important;
}

.k-scroll-p-12 {
    scroll-padding: 48px;
}

.\!k-scroll-p-12 {
    scroll-padding: 48px !important;
}

.k-scroll-p-13 {
    scroll-padding: 52px;
}

.\!k-scroll-p-13 {
    scroll-padding: 52px !important;
}

.k-scroll-p-14 {
    scroll-padding: 56px;
}

.\!k-scroll-p-14 {
    scroll-padding: 56px !important;
}

.k-scroll-p-15 {
    scroll-padding: 60px;
}

.\!k-scroll-p-15 {
    scroll-padding: 60px !important;
}

.k-scroll-p-16 {
    scroll-padding: 64px;
}

.\!k-scroll-p-16 {
    scroll-padding: 64px !important;
}

.k-scroll-p-17 {
    scroll-padding: 68px;
}

.\!k-scroll-p-17 {
    scroll-padding: 68px !important;
}

.k-scroll-p-18 {
    scroll-padding: 72px;
}

.\!k-scroll-p-18 {
    scroll-padding: 72px !important;
}

.k-scroll-p-19 {
    scroll-padding: 76px;
}

.\!k-scroll-p-19 {
    scroll-padding: 76px !important;
}

.k-scroll-p-20 {
    scroll-padding: 80px;
}

.\!k-scroll-p-20 {
    scroll-padding: 80px !important;
}

.k-scroll-p-21 {
    scroll-padding: 84px;
}

.\!k-scroll-p-21 {
    scroll-padding: 84px !important;
}

.k-scroll-p-22 {
    scroll-padding: 88px;
}

.\!k-scroll-p-22 {
    scroll-padding: 88px !important;
}

.k-scroll-p-23 {
    scroll-padding: 92px;
}

.\!k-scroll-p-23 {
    scroll-padding: 92px !important;
}

.k-scroll-p-24 {
    scroll-padding: 96px;
}

.\!k-scroll-p-24 {
    scroll-padding: 96px !important;
}

.k-scroll-pt-0 {
    scroll-padding-top: 0;
}

.\!k-scroll-pt-0 {
    scroll-padding-top: 0 !important;
}

.k-scroll-pt-1px {
    scroll-padding-top: 1px;
}

.\!k-scroll-pt-1px {
    scroll-padding-top: 1px !important;
}

.k-scroll-pt-0\.5 {
    scroll-padding-top: 2px;
}

.\!k-scroll-pt-0\.5 {
    scroll-padding-top: 2px !important;
}

.k-scroll-pt-1 {
    scroll-padding-top: 4px;
}

.\!k-scroll-pt-1 {
    scroll-padding-top: 4px !important;
}

.k-scroll-pt-1\.5 {
    scroll-padding-top: 6px;
}

.\!k-scroll-pt-1\.5 {
    scroll-padding-top: 6px !important;
}

.k-scroll-pt-2 {
    scroll-padding-top: 8px;
}

.\!k-scroll-pt-2 {
    scroll-padding-top: 8px !important;
}

.k-scroll-pt-2\.5 {
    scroll-padding-top: 10px;
}

.\!k-scroll-pt-2\.5 {
    scroll-padding-top: 10px !important;
}

.k-scroll-pt-3 {
    scroll-padding-top: 12px;
}

.\!k-scroll-pt-3 {
    scroll-padding-top: 12px !important;
}

.k-scroll-pt-3\.5 {
    scroll-padding-top: 14px;
}

.\!k-scroll-pt-3\.5 {
    scroll-padding-top: 14px !important;
}

.k-scroll-pt-4 {
    scroll-padding-top: 16px;
}

.\!k-scroll-pt-4 {
    scroll-padding-top: 16px !important;
}

.k-scroll-pt-4\.5 {
    scroll-padding-top: 18px;
}

.\!k-scroll-pt-4\.5 {
    scroll-padding-top: 18px !important;
}

.k-scroll-pt-5 {
    scroll-padding-top: 20px;
}

.\!k-scroll-pt-5 {
    scroll-padding-top: 20px !important;
}

.k-scroll-pt-5\.5 {
    scroll-padding-top: 22px;
}

.\!k-scroll-pt-5\.5 {
    scroll-padding-top: 22px !important;
}

.k-scroll-pt-6 {
    scroll-padding-top: 24px;
}

.\!k-scroll-pt-6 {
    scroll-padding-top: 24px !important;
}

.k-scroll-pt-6\.5 {
    scroll-padding-top: 26px;
}

.\!k-scroll-pt-6\.5 {
    scroll-padding-top: 26px !important;
}

.k-scroll-pt-7 {
    scroll-padding-top: 28px;
}

.\!k-scroll-pt-7 {
    scroll-padding-top: 28px !important;
}

.k-scroll-pt-7\.5 {
    scroll-padding-top: 30px;
}

.\!k-scroll-pt-7\.5 {
    scroll-padding-top: 30px !important;
}

.k-scroll-pt-8 {
    scroll-padding-top: 32px;
}

.\!k-scroll-pt-8 {
    scroll-padding-top: 32px !important;
}

.k-scroll-pt-9 {
    scroll-padding-top: 36px;
}

.\!k-scroll-pt-9 {
    scroll-padding-top: 36px !important;
}

.k-scroll-pt-10 {
    scroll-padding-top: 40px;
}

.\!k-scroll-pt-10 {
    scroll-padding-top: 40px !important;
}

.k-scroll-pt-11 {
    scroll-padding-top: 44px;
}

.\!k-scroll-pt-11 {
    scroll-padding-top: 44px !important;
}

.k-scroll-pt-12 {
    scroll-padding-top: 48px;
}

.\!k-scroll-pt-12 {
    scroll-padding-top: 48px !important;
}

.k-scroll-pt-13 {
    scroll-padding-top: 52px;
}

.\!k-scroll-pt-13 {
    scroll-padding-top: 52px !important;
}

.k-scroll-pt-14 {
    scroll-padding-top: 56px;
}

.\!k-scroll-pt-14 {
    scroll-padding-top: 56px !important;
}

.k-scroll-pt-15 {
    scroll-padding-top: 60px;
}

.\!k-scroll-pt-15 {
    scroll-padding-top: 60px !important;
}

.k-scroll-pt-16 {
    scroll-padding-top: 64px;
}

.\!k-scroll-pt-16 {
    scroll-padding-top: 64px !important;
}

.k-scroll-pt-17 {
    scroll-padding-top: 68px;
}

.\!k-scroll-pt-17 {
    scroll-padding-top: 68px !important;
}

.k-scroll-pt-18 {
    scroll-padding-top: 72px;
}

.\!k-scroll-pt-18 {
    scroll-padding-top: 72px !important;
}

.k-scroll-pt-19 {
    scroll-padding-top: 76px;
}

.\!k-scroll-pt-19 {
    scroll-padding-top: 76px !important;
}

.k-scroll-pt-20 {
    scroll-padding-top: 80px;
}

.\!k-scroll-pt-20 {
    scroll-padding-top: 80px !important;
}

.k-scroll-pt-21 {
    scroll-padding-top: 84px;
}

.\!k-scroll-pt-21 {
    scroll-padding-top: 84px !important;
}

.k-scroll-pt-22 {
    scroll-padding-top: 88px;
}

.\!k-scroll-pt-22 {
    scroll-padding-top: 88px !important;
}

.k-scroll-pt-23 {
    scroll-padding-top: 92px;
}

.\!k-scroll-pt-23 {
    scroll-padding-top: 92px !important;
}

.k-scroll-pt-24 {
    scroll-padding-top: 96px;
}

.\!k-scroll-pt-24 {
    scroll-padding-top: 96px !important;
}

.k-scroll-pr-0 {
    scroll-padding-right: 0;
}

.\!k-scroll-pr-0 {
    scroll-padding-right: 0 !important;
}

.k-scroll-pr-1px {
    scroll-padding-right: 1px;
}

.\!k-scroll-pr-1px {
    scroll-padding-right: 1px !important;
}

.k-scroll-pr-0\.5 {
    scroll-padding-right: 2px;
}

.\!k-scroll-pr-0\.5 {
    scroll-padding-right: 2px !important;
}

.k-scroll-pr-1 {
    scroll-padding-right: 4px;
}

.\!k-scroll-pr-1 {
    scroll-padding-right: 4px !important;
}

.k-scroll-pr-1\.5 {
    scroll-padding-right: 6px;
}

.\!k-scroll-pr-1\.5 {
    scroll-padding-right: 6px !important;
}

.k-scroll-pr-2 {
    scroll-padding-right: 8px;
}

.\!k-scroll-pr-2 {
    scroll-padding-right: 8px !important;
}

.k-scroll-pr-2\.5 {
    scroll-padding-right: 10px;
}

.\!k-scroll-pr-2\.5 {
    scroll-padding-right: 10px !important;
}

.k-scroll-pr-3 {
    scroll-padding-right: 12px;
}

.\!k-scroll-pr-3 {
    scroll-padding-right: 12px !important;
}

.k-scroll-pr-3\.5 {
    scroll-padding-right: 14px;
}

.\!k-scroll-pr-3\.5 {
    scroll-padding-right: 14px !important;
}

.k-scroll-pr-4 {
    scroll-padding-right: 16px;
}

.\!k-scroll-pr-4 {
    scroll-padding-right: 16px !important;
}

.k-scroll-pr-4\.5 {
    scroll-padding-right: 18px;
}

.\!k-scroll-pr-4\.5 {
    scroll-padding-right: 18px !important;
}

.k-scroll-pr-5 {
    scroll-padding-right: 20px;
}

.\!k-scroll-pr-5 {
    scroll-padding-right: 20px !important;
}

.k-scroll-pr-5\.5 {
    scroll-padding-right: 22px;
}

.\!k-scroll-pr-5\.5 {
    scroll-padding-right: 22px !important;
}

.k-scroll-pr-6 {
    scroll-padding-right: 24px;
}

.\!k-scroll-pr-6 {
    scroll-padding-right: 24px !important;
}

.k-scroll-pr-6\.5 {
    scroll-padding-right: 26px;
}

.\!k-scroll-pr-6\.5 {
    scroll-padding-right: 26px !important;
}

.k-scroll-pr-7 {
    scroll-padding-right: 28px;
}

.\!k-scroll-pr-7 {
    scroll-padding-right: 28px !important;
}

.k-scroll-pr-7\.5 {
    scroll-padding-right: 30px;
}

.\!k-scroll-pr-7\.5 {
    scroll-padding-right: 30px !important;
}

.k-scroll-pr-8 {
    scroll-padding-right: 32px;
}

.\!k-scroll-pr-8 {
    scroll-padding-right: 32px !important;
}

.k-scroll-pr-9 {
    scroll-padding-right: 36px;
}

.\!k-scroll-pr-9 {
    scroll-padding-right: 36px !important;
}

.k-scroll-pr-10 {
    scroll-padding-right: 40px;
}

.\!k-scroll-pr-10 {
    scroll-padding-right: 40px !important;
}

.k-scroll-pr-11 {
    scroll-padding-right: 44px;
}

.\!k-scroll-pr-11 {
    scroll-padding-right: 44px !important;
}

.k-scroll-pr-12 {
    scroll-padding-right: 48px;
}

.\!k-scroll-pr-12 {
    scroll-padding-right: 48px !important;
}

.k-scroll-pr-13 {
    scroll-padding-right: 52px;
}

.\!k-scroll-pr-13 {
    scroll-padding-right: 52px !important;
}

.k-scroll-pr-14 {
    scroll-padding-right: 56px;
}

.\!k-scroll-pr-14 {
    scroll-padding-right: 56px !important;
}

.k-scroll-pr-15 {
    scroll-padding-right: 60px;
}

.\!k-scroll-pr-15 {
    scroll-padding-right: 60px !important;
}

.k-scroll-pr-16 {
    scroll-padding-right: 64px;
}

.\!k-scroll-pr-16 {
    scroll-padding-right: 64px !important;
}

.k-scroll-pr-17 {
    scroll-padding-right: 68px;
}

.\!k-scroll-pr-17 {
    scroll-padding-right: 68px !important;
}

.k-scroll-pr-18 {
    scroll-padding-right: 72px;
}

.\!k-scroll-pr-18 {
    scroll-padding-right: 72px !important;
}

.k-scroll-pr-19 {
    scroll-padding-right: 76px;
}

.\!k-scroll-pr-19 {
    scroll-padding-right: 76px !important;
}

.k-scroll-pr-20 {
    scroll-padding-right: 80px;
}

.\!k-scroll-pr-20 {
    scroll-padding-right: 80px !important;
}

.k-scroll-pr-21 {
    scroll-padding-right: 84px;
}

.\!k-scroll-pr-21 {
    scroll-padding-right: 84px !important;
}

.k-scroll-pr-22 {
    scroll-padding-right: 88px;
}

.\!k-scroll-pr-22 {
    scroll-padding-right: 88px !important;
}

.k-scroll-pr-23 {
    scroll-padding-right: 92px;
}

.\!k-scroll-pr-23 {
    scroll-padding-right: 92px !important;
}

.k-scroll-pr-24 {
    scroll-padding-right: 96px;
}

.\!k-scroll-pr-24 {
    scroll-padding-right: 96px !important;
}

.k-scroll-pb-0 {
    scroll-padding-bottom: 0;
}

.\!k-scroll-pb-0 {
    scroll-padding-bottom: 0 !important;
}

.k-scroll-pb-1px {
    scroll-padding-bottom: 1px;
}

.\!k-scroll-pb-1px {
    scroll-padding-bottom: 1px !important;
}

.k-scroll-pb-0\.5 {
    scroll-padding-bottom: 2px;
}

.\!k-scroll-pb-0\.5 {
    scroll-padding-bottom: 2px !important;
}

.k-scroll-pb-1 {
    scroll-padding-bottom: 4px;
}

.\!k-scroll-pb-1 {
    scroll-padding-bottom: 4px !important;
}

.k-scroll-pb-1\.5 {
    scroll-padding-bottom: 6px;
}

.\!k-scroll-pb-1\.5 {
    scroll-padding-bottom: 6px !important;
}

.k-scroll-pb-2 {
    scroll-padding-bottom: 8px;
}

.\!k-scroll-pb-2 {
    scroll-padding-bottom: 8px !important;
}

.k-scroll-pb-2\.5 {
    scroll-padding-bottom: 10px;
}

.\!k-scroll-pb-2\.5 {
    scroll-padding-bottom: 10px !important;
}

.k-scroll-pb-3 {
    scroll-padding-bottom: 12px;
}

.\!k-scroll-pb-3 {
    scroll-padding-bottom: 12px !important;
}

.k-scroll-pb-3\.5 {
    scroll-padding-bottom: 14px;
}

.\!k-scroll-pb-3\.5 {
    scroll-padding-bottom: 14px !important;
}

.k-scroll-pb-4 {
    scroll-padding-bottom: 16px;
}

.\!k-scroll-pb-4 {
    scroll-padding-bottom: 16px !important;
}

.k-scroll-pb-4\.5 {
    scroll-padding-bottom: 18px;
}

.\!k-scroll-pb-4\.5 {
    scroll-padding-bottom: 18px !important;
}

.k-scroll-pb-5 {
    scroll-padding-bottom: 20px;
}

.\!k-scroll-pb-5 {
    scroll-padding-bottom: 20px !important;
}

.k-scroll-pb-5\.5 {
    scroll-padding-bottom: 22px;
}

.\!k-scroll-pb-5\.5 {
    scroll-padding-bottom: 22px !important;
}

.k-scroll-pb-6 {
    scroll-padding-bottom: 24px;
}

.\!k-scroll-pb-6 {
    scroll-padding-bottom: 24px !important;
}

.k-scroll-pb-6\.5 {
    scroll-padding-bottom: 26px;
}

.\!k-scroll-pb-6\.5 {
    scroll-padding-bottom: 26px !important;
}

.k-scroll-pb-7 {
    scroll-padding-bottom: 28px;
}

.\!k-scroll-pb-7 {
    scroll-padding-bottom: 28px !important;
}

.k-scroll-pb-7\.5 {
    scroll-padding-bottom: 30px;
}

.\!k-scroll-pb-7\.5 {
    scroll-padding-bottom: 30px !important;
}

.k-scroll-pb-8 {
    scroll-padding-bottom: 32px;
}

.\!k-scroll-pb-8 {
    scroll-padding-bottom: 32px !important;
}

.k-scroll-pb-9 {
    scroll-padding-bottom: 36px;
}

.\!k-scroll-pb-9 {
    scroll-padding-bottom: 36px !important;
}

.k-scroll-pb-10 {
    scroll-padding-bottom: 40px;
}

.\!k-scroll-pb-10 {
    scroll-padding-bottom: 40px !important;
}

.k-scroll-pb-11 {
    scroll-padding-bottom: 44px;
}

.\!k-scroll-pb-11 {
    scroll-padding-bottom: 44px !important;
}

.k-scroll-pb-12 {
    scroll-padding-bottom: 48px;
}

.\!k-scroll-pb-12 {
    scroll-padding-bottom: 48px !important;
}

.k-scroll-pb-13 {
    scroll-padding-bottom: 52px;
}

.\!k-scroll-pb-13 {
    scroll-padding-bottom: 52px !important;
}

.k-scroll-pb-14 {
    scroll-padding-bottom: 56px;
}

.\!k-scroll-pb-14 {
    scroll-padding-bottom: 56px !important;
}

.k-scroll-pb-15 {
    scroll-padding-bottom: 60px;
}

.\!k-scroll-pb-15 {
    scroll-padding-bottom: 60px !important;
}

.k-scroll-pb-16 {
    scroll-padding-bottom: 64px;
}

.\!k-scroll-pb-16 {
    scroll-padding-bottom: 64px !important;
}

.k-scroll-pb-17 {
    scroll-padding-bottom: 68px;
}

.\!k-scroll-pb-17 {
    scroll-padding-bottom: 68px !important;
}

.k-scroll-pb-18 {
    scroll-padding-bottom: 72px;
}

.\!k-scroll-pb-18 {
    scroll-padding-bottom: 72px !important;
}

.k-scroll-pb-19 {
    scroll-padding-bottom: 76px;
}

.\!k-scroll-pb-19 {
    scroll-padding-bottom: 76px !important;
}

.k-scroll-pb-20 {
    scroll-padding-bottom: 80px;
}

.\!k-scroll-pb-20 {
    scroll-padding-bottom: 80px !important;
}

.k-scroll-pb-21 {
    scroll-padding-bottom: 84px;
}

.\!k-scroll-pb-21 {
    scroll-padding-bottom: 84px !important;
}

.k-scroll-pb-22 {
    scroll-padding-bottom: 88px;
}

.\!k-scroll-pb-22 {
    scroll-padding-bottom: 88px !important;
}

.k-scroll-pb-23 {
    scroll-padding-bottom: 92px;
}

.\!k-scroll-pb-23 {
    scroll-padding-bottom: 92px !important;
}

.k-scroll-pb-24 {
    scroll-padding-bottom: 96px;
}

.\!k-scroll-pb-24 {
    scroll-padding-bottom: 96px !important;
}

.k-scroll-pl-0 {
    scroll-padding-left: 0;
}

.\!k-scroll-pl-0 {
    scroll-padding-left: 0 !important;
}

.k-scroll-pl-1px {
    scroll-padding-left: 1px;
}

.\!k-scroll-pl-1px {
    scroll-padding-left: 1px !important;
}

.k-scroll-pl-0\.5 {
    scroll-padding-left: 2px;
}

.\!k-scroll-pl-0\.5 {
    scroll-padding-left: 2px !important;
}

.k-scroll-pl-1 {
    scroll-padding-left: 4px;
}

.\!k-scroll-pl-1 {
    scroll-padding-left: 4px !important;
}

.k-scroll-pl-1\.5 {
    scroll-padding-left: 6px;
}

.\!k-scroll-pl-1\.5 {
    scroll-padding-left: 6px !important;
}

.k-scroll-pl-2 {
    scroll-padding-left: 8px;
}

.\!k-scroll-pl-2 {
    scroll-padding-left: 8px !important;
}

.k-scroll-pl-2\.5 {
    scroll-padding-left: 10px;
}

.\!k-scroll-pl-2\.5 {
    scroll-padding-left: 10px !important;
}

.k-scroll-pl-3 {
    scroll-padding-left: 12px;
}

.\!k-scroll-pl-3 {
    scroll-padding-left: 12px !important;
}

.k-scroll-pl-3\.5 {
    scroll-padding-left: 14px;
}

.\!k-scroll-pl-3\.5 {
    scroll-padding-left: 14px !important;
}

.k-scroll-pl-4 {
    scroll-padding-left: 16px;
}

.\!k-scroll-pl-4 {
    scroll-padding-left: 16px !important;
}

.k-scroll-pl-4\.5 {
    scroll-padding-left: 18px;
}

.\!k-scroll-pl-4\.5 {
    scroll-padding-left: 18px !important;
}

.k-scroll-pl-5 {
    scroll-padding-left: 20px;
}

.\!k-scroll-pl-5 {
    scroll-padding-left: 20px !important;
}

.k-scroll-pl-5\.5 {
    scroll-padding-left: 22px;
}

.\!k-scroll-pl-5\.5 {
    scroll-padding-left: 22px !important;
}

.k-scroll-pl-6 {
    scroll-padding-left: 24px;
}

.\!k-scroll-pl-6 {
    scroll-padding-left: 24px !important;
}

.k-scroll-pl-6\.5 {
    scroll-padding-left: 26px;
}

.\!k-scroll-pl-6\.5 {
    scroll-padding-left: 26px !important;
}

.k-scroll-pl-7 {
    scroll-padding-left: 28px;
}

.\!k-scroll-pl-7 {
    scroll-padding-left: 28px !important;
}

.k-scroll-pl-7\.5 {
    scroll-padding-left: 30px;
}

.\!k-scroll-pl-7\.5 {
    scroll-padding-left: 30px !important;
}

.k-scroll-pl-8 {
    scroll-padding-left: 32px;
}

.\!k-scroll-pl-8 {
    scroll-padding-left: 32px !important;
}

.k-scroll-pl-9 {
    scroll-padding-left: 36px;
}

.\!k-scroll-pl-9 {
    scroll-padding-left: 36px !important;
}

.k-scroll-pl-10 {
    scroll-padding-left: 40px;
}

.\!k-scroll-pl-10 {
    scroll-padding-left: 40px !important;
}

.k-scroll-pl-11 {
    scroll-padding-left: 44px;
}

.\!k-scroll-pl-11 {
    scroll-padding-left: 44px !important;
}

.k-scroll-pl-12 {
    scroll-padding-left: 48px;
}

.\!k-scroll-pl-12 {
    scroll-padding-left: 48px !important;
}

.k-scroll-pl-13 {
    scroll-padding-left: 52px;
}

.\!k-scroll-pl-13 {
    scroll-padding-left: 52px !important;
}

.k-scroll-pl-14 {
    scroll-padding-left: 56px;
}

.\!k-scroll-pl-14 {
    scroll-padding-left: 56px !important;
}

.k-scroll-pl-15 {
    scroll-padding-left: 60px;
}

.\!k-scroll-pl-15 {
    scroll-padding-left: 60px !important;
}

.k-scroll-pl-16 {
    scroll-padding-left: 64px;
}

.\!k-scroll-pl-16 {
    scroll-padding-left: 64px !important;
}

.k-scroll-pl-17 {
    scroll-padding-left: 68px;
}

.\!k-scroll-pl-17 {
    scroll-padding-left: 68px !important;
}

.k-scroll-pl-18 {
    scroll-padding-left: 72px;
}

.\!k-scroll-pl-18 {
    scroll-padding-left: 72px !important;
}

.k-scroll-pl-19 {
    scroll-padding-left: 76px;
}

.\!k-scroll-pl-19 {
    scroll-padding-left: 76px !important;
}

.k-scroll-pl-20 {
    scroll-padding-left: 80px;
}

.\!k-scroll-pl-20 {
    scroll-padding-left: 80px !important;
}

.k-scroll-pl-21 {
    scroll-padding-left: 84px;
}

.\!k-scroll-pl-21 {
    scroll-padding-left: 84px !important;
}

.k-scroll-pl-22 {
    scroll-padding-left: 88px;
}

.\!k-scroll-pl-22 {
    scroll-padding-left: 88px !important;
}

.k-scroll-pl-23 {
    scroll-padding-left: 92px;
}

.\!k-scroll-pl-23 {
    scroll-padding-left: 92px !important;
}

.k-scroll-pl-24 {
    scroll-padding-left: 96px;
}

.\!k-scroll-pl-24 {
    scroll-padding-left: 96px !important;
}

.k-scroll-px-0 {
    scroll-padding-inline: 0;
}

.\!k-scroll-px-0 {
    scroll-padding-inline: 0 !important;
}

.k-scroll-px-1px {
    scroll-padding-inline: 1px;
}

.\!k-scroll-px-1px {
    scroll-padding-inline: 1px !important;
}

.k-scroll-px-0\.5 {
    scroll-padding-inline: 2px;
}

.\!k-scroll-px-0\.5 {
    scroll-padding-inline: 2px !important;
}

.k-scroll-px-1 {
    scroll-padding-inline: 4px;
}

.\!k-scroll-px-1 {
    scroll-padding-inline: 4px !important;
}

.k-scroll-px-1\.5 {
    scroll-padding-inline: 6px;
}

.\!k-scroll-px-1\.5 {
    scroll-padding-inline: 6px !important;
}

.k-scroll-px-2 {
    scroll-padding-inline: 8px;
}

.\!k-scroll-px-2 {
    scroll-padding-inline: 8px !important;
}

.k-scroll-px-2\.5 {
    scroll-padding-inline: 10px;
}

.\!k-scroll-px-2\.5 {
    scroll-padding-inline: 10px !important;
}

.k-scroll-px-3 {
    scroll-padding-inline: 12px;
}

.\!k-scroll-px-3 {
    scroll-padding-inline: 12px !important;
}

.k-scroll-px-3\.5 {
    scroll-padding-inline: 14px;
}

.\!k-scroll-px-3\.5 {
    scroll-padding-inline: 14px !important;
}

.k-scroll-px-4 {
    scroll-padding-inline: 16px;
}

.\!k-scroll-px-4 {
    scroll-padding-inline: 16px !important;
}

.k-scroll-px-4\.5 {
    scroll-padding-inline: 18px;
}

.\!k-scroll-px-4\.5 {
    scroll-padding-inline: 18px !important;
}

.k-scroll-px-5 {
    scroll-padding-inline: 20px;
}

.\!k-scroll-px-5 {
    scroll-padding-inline: 20px !important;
}

.k-scroll-px-5\.5 {
    scroll-padding-inline: 22px;
}

.\!k-scroll-px-5\.5 {
    scroll-padding-inline: 22px !important;
}

.k-scroll-px-6 {
    scroll-padding-inline: 24px;
}

.\!k-scroll-px-6 {
    scroll-padding-inline: 24px !important;
}

.k-scroll-px-6\.5 {
    scroll-padding-inline: 26px;
}

.\!k-scroll-px-6\.5 {
    scroll-padding-inline: 26px !important;
}

.k-scroll-px-7 {
    scroll-padding-inline: 28px;
}

.\!k-scroll-px-7 {
    scroll-padding-inline: 28px !important;
}

.k-scroll-px-7\.5 {
    scroll-padding-inline: 30px;
}

.\!k-scroll-px-7\.5 {
    scroll-padding-inline: 30px !important;
}

.k-scroll-px-8 {
    scroll-padding-inline: 32px;
}

.\!k-scroll-px-8 {
    scroll-padding-inline: 32px !important;
}

.k-scroll-px-9 {
    scroll-padding-inline: 36px;
}

.\!k-scroll-px-9 {
    scroll-padding-inline: 36px !important;
}

.k-scroll-px-10 {
    scroll-padding-inline: 40px;
}

.\!k-scroll-px-10 {
    scroll-padding-inline: 40px !important;
}

.k-scroll-px-11 {
    scroll-padding-inline: 44px;
}

.\!k-scroll-px-11 {
    scroll-padding-inline: 44px !important;
}

.k-scroll-px-12 {
    scroll-padding-inline: 48px;
}

.\!k-scroll-px-12 {
    scroll-padding-inline: 48px !important;
}

.k-scroll-px-13 {
    scroll-padding-inline: 52px;
}

.\!k-scroll-px-13 {
    scroll-padding-inline: 52px !important;
}

.k-scroll-px-14 {
    scroll-padding-inline: 56px;
}

.\!k-scroll-px-14 {
    scroll-padding-inline: 56px !important;
}

.k-scroll-px-15 {
    scroll-padding-inline: 60px;
}

.\!k-scroll-px-15 {
    scroll-padding-inline: 60px !important;
}

.k-scroll-px-16 {
    scroll-padding-inline: 64px;
}

.\!k-scroll-px-16 {
    scroll-padding-inline: 64px !important;
}

.k-scroll-px-17 {
    scroll-padding-inline: 68px;
}

.\!k-scroll-px-17 {
    scroll-padding-inline: 68px !important;
}

.k-scroll-px-18 {
    scroll-padding-inline: 72px;
}

.\!k-scroll-px-18 {
    scroll-padding-inline: 72px !important;
}

.k-scroll-px-19 {
    scroll-padding-inline: 76px;
}

.\!k-scroll-px-19 {
    scroll-padding-inline: 76px !important;
}

.k-scroll-px-20 {
    scroll-padding-inline: 80px;
}

.\!k-scroll-px-20 {
    scroll-padding-inline: 80px !important;
}

.k-scroll-px-21 {
    scroll-padding-inline: 84px;
}

.\!k-scroll-px-21 {
    scroll-padding-inline: 84px !important;
}

.k-scroll-px-22 {
    scroll-padding-inline: 88px;
}

.\!k-scroll-px-22 {
    scroll-padding-inline: 88px !important;
}

.k-scroll-px-23 {
    scroll-padding-inline: 92px;
}

.\!k-scroll-px-23 {
    scroll-padding-inline: 92px !important;
}

.k-scroll-px-24 {
    scroll-padding-inline: 96px;
}

.\!k-scroll-px-24 {
    scroll-padding-inline: 96px !important;
}

.k-scroll-py-0 {
    scroll-padding-block: 0;
}

.\!k-scroll-py-0 {
    scroll-padding-block: 0 !important;
}

.k-scroll-py-1px {
    scroll-padding-block: 1px;
}

.\!k-scroll-py-1px {
    scroll-padding-block: 1px !important;
}

.k-scroll-py-0\.5 {
    scroll-padding-block: 2px;
}

.\!k-scroll-py-0\.5 {
    scroll-padding-block: 2px !important;
}

.k-scroll-py-1 {
    scroll-padding-block: 4px;
}

.\!k-scroll-py-1 {
    scroll-padding-block: 4px !important;
}

.k-scroll-py-1\.5 {
    scroll-padding-block: 6px;
}

.\!k-scroll-py-1\.5 {
    scroll-padding-block: 6px !important;
}

.k-scroll-py-2 {
    scroll-padding-block: 8px;
}

.\!k-scroll-py-2 {
    scroll-padding-block: 8px !important;
}

.k-scroll-py-2\.5 {
    scroll-padding-block: 10px;
}

.\!k-scroll-py-2\.5 {
    scroll-padding-block: 10px !important;
}

.k-scroll-py-3 {
    scroll-padding-block: 12px;
}

.\!k-scroll-py-3 {
    scroll-padding-block: 12px !important;
}

.k-scroll-py-3\.5 {
    scroll-padding-block: 14px;
}

.\!k-scroll-py-3\.5 {
    scroll-padding-block: 14px !important;
}

.k-scroll-py-4 {
    scroll-padding-block: 16px;
}

.\!k-scroll-py-4 {
    scroll-padding-block: 16px !important;
}

.k-scroll-py-4\.5 {
    scroll-padding-block: 18px;
}

.\!k-scroll-py-4\.5 {
    scroll-padding-block: 18px !important;
}

.k-scroll-py-5 {
    scroll-padding-block: 20px;
}

.\!k-scroll-py-5 {
    scroll-padding-block: 20px !important;
}

.k-scroll-py-5\.5 {
    scroll-padding-block: 22px;
}

.\!k-scroll-py-5\.5 {
    scroll-padding-block: 22px !important;
}

.k-scroll-py-6 {
    scroll-padding-block: 24px;
}

.\!k-scroll-py-6 {
    scroll-padding-block: 24px !important;
}

.k-scroll-py-6\.5 {
    scroll-padding-block: 26px;
}

.\!k-scroll-py-6\.5 {
    scroll-padding-block: 26px !important;
}

.k-scroll-py-7 {
    scroll-padding-block: 28px;
}

.\!k-scroll-py-7 {
    scroll-padding-block: 28px !important;
}

.k-scroll-py-7\.5 {
    scroll-padding-block: 30px;
}

.\!k-scroll-py-7\.5 {
    scroll-padding-block: 30px !important;
}

.k-scroll-py-8 {
    scroll-padding-block: 32px;
}

.\!k-scroll-py-8 {
    scroll-padding-block: 32px !important;
}

.k-scroll-py-9 {
    scroll-padding-block: 36px;
}

.\!k-scroll-py-9 {
    scroll-padding-block: 36px !important;
}

.k-scroll-py-10 {
    scroll-padding-block: 40px;
}

.\!k-scroll-py-10 {
    scroll-padding-block: 40px !important;
}

.k-scroll-py-11 {
    scroll-padding-block: 44px;
}

.\!k-scroll-py-11 {
    scroll-padding-block: 44px !important;
}

.k-scroll-py-12 {
    scroll-padding-block: 48px;
}

.\!k-scroll-py-12 {
    scroll-padding-block: 48px !important;
}

.k-scroll-py-13 {
    scroll-padding-block: 52px;
}

.\!k-scroll-py-13 {
    scroll-padding-block: 52px !important;
}

.k-scroll-py-14 {
    scroll-padding-block: 56px;
}

.\!k-scroll-py-14 {
    scroll-padding-block: 56px !important;
}

.k-scroll-py-15 {
    scroll-padding-block: 60px;
}

.\!k-scroll-py-15 {
    scroll-padding-block: 60px !important;
}

.k-scroll-py-16 {
    scroll-padding-block: 64px;
}

.\!k-scroll-py-16 {
    scroll-padding-block: 64px !important;
}

.k-scroll-py-17 {
    scroll-padding-block: 68px;
}

.\!k-scroll-py-17 {
    scroll-padding-block: 68px !important;
}

.k-scroll-py-18 {
    scroll-padding-block: 72px;
}

.\!k-scroll-py-18 {
    scroll-padding-block: 72px !important;
}

.k-scroll-py-19 {
    scroll-padding-block: 76px;
}

.\!k-scroll-py-19 {
    scroll-padding-block: 76px !important;
}

.k-scroll-py-20 {
    scroll-padding-block: 80px;
}

.\!k-scroll-py-20 {
    scroll-padding-block: 80px !important;
}

.k-scroll-py-21 {
    scroll-padding-block: 84px;
}

.\!k-scroll-py-21 {
    scroll-padding-block: 84px !important;
}

.k-scroll-py-22 {
    scroll-padding-block: 88px;
}

.\!k-scroll-py-22 {
    scroll-padding-block: 88px !important;
}

.k-scroll-py-23 {
    scroll-padding-block: 92px;
}

.\!k-scroll-py-23 {
    scroll-padding-block: 92px !important;
}

.k-scroll-py-24 {
    scroll-padding-block: 96px;
}

.\!k-scroll-py-24 {
    scroll-padding-block: 96px !important;
}

.k-touch-action-none {
    touch-action: none;
}

.\!k-touch-action-none {
    touch-action: none !important;
}

.k-touch-action-auto {
    touch-action: auto;
}

.\!k-touch-action-auto {
    touch-action: auto !important;
}

.k-touch-action-pan-x {
    touch-action: pan-x;
}

.\!k-touch-action-pan-x {
    touch-action: pan-x !important;
}

.k-touch-action-pan-left {
    touch-action: pan-left;
}

.\!k-touch-action-pan-left {
    touch-action: pan-left !important;
}

.k-touch-action-pan-right {
    touch-action: pan-right;
}

.\!k-touch-action-pan-right {
    touch-action: pan-right !important;
}

.k-touch-action-pan-y {
    touch-action: pan-y;
}

.\!k-touch-action-pan-y {
    touch-action: pan-y !important;
}

.k-touch-action-pan-up {
    touch-action: pan-up;
}

.\!k-touch-action-pan-up {
    touch-action: pan-up !important;
}

.k-touch-action-pan-down {
    touch-action: pan-down;
}

.\!k-touch-action-pan-down {
    touch-action: pan-down !important;
}

.k-touch-action-pinch-zoom {
    touch-action: pinch-zoom;
}

.\!k-touch-action-pinch-zoom {
    touch-action: pinch-zoom !important;
}

.k-touch-action-manipulation {
    touch-action: manipulation;
}

.\!k-touch-action-manipulation {
    touch-action: manipulation !important;
}

.k-user-select-none {
    user-select: none;
}

.\!k-user-select-none {
    user-select: none !important;
}

.k-user-select-auto {
    user-select: auto;
}

.\!k-user-select-auto {
    user-select: auto !important;
}

.k-user-select-text {
    user-select: text;
}

.\!k-user-select-text {
    user-select: text !important;
}

.k-user-select-all {
    user-select: all;
}

.\!k-user-select-all {
    user-select: all !important;
}

.k-user-select-contain {
    user-select: contain;
}

.\!k-user-select-contain {
    user-select: contain !important;
}

.k-will-change-auto {
    will-change: auto;
}

.\!k-will-change-auto {
    will-change: auto !important;
}

.k-will-change-scroll {
    will-change: scroll-position;
}

.\!k-will-change-scroll {
    will-change: scroll-position !important;
}

.k-will-change-contents {
    will-change: contents;
}

.\!k-will-change-contents {
    will-change: contents !important;
}

.k-will-change-transform {
    will-change: transform;
}

.\!k-will-change-transform {
    will-change: transform !important;
}

.k-fill-none {
    fill: none;
}

.\!k-fill-none {
    fill: none !important;
}

.k-fill-inherit {
    fill: inherit;
}

.\!k-fill-inherit {
    fill: inherit !important;
}

.k-fill-current {
    fill: currentColor;
}

.\!k-fill-current {
    fill: currentColor !important;
}

.k-fill-transparent {
    fill: transparent;
}

.\!k-fill-transparent {
    fill: transparent !important;
}

.k-fill-black {
    fill: black;
}

.\!k-fill-black {
    fill: black !important;
}

.k-fill-white {
    fill: white;
}

.\!k-fill-white {
    fill: white !important;
}

.k-stroke-none {
    stroke: none;
}

.\!k-stroke-none {
    stroke: none !important;
}

.k-stroke-inherit {
    stroke: inherit;
}

.\!k-stroke-inherit {
    stroke: inherit !important;
}

.k-stroke-current {
    stroke: currentColor;
}

.\!k-stroke-current {
    stroke: currentColor !important;
}

.k-stroke-transparent {
    stroke: transparent;
}

.\!k-stroke-transparent {
    stroke: transparent !important;
}

.k-stroke-black {
    stroke: black;
}

.\!k-stroke-black {
    stroke: black !important;
}

.k-stroke-white {
    stroke: white;
}

.\!k-stroke-white {
    stroke: white !important;
}

.k-elevation-1 {
    box-shadow:
        0 2px 3px rgba(0, 0, 0, 0.04),
        0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-2 {
    box-shadow:
        0 4px 6px rgba(0, 0, 0, 0.06),
        0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-3 {
    box-shadow:
        0 6px 8px rgba(0, 0, 0, 0.08),
        0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-4 {
    box-shadow:
        0 8px 10px rgba(0, 0, 0, 0.12),
        0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-5 {
    box-shadow:
        0 10px 12px rgba(0, 0, 0, 0.16),
        0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-6 {
    box-shadow:
        0 12px 14px rgba(0, 0, 0, 0.2),
        0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-7 {
    box-shadow:
        0 14px 16px rgba(0, 0, 0, 0.24),
        0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-8 {
    box-shadow:
        0 16px 18px rgba(0, 0, 0, 0.28),
        0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-9 {
    box-shadow:
        0 32px 34px rgba(0, 0, 0, 0.32),
        0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-svg-icon {
    width: 16px;
    height: 16px;
    outline: 0;
    line-height: 1;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
}
.k-svg-icon > svg {
    fill: currentColor;
    flex: 1 1 auto;
}

.k-svg-icon.k-icon-xs {
    width: calc(16px * 0.75);
    height: calc(16px * 0.75);
}
.k-svg-icon.k-icon-sm {
    width: calc(16px * 0.875);
    height: calc(16px * 0.875);
}
.k-svg-icon.k-icon-md {
    width: 16px;
    height: 16px;
}
.k-svg-icon.k-icon-lg {
    width: calc(16px * 1.25);
    height: calc(16px * 1.25);
}
.k-svg-icon.k-icon-xl {
    width: calc(16px * 1.5);
    height: calc(16px * 1.5);
}
.k-svg-icon.k-icon-xxl {
    width: calc(16px * 2);
    height: calc(16px * 2);
}
.k-svg-icon.k-icon-xxxl {
    width: calc(16px * 3);
    height: calc(16px * 3);
}

.k-flip-h.k-svg-icon,
.k-svg-icon.k-flip-x,
.k-flip-v.k-svg-icon,
.k-svg-icon.k-flip-y,
.k-flip-h.k-flip-v.k-svg-icon {
    transform: none;
}

.k-flip-h > svg,
.k-flip-x > svg {
    transform: scaleX(-1);
}

.k-flip-v > svg,
.k-flip-y > svg {
    transform: scaleY(-1);
}

.k-flip-h.k-flip-v > svg,
.k-flip-v.k-flip-x > svg,
.k-flip-h.k-flip-y > svg,
.k-flip-x.k-flip-y > svg,
.k-flip-both > svg {
    transform: scale(-1, -1);
}

.k-rotate-0.k-svg-icon {
    transform: none;
}
.k-rotate-0 > svg {
    transform: rotate(0deg);
}

.k-rotate-45.k-svg-icon {
    transform: none;
}
.k-rotate-45 > svg {
    transform: rotate(45deg);
}

.k-rotate-90.k-svg-icon {
    transform: none;
}
.k-rotate-90 > svg {
    transform: rotate(90deg);
}

.k-rotate-135.k-svg-icon {
    transform: none;
}
.k-rotate-135 > svg {
    transform: rotate(135deg);
}

.k-rotate-180.k-svg-icon {
    transform: none;
}
.k-rotate-180 > svg {
    transform: rotate(180deg);
}

.k-rotate-225.k-svg-icon {
    transform: none;
}
.k-rotate-225 > svg {
    transform: rotate(225deg);
}

.k-rotate-270.k-svg-icon {
    transform: none;
}
.k-rotate-270 > svg {
    transform: rotate(270deg);
}

.k-rotate-315.k-svg-icon {
    transform: none;
}
.k-rotate-315 > svg {
    transform: rotate(315deg);
}

.k-icon-wrap {
    flex: none;
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 0;
    align-items: center;
    align-self: flex-start;
    vertical-align: middle;
    position: relative;
}
.k-icon-wrap::before {
    content: '​';
    width: 0;
    overflow: hidden;
    flex: none;
    display: inline-block;
    vertical-align: top;
}

.k-icon-wrapper-host {
    display: contents;
}

.k-icon-with-modifier {
    position: relative;
    margin: 0.25em;
}

.k-icon.k-icon-modifier {
    width: 1em;
    height: 1em;
    position: absolute;
    font-size: 0.5em;
    bottom: 0;
    right: 0;
    margin: 0 -0.5em -0.5em 0;
}

.k-i-none::before {
    content: '';
    display: none;
}

.k-icon-action {
    display: inline-flex;
    padding: 4px;
    line-height: 1;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
}

.k-sprite {
    display: inline-block;
    width: 16px;
    height: 16px;
    overflow: hidden;
    background-repeat: no-repeat;
    font-size: 0;
    line-height: 0;
    text-align: center;
}

.k-image {
    display: inline-block;
}

.k-badge {
    padding: 0;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
    color: inherit;
    background-color: transparent;
    font: inherit;
    text-align: center;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}
.k-badge *,
.k-badge *::before,
.k-badge *::after {
    box-sizing: border-box;
}

.k-badge > * {
    color: inherit;
}

a.k-badge,
.k-badge > a {
    text-decoration: none;
}

a.k-badge:hover,
.k-badge > a:hover {
    text-decoration: underline;
}

.k-badge-icon {
    max-width: 1em;
    max-height: 1em;
    font-size: inherit;
}

.k-badge-icon.k-svg-icon svg {
    fill: currentColor;
}

.k-badge-sm {
    padding-block: 2px;
    padding-inline: 2px;
    font-size: 10px;
    line-height: 1;
    min-width: calc(1em + 4px + 2px);
}
.k-badge-sm:empty {
    padding: 2px;
    min-width: auto;
}

.k-badge-md {
    padding-block: 4px;
    padding-inline: 4px;
    font-size: 10px;
    line-height: 1;
    min-width: calc(1em + 8px + 2px);
}
.k-badge-md:empty {
    padding: 4px;
    min-width: auto;
}

.k-badge-lg {
    padding-block: 6px;
    padding-inline: 6px;
    font-size: 10px;
    line-height: 1;
    min-width: calc(1em + 12px + 2px);
}
.k-badge-lg:empty {
    padding: 6px;
    min-width: auto;
}

kendo-badge-container {
    display: inline-block;
}

.k-badge-container {
    position: relative;
    overflow: visible;
}

.k-badge-inside {
    position: absolute;
    z-index: 9999;
}
.k-badge-edge {
    position: absolute;
    z-index: 9999;
}
.k-badge-edge.k-top-start {
    transform: translate(-50%, -50%);
}
.k-badge-edge.k-top-end {
    transform: translate(50%, -50%);
}
.k-badge-edge.k-bottom-start {
    transform: translate(-50%, 50%);
}
.k-badge-edge.k-bottom-end {
    transform: translate(50%, 50%);
}

.k-rtl .k-badge-edge.k-top-start,
[dir='rtl'].k-badge-edge.k-top-start,
[dir='rtl'] .k-badge-edge.k-top-start {
    transform: translate(50%, -50%);
}
.k-rtl .k-badge-edge.k-top-end,
[dir='rtl'].k-badge-edge.k-top-end,
[dir='rtl'] .k-badge-edge.k-top-end {
    transform: translate(-50%, -50%);
}
.k-rtl .k-badge-edge.k-bottom-start,
[dir='rtl'].k-badge-edge.k-bottom-start,
[dir='rtl'] .k-badge-edge.k-bottom-start {
    transform: translate(50%, 50%);
}
.k-rtl .k-badge-edge.k-bottom-end,
[dir='rtl'].k-badge-edge.k-bottom-end,
[dir='rtl'] .k-badge-edge.k-bottom-end {
    transform: translate(-50%, 50%);
}

.k-badge-outside {
    position: absolute;
    z-index: 9999;
}
.k-badge-outside.k-top-start {
    transform: translate(-100%, -100%);
}
.k-badge-outside.k-top-end {
    transform: translate(100%, -100%);
}
.k-badge-outside.k-bottom-start {
    transform: translate(-100%, 100%);
}
.k-badge-outside.k-bottom-end {
    transform: translate(100%, 100%);
}

.k-rtl .k-badge-outside.k-top-start,
[dir='rtl'].k-badge-outside.k-top-start,
[dir='rtl'] .k-badge-outside.k-top-start {
    transform: translate(100%, -100%);
}
.k-rtl .k-badge-outside.k-top-end,
[dir='rtl'].k-badge-outside.k-top-end,
[dir='rtl'] .k-badge-outside.k-top-end {
    transform: translate(-100%, -100%);
}
.k-rtl .k-badge-outside.k-bottom-start,
[dir='rtl'].k-badge-outside.k-bottom-start,
[dir='rtl'] .k-badge-outside.k-bottom-start {
    transform: translate(100%, 100%);
}
.k-rtl .k-badge-outside.k-bottom-end,
[dir='rtl'].k-badge-outside.k-bottom-end,
[dir='rtl'] .k-badge-outside.k-bottom-end {
    transform: translate(-100%, 100%);
}

.k-badge-border-cutout {
    box-shadow: 0 0 0 2px #ffffff;
}

.k-badge-solid-primary {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}

.k-badge-solid-secondary {
    border-color: #666666;
    color: white;
    background-color: #666666;
}

.k-badge-solid-tertiary {
    border-color: #03a9f4;
    color: white;
    background-color: #03a9f4;
}

.k-badge-solid-info {
    border-color: #0058e9;
    color: white;
    background-color: #0058e9;
}

.k-badge-solid-success {
    border-color: #37b400;
    color: white;
    background-color: #37b400;
}

.k-badge-solid-warning {
    border-color: #ffc000;
    color: black;
    background-color: #ffc000;
}

.k-badge-solid-error {
    border-color: #f31700;
    color: white;
    background-color: #f31700;
}

.k-badge-solid-dark {
    border-color: #424242;
    color: white;
    background-color: #424242;
}

.k-badge-solid-light {
    border-color: #ebebeb;
    color: black;
    background-color: #ebebeb;
}

.k-badge-solid-inverse {
    border-color: #424242;
    color: white;
    background-color: #424242;
}

.k-badge-outline {
    border-color: currentColor;
    background-color: #ffffff;
}

.k-badge-outline-primary {
    color: #4e1289;
}

.k-badge-outline-secondary {
    color: #666666;
}

.k-badge-outline-tertiary {
    color: #03a9f4;
}

.k-badge-outline-info {
    color: #0058e9;
}

.k-badge-outline-success {
    color: #37b400;
}

.k-badge-outline-warning {
    color: #ffc000;
}

.k-badge-outline-error {
    color: #f31700;
}

.k-badge-outline-dark {
    color: #424242;
}

.k-badge-outline-light {
    color: #ebebeb;
}

.k-badge-outline-inverse {
    color: #424242;
}

.k-button {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    color: inherit;
    background: none;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    position: relative;
    transition: color 0.2s ease-in-out;
}
.k-button *,
.k-button *::before,
.k-button *::after {
    box-sizing: border-box;
}
.k-button:disabled,
.k-button.k-disabled {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}
.k-button::-moz-focus-inner {
    padding: 0;
    border: 0;
    outline: 0;
}
.k-button:hover,
.k-button:focus {
    text-decoration: none;
    outline: 0;
}

.k-icon-button {
    gap: 0;
}
.k-icon-button .k-icon {
    display: inline-flex;
}

.k-button-icon {
    color: inherit;
    align-self: center;
    position: relative;
}

.k-button-sm {
    padding-block: 2px;
    padding-inline: 8px;
    font-size: 14px;
    line-height: 1.4285714286;
}

.k-button-sm.k-icon-button {
    padding: 2px;
}
.k-button-sm.k-icon-button .k-button-icon {
    min-width: calc(14px * 1.4285714286);
    min-height: calc(14px * 1.4285714286);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.k-button-sm.k-icon-button .k-button-icon.k-svg-icon > svg,
.k-button-sm.k-icon-button .k-button-icon.k-icon-md > svg {
    width: 16px;
    height: 16px;
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xs > svg {
    width: calc(16px * 0.75);
    height: calc(16px * 0.75);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-sm > svg {
    width: calc(16px * 0.875);
    height: calc(16px * 0.875);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-lg > svg {
    width: calc(16px * 1.25);
    height: calc(16px * 1.25);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xl > svg {
    width: calc(16px * 1.5);
    height: calc(16px * 1.5);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xxl > svg {
    width: calc(16px * 2);
    height: calc(16px * 2);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xxxl > svg {
    width: calc(16px * 3);
    height: calc(16px * 3);
}

.k-button-md {
    padding-block: 4px;
    padding-inline: 8px;
    font-size: 14px;
    line-height: 1.4285714286;
}

.k-button-md.k-icon-button {
    padding: 4px;
}
.k-button-md.k-icon-button .k-button-icon {
    min-width: calc(14px * 1.4285714286);
    min-height: calc(14px * 1.4285714286);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.k-button-md.k-icon-button .k-button-icon.k-svg-icon > svg,
.k-button-md.k-icon-button .k-button-icon.k-icon-md > svg {
    width: 16px;
    height: 16px;
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xs > svg {
    width: calc(16px * 0.75);
    height: calc(16px * 0.75);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-sm > svg {
    width: calc(16px * 0.875);
    height: calc(16px * 0.875);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-lg > svg {
    width: calc(16px * 1.25);
    height: calc(16px * 1.25);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xl > svg {
    width: calc(16px * 1.5);
    height: calc(16px * 1.5);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xxl > svg {
    width: calc(16px * 2);
    height: calc(16px * 2);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xxxl > svg {
    width: calc(16px * 3);
    height: calc(16px * 3);
}

.k-button-lg {
    padding-block: 8px;
    padding-inline: 8px;
    font-size: 16px;
    line-height: 1.5;
}

.k-button-lg.k-icon-button {
    padding: 8px;
}
.k-button-lg.k-icon-button .k-button-icon {
    min-width: calc(16px * 1.5);
    min-height: calc(16px * 1.5);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.k-button-lg.k-icon-button .k-button-icon.k-svg-icon > svg,
.k-button-lg.k-icon-button .k-button-icon.k-icon-md > svg {
    width: 16px;
    height: 16px;
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xs > svg {
    width: calc(16px * 0.75);
    height: calc(16px * 0.75);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-sm > svg {
    width: calc(16px * 0.875);
    height: calc(16px * 0.875);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-lg > svg {
    width: calc(16px * 1.25);
    height: calc(16px * 1.25);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xl > svg {
    width: calc(16px * 1.5);
    height: calc(16px * 1.5);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xxl > svg {
    width: calc(16px * 2);
    height: calc(16px * 2);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xxxl > svg {
    width: calc(16px * 3);
    height: calc(16px * 3);
}

.k-button-square {
    aspect-ratio: 1;
}

.k-button-group {
    margin: 0;
    padding: 0;
    border-width: 0;
    box-sizing: border-box;
    list-style: none;
    outline: 0;
    display: inline-flex;
    flex-flow: row nowrap;
    vertical-align: middle;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-button-group > .k-button + .k-button {
    margin-inline-start: -1px;
}
.k-button-group > .k-button:hover,
.k-button-group > .k-button.k-hover,
.k-button-group > .k-button:focus,
.k-button-group > .k-button.k-focus,
.k-button-group > .k-button:active,
.k-button-group > .k-button.k-active,
.k-button-group > .k-button.k-selected {
    z-index: 2;
}
.k-button-group .k-button:not(:first-child):not(:last-child) {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    border-start-start-radius: 0;
    border-end-start-radius: 0;
}
.k-button-group > .k-button:first-child:not(:only-child) {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
}
.k-button-group > .k-button:last-child:not(:only-child) {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
}
.k-button-group:disabled,
.k-button-group[disabled],
.k-button-group.k-disabled {
    opacity: 1;
    filter: none;
}

.k-button-group-stretched {
    width: 100%;
}
.k-button-group-stretched > * {
    flex: 1 0 0%;
    overflow: hidden;
}

.k-button-flat {
    border-color: transparent !important;
    color: inherit;
    background: none !important;
    box-shadow: none !important;
}
.k-button-flat::before {
    display: block !important;
}
.k-button-flat::after {
    box-shadow: inset 0 0 0 2px currentColor;
    display: block !important;
}
.k-button-flat:focus::after,
.k-button-flat.k-focus::after {
    opacity: 0.12;
}

.k-button-outline {
    border-color: currentColor;
    color: inherit;
    background: none;
}

.k-button-link {
    border-color: transparent;
    color: inherit;
    text-decoration: none;
    background: none;
}
.k-button-link:hover,
.k-button-link.k-hover {
    text-decoration: underline;
}

.k-button-clear {
    border-color: transparent !important;
    color: inherit;
    background: none !important;
    box-shadow: none !important;
}

.k-button-overlay,
.k-button::before {
    border-radius: inherit;
    content: '';
    background: currentColor;
    opacity: 0;
    display: none;
    pointer-events: none;
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    z-index: 0;
    transition: opacity 0.2s ease-in-out;
}

.k-button:hover::before,
.k-button.k-hover::before {
    opacity: 0.04;
}
.k-button.k-no-focus:not(:hover)::before,
.k-button.k-no-focus:not(.k-hover)::before {
    opacity: 0;
}
.k-button:active::before,
.k-button.k-active::before {
    opacity: 0.16;
}
.k-button.k-selected::before {
    opacity: 0.2;
}

.k-button::after {
    border-radius: inherit;
    content: '';
    opacity: 0;
    display: none;
    pointer-events: none;
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    z-index: 0;
    transition: opacity 0.2s ease-in-out;
}

.k-button-solid-base {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #f5f5f5;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-base:hover,
.k-button-solid-base.k-hover {
    background-color: #ebebeb;
}
.k-button-solid-base:focus,
.k-button-solid-base.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-button-solid-base:active,
.k-button-solid-base.k-active {
    background-color: #d8d8d8;
}
.k-button-solid-base.k-selected {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}
.k-button-solid-primary {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-primary:hover,
.k-button-solid-primary.k-hover {
    border-color: #4b1184;
    background-color: #4b1184;
}
.k-button-solid-primary:focus,
.k-button-solid-primary.k-focus {
    box-shadow: 0 0 0px 2px rgba(78, 18, 137, 0.3);
}
.k-button-solid-primary:active,
.k-button-solid-primary.k-active {
    border-color: #451079;
    background-color: #451079;
}
.k-button-solid-primary.k-selected {
    border-color: #451079;
    background-color: #451079;
}
.k-button-solid-secondary {
    border-color: #666666;
    color: white;
    background-color: #666666;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-secondary:hover,
.k-button-solid-secondary.k-hover {
    border-color: #626262;
    background-color: #626262;
}
.k-button-solid-secondary:focus,
.k-button-solid-secondary.k-focus {
    box-shadow: 0 0 0px 2px rgba(102, 102, 102, 0.3);
}
.k-button-solid-secondary:active,
.k-button-solid-secondary.k-active {
    border-color: #5a5a5a;
    background-color: #5a5a5a;
}
.k-button-solid-secondary.k-selected {
    border-color: #5a5a5a;
    background-color: #5a5a5a;
}
.k-button-solid-tertiary {
    border-color: #03a9f4;
    color: white;
    background-color: #03a9f4;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-tertiary:hover,
.k-button-solid-tertiary.k-hover {
    border-color: #03a2ea;
    background-color: #03a2ea;
}
.k-button-solid-tertiary:focus,
.k-button-solid-tertiary.k-focus {
    box-shadow: 0 0 0px 2px rgba(3, 169, 244, 0.3);
}
.k-button-solid-tertiary:active,
.k-button-solid-tertiary.k-active {
    border-color: #0395d7;
    background-color: #0395d7;
}
.k-button-solid-tertiary.k-selected {
    border-color: #0395d7;
    background-color: #0395d7;
}
.k-button-solid-info {
    border-color: #0058e9;
    color: white;
    background-color: #0058e9;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-info:hover,
.k-button-solid-info.k-hover {
    border-color: #0054e0;
    background-color: #0054e0;
}
.k-button-solid-info:focus,
.k-button-solid-info.k-focus {
    box-shadow: 0 0 0px 2px rgba(0, 88, 233, 0.3);
}
.k-button-solid-info:active,
.k-button-solid-info.k-active {
    border-color: #004dcd;
    background-color: #004dcd;
}
.k-button-solid-info.k-selected {
    border-color: #004dcd;
    background-color: #004dcd;
}
.k-button-solid-success {
    border-color: #37b400;
    color: white;
    background-color: #37b400;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-success:hover,
.k-button-solid-success.k-hover {
    border-color: #35ad00;
    background-color: #35ad00;
}
.k-button-solid-success:focus,
.k-button-solid-success.k-focus {
    box-shadow: 0 0 0px 2px rgba(55, 180, 0, 0.3);
}
.k-button-solid-success:active,
.k-button-solid-success.k-active {
    border-color: #309e00;
    background-color: #309e00;
}
.k-button-solid-success.k-selected {
    border-color: #309e00;
    background-color: #309e00;
}
.k-button-solid-warning {
    border-color: #ffc000;
    color: black;
    background-color: #ffc000;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-warning:hover,
.k-button-solid-warning.k-hover {
    border-color: #f5b800;
    background-color: #f5b800;
}
.k-button-solid-warning:focus,
.k-button-solid-warning.k-focus {
    box-shadow: 0 0 0px 2px rgba(255, 192, 0, 0.3);
}
.k-button-solid-warning:active,
.k-button-solid-warning.k-active {
    border-color: #e0a900;
    background-color: #e0a900;
}
.k-button-solid-warning.k-selected {
    border-color: #e0a900;
    background-color: #e0a900;
}
.k-button-solid-error {
    border-color: #f31700;
    color: white;
    background-color: #f31700;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-error:hover,
.k-button-solid-error.k-hover {
    border-color: #e91600;
    background-color: #e91600;
}
.k-button-solid-error:focus,
.k-button-solid-error.k-focus {
    box-shadow: 0 0 0px 2px rgba(243, 23, 0, 0.3);
}
.k-button-solid-error:active,
.k-button-solid-error.k-active {
    border-color: #d61400;
    background-color: #d61400;
}
.k-button-solid-error.k-selected {
    border-color: #d61400;
    background-color: #d61400;
}
.k-button-solid-dark {
    border-color: #424242;
    color: white;
    background-color: #424242;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-dark:hover,
.k-button-solid-dark.k-hover {
    border-color: #3f3f3f;
    background-color: #3f3f3f;
}
.k-button-solid-dark:focus,
.k-button-solid-dark.k-focus {
    box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-solid-dark:active,
.k-button-solid-dark.k-active {
    border-color: #3a3a3a;
    background-color: #3a3a3a;
}
.k-button-solid-dark.k-selected {
    border-color: #3a3a3a;
    background-color: #3a3a3a;
}
.k-button-solid-light {
    border-color: #ebebeb;
    color: black;
    background-color: #ebebeb;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-light:hover,
.k-button-solid-light.k-hover {
    border-color: #e2e2e2;
    background-color: #e2e2e2;
}
.k-button-solid-light:focus,
.k-button-solid-light.k-focus {
    box-shadow: 0 0 0px 2px rgba(235, 235, 235, 0.3);
}
.k-button-solid-light:active,
.k-button-solid-light.k-active {
    border-color: #cfcfcf;
    background-color: #cfcfcf;
}
.k-button-solid-light.k-selected {
    border-color: #cfcfcf;
    background-color: #cfcfcf;
}
.k-button-solid-inverse {
    border-color: #424242;
    color: white;
    background-color: #424242;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-inverse:hover,
.k-button-solid-inverse.k-hover {
    border-color: #3f3f3f;
    background-color: #3f3f3f;
}
.k-button-solid-inverse:focus,
.k-button-solid-inverse.k-focus {
    box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-solid-inverse:active,
.k-button-solid-inverse.k-active {
    border-color: #3a3a3a;
    background-color: #3a3a3a;
}
.k-button-solid-inverse.k-selected {
    border-color: #3a3a3a;
    background-color: #3a3a3a;
}
.k-button-outline-primary {
    box-shadow: none;
    border-color: currentColor;
    color: #4e1289;
    background-color: transparent;
}
.k-button-outline-primary:hover,
.k-button-outline-primary.k-hover {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}
.k-button-outline-primary:focus,
.k-button-outline-primary.k-focus {
    box-shadow: 0 0 0px 2px rgba(78, 18, 137, 0.3);
}
.k-button-outline-primary:active,
.k-button-outline-primary.k-active {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}
.k-button-outline-primary.k-selected {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}
.k-button-outline-secondary {
    box-shadow: none;
    border-color: currentColor;
    color: #666666;
    background-color: transparent;
}
.k-button-outline-secondary:hover,
.k-button-outline-secondary.k-hover {
    border-color: #666666;
    color: white;
    background-color: #666666;
}
.k-button-outline-secondary:focus,
.k-button-outline-secondary.k-focus {
    box-shadow: 0 0 0px 2px rgba(102, 102, 102, 0.3);
}
.k-button-outline-secondary:active,
.k-button-outline-secondary.k-active {
    border-color: #666666;
    color: white;
    background-color: #666666;
}
.k-button-outline-secondary.k-selected {
    border-color: #666666;
    color: white;
    background-color: #666666;
}
.k-button-outline-tertiary {
    box-shadow: none;
    border-color: currentColor;
    color: #03a9f4;
    background-color: transparent;
}
.k-button-outline-tertiary:hover,
.k-button-outline-tertiary.k-hover {
    border-color: #03a9f4;
    color: white;
    background-color: #03a9f4;
}
.k-button-outline-tertiary:focus,
.k-button-outline-tertiary.k-focus {
    box-shadow: 0 0 0px 2px rgba(3, 169, 244, 0.3);
}
.k-button-outline-tertiary:active,
.k-button-outline-tertiary.k-active {
    border-color: #03a9f4;
    color: white;
    background-color: #03a9f4;
}
.k-button-outline-tertiary.k-selected {
    border-color: #03a9f4;
    color: white;
    background-color: #03a9f4;
}
.k-button-outline-info {
    box-shadow: none;
    border-color: currentColor;
    color: #0058e9;
    background-color: transparent;
}
.k-button-outline-info:hover,
.k-button-outline-info.k-hover {
    border-color: #0058e9;
    color: white;
    background-color: #0058e9;
}
.k-button-outline-info:focus,
.k-button-outline-info.k-focus {
    box-shadow: 0 0 0px 2px rgba(0, 88, 233, 0.3);
}
.k-button-outline-info:active,
.k-button-outline-info.k-active {
    border-color: #0058e9;
    color: white;
    background-color: #0058e9;
}
.k-button-outline-info.k-selected {
    border-color: #0058e9;
    color: white;
    background-color: #0058e9;
}
.k-button-outline-success {
    box-shadow: none;
    border-color: currentColor;
    color: #37b400;
    background-color: transparent;
}
.k-button-outline-success:hover,
.k-button-outline-success.k-hover {
    border-color: #37b400;
    color: white;
    background-color: #37b400;
}
.k-button-outline-success:focus,
.k-button-outline-success.k-focus {
    box-shadow: 0 0 0px 2px rgba(55, 180, 0, 0.3);
}
.k-button-outline-success:active,
.k-button-outline-success.k-active {
    border-color: #37b400;
    color: white;
    background-color: #37b400;
}
.k-button-outline-success.k-selected {
    border-color: #37b400;
    color: white;
    background-color: #37b400;
}
.k-button-outline-warning {
    box-shadow: none;
    border-color: currentColor;
    color: #ffc000;
    background-color: transparent;
}
.k-button-outline-warning:hover,
.k-button-outline-warning.k-hover {
    border-color: #ffc000;
    color: black;
    background-color: #ffc000;
}
.k-button-outline-warning:focus,
.k-button-outline-warning.k-focus {
    box-shadow: 0 0 0px 2px rgba(255, 192, 0, 0.3);
}
.k-button-outline-warning:active,
.k-button-outline-warning.k-active {
    border-color: #ffc000;
    color: black;
    background-color: #ffc000;
}
.k-button-outline-warning.k-selected {
    border-color: #ffc000;
    color: black;
    background-color: #ffc000;
}
.k-button-outline-error {
    box-shadow: none;
    border-color: currentColor;
    color: #f31700;
    background-color: transparent;
}
.k-button-outline-error:hover,
.k-button-outline-error.k-hover {
    border-color: #f31700;
    color: white;
    background-color: #f31700;
}
.k-button-outline-error:focus,
.k-button-outline-error.k-focus {
    box-shadow: 0 0 0px 2px rgba(243, 23, 0, 0.3);
}
.k-button-outline-error:active,
.k-button-outline-error.k-active {
    border-color: #f31700;
    color: white;
    background-color: #f31700;
}
.k-button-outline-error.k-selected {
    border-color: #f31700;
    color: white;
    background-color: #f31700;
}
.k-button-outline-dark {
    box-shadow: none;
    border-color: currentColor;
    color: #424242;
    background-color: transparent;
}
.k-button-outline-dark:hover,
.k-button-outline-dark.k-hover {
    border-color: #424242;
    color: white;
    background-color: #424242;
}
.k-button-outline-dark:focus,
.k-button-outline-dark.k-focus {
    box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-outline-dark:active,
.k-button-outline-dark.k-active {
    border-color: #424242;
    color: white;
    background-color: #424242;
}
.k-button-outline-dark.k-selected {
    border-color: #424242;
    color: white;
    background-color: #424242;
}
.k-button-outline-light {
    box-shadow: none;
    border-color: currentColor;
    color: #ebebeb;
    background-color: transparent;
}
.k-button-outline-light:hover,
.k-button-outline-light.k-hover {
    border-color: #ebebeb;
    color: black;
    background-color: #ebebeb;
}
.k-button-outline-light:focus,
.k-button-outline-light.k-focus {
    box-shadow: 0 0 0px 2px rgba(235, 235, 235, 0.3);
}
.k-button-outline-light:active,
.k-button-outline-light.k-active {
    border-color: #ebebeb;
    color: black;
    background-color: #ebebeb;
}
.k-button-outline-light.k-selected {
    border-color: #ebebeb;
    color: black;
    background-color: #ebebeb;
}
.k-button-outline-inverse {
    box-shadow: none;
    border-color: currentColor;
    color: #424242;
    background-color: transparent;
}
.k-button-outline-inverse:hover,
.k-button-outline-inverse.k-hover {
    border-color: #424242;
    color: white;
    background-color: #424242;
}
.k-button-outline-inverse:focus,
.k-button-outline-inverse.k-focus {
    box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-outline-inverse:active,
.k-button-outline-inverse.k-active {
    border-color: #424242;
    color: white;
    background-color: #424242;
}
.k-button-outline-inverse.k-selected {
    border-color: #424242;
    color: white;
    background-color: #424242;
}
.k-button-outline-base {
    box-shadow: none;
    border-color: currentColor;
    color: #424242;
    background-color: transparent;
}
.k-button-outline-base:hover,
.k-button-outline-base.k-hover {
    border-color: #424242;
    color: white;
    background-color: #424242;
}
.k-button-outline-base:focus,
.k-button-outline-base.k-focus {
    box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-outline-base:active,
.k-button-outline-base.k-active {
    border-color: #424242;
    color: white;
    background-color: #424242;
}
.k-button-outline-base.k-selected {
    border-color: #424242;
    color: white;
    background-color: #424242;
}
.k-button-flat-primary {
    color: #4e1289;
}
.k-button-flat-secondary {
    color: #666666;
}
.k-button-flat-tertiary {
    color: #03a9f4;
}
.k-button-flat-info {
    color: #0058e9;
}
.k-button-flat-success {
    color: #37b400;
}
.k-button-flat-warning {
    color: #ffc000;
}
.k-button-flat-error {
    color: #f31700;
}
.k-button-flat-dark {
    color: #424242;
}
.k-button-flat-light {
    color: #ebebeb;
}
.k-button-flat-inverse {
    color: #424242;
}
.k-button-flat-base {
    color: inherit;
}
.k-button-link-primary {
    color: #4e1289;
}
.k-button-link-primary:hover,
.k-button-link-primary.k-hover {
    color: #420f73;
}
.k-button-link-primary:focus,
.k-button-link-primary.k-focus {
    box-shadow: 0 0 0px 2px rgba(78, 18, 137, 0.3);
}
.k-button-link-primary:active,
.k-button-link-primary.k-active {
    color: #420f73;
}
.k-button-link-primary.k-selected {
    color: #420f73;
}
.k-button-link-secondary {
    color: #666666;
}
.k-button-link-secondary:hover,
.k-button-link-secondary.k-hover {
    color: #565656;
}
.k-button-link-secondary:focus,
.k-button-link-secondary.k-focus {
    box-shadow: 0 0 0px 2px rgba(102, 102, 102, 0.3);
}
.k-button-link-secondary:active,
.k-button-link-secondary.k-active {
    color: #565656;
}
.k-button-link-secondary.k-selected {
    color: #565656;
}
.k-button-link-tertiary {
    color: #03a9f4;
}
.k-button-link-tertiary:hover,
.k-button-link-tertiary.k-hover {
    color: #038ecd;
}
.k-button-link-tertiary:focus,
.k-button-link-tertiary.k-focus {
    box-shadow: 0 0 0px 2px rgba(3, 169, 244, 0.3);
}
.k-button-link-tertiary:active,
.k-button-link-tertiary.k-active {
    color: #038ecd;
}
.k-button-link-tertiary.k-selected {
    color: #038ecd;
}
.k-button-link-info {
    color: #0058e9;
}
.k-button-link-info:hover,
.k-button-link-info.k-hover {
    color: #004ac4;
}
.k-button-link-info:focus,
.k-button-link-info.k-focus {
    box-shadow: 0 0 0px 2px rgba(0, 88, 233, 0.3);
}
.k-button-link-info:active,
.k-button-link-info.k-active {
    color: #004ac4;
}
.k-button-link-info.k-selected {
    color: #004ac4;
}
.k-button-link-success {
    color: #37b400;
}
.k-button-link-success:hover,
.k-button-link-success.k-hover {
    color: #2e9700;
}
.k-button-link-success:focus,
.k-button-link-success.k-focus {
    box-shadow: 0 0 0px 2px rgba(55, 180, 0, 0.3);
}
.k-button-link-success:active,
.k-button-link-success.k-active {
    color: #2e9700;
}
.k-button-link-success.k-selected {
    color: #2e9700;
}
.k-button-link-warning {
    color: #ffc000;
}
.k-button-link-warning:hover,
.k-button-link-warning.k-hover {
    color: #d6a100;
}
.k-button-link-warning:focus,
.k-button-link-warning.k-focus {
    box-shadow: 0 0 0px 2px rgba(255, 192, 0, 0.3);
}
.k-button-link-warning:active,
.k-button-link-warning.k-active {
    color: #d6a100;
}
.k-button-link-warning.k-selected {
    color: #d6a100;
}
.k-button-link-error {
    color: #f31700;
}
.k-button-link-error:hover,
.k-button-link-error.k-hover {
    color: #cc1300;
}
.k-button-link-error:focus,
.k-button-link-error.k-focus {
    box-shadow: 0 0 0px 2px rgba(243, 23, 0, 0.3);
}
.k-button-link-error:active,
.k-button-link-error.k-active {
    color: #cc1300;
}
.k-button-link-error.k-selected {
    color: #cc1300;
}
.k-button-link-dark {
    color: #424242;
}
.k-button-link-dark:hover,
.k-button-link-dark.k-hover {
    color: #373737;
}
.k-button-link-dark:focus,
.k-button-link-dark.k-focus {
    box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-link-dark:active,
.k-button-link-dark.k-active {
    color: #373737;
}
.k-button-link-dark.k-selected {
    color: #373737;
}
.k-button-link-light {
    color: #ebebeb;
}
.k-button-link-light:hover,
.k-button-link-light.k-hover {
    color: #c5c5c5;
}
.k-button-link-light:focus,
.k-button-link-light.k-focus {
    box-shadow: 0 0 0px 2px rgba(235, 235, 235, 0.3);
}
.k-button-link-light:active,
.k-button-link-light.k-active {
    color: #c5c5c5;
}
.k-button-link-light.k-selected {
    color: #c5c5c5;
}
.k-button-link-inverse {
    color: #424242;
}
.k-button-link-inverse:hover,
.k-button-link-inverse.k-hover {
    color: #373737;
}
.k-button-link-inverse:focus,
.k-button-link-inverse.k-focus {
    box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-link-inverse:active,
.k-button-link-inverse.k-active {
    color: #373737;
}
.k-button-link-inverse.k-selected {
    color: #373737;
}
.k-button-link-base {
    color: #424242;
}
.k-button-link-base:hover,
.k-button-link-base.k-hover {
    color: #373737;
}
.k-button-link-base:focus,
.k-button-link-base.k-focus {
    box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-link-base:active,
.k-button-link-base.k-active {
    color: #373737;
}
.k-button-link-base.k-selected {
    color: #373737;
}
.k-button-clear-primary {
    color: #4e1289;
}
.k-button-clear-primary:focus::after,
.k-button-clear-primary.k-focus::after,
.k-button-clear-primary:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-secondary {
    color: #666666;
}
.k-button-clear-secondary:focus::after,
.k-button-clear-secondary.k-focus::after,
.k-button-clear-secondary:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-tertiary {
    color: #03a9f4;
}
.k-button-clear-tertiary:focus::after,
.k-button-clear-tertiary.k-focus::after,
.k-button-clear-tertiary:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-info {
    color: #0058e9;
}
.k-button-clear-info:focus::after,
.k-button-clear-info.k-focus::after,
.k-button-clear-info:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-success {
    color: #37b400;
}
.k-button-clear-success:focus::after,
.k-button-clear-success.k-focus::after,
.k-button-clear-success:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-warning {
    color: #ffc000;
}
.k-button-clear-warning:focus::after,
.k-button-clear-warning.k-focus::after,
.k-button-clear-warning:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-error {
    color: #f31700;
}
.k-button-clear-error:focus::after,
.k-button-clear-error.k-focus::after,
.k-button-clear-error:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-dark {
    color: #424242;
}
.k-button-clear-dark:focus::after,
.k-button-clear-dark.k-focus::after,
.k-button-clear-dark:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-light {
    color: #ebebeb;
}
.k-button-clear-light:focus::after,
.k-button-clear-light.k-focus::after,
.k-button-clear-light:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-inverse {
    color: #424242;
}
.k-button-clear-inverse:focus::after,
.k-button-clear-inverse.k-focus::after,
.k-button-clear-inverse:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-button-clear-base {
    color: #424242;
}
.k-button-clear-base:focus::after,
.k-button-clear-base.k-focus::after,
.k-button-clear-base:active::after {
    background-color: currentColor;
    opacity: 0.1;
    display: block;
}
.k-chip {
    padding-block: 4px;
    padding-inline: 4px;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
}
.k-chip *,
.k-chip *::before,
.k-chip *::after {
    box-sizing: border-box;
}
.k-chip .k-selected-icon-wrapper {
    display: none !important;
}

.k-chip-content {
    padding-block: 0.5em;
    padding-inline: 0;
    margin-block: -0.5em;
    margin-inline: 0;
    min-width: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    overflow: hidden;
    flex: 1 1 auto;
}
.k-chip-content .k-icon-wrapper-host {
    display: initial;
}

.k-chip-content:first-child {
    margin-inline-start: 4px;
}

.k-chip-content:last-child {
    margin-inline-end: 4px;
}

.k-chip-text,
.k-chip-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1 auto;
}

.k-chip-avatar {
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    flex: none;
}

.k-chip-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
}

.k-chip-actions {
    margin-block: -5em;
    margin-inline: 0;
    flex: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-self: center;
}

.k-chip-action {
    flex: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-self: center;
}

.k-remove-icon {
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
}

.k-chip-list {
    min-width: 0px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}

.k-chip-sm {
    padding-block: 2px;
    padding-inline: 4px;
    font-size: 14px;
    line-height: 1;
}
.k-chip-sm .k-chip-action {
    padding: 2px;
}

.k-chip-avatar {
    width: 1em;
    height: 1em;
    flex-basis: 1em;
}

.k-chip-md {
    padding-block: 4px;
    padding-inline: 4px;
    font-size: 14px;
    line-height: 1;
}
.k-chip-md .k-chip-action {
    padding: 4px;
}

.k-chip-avatar {
    width: 1em;
    height: 1em;
    flex-basis: 1em;
}

.k-chip-lg {
    padding-block: 6px;
    padding-inline: 4px;
    font-size: 14px;
    line-height: 1;
}
.k-chip-lg .k-chip-action {
    padding: 6px;
}

.k-chip-avatar {
    width: 1em;
    height: 1em;
    flex-basis: 1em;
}

.k-chip-list-sm {
    gap: 4px;
}

.k-chip-list-md {
    gap: 4px;
}

.k-chip-list-lg {
    gap: 4px;
}

.k-chip-solid-base {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #f5f5f5;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-chip-solid-base:focus,
.k-chip-solid-base.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-chip-solid-base:hover,
.k-chip-solid-base.k-hover {
    background-color: #ebebeb;
}
.k-chip-solid-base.k-selected {
    background-color: #d8d8d8;
}

.k-chip-solid-error {
    border-color: #f65140;
    color: #550800;
    background-color: #fdd1cc;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-chip-solid-error:focus,
.k-chip-solid-error.k-focus {
    box-shadow: 0 0 0 2px rgba(243, 23, 0, 0.16);
}
.k-chip-solid-error:hover,
.k-chip-solid-error.k-hover {
    background-color: #fbaea6;
}
.k-chip-solid-error.k-selected {
    background-color: #f98b80;
}

.k-chip-solid-info {
    border-color: #4082ef;
    color: #001f52;
    background-color: #ccdefb;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-chip-solid-info:focus,
.k-chip-solid-info.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 88, 233, 0.16);
}
.k-chip-solid-info:hover,
.k-chip-solid-info.k-hover {
    background-color: #a6c5f7;
}
.k-chip-solid-info.k-selected {
    background-color: #80acf4;
}

.k-chip-solid-warning {
    border-color: #ffd040;
    color: #594300;
    background-color: #fff2cc;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-chip-solid-warning:focus,
.k-chip-solid-warning.k-focus {
    box-shadow: 0 0 0 2px rgba(255, 192, 0, 0.16);
}
.k-chip-solid-warning:hover,
.k-chip-solid-warning.k-hover {
    background-color: #ffe9a6;
}
.k-chip-solid-warning.k-selected {
    background-color: #ffe080;
}

.k-chip-solid-success {
    border-color: #69c740;
    color: #133f00;
    background-color: #d7f0cc;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-chip-solid-success:focus,
.k-chip-solid-success.k-focus {
    box-shadow: 0 0 0 2px rgba(55, 180, 0, 0.16);
}
.k-chip-solid-success:hover,
.k-chip-solid-success.k-hover {
    background-color: #b9e5a6;
}
.k-chip-solid-success.k-selected {
    background-color: #9bda80;
}

.k-chip-outline-base {
    border-color: #424242;
    color: #424242;
    background-color: #ffffff;
}
.k-chip-outline-base:focus,
.k-chip-outline-base.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-chip-outline-base:hover,
.k-chip-outline-base.k-hover {
    color: white;
    background-color: #424242;
}
.k-chip-outline-base.k-selected {
    color: white;
    background-color: #424242;
}

.k-chip-outline-error {
    border-color: #b61100;
    color: #b61100;
    background-color: #ffffff;
}
.k-chip-outline-error:focus,
.k-chip-outline-error.k-focus {
    box-shadow: 0 0 0 2px rgba(243, 23, 0, 0.16);
}
.k-chip-outline-error:hover,
.k-chip-outline-error.k-hover {
    color: white;
    background-color: #b61100;
}
.k-chip-outline-error.k-selected {
    color: white;
    background-color: #b61100;
}

.k-chip-outline-info {
    border-color: #0042af;
    color: #0042af;
    background-color: #ffffff;
}
.k-chip-outline-info:focus,
.k-chip-outline-info.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 88, 233, 0.16);
}
.k-chip-outline-info:hover,
.k-chip-outline-info.k-hover {
    color: white;
    background-color: #0042af;
}
.k-chip-outline-info.k-selected {
    color: white;
    background-color: #0042af;
}

.k-chip-outline-warning {
    border-color: #ffc000;
    color: #424242;
    background-color: #ffffff;
}
.k-chip-outline-warning:focus,
.k-chip-outline-warning.k-focus {
    box-shadow: 0 0 0 2px rgba(255, 192, 0, 0.16);
}
.k-chip-outline-warning:hover,
.k-chip-outline-warning.k-hover {
    color: #332600;
    background-color: #ffc000;
}
.k-chip-outline-warning.k-selected {
    color: #332600;
    background-color: #ffc000;
}

.k-chip-outline-success {
    border-color: #298700;
    color: #298700;
    background-color: #ffffff;
}
.k-chip-outline-success:focus,
.k-chip-outline-success.k-focus {
    box-shadow: 0 0 0 2px rgba(55, 180, 0, 0.16);
}
.k-chip-outline-success:hover,
.k-chip-outline-success.k-hover {
    color: white;
    background-color: #298700;
}
.k-chip-outline-success.k-selected {
    color: white;
    background-color: #298700;
}

.k-reset {
    margin: 0;
    padding: 0;
    border-width: 0;
    outline: 0;
    text-decoration: none;
    font: inherit;
    list-style: none;
}

kendo-sortable {
    display: block;
}

.k-link,
.k-link:hover {
    color: inherit;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
}

.k-content {
    outline: 0;
}

.k-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.k-no-click {
    pointer-events: none;
}

.k-pdf-export-shadow {
    position: absolute;
    overflow: hidden;
    left: -15000px;
    width: 14400px;
}

.kendo-pdf-hide-pseudo-elements::before,
.kendo-pdf-hide-pseudo-elements::after {
    display: none !important;
}

.k-text-selection ::selection {
    background-color: #4e1289;
    color: white;
}

.k-block,
.k-panel {
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
}
.k-block > .k-header,
.k-panel > .k-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #fafafa;
    padding-inline: 8px;
    padding-block: 4px;
}
.k-block > .k-content,
.k-panel > .k-content {
    padding-inline: 8px;
    padding-block: 4px;
}

.k-content {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-disabled,
.k-widget[disabled] {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}
.k-disabled .k-link,
.k-widget[disabled] .k-link {
    cursor: default;
    outline: 0;
}
.k-disabled [disabled],
.k-disabled .k-disabled,
.k-widget[disabled] [disabled],
.k-widget[disabled] .k-disabled {
    opacity: 1;
    filter: grayscale(0);
}

.k-hr {
    margin: 16px auto;
    padding: 0;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    display: block;
    float: none;
    clear: both;
}

.k-d-flex-row > .k-hr {
    margin: 0;
    width: 0;
    height: auto;
    border-width: 0 0 0 1px;
    flex: 0 0 auto;
}

.k-d-flex-col > .k-hr {
    margin: 0;
    flex: 0 0 auto;
}

.k-dirty {
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    border-width: 3px;
    border-style: solid;
    border-color: #f31700 #f31700 transparent transparent;
    position: absolute;
    top: 0;
    right: 0;
}

[dir='rtl'] .k-dirty,
.k-rtl .k-dirty {
    border-color: transparent transparent #f31700 #f31700;
    right: auto;
    left: 0;
}

.k-animation-container {
    position: absolute;
    overflow: hidden;
    z-index: 100;
}
.k-animation-container-fixed {
    position: fixed;
}
.k-animation-container-relative {
    position: relative;
    display: inline-block;
}

.k-push-right-enter,
.k-push-right-appear {
    transform: translate(-100%, 0);
}
.k-push-right-enter-active,
.k-push-right-appear-active {
    transform: translate(0, 0);
    transition: transform 300ms ease-in-out;
}
.k-push-right-exit {
    transform: translate(0, 0);
}
.k-push-right-exit-active {
    transform: translate(100%, 0);
    transition: transform 300ms ease-in-out;
}

.k-push-left-enter,
.k-push-left-appear {
    transform: translate(100%, 0);
}
.k-push-left-enter-active,
.k-push-left-appear-active {
    transform: translate(0, 0);
    transition: transform 300ms ease-in-out;
}
.k-push-left-exit {
    transform: translate(0, 0);
}
.k-push-left-exit-active {
    transform: translate(-100%, 0);
    transition: transform 300ms ease-in-out;
}

.k-push-down-enter,
.k-push-down-appear {
    transform: translate(0, -100%);
}
.k-push-down-enter-active,
.k-push-down-appear-active {
    transform: translate(0, 0);
    transition: transform 300ms ease-in-out;
}
.k-push-down-exit {
    transform: translate(0, 0);
}
.k-push-down-exit-active {
    transform: translate(0, 100%);
    transition: transform 300ms ease-in-out;
}

.k-push-up-enter,
.k-push-up-appear {
    transform: translate(0, 100%);
}
.k-push-up-enter-active,
.k-push-up-appear-active {
    transform: translate(0, 0);
    transition: transform 300ms ease-in-out;
}
.k-push-up-exit {
    transform: translate(0, 0);
}
.k-push-up-exit-active {
    transform: translate(0, -100%);
    transition: transform 300ms ease-in-out;
}

.k-expand-vertical-enter,
.k-expand-vertical-appear {
    transform: scaleY(0);
}
.k-expand-vertical-enter-active,
.k-expand-vertical-appear-active {
    transform: scaleY(1);
    transition: transform 300ms ease-in-out;
}
.k-expand-vertical-exit {
    transform: scaleY(1);
}
.k-expand-vertical-exit-active {
    transform: scaleY(0);
    transition: transform 300ms ease-in-out;
}

.k-expand-horizontal-enter,
.k-expand-horizontal-appear {
    transform: scaleX(0);
}
.k-expand-horizontal-enter-active,
.k-expand-horizontal-appear-active {
    transform: scaleX(1);
    transition: transform 300ms ease-in-out;
}
.k-expand-horizontal-exit {
    transform: scaleX(1);
}
.k-expand-horizontal-exit-active {
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
}

.k-fade-enter,
.k-fade-appear {
    opacity: 0;
}
.k-fade-enter-active,
.k-fade-appear-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
}
.k-fade-exit {
    opacity: 1;
}
.k-fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}
.k-fade-exit-active + .k-fade-exit-active,
.k-fade-enter-active + .k-fade-enter-active {
    display: none;
}

.k-zoom-in-enter,
.k-zoom-in-appear {
    opacity: 0;
    transform: scale(0);
}
.k-zoom-in-enter-active,
.k-zoom-in-appear-active {
    opacity: 1;
    transform: scale(1);
    transition:
        transform,
        opacity 300ms ease-in-out;
}
.k-zoom-in-exit {
    opacity: 1;
    transform: scale(1);
}
.k-zoom-in-exit-active {
    opacity: 0;
    transform: scale(2);
    transition:
        transform,
        opacity 300ms ease-in-out;
}

.k-zoom-out-enter,
.k-zoom-out-appear {
    opacity: 0;
    transform: scale(2);
}
.k-zoom-out-enter-active,
.k-zoom-out-appear-active {
    opacity: 1;
    transform: scale(1);
    transition:
        transform,
        opacity 300ms ease-in-out;
}
.k-zoom-out-exit {
    opacity: 1;
    transform: scale(1);
}
.k-zoom-out-exit-active {
    opacity: 0;
    transform: scale(0);
    transition:
        transform,
        opacity 300ms ease-in-out;
}

.k-slide-in-appear {
    opacity: 0.1;
    transform: translate(0, -3em);
}
.k-slide-in-appear .k-centered {
    transform: translate(-50%, -60%);
}
.k-slide-in-appear-active {
    opacity: 1;
    transform: translate(0, 0);
    transition:
        transform 0.3s cubic-bezier(0.2, 0.6, 0.4, 1),
        opacity 0.3s cubic-bezier(0.2, 1, 0.2, 1);
}
.k-slide-in-appear-active .k-centered {
    transform: translate(-50%, -50%);
}

.k-slide-down-enter,
.k-slide-down-appear {
    transform: translateY(-100%);
}
.k-slide-down-enter-active,
.k-slide-down-appear-active {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
}
.k-slide-down-exit {
    transform: translateY(0);
}
.k-slide-down-exit-active {
    transform: translateY(-100%);
    transition: transform 300ms ease-in-out;
}

.k-slide-up-enter,
.k-slide-up-appear {
    transform: translateY(100%);
}
.k-slide-up-enter-active,
.k-slide-up-appear-active {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
}
.k-slide-up-exit {
    transform: translateY(0);
}
.k-slide-up-exit-active {
    transform: translateY(100%);
    transition: transform 300ms ease-in-out;
}

.k-slide-right-enter,
.k-slide-right-appear {
    transform: translateX(-100%);
}
.k-slide-right-enter-active,
.k-slide-right-appear-active {
    transform: translateX(0);
    transition: transform 300ms ease-in-out;
}
.k-slide-right-exit {
    transform: translateX(0);
}
.k-slide-right-exit-active {
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
}

.k-slide-left-enter,
.k-slide-left-appear {
    transform: translateX(100%);
}
.k-slide-left-enter-active,
.k-slide-left-appear-active {
    transform: translateX(0);
    transition: transform 300ms ease-in-out;
}
.k-slide-left-exit {
    transform: translateX(0);
}
.k-slide-left-exit-active {
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;
}

.k-reveal-vertical-enter,
.k-reveal-vertical-appear {
    max-height: 0;
}
.k-reveal-vertical-enter-active,
.k-reveal-vertical-appear-active {
    transition: max-height 300ms ease-in-out;
}
.k-reveal-vertical-exit-active {
    max-height: 0 !important;
    transition: max-height 300ms ease-in-out;
}

.k-reveal-horizontal-enter,
.k-reveal-horizontal-appear {
    max-width: 0;
}
.k-reveal-horizontal-enter-active,
.k-reveal-horizontal-appear-active {
    transition: max-width 300ms ease-in-out;
}
.k-reveal-horizontal-exit-active {
    max-width: 0 !important;
    transition: max-width 300ms ease-in-out;
}

.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
    transition: all 350ms ease-out;
}

.k-fx {
    position: relative;
}
.k-fx .k-fx-current {
    z-index: 0;
}
.k-fx .k-fx-next {
    z-index: 1;
}

.k-fx-hidden,
.k-fx-hidden * {
    visibility: hidden !important;
}

.k-fx-reverse .k-fx-current {
    z-index: 1;
}
.k-fx-reverse .k-fx-next {
    z-index: 0;
}

.k-fx-zoom.k-fx-start .k-fx-next {
    transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
    transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next,
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next {
    transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
    transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
    transform: scale(0) !important;
}

.k-fx-fade.k-fx-start .k-fx-next {
    will-change: opacity;
    opacity: 0;
}
.k-fx-fade.k-fx-end .k-fx-next {
    opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
    will-change: opacity;
    opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
    opacity: 0;
}

.k-fx-slide.k-fx-end .k-fx-next .k-content,
.k-fx-slide.k-fx-end .k-fx-next .k-header,
.k-fx-slide.k-fx-end .k-fx-next .k-footer,
.k-fx-slide.k-fx-end .k-fx-current .k-content,
.k-fx-slide.k-fx-end .k-fx-current .k-header,
.k-fx-slide.k-fx-end .k-fx-current .k-footer,
.k-fx-slide.k-fx-end .k-fx-next .km-content,
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer,
.k-fx-slide.k-fx-end .k-fx-current .km-content,
.k-fx-slide.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-end .k-fx-current .km-footer {
    transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .k-content,
.k-fx-slide.k-fx-start .k-fx-next .km-content {
    will-change: transform;
    transform: translateX(100%);
}
.k-fx-slide.k-fx-start .k-fx-next .k-header,
.k-fx-slide.k-fx-start .k-fx-next .k-footer,
.k-fx-slide.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-start .k-fx-next .km-footer {
    will-change: opacity;
    opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .k-content,
.k-fx-slide.k-fx-end .k-fx-current .km-content {
    transform: translateX(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .k-header,
.k-fx-slide.k-fx-end .k-fx-next .k-footer,
.k-fx-slide.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-end .k-fx-next .km-footer {
    opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-content,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
    will-change: transform;
    transform: translateX(0);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-content,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
    transform: translateX(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-content,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
    transform: translateX(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-content,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
    transform: translateX(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-footer,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
    will-change: opacity;
    opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-footer,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
    opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-footer,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
    opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-footer,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header,
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
    opacity: 1;
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .k-content,
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
    transform: translateX(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .k-content,
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
    transform: translateX(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .k-content,
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
    transform: translateX(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .k-content,
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
    transform: translateX(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .k-content,
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
    transform: translateX(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .k-content,
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
    transform: translateX(0%);
}

.k-fx-tile.k-fx-start .k-fx-next {
    will-change: transform;
    transform: translateX(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
    transform: translateX(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
    will-change: transform;
    transform: translateX(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
    transform: translateX(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
    transform: translateX(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
    transform: translateX(0);
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
    transform: translateX(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
    transform: translateX(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
    transform: translateX(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
    transform: translateX(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
    transform: translateX(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
    transform: translateX(0%);
}

.k-fx.k-fx-overlay.k-fx-start .k-fx-next,
.k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
    will-change: transform;
    transform: translateX(100%);
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
    transform: translateX(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
    transform: translateY(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
    transform: translateY(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
    transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
    will-change: transform;
    transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current,
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
    transform: translateX(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
    transform: translateX(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
    transform: translateY(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
    transform: translateY(-100%);
}

.k-current-time {
    width: 1px;
    position: absolute;
}
.k-current-time.k-current-time-arrow-left,
.k-current-time.k-current-time-arrow-right,
.k-current-time.k-current-time-arrow-down {
    width: 0;
    height: 0;
    background: transparent;
    border: 4px solid transparent;
}

.k-current-time {
    background: #ff0000;
}
.k-current-time.k-current-time-arrow-left {
    border-right-color: #ff0000;
}
.k-current-time.k-current-time-arrow-right {
    border-left-color: #ff0000;
}
.k-current-time.k-current-time-arrow-down {
    border-top-color: #ff0000;
}

.k-resize-handle,
.k-resize-hint {
    position: absolute;
    border-color: inherit;
    z-index: 200;
}

.k-resize-handle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.k-resize-handle::before {
    content: '';
    border: 0 solid;
    border-color: inherit;
}

.k-resize-n {
    width: 100%;
    height: 6px;
    flex-direction: row;
    left: 0;
    top: -3px;
    cursor: n-resize;
}

.k-resize-s {
    width: 100%;
    height: 6px;
    flex-direction: row;
    left: 0;
    bottom: -3px;
    cursor: s-resize;
}

.k-resize-w {
    width: 6px;
    height: 100%;
    flex-direction: column;
    top: 0;
    left: -3px;
    cursor: w-resize;
}

.k-resize-e {
    width: 6px;
    height: 100%;
    flex-direction: column;
    top: 0;
    right: -3px;
    cursor: e-resize;
}

.k-resize-sw,
.k-resize-se,
.k-resize-nw,
.k-resize-ne {
    width: 5px;
    height: 5px;
}

.k-resize-sw {
    cursor: sw-resize;
    bottom: 0;
    left: 0;
}

.k-resize-se {
    cursor: se-resize;
    bottom: 0;
    right: 0;
}

.k-resize-nw {
    cursor: nw-resize;
    top: 0;
    left: 0;
}

.k-resize-ne {
    cursor: ne-resize;
    top: 0;
    right: 0;
}

.k-vertical-resize {
    cursor: row-resize;
}

.k-horizontal-resize {
    cursor: col-resize;
}

.k-resize-hint {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.k-resize-hint .k-resize-hint-handle {
    width: auto;
    height: 20px;
    align-self: stretch;
}
.k-resize-hint .k-resize-hint-marker {
    width: 2px;
    height: auto;
    flex: 1 1 auto;
}

.k-resize-hint-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.k-resize-hint-vertical .k-resize-hint-handle {
    width: 20px;
    height: auto;
    align-self: stretch;
}
.k-resize-hint-vertical .k-resize-hint-marker {
    width: auto;
    height: 2px;
    flex: 1 1 auto;
}

.k-scrollbar {
    position: absolute;
    overflow: scroll;
}

.k-scrollbar-vertical {
    top: 0;
    right: 0;
    width: 17px;
    height: 100%;
    overflow-x: hidden;
}

.k-touch-scrollbar {
    display: none;
    position: absolute;
    z-index: 200000;
    height: 8px;
    width: 8px;
    border: 1px solid #8a8a8a;
    background-color: #858585;
}

.k-fab {
    padding: 0;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
    color: inherit;
    background-color: transparent;
    font-size: 14px;
    line-height: 1.4285714286;
    font-family: inherit;
    text-align: center;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    outline: none;
}
.k-fab *,
.k-fab *::before,
.k-fab *::after {
    box-sizing: border-box;
}
.k-fab:focus {
    outline: none;
}

.k-fab-icon {
    padding-inline: 2px;
    padding-block: 2px;
    box-sizing: content-box;
}
.k-icon-wrapper-host:not(:only-child) .k-fab-icon,
.k-fab-icon:not(:only-child) {
    margin-inline-end: 2px;
    margin-inline-start: -2px;
}

.k-fab-sm {
    padding: 8px 8px;
}

.k-fab-md {
    padding: 16px 16px;
}

.k-fab-lg {
    padding: 24px 24px;
}

.k-fab-items {
    margin: 0;
    padding-block: 16px;
    padding-inline: 0px;
    display: flex;
}

.k-fab-items-bottom {
    flex-direction: column;
}

.k-fab-items-bottom .k-fab-item:last-child {
    margin-block-end: 0;
}

.k-fab-items-top {
    flex-direction: column-reverse;
}

.k-fab-items-top .k-fab-item:first-child {
    margin-block-end: 0;
}

.k-fab-item {
    list-style-type: none;
    margin-block-end: 8px;
    outline: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}
.k-fab-item.k-text-right {
    flex-direction: row;
}
.k-fab-item.k-text-right .k-fab-item-text {
    margin-right: 8px;
}
.k-fab-item.k-text-left {
    flex-direction: row-reverse;
}
.k-fab-item.k-text-left .k-fab-item-text {
    margin-left: 8px;
}

.k-fab-item-text {
    padding-block: 4px;
    padding-inline: 4px;
    border-width: 1px;
    border-radius: 2px;
    font-size: 10px;
    line-height: 1.2;
}

.k-fab-item-icon {
    padding-block: 10px;
    padding-inline: 10px;
    border-width: 0;
    border-radius: 50%;
    box-sizing: content-box;
    outline: none;
}

.k-rtl .k-fab-popup .k-fab-item.k-text-left,
[dir='rtl'] .k-fab-popup .k-fab-item.k-text-left {
    flex-direction: row;
}
.k-rtl .k-fab-popup .k-fab-item.k-text-right,
[dir='rtl'] .k-fab-popup .k-fab-item.k-text-right {
    flex-direction: row-reverse;
}

.k-fab-solid-primary {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid #4e1289;
    outline-offset: -1px;
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}

.k-fab-solid-secondary {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid #666666;
    outline-offset: -1px;
    border-color: #666666;
    color: white;
    background-color: #666666;
}

.k-fab-solid-tertiary {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid #03a9f4;
    outline-offset: -1px;
    border-color: #03a9f4;
    color: white;
    background-color: #03a9f4;
}

.k-fab-solid-info {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid #0058e9;
    outline-offset: -1px;
    border-color: #0058e9;
    color: white;
    background-color: #0058e9;
}

.k-fab-solid-success {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid #37b400;
    outline-offset: -1px;
    border-color: #37b400;
    color: white;
    background-color: #37b400;
}

.k-fab-solid-warning {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid #ffc000;
    outline-offset: -1px;
    border-color: #ffc000;
    color: black;
    background-color: #ffc000;
}

.k-fab-solid-error {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid #f31700;
    outline-offset: -1px;
    border-color: #f31700;
    color: white;
    background-color: #f31700;
}

.k-fab-solid-dark {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid #424242;
    outline-offset: -1px;
    border-color: #424242;
    color: white;
    background-color: #424242;
}

.k-fab-solid-light {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid #ebebeb;
    outline-offset: -1px;
    border-color: #ebebeb;
    color: black;
    background-color: #ebebeb;
}

.k-fab-solid-inverse {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid #424242;
    outline-offset: -1px;
    border-color: #424242;
    color: white;
    background-color: #424242;
}

.k-hover.k-fab-solid-primary,
.k-fab-solid-primary:hover {
    border-color: #4b1184;
    background-color: #4b1184;
}

.k-hover.k-fab-solid-secondary,
.k-fab-solid-secondary:hover {
    border-color: #626262;
    background-color: #626262;
}

.k-hover.k-fab-solid-tertiary,
.k-fab-solid-tertiary:hover {
    border-color: #03a2ea;
    background-color: #03a2ea;
}

.k-hover.k-fab-solid-info,
.k-fab-solid-info:hover {
    border-color: #0054e0;
    background-color: #0054e0;
}

.k-hover.k-fab-solid-success,
.k-fab-solid-success:hover {
    border-color: #35ad00;
    background-color: #35ad00;
}

.k-hover.k-fab-solid-warning,
.k-fab-solid-warning:hover {
    border-color: #f5b800;
    background-color: #f5b800;
}

.k-hover.k-fab-solid-error,
.k-fab-solid-error:hover {
    border-color: #e91600;
    background-color: #e91600;
}

.k-hover.k-fab-solid-dark,
.k-fab-solid-dark:hover {
    border-color: #3f3f3f;
    background-color: #3f3f3f;
}

.k-hover.k-fab-solid-light,
.k-fab-solid-light:hover {
    border-color: #e2e2e2;
    background-color: #e2e2e2;
}

.k-hover.k-fab-solid-inverse,
.k-fab-solid-inverse:hover {
    border-color: #3f3f3f;
    background-color: #3f3f3f;
}

.k-fab-solid-primary.k-focus,
.k-fab-solid-primary:focus {
    outline: solid 2px rgba(78, 18, 137, 0.3);
}

.k-fab-solid-secondary.k-focus,
.k-fab-solid-secondary:focus {
    outline: solid 2px rgba(102, 102, 102, 0.3);
}

.k-fab-solid-tertiary.k-focus,
.k-fab-solid-tertiary:focus {
    outline: solid 2px rgba(3, 169, 244, 0.3);
}

.k-fab-solid-info.k-focus,
.k-fab-solid-info:focus {
    outline: solid 2px rgba(0, 88, 233, 0.3);
}

.k-fab-solid-success.k-focus,
.k-fab-solid-success:focus {
    outline: solid 2px rgba(55, 180, 0, 0.3);
}

.k-fab-solid-warning.k-focus,
.k-fab-solid-warning:focus {
    outline: solid 2px rgba(255, 192, 0, 0.3);
}

.k-fab-solid-error.k-focus,
.k-fab-solid-error:focus {
    outline: solid 2px rgba(243, 23, 0, 0.3);
}

.k-fab-solid-dark.k-focus,
.k-fab-solid-dark:focus {
    outline: solid 2px rgba(66, 66, 66, 0.3);
}

.k-fab-solid-light.k-focus,
.k-fab-solid-light:focus {
    outline: solid 2px rgba(235, 235, 235, 0.3);
}

.k-fab-solid-inverse.k-focus,
.k-fab-solid-inverse:focus {
    outline: solid 2px rgba(66, 66, 66, 0.3);
}

.k-active.k-fab-solid-primary,
.k-selected.k-fab-solid-primary,
.k-fab-solid-primary:active {
    border-color: #451079;
    background-color: #451079;
}

.k-active.k-fab-solid-secondary,
.k-selected.k-fab-solid-secondary,
.k-fab-solid-secondary:active {
    border-color: #5a5a5a;
    background-color: #5a5a5a;
}

.k-active.k-fab-solid-tertiary,
.k-selected.k-fab-solid-tertiary,
.k-fab-solid-tertiary:active {
    border-color: #0395d7;
    background-color: #0395d7;
}

.k-active.k-fab-solid-info,
.k-selected.k-fab-solid-info,
.k-fab-solid-info:active {
    border-color: #004dcd;
    background-color: #004dcd;
}

.k-active.k-fab-solid-success,
.k-selected.k-fab-solid-success,
.k-fab-solid-success:active {
    border-color: #309e00;
    background-color: #309e00;
}

.k-active.k-fab-solid-warning,
.k-selected.k-fab-solid-warning,
.k-fab-solid-warning:active {
    border-color: #e0a900;
    background-color: #e0a900;
}

.k-active.k-fab-solid-error,
.k-selected.k-fab-solid-error,
.k-fab-solid-error:active {
    border-color: #d61400;
    background-color: #d61400;
}

.k-active.k-fab-solid-dark,
.k-selected.k-fab-solid-dark,
.k-fab-solid-dark:active {
    border-color: #3a3a3a;
    background-color: #3a3a3a;
}

.k-active.k-fab-solid-light,
.k-selected.k-fab-solid-light,
.k-fab-solid-light:active {
    border-color: #cfcfcf;
    background-color: #cfcfcf;
}

.k-active.k-fab-solid-inverse,
.k-selected.k-fab-solid-inverse,
.k-fab-solid-inverse:active {
    border-color: #3a3a3a;
    background-color: #3a3a3a;
}

.k-disabled.k-fab-solid-primary,
.k-fab-solid-primary:disabled {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: #9571b8;
    color: white;
    opacity: 1;
}

.k-disabled.k-fab-solid-secondary,
.k-fab-solid-secondary:disabled {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: #a3a3a3;
    color: white;
    opacity: 1;
}

.k-disabled.k-fab-solid-tertiary,
.k-fab-solid-tertiary:disabled {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: #68cbf8;
    color: white;
    opacity: 1;
}

.k-disabled.k-fab-solid-info,
.k-fab-solid-info:disabled {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: #669bf2;
    color: white;
    opacity: 1;
}

.k-disabled.k-fab-solid-success,
.k-fab-solid-success:disabled {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: #87d266;
    color: white;
    opacity: 1;
}

.k-disabled.k-fab-solid-warning,
.k-fab-solid-warning:disabled {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: #ffd966;
    color: #666666;
    opacity: 1;
}

.k-disabled.k-fab-solid-error,
.k-fab-solid-error:disabled {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: #f87466;
    color: white;
    opacity: 1;
}

.k-disabled.k-fab-solid-dark,
.k-fab-solid-dark:disabled {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: #8e8e8e;
    color: white;
    opacity: 1;
}

.k-disabled.k-fab-solid-light,
.k-fab-solid-light:disabled {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: #f3f3f3;
    color: #666666;
    opacity: 1;
}

.k-disabled.k-fab-solid-inverse,
.k-fab-solid-inverse:disabled {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: #8e8e8e;
    color: white;
    opacity: 1;
}

.k-fab-item-text {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-fab-item-icon {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #f5f5f5;
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    outline: 1px solid rgba(0, 0, 0, 0.08);
    outline-offset: -1px;
}

.k-fab-item.k-hover .k-fab-item-icon,
.k-fab-item:hover .k-fab-item-icon {
    border-color: rgba(0, 0, 0, 0.1168);
    background-color: #ebebeb;
}

.k-fab-item:focus .k-fab-item-text,
.k-fab-item:focus .k-fab-item-icon,
.k-fab-item.k-focus .k-fab-item-text,
.k-fab-item.k-focus .k-fab-item-icon {
    outline: solid 2px rgba(0, 0, 0, 0.08);
}

.k-fab-item.k-active .k-fab-item-icon,
.k-fab-item:active .k-fab-item-icon {
    border-color: rgba(0, 0, 0, 0.1536);
    background-color: #e1e1e1;
}

.k-fab-item.k-disabled,
.k-fab-item:disabled {
    opacity: 1;
}
.k-fab-item.k-disabled .k-fab-item-text,
.k-fab-item.k-disabled .k-fab-item-icon,
.k-fab-item:disabled .k-fab-item-text,
.k-fab-item:disabled .k-fab-item-icon {
    box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
    background-color: white;
    color: #8e8e8e;
}

.k-fab-popup {
    box-shadow: none;
}

.k-input,
.k-picker {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 0;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    font-weight: normal;
    text-align: start;
    box-shadow: none;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: stretch;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
}
.k-input *,
.k-input *::before,
.k-input *::after,
.k-picker *,
.k-picker *::before,
.k-picker *::after {
    box-sizing: border-box;
}
.k-input > .k-input,
.k-input > .k-picker,
.k-picker > .k-input,
.k-picker > .k-picker {
    border-width: 0 !important;
    color: inherit;
    background: none;
    font: inherit;
    outline: 0;
    box-shadow: none;
}
.k-input > *,
.k-picker > * {
    margin: 0;
}
.k-input::placeholder,
.k-picker::placeholder {
    color: #666666;
    opacity: 1;
    user-select: none;
}

input.k-input,
textarea.k-textarea {
    padding-block: 4px;
    padding-inline: 8px;
}
input.k-input:disabled,
input.k-input[disabled],
textarea.k-textarea:disabled,
textarea.k-textarea[disabled] {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}
[disabled] input.k-input:disabled,
.k-disabled input.k-input:disabled,
[disabled] input.k-input[disabled],
.k-disabled input.k-input[disabled],
[disabled] textarea.k-textarea:disabled,
.k-disabled textarea.k-textarea:disabled,
[disabled] textarea.k-textarea[disabled],
.k-disabled textarea.k-textarea[disabled] {
    opacity: 1;
    filter: grayscale(0);
}

textarea.k-textarea {
    overflow: auto;
}

.k-picker {
    cursor: pointer;
}

select.k-picker {
    padding-block: 4px;
    padding-inline: 8px;
    appearance: auto;
}
select.k-picker:disabled,
select.k-picker[disabled] {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}
[disabled] select.k-picker:disabled,
.k-disabled select.k-picker:disabled,
[disabled] select.k-picker[disabled],
.k-disabled select.k-picker[disabled] {
    opacity: 1;
    filter: grayscale(0);
}

.k-input-inner {
    padding-block: 4px;
    padding-inline: 8px;
    width: 100%;
    border: 0;
    outline: 0;
    color: inherit;
    background: none;
    font: inherit;
    flex: 1;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
}
.k-input-inner::placeholder {
    color: #666666;
    opacity: 1;
    user-select: none;
}
.k-input-inner:invalid {
    box-shadow: none;
}

span.k-input-inner {
    white-space: nowrap;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

textarea.k-input-inner {
    margin: 0;
}

.k-input-value-icon {
    flex: none;
}

.k-input-value-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-input-value-text::before {
    content: '​';
    width: 0px;
    overflow: hidden;
    flex: none;
    display: inline-block;
    vertical-align: top;
}

.k-input-values {
    min-width: 0px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    cursor: text;
}

.k-input-values .k-chip-list {
    display: contents;
}

.k-icon-picker .k-input-inner {
    padding: 4px;
    justify-content: center;
}

.k-input-prefix,
.k-input-suffix {
    border-color: inherit;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: none;
}
.k-input-prefix > *,
.k-input-suffix > * {
    flex-shrink: 0;
}

.k-input-separator {
    margin: 0;
    width: 0;
    height: 16px;
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: inherit;
    align-self: center;
}

.k-input-icon,
.k-input-validation-icon,
.k-input-loading-icon,
.k-input-prefix > .k-icon,
.k-input-suffix > .k-icon {
    flex: none;
    align-self: center;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
}

.k-input-loading-icon {
    width: 1em;
    height: 1em;
    font-size: 16px;
}

.k-input-icon.k-svg-icon > svg,
.k-input-icon.k-icon-md > svg,
.k-input-validation-icon.k-svg-icon > svg,
.k-input-validation-icon.k-icon-md > svg,
.k-input-loading-icon.k-svg-icon > svg,
.k-input-loading-icon.k-icon-md > svg {
    width: 16px;
    height: 16px;
}
.k-input-icon.k-icon-xs > svg,
.k-input-validation-icon.k-icon-xs > svg,
.k-input-loading-icon.k-icon-xs > svg {
    width: calc(16px * 0.75);
    height: calc(16px * 0.75);
}
.k-input-icon.k-icon-sm > svg,
.k-input-validation-icon.k-icon-sm > svg,
.k-input-loading-icon.k-icon-sm > svg {
    width: calc(16px * 0.875);
    height: calc(16px * 0.875);
}
.k-input-icon.k-icon-lg > svg,
.k-input-validation-icon.k-icon-lg > svg,
.k-input-loading-icon.k-icon-lg > svg {
    width: calc(16px * 1.25);
    height: calc(16px * 1.25);
}
.k-input-icon.k-icon-xl > svg,
.k-input-validation-icon.k-icon-xl > svg,
.k-input-loading-icon.k-icon-xl > svg {
    width: calc(16px * 1.5);
    height: calc(16px * 1.5);
}
.k-input-icon.k-icon-xxl > svg,
.k-input-validation-icon.k-icon-xxl > svg,
.k-input-loading-icon.k-icon-xxl > svg {
    width: calc(16px * 2);
    height: calc(16px * 2);
}
.k-input-icon.k-icon-xxxl > svg,
.k-input-validation-icon.k-icon-xxxl > svg,
.k-input-loading-icon.k-icon-xxxl > svg {
    width: calc(16px * 3);
    height: calc(16px * 3);
}

.k-clear-value {
    outline: 0;
    flex: none;
    align-self: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.5;
}

.k-clear-value:hover,
.k-clear-value.k-hover {
    opacity: 1;
}

.k-clear-value:focus-visible {
    outline: 1px dotted;
    outline-offset: -1px;
}

.k-input-button {
    width: calc(1.4285714286em + 8px);
    border-width: 0;
    border-inline-start-width: 1px;
    flex: none;
    aspect-ratio: auto;
    box-shadow: none;
}
.k-input-button > .k-button-icon {
    min-width: auto !important;
}
.k-input-button:focus {
    box-shadow: none;
}

.k-picker .k-input-button {
    color: inherit;
    background: none;
    border-color: transparent;
}

.k-input-spinner {
    width: calc(1.4285714286em + 8px);
    flex: none;
    display: flex;
    flex-flow: column nowrap;
}
.k-input-spinner .k-spinner-increase,
.k-input-spinner .k-spinner-decrease {
    border-width: 0;
    border-inline-start-width: 1px;
    flex: 1 1 50%;
    box-shadow: none;
    position: relative;
    aspect-ratio: auto;
}
.k-input-spinner .k-spinner-increase .k-button-icon,
.k-input-spinner .k-spinner-decrease .k-button-icon {
    margin-block: -1em;
    margin-inline: 0;
    min-width: auto !important;
    min-height: auto !important;
}
.k-input-spinner .k-spinner-increase .k-icon {
    bottom: auto;
}
.k-input-spinner .k-spinner-decrease .k-icon {
    top: auto;
}

.k-searchbar {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
}

.k-picker-wrap,
.k-dropdown-wrap,
.k-dateinput-wrap,
.k-multiselect-wrap,
.k-numeric-wrap {
    width: 100%;
    border-width: 0;
    border-color: inherit;
    box-sizing: border-box;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    position: relative;
}

.k-input-flat,
.k-picker-flat {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-width: 1px 0;
    border-top-color: transparent !important;
    background-image: none !important;
}

.k-input-outline {
    background: none !important;
}

.k-input-sm,
.k-picker-sm {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-input-sm .k-input-values,
.k-picker-sm .k-input-values {
    padding: 1px;
    gap: 1px;
}
.k-input-sm .k-input-values > .k-searchbar,
.k-input-sm .k-input-values > .k-input-inner,
.k-picker-sm .k-input-values > .k-searchbar,
.k-picker-sm .k-input-values > .k-input-inner {
    margin: -1px;
}
.k-input-sm .k-input-inner,
.k-picker-sm .k-input-inner {
    padding-block: 2px;
    padding-inline: 8px;
}
.k-input-sm .k-input-button,
.k-input-sm .k-spinner-increase,
.k-input-sm .k-spinner-decrease,
.k-picker-sm .k-input-button,
.k-picker-sm .k-spinner-increase,
.k-picker-sm .k-spinner-decrease {
    padding-block: 2px;
    padding-inline: 2px;
}
.k-input-sm .k-input-icon,
.k-input-sm .k-input-validation-icon,
.k-input-sm .k-input-loading-icon,
.k-input-sm .k-clear-value,
.k-input-sm .k-input-prefix > .k-icon,
.k-input-sm .k-input-suffix > .k-icon,
.k-picker-sm .k-input-icon,
.k-picker-sm .k-input-validation-icon,
.k-picker-sm .k-input-loading-icon,
.k-picker-sm .k-clear-value,
.k-picker-sm .k-input-prefix > .k-icon,
.k-picker-sm .k-input-suffix > .k-icon {
    padding-block: 2px;
    padding-inline: 2px;
    box-sizing: content-box;
}
.k-input-sm.k-icon-picker .k-input-inner,
.k-picker-sm.k-icon-picker .k-input-inner {
    width: calc(1.4285714286em);
    height: calc(1.4285714286em);
    padding: 2px;
    box-sizing: content-box;
}

.k-dropdown-operator.k-picker-sm .k-input-button {
    padding: 2px;
}

select.k-picker-sm {
    padding-block: 2px;
    padding-inline: 8px;
}

.k-input-md,
.k-picker-md {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-input-md .k-input-values,
.k-picker-md .k-input-values {
    padding: 2px;
    gap: 2px;
}
.k-input-md .k-input-values > .k-searchbar,
.k-input-md .k-input-values > .k-input-inner,
.k-picker-md .k-input-values > .k-searchbar,
.k-picker-md .k-input-values > .k-input-inner {
    margin: -2px;
}
.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
    padding-block: 4px;
    padding-inline: 8px;
}
.k-input-md .k-input-button,
.k-input-md .k-spinner-increase,
.k-input-md .k-spinner-decrease,
.k-picker-md .k-input-button,
.k-picker-md .k-spinner-increase,
.k-picker-md .k-spinner-decrease {
    padding-block: 4px;
    padding-inline: 4px;
}
.k-input-md .k-input-icon,
.k-input-md .k-input-validation-icon,
.k-input-md .k-input-loading-icon,
.k-input-md .k-clear-value,
.k-input-md .k-input-prefix > .k-icon,
.k-input-md .k-input-suffix > .k-icon,
.k-picker-md .k-input-icon,
.k-picker-md .k-input-validation-icon,
.k-picker-md .k-input-loading-icon,
.k-picker-md .k-clear-value,
.k-picker-md .k-input-prefix > .k-icon,
.k-picker-md .k-input-suffix > .k-icon {
    padding-block: 4px;
    padding-inline: 4px;
    box-sizing: content-box;
}
.k-input-md.k-icon-picker .k-input-inner,
.k-picker-md.k-icon-picker .k-input-inner {
    width: calc(1.4285714286em);
    height: calc(1.4285714286em);
    padding: 4px;
    box-sizing: content-box;
}

.k-dropdown-operator.k-picker-md .k-input-button {
    padding: 4px;
}

select.k-picker-md {
    padding-block: 4px;
    padding-inline: 8px;
}

.k-input-lg,
.k-picker-lg {
    font-size: 16px;
    line-height: 1.5;
}
.k-input-lg .k-input-values,
.k-picker-lg .k-input-values {
    padding: 4px;
    gap: 4px;
}
.k-input-lg .k-input-values > .k-searchbar,
.k-input-lg .k-input-values > .k-input-inner,
.k-picker-lg .k-input-values > .k-searchbar,
.k-picker-lg .k-input-values > .k-input-inner {
    margin: -4px;
}
.k-input-lg .k-input-inner,
.k-picker-lg .k-input-inner {
    padding-block: 8px;
    padding-inline: 8px;
}
.k-input-lg .k-input-button,
.k-input-lg .k-spinner-increase,
.k-input-lg .k-spinner-decrease,
.k-picker-lg .k-input-button,
.k-picker-lg .k-spinner-increase,
.k-picker-lg .k-spinner-decrease {
    padding-block: 8px;
    padding-inline: 8px;
}
.k-input-lg .k-input-icon,
.k-input-lg .k-input-validation-icon,
.k-input-lg .k-input-loading-icon,
.k-input-lg .k-clear-value,
.k-input-lg .k-input-prefix > .k-icon,
.k-input-lg .k-input-suffix > .k-icon,
.k-picker-lg .k-input-icon,
.k-picker-lg .k-input-validation-icon,
.k-picker-lg .k-input-loading-icon,
.k-picker-lg .k-clear-value,
.k-picker-lg .k-input-prefix > .k-icon,
.k-picker-lg .k-input-suffix > .k-icon {
    padding-block: 8px;
    padding-inline: 8px;
    box-sizing: content-box;
}
.k-input-lg.k-icon-picker .k-input-inner,
.k-picker-lg.k-icon-picker .k-input-inner {
    width: calc(1.5em);
    height: calc(1.5em);
    padding: 8px;
    box-sizing: content-box;
}

.k-dropdown-operator.k-picker-lg .k-input-button {
    padding: 8px;
}

select.k-picker-lg {
    padding-block: 8px;
    padding-inline: 8px;
}

.k-input > kendo-popup,
.k-picker > kendo-popup {
    position: fixed;
}

@keyframes autoFillStart {
}
@keyframes autoFillEnd {
}
.k-autofill:-webkit-autofill {
    animation-name: autoFillStart;
}

.k-autofill:not(:-webkit-autofill) {
    animation-name: autoFillEnd;
}

.k-input:-webkit-autofill {
    animation-name: autoFillStart;
}

.k-input:not(:-webkit-autofill) {
    animation-name: autoFillEnd;
}

.k-input-solid {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-input-solid:hover,
.k-input-solid.k-hover {
    border-color: rgba(0, 0, 0, 0.16);
}
.k-input-solid:focus,
.k-input-solid.k-focus {
    border-color: rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-solid:focus-within {
    border-color: rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-solid.k-invalid,
.k-input-solid.ng-invalid.ng-touched,
.k-input-solid.ng-invalid.ng-dirty {
    border-color: #f31700;
}
.k-input-solid.k-invalid .k-input-validation-icon,
.k-input-solid.ng-invalid.ng-touched .k-input-validation-icon,
.k-input-solid.ng-invalid.ng-dirty .k-input-validation-icon {
    color: #f31700;
}
.k-picker-solid {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #f5f5f5;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-picker-solid:hover,
.k-picker-solid.k-hover {
    background-color: #ebebeb;
}
.k-picker-solid:focus,
.k-picker-solid.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-solid:focus-within {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-solid.k-invalid,
.k-picker-solid.ng-invalid.ng-touched,
.k-picker-solid.ng-invalid.ng-dirty {
    border-color: #f31700;
}
.k-picker-solid.k-invalid .k-input-validation-icon,
.k-picker-solid.ng-invalid.ng-touched .k-input-validation-icon,
.k-picker-solid.ng-invalid.ng-dirty .k-input-validation-icon {
    color: #f31700;
}
.k-input-outline {
    border-color: rgba(66, 66, 66, 0.5);
    color: #424242;
}
.k-input-outline > .k-input-button {
    border-color: inherit;
}
.k-input-outline > .k-input-spinner {
    border-color: inherit;
}
.k-input-outline > .k-input-spinner > .k-spinner-increase,
.k-input-outline > .k-input-spinner > .k-spinner-decrease {
    border-color: inherit;
}
.k-input-outline:hover,
.k-input-outline.k-hover {
    border-color: rgba(66, 66, 66, 0.8);
}
.k-input-outline:focus,
.k-input-outline.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-outline:focus-within {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-outline.k-invalid,
.k-input-outline.ng-invalid.ng-touched,
.k-input-outline.ng-invalid.ng-dirty {
    border-color: #f31700;
}
.k-input-outline.k-invalid .k-input-validation-icon,
.k-input-outline.ng-invalid.ng-touched .k-input-validation-icon,
.k-input-outline.ng-invalid.ng-dirty .k-input-validation-icon {
    color: #f31700;
}
.k-picker-outline {
    border-color: rgba(66, 66, 66, 0.5);
    color: #424242;
}
.k-picker-outline:hover,
.k-picker-outline.k-hover {
    border-color: #424242;
    color: white;
    background-color: #424242;
}
.k-picker-outline:focus,
.k-picker-outline.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-outline:focus-within {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-outline.k-invalid,
.k-picker-outline.ng-invalid.ng-touched,
.k-picker-outline.ng-invalid.ng-dirty {
    border-color: #f31700;
}
.k-picker-outline.k-invalid .k-input-validation-icon,
.k-picker-outline.ng-invalid.ng-touched .k-input-validation-icon,
.k-picker-outline.ng-invalid.ng-dirty .k-input-validation-icon {
    color: #f31700;
}
.k-input-flat {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
}
.k-input-flat:hover,
.k-input-flat.k-hover {
    border-color: rgba(0, 0, 0, 0.16);
}
.k-input-flat:focus,
.k-input-flat.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-flat:focus-within {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-flat.k-invalid,
.k-input-flat.ng-invalid.ng-touched,
.k-input-flat.ng-invalid.ng-dirty {
    border-color: #f31700;
}
.k-input-flat.k-invalid .k-input-validation-icon,
.k-input-flat.ng-invalid.ng-touched .k-input-validation-icon,
.k-input-flat.ng-invalid.ng-dirty .k-input-validation-icon {
    color: #f31700;
}
.k-picker-flat {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
}
.k-picker-flat:hover,
.k-picker-flat.k-hover {
    border-color: rgba(0, 0, 0, 0.16);
    background-color: rgba(66, 66, 66, 0.04);
}
.k-picker-flat:focus,
.k-picker-flat.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-flat:focus-within {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-flat.k-invalid,
.k-picker-flat.ng-invalid.ng-touched,
.k-picker-flat.ng-invalid.ng-dirty {
    border-color: #f31700;
}
.k-picker-flat.k-invalid .k-input-validation-icon,
.k-picker-flat.ng-invalid.ng-touched .k-input-validation-icon,
.k-picker-flat.ng-invalid.ng-dirty .k-input-validation-icon {
    color: #f31700;
}
.k-popup {
    margin: 0;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.4285714286;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.k-popup .k-item {
    outline: none;
}

.k-animation-container {
    border-radius: 0 0 4px 4px;
}

.k-animation-container-shown {
    overflow: visible;
}

.k-popup > .k-colorpalette {
    padding-block: 8px;
    padding-inline: 8px;
}

.k-popup.k-popup-transparent {
    border-width: 0;
    background-color: transparent;
}

.k-popup.k-popup-flush {
    padding: 0;
}

.k-popup > .k-widget,
.k-popup > .k-coloreditor {
    border-width: 0;
}

.k-shadow {
    box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-popup {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
    box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-animation-container {
    border-radius: 0 0 4px 4px;
}

.k-checkbox {
    margin: 0;
    padding: 0;
    line-height: initial;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    flex: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M3,8 l3,3 l7-7'/%3e%3c/svg%3e");
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='black' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M4,8 h8'/%3e%3c/svg%3e");
}

.k-checkbox:disabled,
.k-checkbox.k-disabled {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}

.k-checkbox-wrap {
    flex: none;
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 0;
    align-items: center;
    align-self: flex-start;
    vertical-align: middle;
    position: relative;
}
.k-checkbox-wrap::before {
    content: '​';
    width: 0px;
    overflow: hidden;
    flex: none;
    display: inline-block;
    vertical-align: top;
}

.k-checkbox-label {
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: flex-start;
    gap: 4px;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
}
.k-checkbox-label .k-ripple {
    visibility: hidden !important;
}

.k-checkbox + .k-label,
.k-checkbox-wrap + .k-label,
.k-checkbox + .k-checkbox-label,
.k-checkbox-wrap + .k-checkbox-label {
    display: inline;
    margin-inline-start: 4px;
}

.k-checkbox-label:empty {
    display: none !important;
}

.k-checkbox-label.k-no-text {
    min-width: 1px;
}

.k-checkbox-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    gap: 0;
    list-style: none;
}

.k-checkbox-item,
.k-checkbox-list-item {
    padding-block: 4px;
    padding-inline: 0px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    gap: 4px;
}
.k-checkbox-item .k-checkbox-label,
.k-checkbox-list-item .k-checkbox-label {
    margin: 0;
}

.k-checkbox-list-horizontal,
.k-checkbox-list.k-list-horizontal {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
}

.k-ripple-container .k-checkbox::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    z-index: -1;
    transition:
        opacity 100ms linear,
        transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center center;
}
.k-ripple-container .k-checkbox:focus,
.k-ripple-container .k-checkbox.k-focus {
    box-shadow: none !important;
}
.k-ripple-container .k-checkbox:disabled::after,
.k-ripple-container .k-checkbox.k-disabled::after {
    display: none;
}

.k-checkbox-sm {
    width: 12px;
    height: 12px;
}
.k-checkbox-sm::before {
    font-size: 10px;
}

.k-ripple-container .k-checkbox-sm::after {
    width: 300%;
    height: 300%;
}

.k-checkbox-md {
    width: 16px;
    height: 16px;
}
.k-checkbox-md::before {
    font-size: 14px;
}

.k-ripple-container .k-checkbox-md::after {
    width: 300%;
    height: 300%;
}

.k-checkbox-lg {
    width: 20px;
    height: 20px;
}
.k-checkbox-lg::before {
    font-size: 18px;
}

.k-ripple-container .k-checkbox-lg::after {
    width: 300%;
    height: 300%;
}

.k-checkbox {
    border-color: rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
}

.k-checkbox:focus,
.k-checkbox.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
    border-color: rgba(0, 0, 0, 0.08);
    color: #4e1289;
    background-color: #ffffff;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
    box-shadow: 0 0 0 2px rgba(78, 18, 137, 0.3);
}

.k-checkbox.k-invalid {
    border-color: #f31700;
}

.k-checkbox.k-invalid + .k-checkbox-label {
    color: #f31700;
}

.k-checkbox-wrap .k-ripple-blob {
    color: #4e1289;
    opacity: 0.25;
}

.k-ripple-container .k-checkbox::after {
    background: #4e1289;
    opacity: 0.25;
}

.k-list-container {
    display: flex;
    flex-flow: column nowrap;
}
.k-list-container > .k-list {
    flex: 1;
    height: 100%;
}

.k-list {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: inherit;
    display: flex;
    flex-flow: column nowrap;
    outline: none;
    position: relative;
    overflow: hidden;
}
.k-list *,
.k-list *::before,
.k-list *::after {
    box-sizing: border-box;
}

.k-popup > .k-list {
    height: 100%;
    border-width: 0;
}

.k-list-group-sticky-header {
    border-width: 0;
    border-width: 0 0 1px;
    border-style: solid;
    font-weight: 700;
    white-space: nowrap;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: none;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.k-list-content {
    border-color: inherit;
    flex: 1 1 auto;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
}

.k-list-ul {
    margin: 0;
    padding: 0;
    border-width: 0;
    border-color: inherit;
    height: auto;
    list-style: none;
}

.k-list-item,
.k-list-optionlabel {
    border: 0;
    outline: none;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    gap: 4px;
    position: relative;
    transition-property: color, background-color, outline-color, box-shadow;
    transition-duration: 200ms;
    transition-timing-function: ease;
}
.k-list-item.k-first::before,
.k-first.k-list-optionlabel::before {
    content: '';
    border-width: 1px 0 0;
    border-style: solid;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.k-list-item-text::before,
.k-list-header-text::before,
.k-list-optionlabel::before {
    content: '​';
    width: 0px;
    overflow: hidden;
}

.k-list-optionlabel {
    column-gap: 0;
}

.k-list-group-item {
    border-width: 0;
    border-width: 1px 0 0;
    border-style: solid;
    font-weight: 700;
    cursor: default;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    gap: 4px;
    position: relative;
}

.k-list-item-group-label {
    padding-block: 0;
    padding-inline: 0.5em;
    font-size: 0.75em;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
}

.k-virtual-content,
.k-virtual-list .k-list-content {
    overflow-y: scroll;
}

.k-virtual-list .k-list-item,
.k-virtual-list .k-list-optionlabel,
.k-virtual-list .k-list-group-item,
.k-virtual-content .k-list-item,
.k-virtual-content .k-list-optionlabel,
.k-virtual-content .k-list-group-item {
    position: absolute;
    width: 100%;
}

.k-virtual-list .k-list-item-text,
.k-virtual-list .k-list-header-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-virtual-list .k-list-optionlabel {
    position: relative;
}

.k-list-filter {
    display: block;
    position: relative;
    padding: 8px;
    box-sizing: border-box;
    flex: none;
}

.k-list-sm {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-list-sm .k-list-group-sticky-header {
    padding-block: 2px;
    padding-inline: 8px;
}
.k-list-sm .k-list-item,
.k-list-sm .k-list-optionlabel {
    padding-block: 2px;
    padding-inline: 8px;
}
.k-list-sm .k-list-group-item {
    padding-block: 2px;
    padding-inline: 8px;
}

.k-list-md {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-list-md .k-list-group-sticky-header {
    padding-block: 4px;
    padding-inline: 8px;
}
.k-list-md .k-list-item,
.k-list-md .k-list-optionlabel {
    padding-block: 4px;
    padding-inline: 8px;
}
.k-list-md .k-list-group-item {
    padding-block: 4px;
    padding-inline: 8px;
}

.k-list-lg {
    font-size: 16px;
    line-height: 1.5;
}
.k-list-lg .k-list-group-sticky-header {
    padding-block: 6px;
    padding-inline: 8px;
}
.k-list-lg .k-list-item,
.k-list-lg .k-list-optionlabel {
    padding-block: 8px;
    padding-inline: 8px;
}
.k-list-lg .k-list-group-item {
    padding-block: 6px;
    padding-inline: 8px;
}

.k-no-data,
.k-nodata {
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: lighter;
    text-align: center;
    white-space: normal;
}

.k-list {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-list-group-sticky-header {
    border-color: inherit;
    box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-list-item:hover,
.k-list-optionlabel:hover,
.k-list-item.k-hover,
.k-hover.k-list-optionlabel {
    color: #424242;
    background-color: #f0f0f0;
}
.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
    color: white;
    background-color: #4e1289;
}
.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
    color: white;
    background-color: #48117e;
}

.k-list-optionlabel,
.k-list-optionlabel.k-hover,
.k-list-optionlabel:hover {
    color: #666666;
}

.k-list-group-item {
    border-color: inherit;
}

.k-list-item-group-label {
    color: #ffffff;
    background-color: #424242;
}

.k-no-data,
.k-nodata {
    color: #666666;
}

.k-calendar {
    inline-size: var(--INTERNAL--kendo-calendar-width, min-content);
    block-size: var(--INTERNAL--kendo-calendar-height, min-content);
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    position: relative;
    overflow: hidden;
    display: inline-flex;
    flex-flow: column nowrap;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-calendar > .k-calendar {
    border: 0;
}

.k-calendar-table {
    margin-block: 0;
    margin-inline: auto;
    border-width: 0;
    border-color: inherit;
    border-spacing: 0;
    border-collapse: separate;
    table-layout: fixed;
    text-align: center;
    outline: 0;
    display: table;
    position: relative;
    z-index: 1;
}

.k-calendar-caption,
.k-calendar-caption.k-calendar-th,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
    padding-block: 4px;
    padding-inline: 8px;
    height: 32px;
    box-sizing: border-box;
    text-transform: none;
    text-align: start;
    font-weight: bold;
    cursor: default;
}

.k-calendar-th,
.k-calendar-td {
    border-width: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    border-color: inherit;
    font-weight: normal;
    cursor: default;
}

.k-calendar-th {
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
    font-size: 12px;
    line-height: 2;
    text-transform: uppercase;
}

.k-calendar-td {
    border-radius: 4px;
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, min-content);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, min-content);
    border-color: transparent;
}

.k-calendar .k-calendar-cell-inner,
.k-calendar .k-link {
    border-radius: 4px;
    padding-inline: var(--INTERNAL--kendo-calendar-cell-padding-x, 0.25em);
    padding-block: var(--INTERNAL--kendo-calendar-cell-padding-y, 0.25em);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: normal;
    position: relative;
    overflow: hidden;
}

.k-calendar .k-header {
    padding-block: 4px;
    padding-inline: 4px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    z-index: 2;
}

.k-calendar-header {
    padding-block: 4px;
    padding-inline: 4px;
    min-width: 256px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
}
.k-calendar-header .k-title,
.k-calendar-header .k-calendar-title {
    font-weight: bold;
}
.k-calendar-header .k-calendar-nav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.k-calendar-footer,
.k-calendar .k-footer {
    padding-block: 8px;
    padding-inline: 16px;
    text-align: center;
    clear: both;
}

.k-calendar-view {
    margin: auto;
    padding-block: 0;
    padding-inline: 4px;
    width: 256px;
    inline-size: var(--INTERNAL--kendo-calendar-view-width, 256px);
    min-height: 224px;
    box-sizing: content-box;
    gap: 16px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.k-month-calendar .k-calendar-td,
.k-calendar-monthview .k-calendar-td {
    width: 32px;
    height: 32px;
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
}

.k-year-calendar .k-calendar-td,
.k-calendar-yearview .k-calendar-td {
    width: 64px;
    height: 64px;
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}

.k-decade-calendar .k-calendar-td,
.k-calendar-decadeview .k-calendar-td {
    width: 64px;
    height: 64px;
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}

.k-century-calendar .k-calendar-td,
.k-calendar-centuryview .k-calendar-td {
    width: 64px;
    height: 64px;
    inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
    block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}
.k-century-calendar .k-calendar-cell-inner,
.k-century-calendar .k-link,
.k-calendar-centuryview .k-calendar-cell-inner,
.k-calendar-centuryview .k-link {
    text-align: start;
}

.k-popup .k-calendar {
    height: 100%;
}

.k-calendar-container,
.k-datetime-container {
    padding: 0;
}
.k-calendar-container .k-calendar,
.k-datetime-container .k-calendar {
    border-width: 0;
}

.k-calendar .k-content.k-scrollable,
.k-calendar .k-calendar-content.k-scrollable {
    box-sizing: content-box;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    padding-right: 100px;
    padding-left: 100px;
    margin-left: -100px;
    margin-right: calc(-100px - var(--kendo-scrollbar-width, 17px));
}

.k-calendar .k-scrollable-placeholder {
    position: absolute;
    z-index: -1;
    width: 1px;
    top: 0;
    right: 0;
}

.k-calendar-sm {
    --INTERNAL--kendo-calendar-view-width: 224px;
    --INTERNAL--kendo-calendar-cell-padding-x: var(--kendo-calendar-sm-cell-padding-x, 2px);
    --INTERNAL--kendo-calendar-cell-padding-y: var(--kendo-calendar-sm-cell-padding-y, 2px);
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-calendar-sm.k-month-calendar,
.k-calendar-sm .k-calendar-monthview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-sm-month-cell-size, 28px);
}
.k-calendar-sm.k-year-calendar,
.k-calendar-sm .k-calendar-yearview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-sm-year-cell-size, 56px);
}
.k-calendar-sm.k-decade-calendar,
.k-calendar-sm .k-calendar-decadeview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-sm-decade-cell-size, 56px);
}
.k-calendar-sm.k-century-calendar,
.k-calendar-sm .k-calendar-centuryview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-sm-century-cell-size, 56px);
}

.k-calendar-md {
    --INTERNAL--kendo-calendar-view-width: 256px;
    --INTERNAL--kendo-calendar-cell-padding-x: var(--kendo-calendar-md-cell-padding-x, 4px);
    --INTERNAL--kendo-calendar-cell-padding-y: var(--kendo-calendar-md-cell-padding-y, 4px);
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-calendar-md.k-month-calendar,
.k-calendar-md .k-calendar-monthview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-md-month-cell-size, 32px);
}
.k-calendar-md.k-year-calendar,
.k-calendar-md .k-calendar-yearview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-md-year-cell-size, 64px);
}
.k-calendar-md.k-decade-calendar,
.k-calendar-md .k-calendar-decadeview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-md-decade-cell-size, 64px);
}
.k-calendar-md.k-century-calendar,
.k-calendar-md .k-calendar-centuryview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-md-century-cell-size, 64px);
}

.k-calendar-lg {
    --INTERNAL--kendo-calendar-view-width: 320px;
    --INTERNAL--kendo-calendar-cell-padding-x: var(--kendo-calendar-lg-cell-padding-x, 8px);
    --INTERNAL--kendo-calendar-cell-padding-y: var(--kendo-calendar-lg-cell-padding-y, 8px);
    font-size: 16px;
    line-height: 1.5;
}
.k-calendar-lg.k-month-calendar,
.k-calendar-lg .k-calendar-monthview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-lg-month-cell-size, 40px);
}
.k-calendar-lg.k-year-calendar,
.k-calendar-lg .k-calendar-yearview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-lg-year-cell-size, 80px);
}
.k-calendar-lg.k-decade-calendar,
.k-calendar-lg .k-calendar-decadeview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-lg-decade-cell-size, 80px);
}
.k-calendar-lg.k-century-calendar,
.k-calendar-lg .k-calendar-centuryview {
    --INTERNAL--kendo-calendar-cell-size: var(--kendo-calendar-lg-century-cell-size, 80px);
}

.k-calendar-infinite {
    box-sizing: content-box;
    display: inline-flex;
    flex-flow: row nowrap;
}
.k-calendar-infinite .k-calendar-view {
    padding-block: 0px;
    padding-inline: 16px;
    height: 288px;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column nowrap;
    gap: 0;
    overflow: hidden;
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
    position: relative;
}
.k-calendar-infinite .k-calendar-view::after {
    display: block;
    position: absolute;
    bottom: 0;
    content: '​';
    height: 0;
    line-height: 0;
    z-index: 1;
    width: 150%;
    left: -25%;
    box-shadow: 0 0 32px 16px #ffffff;
}
.k-calendar-infinite .k-calendar-header {
    margin-left: -16px;
    margin-right: -16px;
    padding-block: 8px;
    padding-inline: 16px;
    width: auto;
    min-width: 0;
}
.k-calendar-infinite .k-calendar-weekdays {
    flex: 0 0 auto;
}

.k-calendar-navigation {
    width: 5em;
    text-align: center;
    flex: 0 0 auto;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.k-calendar-navigation::before,
.k-calendar-navigation::after {
    display: block;
    position: absolute;
    content: '​';
    height: 0;
    line-height: 0;
    z-index: 1;
    width: 200%;
    left: -50%;
    box-shadow: 0 0 6em 3em #fafafa;
}
.k-calendar-navigation::before {
    top: 0;
}
.k-calendar-navigation::after {
    bottom: 0;
}
.k-calendar-navigation .k-content,
.k-calendar-navigation .k-calendar-content {
    background: transparent;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.k-calendar-navigation .k-content ul,
.k-calendar-navigation .k-calendar-content ul {
    width: 5em;
}
.k-calendar-navigation .k-content li,
.k-calendar-navigation .k-calendar-content li {
    height: 2em;
    line-height: 2em;
    cursor: pointer;
    padding-block: 0;
    padding-inline: 1em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
}
.k-calendar-navigation .k-calendar-navigation-marker {
    font-weight: bold;
}
.k-calendar-navigation .k-calendar-navigation-highlight {
    width: 100%;
    border-width: 1px 0;
    border-style: solid;
    height: 2em;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.k-calendar-range {
    width: auto;
}
.k-calendar-range .k-calendar-view {
    width: auto;
    white-space: nowrap;
}
.k-calendar-range .k-calendar-view::after {
    display: none;
}
.k-calendar-range .k-calendar-view:focus {
    outline: 0;
}
.k-calendar-range .k-range-start {
    border-color: inherit;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.k-calendar-range .k-range-end {
    border-color: inherit;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.k-calendar-range .k-range-mid {
    border-color: inherit;
    border-radius: 0;
}

.k-rtl .k-calendar .k-content.k-scrollable,
.k-rtl .k-calendar .k-calendar-content.k-scrollable,
[dir='rtl'] .k-calendar .k-content.k-scrollable,
[dir='rtl'] .k-calendar .k-calendar-content.k-scrollable,
.k-calendar.k-rtl .k-content.k-scrollable,
.k-calendar.k-rtl .k-calendar-content.k-scrollable,
.k-calendar[dir='rtl'] .k-content.k-scrollable,
.k-calendar[dir='rtl'] .k-calendar-content.k-scrollable {
    padding-right: 100px;
    padding-left: 100px;
    margin-left: calc(-100px - var(--kendo-scrollbar-width, 17px));
    margin-right: -100px;
}

.k-calendar {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-calendar .k-header {
    border-color: inherit;
    color: #424242;
    background-color: #fafafa;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.k-calendar .k-calendar-th,
.k-calendar .k-calendar-caption,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
    color: #666666;
}
.k-calendar .k-calendar-view .k-today {
    color: #4e1289;
}
.k-calendar .k-other-month {
    color: #666666;
}
.k-calendar .k-alt {
    background-color: transparent;
    color: #666666;
}
.k-calendar .k-out-of-range {
    pointer-events: none;
}
.k-calendar .k-calendar-td:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #f0f0f0;
}
.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
    border-color: rgba(0, 0, 0, 0.08);
    color: white;
    background-color: #4e1289;
}
.k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
    color: white;
    background-color: #4b1184;
}
.k-calendar .k-calendar-td:focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td:focus .k-link,
.k-calendar .k-calendar-td.k-focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-focus .k-link {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-calendar .k-calendar-td.k-selected:focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected:focus .k-link,
.k-calendar .k-calendar-td.k-selected.k-focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-focus .k-link {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-calendar .k-calendar-navigation {
    color: #424242;
    background-color: #fafafa;
    box-shadow: inset -1px 0 rgba(0, 0, 0, 0.08);
}
.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-hover {
    color: #420f73;
}
.k-calendar .k-calendar-navigation-highlight {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-calendar[dir='rtl'] .k-calendar-navigation,
.k-rtl .k-calendar .k-calendar-navigation {
    box-shadow: inset 1px 0 rgba(0, 0, 0, 0.08);
}
.k-calendar.k-invalid,
.k-calendar.k-invalid:hover,
.k-calendar.ng-invalid.ng-touched,
.k-calendar.ng-invalid.ng-dirty {
    border-color: #f31700;
}

.k-scheduler .k-calendar .k-other-month {
    color: #666666;
    background-color: transparent;
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
    background-color: rgba(78, 18, 137, 0.25);
}
.k-calendar .k-range-start.k-range-end {
    background-image: none;
    background-color: transparent;
}
.k-calendar .k-range-start .k-calendar-cell-inner,
.k-calendar .k-range-start .k-link,
.k-calendar .k-range-end .k-calendar-cell-inner,
.k-calendar .k-range-end .k-link {
    background-color: #4e1289;
}
.k-calendar .k-range-start.k-active .k-calendar-cell-inner,
.k-calendar .k-range-start.k-active .k-link,
.k-calendar .k-range-end.k-active .k-calendar-cell-inner,
.k-calendar .k-range-end.k-active .k-link {
    box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
.k-calendar .k-range-split-start,
.k-calendar .k-range-split-end {
    position: relative;
}
.k-calendar .k-range-split-start::after,
.k-calendar .k-range-split-end::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
}
.k-calendar .k-range-split-start::after {
    left: -5px;
    right: auto;
    background-image: linear-gradient(to left, rgba(78, 18, 137, 0.25), transparent 100%);
}
.k-calendar .k-range-split-end::after {
    right: -5px;
    left: auto;
    background-image: linear-gradient(to right, rgba(78, 18, 137, 0.25), transparent 100%);
}

.k-floating-label-container {
    padding-top: calc(1.4285714286 * 14px);
    box-sizing: border-box;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    flex-direction: column;
    justify-content: stretch;
}
.k-floating-label-container *,
.k-floating-label-container *::before,
.k-floating-label-container *::after {
    box-sizing: border-box;
}
.k-floating-label-container > .k-label {
    max-width: 90%;
    font-size: 14px;
    line-height: 1.4285714286;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    top: calc(calc(1.4285714286 * 14px) + 1px + 4px);
    left: calc(8px + 1px);
    overflow: hidden;
    cursor: text;
    transform-origin: left center;
    transition:
        transform 0.2s ease-out,
        color 0.2s ease-out,
        top 0.2s ease-out,
        left 0.2s ease-out;
}
.k-floating-label-container > .k-widget {
    flex: 1 1 auto;
    width: auto;
}
.k-floating-label-container.k-empty > .k-label {
    top: calc(calc(1.4285714286 * 14px) + 1px + 4px);
    left: calc(8px + 1px);
    transform: scale(1);
    pointer-events: none;
}
.k-floating-label-container > .k-label,
.k-floating-label-container.k-focus > .k-label {
    top: 0;
    left: 0;
    transform: scale(1);
}
.k-floating-label-container:focus-within > .k-label {
    top: 0;
    left: 0;
    transform: scale(1);
}
.k-floating-label-container.k-empty:not(.k-focus):not(:focus-within) ::placeholder {
    color: transparent;
}
[dir='rtl'] .k-floating-label-container > .k-label,
.k-rtl .k-floating-label-container > .k-label,
.k-floating-label-container[dir='rtl'] > .k-label {
    transform-origin: right center;
    transition:
        transform 0.2s ease-out,
        color 0.2s ease-out,
        top 0.2s ease-out,
        right 0.2s ease-out;
}
[dir='rtl'] .k-floating-label-container.k-empty > .k-label,
.k-rtl .k-floating-label-container.k-empty > .k-label,
.k-floating-label-container[dir='rtl'].k-empty > .k-label {
    left: auto;
    right: calc(8px + 1px);
}
[dir='rtl'] .k-floating-label-container > .k-label,
[dir='rtl'] .k-floating-label-container.k-focus > .k-label,
.k-rtl .k-floating-label-container > .k-label,
.k-rtl .k-floating-label-container.k-focus > .k-label,
.k-floating-label-container[dir='rtl'] > .k-label,
.k-floating-label-container[dir='rtl'].k-focus > .k-label {
    left: auto;
    right: 0;
}
[dir='rtl'] .k-floating-label-container:focus-within > .k-label,
.k-rtl .k-floating-label-container:focus-within > .k-label,
.k-floating-label-container[dir='rtl']:focus-within > .k-label {
    left: auto;
    right: 0;
}

.k-floating-label-container.k-invalid > .k-label,
.k-floating-label-container.ng-invalid.ng-touched > .k-label,
.k-floating-label-container.ng-invalid.ng-dirty > .k-label {
    color: #f31700;
}

.k-daterange-picker,
.k-daterangepicker {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    gap: 8px;
}
.k-daterange-picker *,
.k-daterangepicker *,
.k-daterange-picker *::before,
.k-daterangepicker *::before,
.k-daterange-picker *::after,
.k-daterangepicker *::after {
    box-sizing: border-box;
}
.k-daterange-picker .k-dateinput,
.k-daterangepicker .k-dateinput {
    width: 10em;
}

.k-daterangepicker-wrap {
    display: inherit;
    flex-flow: inherit;
    align-items: inherit;
    gap: inherit;
}

.k-actions,
.k-form-buttons,
.k-edit-buttons,
.k-action-buttons,
.k-columnmenu-actions {
    padding-block: 8px;
    padding-inline: 8px;
    box-sizing: border-box;
    border-width: 0px 0 0 0;
    border-style: solid;
    border-color: inherit;
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    clear: both;
}
.k-actions *,
.k-form-buttons *,
.k-edit-buttons *,
.k-action-buttons *,
.k-columnmenu-actions *,
.k-actions *::before,
.k-form-buttons *::before,
.k-edit-buttons *::before,
.k-action-buttons *::before,
.k-columnmenu-actions *::before,
.k-actions *::after,
.k-form-buttons *::after,
.k-edit-buttons *::after,
.k-action-buttons *::after,
.k-columnmenu-actions *::after {
    box-sizing: border-box;
}

.k-popup .k-actions,
.k-popup .k-form-buttons,
.k-popup .k-edit-buttons,
.k-popup .k-action-buttons,
.k-popup .k-columnmenu-actions {
    margin-top: 16px;
}

.k-actions-start {
    justify-content: flex-start;
}

.k-actions-center {
    justify-content: center;
}

.k-actions-end {
    justify-content: flex-end;
}

.k-actions-stretched > * {
    flex: 1 0 0%;
}

.k-actions-horizontal {
    width: 100%;
    flex-flow: row nowrap;
}

.k-actions-vertical {
    flex-flow: column nowrap;
}

.k-timeselector {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-popup > .k-timeselector {
    border-width: 0;
}

.k-time-header,
.k-time-selector-header {
    padding-block: 8px;
    padding-inline: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
}
.k-time-header .k-title,
.k-time-header .k-time-selector-header-title,
.k-time-selector-header .k-title,
.k-time-selector-header .k-time-selector-header-title {
    padding-block: 4px;
    padding-inline: 8px;
    font-weight: bold;
    display: inline-block;
}
.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
    border-width: 0;
    line-height: inherit;
    cursor: pointer;
}

.k-actions.k-time-fast-selection,
.k-time-fast-selection.k-form-buttons,
.k-time-fast-selection.k-edit-buttons,
.k-time-fast-selection.k-action-buttons,
.k-time-fast-selection.k-columnmenu-actions {
    margin-top: 0;
}

.k-time-list-container {
    display: flex;
    position: relative;
    flex: 1 1 auto;
    overflow: hidden;
}

.k-time-highlight,
.k-time-list-highlight {
    width: 100%;
    height: calc(20px + 8px);
    border-width: 1px 0px;
    border-style: solid;
    box-sizing: border-box;
    position: absolute;
    top: calc(50% + 9px);
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
}

.k-time-list-wrapper {
    min-width: 4em;
    height: 240px;
    box-sizing: content-box;
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: stretch;
    overflow: hidden;
    position: relative;
    text-align: center;
    flex: 1 1 auto;
}
.k-time-list-wrapper .k-title {
    font-size: 12px;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    display: block;
}
.k-time-list-wrapper.k-focus::before,
.k-time-list-wrapper.k-focus::after {
    display: block;
    content: '​';
    position: absolute;
    width: 100%;
    left: 0;
    pointer-events: none;
    height: calc(50% - calc(20px + 8px) / 2);
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
}
.k-time-list-wrapper.k-focus::before {
    top: 18px;
}
.k-time-list-wrapper.k-focus::after {
    bottom: 0;
}

.k-time-list {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    flex: 1;
    position: relative;
    z-index: 1;
    outline: 0;
    overflow: hidden;
}
.k-time-list::before,
.k-time-list::after {
    display: block;
    position: absolute;
    content: '​';
    height: 0;
    line-height: 0;
    z-index: 1;
    width: 200%;
    left: -50%;
}
.k-time-list::before {
    top: 0;
}
.k-time-list::after {
    bottom: 0;
}

.k-time-container,
.k-time-list-content {
    position: relative;
    flex: 1 1 auto;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 100px;
    padding-left: 100px;
    margin-left: -100px;
    margin-right: calc(-100px - var(--kendo-scrollbar-width, 17px));
}
.k-time-container > ul,
.k-time-list-content > ul {
    height: auto;
    width: 4em;
    margin: auto;
}
.k-time-container .k-scrollable-placeholder,
.k-time-list-content .k-scrollable-placeholder {
    position: absolute;
    width: 1px;
    top: 0;
    right: 0;
}

.k-time-list-item,
.k-time-list .k-item {
    padding-block: 4px;
    padding-inline: 8px;
}

.k-time-separator {
    width: 0;
    height: calc(20px + 8px);
    align-self: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 11;
    top: calc(9px);
}

.k-timeselector-sm {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-timeselector-sm .k-time-highlight,
.k-timeselector-sm .k-time-list-highlight {
    height: calc(20px + 4px);
}
.k-timeselector-sm .k-time-separator {
    height: calc(20px + 4px);
}
.k-timeselector-sm .k-time-list-item,
.k-timeselector-sm .k-time-list .k-item {
    padding-block: 2px;
    padding-inline: 8px;
}

.k-timeselector-md {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-timeselector-md .k-time-highlight,
.k-timeselector-md .k-time-list-highlight {
    height: calc(20px + 8px);
}
.k-timeselector-md .k-time-separator {
    height: calc(20px + 8px);
}
.k-timeselector-md .k-time-list-item,
.k-timeselector-md .k-time-list .k-item {
    padding-block: 4px;
    padding-inline: 8px;
}

.k-timeselector-lg {
    font-size: 16px;
    line-height: 1.5;
}
.k-timeselector-lg .k-time-highlight,
.k-timeselector-lg .k-time-list-highlight {
    height: calc(24px + 16px);
}
.k-timeselector-lg .k-time-separator {
    height: calc(24px + 16px);
}
.k-timeselector-lg .k-time-list-item,
.k-timeselector-lg .k-time-list .k-item {
    padding-block: 8px;
    padding-inline: 8px;
}

.k-rtl .k-time-container,
.k-rtl .k-time-list-content,
[dir='rtl'] .k-time-container,
[dir='rtl'] .k-time-list-content {
    padding-right: 100px;
    padding-left: 100px;
    margin-left: calc(-100px - var(--kendo-scrollbar-width, 17px));
    margin-right: -100px;
}

.k-timeselector {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
    color: #4e1289;
}
.k-time-header .k-time-now:hover,
.k-time-selector-header .k-time-now:hover {
    color: #420f73;
}

.k-time-list-wrapper .k-title {
    color: #666666;
}
.k-time-list-wrapper.k-focus .k-title {
    color: #424242;
}
.k-time-list-wrapper.k-focus::before,
.k-time-list-wrapper.k-focus::after {
    background-color: rgba(0, 0, 0, 0.04);
}

.k-time-list::before,
.k-time-list::after {
    box-shadow: 0 0 3em 1.5em #ffffff;
}
.k-time-list .k-item:hover {
    color: #4e1289;
}

.k-time-container {
    background: transparent;
}

.k-time-highlight {
    border-color: rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
}

.k-datetime-selector {
    display: flex;
    transition: transform 0.2s;
}

.k-datetime-wrap {
    width: calc(256px + 32px);
    overflow: hidden;
}
.k-datetime-wrap .k-datetime-buttongroup {
    padding-block: 8px;
    padding-inline: 8px;
}
.k-datetime-wrap .k-calendar,
.k-datetime-wrap .k-timeselector {
    width: 100%;
    border-width: 0;
}

.k-datetime-calendar-wrap,
.k-datetime-time-wrap {
    text-align: center;
    flex: 0 0 calc(256px + 32px);
}

.k-date-tab .k-datetime-selector {
    transform: translateX(0);
}

.k-time-tab .k-datetime-selector {
    transform: translateX(-100%);
}

.k-time-tab .k-datetime-selector.k-rtl,
.k-time-tab .k-datetime-selector[dir='rtl'],
.k-rtl .k-time-tab .k-datetime-selector,
[dir='rtl'] .k-time-tab .k-datetime-selector {
    transform: translateX(100%);
}

.k-dropdown-operator {
    width: min-content;
    flex: none;
}
.k-dropdown-operator .k-input-button {
    border-width: 0;
    width: min-content;
}
.k-dropdown-operator .k-input-button .k-button-icon {
    width: auto;
    aspect-ratio: 1;
}
.k-dropdown-operator .k-input-inner {
    display: none;
}

.k-listgroup {
    border-radius: 4px;
    margin: 0;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    font-size: 14px;
    line-height: 1.4285714286;
    list-style: none;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}
.k-listgroup > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}
.k-listgroup .k-listgroup-item + .k-listgroup-item {
    border-top-width: 1px;
}

.k-listgroup-flush {
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0;
}

.k-listgroup-item {
    padding-block: 8px;
    padding-inline: 8px;
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 auto;
    position: relative;
}
.k-listgroup-item > .k-link {
    margin-block: -8px;
    margin-inline: -8px;
    padding-block: 8px;
    padding-inline: 8px;
    color: inherit;
    text-decoration: none;
    outline: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 auto;
    position: relative;
}
.k-listgroup-item > .k-link > .k-select {
    padding: 8px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    transform: translateY(-50%);
}

.k-listgroup-item-segmented {
    align-items: stretch;
}
.k-listgroup-item-segmented > .k-link {
    margin-right: 0;
}
.k-listgroup-item-segmented > .k-select {
    margin: -8px -8px -8px 0;
    padding: 8px;
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: inherit;
    cursor: pointer;
}

.k-listgroup-form-row {
    margin-block: -8px;
    margin-inline: -8px;
    padding-block: 8px;
    padding-inline: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
    position: relative;
}
.k-listgroup-form-row .k-listgroup-form-field-label {
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    text-align: end;
    position: relative;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper select,
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type='text'],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type='password'],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type='date'],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type='time'],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type='datetime-local'],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type='number'],
.k-listgroup-form-row .k-listgroup-form-field-wrapper textarea {
    width: 100%;
    box-sizing: border-box;
    flex: 1 1 100%;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper textarea {
    min-height: 4em;
    resize: vertical;
}

.k-listgroup-item.k-listgroup-form-row {
    margin: 0;
}

[dir='rtl'] .k-listgroup-item > .k-link > .k-select {
    right: auto;
    left: 0;
}
[dir='rtl'] .k-listgroup-item-segmented > .k-link {
    margin-right: -8px;
    margin-left: 0;
}
[dir='rtl'] .k-listgroup-item-segmented > .k-select {
    margin-right: 0;
    margin-left: -8px;
    border-right-width: 1px;
    border-left-width: 0;
}

.k-noflexbox .k-listgroup,
.k-noflexbox .k-listgroup > ul .k-listgroup-item,
.k-noflexbox .k-listgroup-item > .k-link {
    display: block;
}

.k-listgroup {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-radio {
    border-radius: 50%;
    margin: 0;
    padding: 0;
    line-height: initial;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    flex: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
}

.k-radio:checked,
.k-radio.k-checked {
    background-image: linear-gradient(black, black);
}

.k-radio:disabled,
.k-radio.k-disabled {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}

.k-radio-wrap {
    flex: none;
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 0;
    align-items: center;
    align-self: start;
    vertical-align: middle;
    position: relative;
}
.k-radio-wrap::before {
    content: '​';
    width: 0px;
    overflow: hidden;
    flex: none;
    display: inline-block;
    vertical-align: top;
}

.k-radio-label {
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: flex-start;
    gap: 4px;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
}
.k-radio-label .k-ripple {
    visibility: hidden !important;
}

.k-radio + .k-label,
.k-radio-wrap + .k-label,
.k-radio + .k-radio-label,
.k-radio-wrap + .k-radio-label {
    display: inline;
    margin-inline-start: 4px;
}

.k-radio-label:empty {
    display: none !important;
}

.k-radio-label.k-no-text {
    min-width: 1px;
}

.k-radio-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    gap: 0;
    list-style: none;
}

.k-radio-item,
.k-radio-list-item {
    padding-block: 4px;
    padding-inline: 0px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    gap: 4px;
}
.k-radio-item .k-radio-label,
.k-radio-list-item .k-radio-label {
    margin: 0;
}

.k-radio-list-horizontal,
.k-radio-list.k-list-horizontal {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
}

.k-ripple-container .k-radio::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    z-index: -1;
    transition:
        opacity 100ms linear,
        transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center center;
}
.k-ripple-container .k-radio:focus,
.k-ripple-container .k-radio.k-focus {
    box-shadow: none !important;
}
.k-ripple-container .k-radio:disabled::after,
.k-ripple-container .k-radio.k-disabled::after {
    display: none;
}
.k-ripple-container .k-radio:disabled::after,
.k-ripple-container .k-radio.k-disabled::after {
    display: none;
}

.k-radio-sm {
    width: 12px;
    height: 12px;
}
.k-radio-sm::before {
    font-size: 10px;
}

.k-ripple-container .k-radio-sm::after {
    width: 300%;
    height: 300%;
}

.k-radio-md {
    width: 16px;
    height: 16px;
}
.k-radio-md::before {
    font-size: 14px;
}

.k-ripple-container .k-radio-md::after {
    width: 300%;
    height: 300%;
}

.k-radio-lg {
    width: 20px;
    height: 20px;
}
.k-radio-lg::before {
    font-size: 18px;
}

.k-ripple-container .k-radio-lg::after {
    width: 300%;
    height: 300%;
}

.k-radio {
    border-color: rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
}

.k-radio:focus,
.k-radio.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}

.k-radio.k-invalid,
.k-radio.ng-invalid.ng-touched,
.k-radio.ng-invalid.ng-dirty {
    border-color: #f31700;
}

.k-radio.k-invalid + .k-radio-label,
.k-radio.ng-invalid.ng-touched + .k-radio-label,
.k-radio.ng-invalid.ng-dirty + .k-radio-label {
    color: #f31700;
}

.k-radio:checked,
.k-radio.k-checked {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}

.k-radio:checked:focus,
.k-radio.k-checked.k-focus {
    box-shadow: 0 0 0 2px rgba(78, 18, 137, 0.3);
}

.k-ripple-container .k-radio::after {
    background: #4e1289;
    opacity: 0.25;
}

.k-switch {
    box-sizing: border-box;
    outline: 0;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    line-height: 1;
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-switch *,
.k-switch *::before,
.k-switch *::after {
    box-sizing: border-box;
}
.k-switch [type='checkbox'] {
    display: none;
}
.k-switch[aria-readonly='true'] {
    pointer-events: none;
}

.k-switch.k-readonly {
    pointer-events: none;
}

.k-switch-track {
    border-width: 0px;
    border-style: solid;
    outline: 0;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
    position: relative;
    transition: background-color 200ms ease-in-out;
}

.k-switch-thumb-wrap {
    width: 0;
    height: 0;
    overflow: visible;
    position: absolute;
    transition: left 200ms ease-in-out;
    top: 50%;
}

.k-switch-thumb {
    border-width: 1px;
    border-style: solid;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
}

.k-switch-label-on,
.k-switch-label-off {
    text-transform: uppercase;
    display: inline;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
}

.k-switch-sm {
    width: 52px;
    height: 26px;
    font-size: 10px;
}
.k-switch-sm .k-switch-track {
    width: 52px;
    height: 26px;
}
.k-switch-sm .k-switch-label-on {
    left: 4px;
}
.k-switch-sm .k-switch-label-off {
    right: 4px;
}
.k-switch-sm .k-switch-thumb {
    width: 26px;
    height: 26px;
}
.k-switch-sm.k-switch-on .k-switch-thumb-wrap {
    left: calc(100% - 13px - 0px);
}
.k-switch-sm.k-switch-off .k-switch-thumb-wrap {
    left: calc(13px + 0px);
}

.k-switch-sm[dir='rtl'] .k-switch-label-on,
[dir='rtl'] .k-switch-sm .k-switch-label-on,
.k-rtl .k-switch-sm .k-switch-label-on {
    left: auto;
    right: 4px;
}
.k-switch-sm[dir='rtl'] .k-switch-label-off,
[dir='rtl'] .k-switch-sm .k-switch-label-off,
.k-rtl .k-switch-sm .k-switch-label-off {
    right: auto;
    left: 4px;
}
.k-switch-sm[dir='rtl'].k-switch-on .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-sm.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-sm.k-switch-on .k-switch-thumb-wrap {
    left: calc(13px + 0px);
}
.k-switch-sm[dir='rtl'].k-switch-off .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-sm.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-sm.k-switch-off .k-switch-thumb-wrap {
    left: calc(100% - 13px - 0px);
}

.k-switch-md {
    width: 60px;
    height: 30px;
    font-size: 10px;
}
.k-switch-md .k-switch-track {
    width: 60px;
    height: 30px;
}
.k-switch-md .k-switch-label-on {
    left: 6px;
}
.k-switch-md .k-switch-label-off {
    right: 6px;
}
.k-switch-md .k-switch-thumb {
    width: 30px;
    height: 30px;
}
.k-switch-md.k-switch-on .k-switch-thumb-wrap {
    left: calc(100% - 15px - 0px);
}
.k-switch-md.k-switch-off .k-switch-thumb-wrap {
    left: calc(15px + 0px);
}

.k-switch-md[dir='rtl'] .k-switch-label-on,
[dir='rtl'] .k-switch-md .k-switch-label-on,
.k-rtl .k-switch-md .k-switch-label-on {
    left: auto;
    right: 6px;
}
.k-switch-md[dir='rtl'] .k-switch-label-off,
[dir='rtl'] .k-switch-md .k-switch-label-off,
.k-rtl .k-switch-md .k-switch-label-off {
    right: auto;
    left: 6px;
}
.k-switch-md[dir='rtl'].k-switch-on .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-md.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-md.k-switch-on .k-switch-thumb-wrap {
    left: calc(15px + 0px);
}
.k-switch-md[dir='rtl'].k-switch-off .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-md.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-md.k-switch-off .k-switch-thumb-wrap {
    left: calc(100% - 15px - 0px);
}

.k-switch-lg {
    width: 68px;
    height: 34px;
    font-size: 10px;
}
.k-switch-lg .k-switch-track {
    width: 68px;
    height: 34px;
}
.k-switch-lg .k-switch-label-on {
    left: 8px;
}
.k-switch-lg .k-switch-label-off {
    right: 8px;
}
.k-switch-lg .k-switch-thumb {
    width: 34px;
    height: 34px;
}
.k-switch-lg.k-switch-on .k-switch-thumb-wrap {
    left: calc(100% - 17px - 0px);
}
.k-switch-lg.k-switch-off .k-switch-thumb-wrap {
    left: calc(17px + 0px);
}

.k-switch-lg[dir='rtl'] .k-switch-label-on,
[dir='rtl'] .k-switch-lg .k-switch-label-on,
.k-rtl .k-switch-lg .k-switch-label-on {
    left: auto;
    right: 8px;
}
.k-switch-lg[dir='rtl'] .k-switch-label-off,
[dir='rtl'] .k-switch-lg .k-switch-label-off,
.k-rtl .k-switch-lg .k-switch-label-off {
    right: auto;
    left: 8px;
}
.k-switch-lg[dir='rtl'].k-switch-on .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-lg.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-lg.k-switch-on .k-switch-thumb-wrap {
    left: calc(17px + 0px);
}
.k-switch-lg[dir='rtl'].k-switch-off .k-switch-thumb-wrap,
[dir='rtl'] .k-switch-lg.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-lg.k-switch-off .k-switch-thumb-wrap {
    left: calc(100% - 17px - 0px);
}

.k-switch[dir='rtl'] .k-switch-thumb,
[dir='rtl'] .k-switch .k-switch-thumb,
.k-switch.k-rtl .k-switch-thumb,
.k-rtl .k-switch .k-switch-thumb {
    transform: translate(50%, -50%);
}

.k-switch-off .k-switch-track {
    border-color: #ebebeb;
    color: #424242;
    background-color: #ffffff;
}
.k-switch-off .k-switch-thumb {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #4e1289;
}
.k-switch-off:focus .k-switch-track,
.k-switch-off.k-focus .k-switch-track {
    outline: 2px solid rgba(0, 0, 0, 0.08);
}
.k-switch-off .k-switch-label-on {
    color: transparent;
}

.k-switch-on .k-switch-track {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}
.k-switch-on .k-switch-thumb {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-switch-on:focus .k-switch-track,
.k-switch-on.k-focus .k-switch-track {
    outline: 2px solid rgba(78, 18, 137, 0.25);
}
.k-switch-on .k-switch-label-off {
    color: transparent;
}

.k-menu-bar,
.k-menu {
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    position: relative;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-menu-bar *,
.k-menu *,
.k-menu-bar *::before,
.k-menu *::before,
.k-menu-bar *::after,
.k-menu *::after {
    box-sizing: border-box;
}

.k-menu-item {
    border-width: 0;
    outline: 0;
    display: flex;
    flex-flow: column nowrap;
    flex: none;
    position: relative;
    user-select: none;
}

.k-menu-item-content {
    display: block;
    height: auto;
    overflow: visible;
}
.k-menu-item-content .k-actions,
.k-menu-item-content .k-form-buttons,
.k-menu-item-content .k-edit-buttons,
.k-menu-item-content .k-action-buttons,
.k-menu-item-content .k-columnmenu-actions {
    margin-top: 0;
}

.k-menu-link {
    padding-block: 6px;
    padding-inline: 12px;
    outline: 0;
    color: inherit;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    gap: 4px;
    align-items: center;
    position: relative;
    white-space: nowrap;
    cursor: pointer;
}

.k-menu-link-text {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-menu-expand-arrow {
    margin-inline-end: -4px;
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    flex: none;
    position: relative;
}

.k-menu-horizontal {
    flex-direction: row;
}
.k-menu-horizontal > .k-menu-item + .k-menu-item {
    margin-inline-start: 0;
}
.k-menu-horizontal > .k-separator {
    margin-block: 0;
    margin-inline: 4px;
    width: 0;
    height: auto;
    border-width: 0 0 0 1px;
    border-style: solid;
}

.k-menu-vertical {
    flex-direction: column;
}
.k-menu-vertical > .k-menu-item + .k-menu-item {
    margin-top: 0;
}
.k-menu-vertical > .k-menu-item > .k-menu-link {
    padding-block: 4px;
    padding-inline: 8px;
    padding-inline-end: calc(16px + 16px);
}
.k-menu-vertical > .k-menu-item > .k-menu-link > .k-menu-expand-arrow {
    margin-inline-start: 8px;
    margin-inline-end: calc(-1 * (calc(16px + 16px) - 4px));
}
.k-menu-vertical > .k-separator {
    margin-block: 4px;
    margin-inline: 0;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    display: block;
}

.k-menu-popup {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    overflow: auto;
    max-height: 80vh;
}
.k-menu-popup *,
.k-menu-popup *::before,
.k-menu-popup *::after {
    box-sizing: border-box;
}

.k-menu-group {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
}
.k-menu-group .k-menu-item + .k-menu-item {
    margin-top: 0px;
}
.k-menu-group .k-separator {
    margin-block: 4px;
    margin-inline: 0;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);
    display: block;
}

.k-menu-popup .k-menu-group {
    position: relative;
}

.k-menu-group-sm {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-menu-group-sm .k-menu-item {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-menu-group-sm .k-menu-link {
    padding-block: 2px;
    padding-inline: 8px;
    padding-inline-end: calc(16px + 16px);
}
.k-menu-group-sm .k-menu-expand-arrow {
    margin-inline-start: 8px;
    margin-inline-end: calc(-1 * (calc(16px + 16px) - 4px));
}

.k-menu-group-md {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-menu-group-md .k-menu-item {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-menu-group-md .k-menu-link {
    padding-block: 4px;
    padding-inline: 8px;
    padding-inline-end: calc(16px + 16px);
}
.k-menu-group-md .k-menu-expand-arrow {
    margin-inline-start: 8px;
    margin-inline-end: calc(-1 * (calc(16px + 16px) - 4px));
}

.k-menu-group-lg {
    font-size: 16px;
    line-height: 1.5;
}
.k-menu-group-lg .k-menu-item {
    font-size: 16px;
    line-height: 1.5;
}
.k-menu-group-lg .k-menu-link {
    padding-block: 6px;
    padding-inline: 8px;
    padding-inline-end: calc(16px + 16px);
}
.k-menu-group-lg .k-menu-expand-arrow {
    margin-inline-start: 8px;
    margin-inline-end: calc(-1 * (calc(16px + 16px) - 4px));
}

.k-popups-wrapper {
    position: relative;
    border: 0;
    margin: 0;
    padding: 0;
}

.k-context-menu {
    margin: 0;
    border-width: 1px;
    border-style: solid;
}
.k-animation-container .k-context-menu.k-menu-horizontal {
    display: flex !important;
    flex-wrap: nowrap;
}

.k-context-menu-popup {
    z-index: 12000;
}
.k-context-menu-popup .k-context-menu {
    border-width: 0;
}

.k-popup .k-context-menu,
.k-context-menu-popup .k-context-menu {
    border-width: 0;
}

.k-menu-scroll-wrapper {
    margin: 0;
    padding: 0;
    border: 0;
    position: relative;
}
.k-menu-scroll-wrapper .k-menu {
    overflow: hidden;
    flex-wrap: nowrap;
}
.k-menu-scroll-wrapper .k-menu-scroll-button {
    border-radius: 0;
    padding: 0;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: inherit;
    position: absolute;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-left {
    top: 0;
    inset-inline-start: 0;
    height: 100%;
    width: 16px;
    border-right-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-right {
    top: 0;
    inset-inline-end: 0;
    height: 100%;
    width: 16px;
    border-left-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-up {
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 16px;
    border-bottom-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-down {
    bottom: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 16px;
    border-top-width: 1px;
}

.k-menu:not(.k-context-menu) {
    color: #424242;
    background-color: transparent;
}
.k-menu:not(.k-context-menu) > .k-item {
    color: #4e1289;
}
.k-menu:not(.k-context-menu) > .k-item:hover,
.k-menu:not(.k-context-menu) > .k-item.k-hover {
    color: #420f73;
}
.k-menu:not(.k-context-menu) > .k-item:active,
.k-menu:not(.k-context-menu) > .k-item.k-active {
    color: #424242;
}
.k-menu:not(.k-context-menu) > .k-item:focus,
.k-menu:not(.k-context-menu) > .k-item.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-menu-group,
.k-menu.k-context-menu {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-menu-group .k-item > .k-link:hover,
.k-menu-group .k-item > .k-link.k-hover,
.k-menu.k-context-menu .k-item > .k-link:hover,
.k-menu.k-context-menu .k-item > .k-link.k-hover {
    color: #424242;
    background-color: #f0f0f0;
}
.k-menu-group .k-item > .k-link:active,
.k-menu-group .k-item > .k-link.k-active,
.k-menu-group .k-item > .k-link.k-selected,
.k-menu.k-context-menu .k-item > .k-link:active,
.k-menu.k-context-menu .k-item > .k-link.k-active,
.k-menu.k-context-menu .k-item > .k-link.k-selected {
    color: white;
    background-color: #4e1289;
}
.k-menu-group .k-item:focus > .k-link,
.k-menu-group .k-item.k-focus > .k-link,
.k-menu.k-context-menu .k-item:focus > .k-link,
.k-menu.k-context-menu .k-item.k-focus > .k-link {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-menu-scroll-wrapper .k-menu-scroll-button {
    border-color: rgba(0, 0, 0, 0.08);
    color: #4e1289;
    background-color: #ffffff;
}
.k-menu-scroll-wrapper .k-menu-scroll-button:hover {
    color: #420f73;
}
.k-menu-scroll-wrapper .k-menu-scroll-button:hover::before {
    opacity: 0;
}

.k-toolbar {
    margin: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-toolbar::before {
    content: '';
    height: calc(1.4285714286em + 8px + 2px);
}
.k-toolbar.k-toolbar-resizable {
    flex-wrap: nowrap;
}
.k-toolbar > * {
    flex-shrink: 0;
    display: inline-flex;
    align-content: center;
    vertical-align: middle;
}
.k-toolbar > * > label {
    align-self: center;
}
.k-toolbar .k-input,
.k-toolbar .k-picker {
    width: 10em;
}
.k-toolbar .k-color-picker,
.k-toolbar .k-colorpicker {
    width: min-content;
}
.k-toolbar .k-toolbar-overflow-button {
    margin-inline-start: auto;
}
.k-toolbar .k-separator,
.k-toolbar .k-toolbar-separator {
    width: 0;
    height: calc(1.4285714286 * 1em);
    border-width: 0 0 0 1px;
    border-style: solid;
    align-self: center;
}
.k-toolbar .k-toolbar-spacer {
    height: calc(1.4285714286 * 1em);
    flex: 1 0 0%;
}
.k-toolbar .k-toolbar-item:focus,
.k-toolbar .k-toolbar-item.k-focus {
    text-decoration: none;
    outline: 0;
}

.k-toolbar-group {
    display: flex;
    flex-direction: inherit;
    flex-wrap: nowrap;
    gap: inherit;
}

.k-toolbar-renderer {
    display: inline-block;
    border-color: inherit;
}

.k-toolbar-sm {
    padding-block: 4px;
    padding-inline: 4px;
    gap: 6px;
}
.k-toolbar-sm::before {
    margin-inline-start: -6px;
}
.k-toolbar-sm > * > label {
    margin-inline-end: 6px;
}

.k-toolbar-md {
    padding-block: 8px;
    padding-inline: 8px;
    gap: 8px;
}
.k-toolbar-md::before {
    margin-inline-start: -8px;
}
.k-toolbar-md > * > label {
    margin-inline-end: 8px;
}

.k-toolbar-lg {
    padding-block: 10px;
    padding-inline: 10px;
    gap: 10px;
}
.k-toolbar-lg::before {
    margin-inline-start: -10px;
}
.k-toolbar-lg > * > label {
    margin-inline-end: 10px;
}

.k-toolbar-sm::before {
    height: calc(1.4285714286em + 4px + 2px);
}

.k-toolbar-md::before {
    height: calc(1.4285714286em + 8px + 2px);
}

.k-toolbar-lg::before {
    height: calc(1.5em + 16px + 2px);
}

.k-floating-toolbar,
.editorToolbarWindow.k-window-content {
    padding-block: 8px !important;
    padding-inline: 8px !important;
    border-width: 1px;
    border-style: solid;
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    align-items: center;
}
.k-floating-toolbar .k-toolbar,
.editorToolbarWindow.k-window-content .k-toolbar {
    padding: 0;
    border-width: 0;
    flex-shrink: 1;
    color: inherit;
    background: none;
}

.k-editortoolbar-dragHandle {
    cursor: move;
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-shrink: 0;
}

.k-overflow-container {
    font-size: 14px;
    line-height: 1.4285714286;
}
.k-overflow-container > .k-item {
    border-color: inherit;
}
.k-overflow-container .k-separator {
    margin: 4px 0;
}
.k-overflow-container .k-overflow-tool-group {
    display: block;
}
.k-overflow-container .k-overflow-button {
    padding-block: 4px;
    padding-inline: 8px;
    width: 100%;
    height: auto;
    border-width: 0;
    border-radius: 0;
    aspect-ratio: auto;
    color: inherit;
    background-color: transparent;
    background-image: none;
    font: inherit;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    gap: 4px;
    justify-content: flex-start;
    position: relative;
}
.k-overflow-container .k-button-group {
    box-shadow: none;
    display: flex;
    flex-flow: column nowrap;
}
.k-overflow-container .k-button-group .k-button {
    margin: 0;
}
.k-overflow-container .k-split-button {
    display: flex;
    flex-direction: column;
}
.k-overflow-container .k-overflow-hidden {
    display: none;
}

.k-toolbar.k-toolbar-flat {
    border-width: 1px 0;
    border-top-color: transparent !important;
    border-bottom-color: inherit;
    color: inherit;
    background: none !important;
    box-shadow: none;
}

.k-toolbar {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #fafafa;
}
.k-toolbar .k-separator,
.k-toolbar .k-toolbar-separator {
    border-color: inherit;
}
.k-toolbar .k-toolbar-item:focus,
.k-toolbar .k-toolbar-item.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-floating-toolbar,
.editorToolbarWindow.k-window-content {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #fafafa;
}

.k-overflow-container .k-button:hover,
.k-overflow-container .k-button.k-hover {
    color: #424242;
    background: #f0f0f0;
}
.k-overflow-container .k-button:focus,
.k-overflow-container .k-button.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-overflow-container .k-button:active,
.k-overflow-container .k-button.k-active {
    color: #424242;
    background: #f0f0f0;
}
.k-overflow-container .k-button.k-selected {
    color: white;
    background: #4e1289;
}
.k-overflow-container .k-button:disabled,
.k-overflow-container .k-button.k-disabled {
    color: inherit;
}

.k-pane-wrapper {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    display: block;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pane-wrapper .k-pane {
    width: 100%;
    height: 100%;
    user-select: none;
    box-sizing: border-box;
    font-family: sans-serif;
    overflow-x: hidden;
}
.k-pane-wrapper .k-view {
    top: 0;
    left: 0;
    position: relative;
    border: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    vertical-align: top;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pane-wrapper .k-content {
    min-height: 1px;
    flex: 1;
    align-items: stretch;
    display: block;
    width: auto;
    overflow: hidden;
    position: relative;
}

.k-pane-wrapper > div.k-pane {
    box-shadow: none;
    font-weight: normal;
}
.k-pane-wrapper .k-popup-edit-form .k-content,
.k-pane-wrapper .k-grid-edit-form .k-content,
.k-pane-wrapper .k-grid-header-menu .k-content,
.k-pane-wrapper .k-grid-filter-menu .k-content {
    overflow-y: auto;
}
.k-pane-wrapper .k-popup-edit-form .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-edit-form .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-header-menu .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-filter-menu .k-content > .k-scroll-container {
    position: absolute;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
}
.k-pane-wrapper .k-grid-edit-form .k-popup-edit-form,
.k-pane-wrapper .k-grid-edit-form .k-edit-form-container {
    width: auto;
    min-width: auto;
}

.k-grid.k-grid-mobile {
    border-width: 0;
}

.k-grid-mobile .k-resize-handle-inner::before {
    content: '\e01e';
    position: absolute;
    font: 16px/1 'WebComponentsIcons';
    top: calc(50% - 1px);
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.2em;
}
.k-grid-mobile .k-header a {
    user-select: none;
}

.k-pane-wrapper .k-scheduler-edit-form .k-recur-view {
    padding: 0;
    flex-direction: column;
    align-items: stretch;
}
.k-pane-wrapper .k-scheduler-edit-form .k-recur-view > .k-listgroup-form-row {
    margin: 0;
}
.k-pane-wrapper .k-scheduler-edit-form .k-recur-items-wrap {
    width: 100%;
    margin-block: -1px;
    margin-inline: 0;
}
.k-pane-wrapper .k-scheduler-edit-form .k-scheduler-recur-end-wrap {
    white-space: nowrap;
}

.k-scheduler.k-scheduler-mobile {
    border-width: 0;
}

.k-scheduler-mobile th {
    font-weight: normal;
}
.k-scheduler-mobile .k-event:hover .k-resize-handle {
    visibility: hidden;
}
.k-scheduler-mobile .k-scheduler-toolbar {
    padding-block: 8px;
    padding-inline: 8px;
}
.k-scheduler-mobile .k-scheduler-toolbar > * {
    margin: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar::before {
    display: none;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-nav-current {
    line-height: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-views-wrapper .k-views-dropdown {
    display: inline-block;
}
.k-scheduler-mobile .k-scheduler-footer {
    padding-block: 8px;
    padding-inline: 8px;
    display: flex;
    justify-content: space-between;
}
.k-scheduler-mobile .k-scheduler-footer > * {
    margin: 0;
}
.k-scheduler-mobile .k-scheduler-footer::before {
    display: none;
}
.k-scheduler-mobile .k-scheduler-monthview .k-hidden {
    height: 40px;
}
.k-scheduler-mobile .k-scheduler-monthview .k-scheduler-table td {
    height: 40px;
    vertical-align: top;
    text-align: center;
}
.k-scheduler-mobile .k-scheduler-monthview .k-events-container {
    position: absolute;
    text-align: center;
    height: 6px;
    line-height: 6px;
}
.k-scheduler-mobile .k-scheduler-monthview .k-event {
    position: static;
    padding: 4px;
    border-radius: 50%;
    display: inline-block;
    width: 4px;
    height: 4px;
    min-height: 0;
    margin: 1px;
}
.k-scheduler-mobile .k-scheduler-dayview .k-mobile-header.k-mobile-horizontal-header .k-scheduler-times table tr:first-child {
    display: none;
}
.k-scheduler-mobile .k-scheduler-dayview .k-mobile-header .k-scheduler-header .k-scheduler-date-group {
    display: none;
}
.k-scheduler-mobile .k-scheduler-header-wrap > div {
    overflow: visible;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-mobile-header {
    display: none;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table {
    table-layout: auto;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table .k-scheduler-groupcolumn {
    width: 1%;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table td {
    white-space: normal;
}
.k-scheduler-mobile .k-mobile-header .k-scheduler-table td,
.k-scheduler-mobile .k-mobile-header .k-scheduler-table th {
    height: 1.5em;
}
.k-scheduler-mobile .k-time-text,
.k-scheduler-mobile .k-time-period {
    display: block;
    line-height: 1;
}
.k-scheduler-mobile .k-time-period {
    font-size: 0.7em;
}
.k-scheduler-mobile .k-scheduler-table td,
.k-scheduler-mobile .k-scheduler-table th {
    height: 2em;
    vertical-align: middle;
}
.k-scheduler-mobile .k-scheduler-datecolumn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.k-scheduler-mobile .k-task {
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.k-scheduler-mobile .k-task .k-scheduler-mark {
    border-radius: 50%;
    margin: 0;
}
.k-scheduler-mobile .k-task .k-scheduler-task-text {
    flex: 1 1 0%;
}
.k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell,
.k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn {
    vertical-align: top;
}
.k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn .k-scheduler-group-text {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    white-space: nowrap;
}
.k-scheduler-mobile .k-scrollbar-h tr + tr .k-scheduler-times {
    border-bottom-width: 0;
}

.k-pane-wrapper .k-appbar {
    padding: 4px;
}
.k-pane-wrapper .k-list-title,
.k-pane-wrapper .k-filter-help-text {
    padding-block: 8px;
    padding-inline: 8px;
    display: block;
}
.k-pane-wrapper .k-listgroup-title {
    padding-block: 8px;
    padding-inline: 8px;
    font-weight: bold;
    text-transform: uppercase;
}
.k-pane-wrapper .k-listgroup .k-listgroup-item {
    border-color: inherit;
}
.k-pane-wrapper .k-listgroup + .k-listgroup {
    margin-top: 16px;
}
.k-pane-wrapper .k-column-menu {
    padding: 8px;
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
}
.k-pane-wrapper .k-column-menu .k-filter-item .k-filterable * {
    pointer-events: none;
}
.k-pane-wrapper .k-column-menu .k-list-title,
.k-pane-wrapper .k-column-menu .k-listgroup-title {
    padding: 0;
}
.k-pane-wrapper .k-column-menu .k-listgroup {
    margin-inline: -8px;
}
.k-pane-wrapper .k-filter-menu {
    padding: 8px;
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
}
.k-pane-wrapper .k-filter-menu .k-list-title {
    padding: 0;
}
.k-pane-wrapper .k-filter-menu .k-list-filter {
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    gap: inherit;
}
.k-pane-wrapper .k-filter-menu .k-filter-tools {
    display: flex;
    flex-flow: row nowrap;
    gap: inherit;
}
.k-pane-wrapper .k-filter-menu .k-listgroup {
    margin-inline: -8px;
}
.k-pane-wrapper .k-popup-edit-form .k-recur-editor-wrap {
    display: flex;
}
.k-pane-wrapper .k-popup-edit-form .k-mobiletimezoneeditor {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    flex: 1 1 auto;
}
.k-pane-wrapper .k-multicheck-wrap.k-listgroup {
    overflow-y: auto;
}
.k-pane-wrapper .k-pager-wrap.k-pager-sm,
.k-pane-wrapper .k-pager.k-pager-sm {
    justify-content: center;
}
.k-pane-wrapper .k-pager-wrap.k-pager-sm .k-pager-refresh,
.k-pane-wrapper .k-pager.k-pager-sm .k-pager-refresh {
    display: none;
}
.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li,
.k-pane-wrapper[dir='rtl'] .k-scheduler-mobile .k-scheduler-toolbar > ul > li,
[dir='rtl'] .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li {
    border: 0;
}
.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload,
.k-pane-wrapper[dir='rtl'] .k-scheduler-mobile .k-task .k-i-reload,
[dir='rtl'] .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload {
    margin-left: 0.5em;
    margin-right: 0;
}

.k-pane-wrapper,
.k-view {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-pane-wrapper .k-pane > .k-view > .k-content {
    background-position: 50% 50%;
    background-color: #fafafa;
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-column-active + th.k-header {
    border-left-color: #424242;
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-resize-handle-inner::before {
    background-color: #fafafa;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-nav-current {
    color: #4e1289;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendadate {
    color: inherit;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-time-period,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendaweekday {
    color: #666666;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-wrap,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-datecolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-group-cell,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-all-day {
    background-color: #fafafa;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-tools > li {
    display: inline-block;
    background-color: inherit;
}
.k-pane-wrapper .k-pane .k-grid-edit-form .k-item,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-link,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-item,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-link,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-item,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-link,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-item,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-link {
    color: #424242;
}
.k-pane-wrapper .k-pane .k-grid-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-resetSeries {
    color: #4e1289;
}
.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-selected {
    color: #4e1289;
    background: none;
}
.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-selected .k-link {
    color: inherit;
}

.k-progressbar {
    border-radius: 4px;
    --kendo-progressbar-value: 0;
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 12px;
    line-height: 1;
    display: inline-grid;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-progressbar *,
.k-progressbar *::before,
.k-progressbar *::after {
    box-sizing: border-box;
}
.k-progressbar > .k-progressbar-value {
    border-width: 0;
    border-style: solid;
    display: flex;
    overflow: hidden;
    grid-column: 1/-1;
    grid-row: 1/-1;
}
.k-progressbar > .k-progressbar-chunks {
    border-width: inherit;
    border-style: inherit;
    white-space: nowrap;
    display: flex;
    align-items: stretch;
    flex: 1;
    gap: 1px;
    grid-column: 1/-1;
    grid-row: 1/-1;
}
.k-progressbar .k-progressbar-chunk {
    display: block;
    border-width: 0;
    flex: 1;
}

.k-progress-status-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    grid-column: 1/-1;
    grid-row: 1/-1;
}
.k-progress-status-wrap.k-progress-start {
    justify-content: flex-start;
}
.k-progress-status-wrap.k-progress-center {
    justify-content: center;
}
.k-progress-status-wrap.k-progress-end {
    justify-content: flex-end;
}

.k-progress-status {
    padding-block: 0;
    padding-inline: 8px;
    min-width: 10px;
    text-align: center;
    display: inline-block;
    white-space: nowrap;
}

.k-progressbar-horizontal {
    width: 100%;
    height: var(--kendo-progressbar-height, 22px);
    grid-template-columns: 1fr;
    grid-template-rows: 100%;
}
.k-progressbar-horizontal .k-progress-status-wrap {
    flex-direction: row;
}
.k-progressbar-horizontal > .k-progressbar-value {
    width: calc(var(--kendo-progressbar-value, 0) * 1%);
    flex-direction: row;
}
.k-progressbar-horizontal > .k-progressbar-value > .k-progress-status-wrap {
    width: calc(100% * 100 / var(--kendo-progressbar-value, 1));
}
.k-progressbar-horizontal > .k-progressbar-chunks {
    flex-direction: row;
}
.k-progressbar-horizontal.k-progressbar-reverse > .k-progressbar-value {
    flex-direction: row-reverse;
    justify-self: flex-end;
}
.k-progressbar-horizontal.k-progressbar-reverse > .k-progressbar-chunks {
    flex-direction: row-reverse;
}

.k-progressbar-vertical {
    width: var(--kendo-progressbar-height, 22px);
    height: 27em;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
}
.k-progressbar-vertical .k-progress-status-wrap {
    flex-direction: column-reverse;
}
.k-progressbar-vertical .k-progress-status {
    writing-mode: vertical-lr;
}
.k-progressbar-vertical > .k-progressbar-value {
    height: calc(var(--kendo-progressbar-value, 0) * 1%);
    flex-direction: column-reverse;
    align-self: flex-end;
    align-items: flex-end;
}
.k-progressbar-vertical > .k-progressbar-value > .k-progress-status-wrap {
    height: calc(100% * 100 / var(--kendo-progressbar-value, 1));
}
.k-progressbar-vertical > .k-progressbar-chunks {
    flex-direction: column-reverse;
}
.k-progressbar-vertical.k-progressbar-reverse {
    flex-direction: column;
}
.k-progressbar-vertical.k-progressbar-reverse > .k-progressbar-value {
    flex-direction: column;
    align-self: flex-start;
}
.k-progressbar-vertical.k-progressbar-reverse > .k-progressbar-chunks {
    flex-direction: column;
}

.k-progressbar-indeterminate .k-progress-status-wrap,
.k-progressbar-indeterminate .k-progressbar-value,
.k-progressbar-indeterminate .k-progressbar-chunk {
    display: none;
}

.telerik-blazor.k-progressbar-horizontal > .k-progressbar-value,
.telerik-blazor.k-progressbar-horizontal > .k-progressbar-value > .k-progress-status-wrap {
    transition: width 0.1s ease-in-out;
}

.k-rtl .k-progressbar,
.k-progressbar[dir='rtl'] {
    direction: rtl;
}

.k-circular-progressbar {
    display: inline-block;
    text-align: start;
    position: relative;
}

.k-circular-progressbar-surface {
    height: 100%;
}
.k-circular-progressbar-surface > div {
    width: 100%;
    height: 100%;
}
.k-circular-progressbar-surface svg {
    width: 100%;
    height: 100%;
}

.k-circular-progressbar-scale {
    fill: none;
}

.k-circular-progressbar-arc {
    transform-box: fill-box;
    transform-origin: center center;
    transform: rotate(-90deg);
    stroke-linecap: round;
    fill: none;
}

.k-circular-progressbar-label {
    position: absolute;
    text-align: center;
    padding: 0;
    margin: 0;
}

@keyframes kendo-progressbar-indeterminate-animation {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 22px 0;
    }
}
.k-progressbar {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ebebeb;
}
.k-progressbar .k-selected {
    border-color: #48117e;
    color: white;
    background-color: #4e1289;
}

.k-chunk-progressbar {
    background-color: transparent;
}

.k-progressbar-chunk {
    background-color: #ebebeb;
}
.k-progressbar-chunk .k-selected {
    background-color: #4e1289;
}

.k-progressbar-indeterminate {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ebebeb;
    background-image: linear-gradient(
        45deg,
        #d8d8d8 25%,
        transparent 25%,
        transparent 50%,
        #d8d8d8 50%,
        #d8d8d8 75%,
        transparent 75%,
        transparent
    );
    background-size: 22px 22px;
    animation: kendo-progressbar-indeterminate-animation 1s linear infinite;
}

.k-circular-progressbar-scale {
    stroke: #ebebeb;
}

.k-circular-progressbar-arc {
    stroke: #4e1289;
    transition: stroke 0.5s ease;
}

.k-tooltip {
    border-radius: 4px;
    margin: 0;
    padding-block: 4px;
    padding-inline: 8px;
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    background-repeat: repeat-x;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.25;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    position: absolute;
    z-index: 12000;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-tooltip *,
.k-tooltip *::before,
.k-tooltip *::after {
    box-sizing: border-box;
}

.k-tooltip-icon {
    margin-inline-end: 4px;
    flex-shrink: 0;
}

.k-tooltip-title {
    margin-bottom: 0.25em;
    font-size: 17.5px;
    line-height: 1;
}

.k-tooltip-content {
    align-self: stretch;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-tooltip-button {
    margin-inline-start: 4px;
    flex-shrink: 0;
}
.k-tooltip-button .k-icon {
    color: inherit;
    vertical-align: top;
}

.k-callout {
    width: 12px;
    height: 12px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent;
    position: absolute;
    pointer-events: none;
}

.k-callout-n {
    margin-left: -6px;
    border-bottom-color: currentColor;
    top: -12px;
    left: 50%;
    pointer-events: none;
}

.k-callout-e {
    margin-top: -6px;
    border-left-color: currentColor;
    top: 50%;
    right: -12px;
    pointer-events: none;
}

.k-callout-s {
    margin-left: -6px;
    border-top-color: currentColor;
    bottom: -12px;
    left: 50%;
    pointer-events: none;
}

.k-callout-w {
    margin-top: -6px;
    border-right-color: currentColor;
    top: 50%;
    left: -12px;
    pointer-events: none;
}

.k-tooltip-wrapper .k-tooltip {
    box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-tooltip {
    border-color: rgba(0, 0, 0, 0.75);
    color: white;
    background-color: rgba(0, 0, 0, 0.75);
}
.k-tooltip .k-callout {
    color: rgba(0, 0, 0, 0.75);
}

.k-tooltip-primary {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}
.k-tooltip-primary .k-callout {
    color: #4e1289;
}

.k-tooltip-info {
    border-color: #0058e9;
    color: white;
    background-color: #0058e9;
}
.k-tooltip-info .k-callout {
    color: #0058e9;
}

.k-tooltip-success {
    border-color: #37b400;
    color: white;
    background-color: #37b400;
}
.k-tooltip-success .k-callout {
    color: #37b400;
}

.k-tooltip-warning {
    border-color: #ffc000;
    color: black;
    background-color: #ffc000;
}
.k-tooltip-warning .k-callout {
    color: #ffc000;
}

.k-tooltip-error {
    border-color: #f31700;
    color: white;
    background-color: #f31700;
}
.k-tooltip-error .k-callout {
    color: #f31700;
}

.k-validator-tooltip {
    margin-top: calc(6px + 0px);
    width: auto;
    white-space: normal;
    display: flex;
    align-items: center;
    z-index: 9999;
}

.k-grid .k-validator-tooltip {
    max-width: 300px;
}

.k-overlay {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
}

.k-overlay {
    background-color: #000000;
}

.k-overlay-primary {
    background-color: #4e1289;
}

.k-overlay-secondary {
    background-color: #666666;
}

.k-overlay-tertiary {
    background-color: #03a9f4;
}

.k-overlay-info {
    background-color: #0058e9;
}

.k-overlay-success {
    background-color: #37b400;
}

.k-overlay-warning {
    background-color: #ffc000;
}

.k-overlay-error {
    background-color: #f31700;
}

.k-overlay-dark {
    background-color: #424242;
}

.k-overlay-light {
    background-color: #ebebeb;
}

.k-overlay-inverse {
    background-color: #424242;
}

.k-form,
.k-form-inline {
    font-size: 14px;
    line-height: 1.4285714286;
}

.k-fieldset {
    margin: 30px;
    border-width: 1px 0 0;
    border-style: solid;
    padding: 25px 0 0;
}
.k-fieldset > legend {
    margin-left: 0;
    padding: 0 8px 0 0;
    text-transform: uppercase;
}

kendo-label > .k-label {
    margin: 0;
}

.k-label-optional {
    margin-left: 6px;
    font-size: 12px;
    font-style: italic;
    align-self: center;
}

.k-label-empty::before {
    content: '';
    display: inline-block;
}

.k-input-label {
    margin-right: 10px;
    z-index: 1;
}
.k-input-label:dir(rtl),
.k-rtl .k-input-label,
.k-input-label.k-rtl,
[dir='rtl'] .k-input-label,
.k-input-label[dir='rtl'] {
    margin-right: 0;
    margin-left: 10px;
}

.k-form {
    border: 0;
}
.k-form .k-form-field > .k-label,
.k-form .k-form-field > kendo-label,
.k-form .k-form-label {
    margin-bottom: 0px;
    display: flex;
    flex-flow: row nowrap;
}
.k-form .k-label + .k-radio {
    margin-left: 0;
}
.k-form .k-form-fieldset {
    margin: 2em 0 0;
    padding: 0px;
    border: 0;
}
.k-form .k-form-fieldset > *:not(.k-hidden):first-child,
.k-form .k-form-fieldset > *.k-hidden + :not(.k-hidden) {
    margin-top: 0;
}
.k-form .k-form-legend {
    margin: 0 0 1em;
    padding: 0px;
    border-width: 0 0 2px;
    border-style: solid;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
}
.k-form .k-form-group {
    padding: 0;
}
.k-form > *:not(.k-hidden):first-child,
.k-form > *.k-hidden + :not(.k-hidden) {
    margin-top: 0;
}
.k-form .k-form-field,
.k-form .k-form-field-wrap {
    display: block;
}
.k-form .k-form-field-disabled .k-label,
.k-form .k-form-field-disabled .k-form-label {
    outline: none;
    cursor: default;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}
.k-form .k-alert-error {
    font-size: 12px;
    margin-top: 8px;
}
.k-form .k-field-info {
    display: inline-block;
    font-size: 10px;
    line-height: 1;
    margin-block: 0;
    margin-inline: 8px;
}
.k-form .k-multiselect,
.k-form .k-floating-label-container,
.k-form .k-daterangepicker .k-dateinput,
.k-form .k-signature {
    display: inline-flex;
    width: 100%;
}

.k-form-buttons {
    padding: 0;
    overflow: visible;
}

.k-form-hint,
.k-form-error {
    margin-top: 4px;
    display: flex;
    font-size: 12px;
    font-style: italic;
}

.k-text-start,
.k-buttons-start {
    justify-content: flex-start;
}

.k-text-end,
.k-buttons-end {
    justify-content: flex-end;
}

.k-form-separator {
    margin: 14px 0 0;
    border-width: 1px 0 0;
    border-style: solid;
    display: block;
    flex: 0 0 auto;
}

.k-form-horizontal .k-form-field {
    display: flex;
}
.k-form-horizontal .k-form-field > .k-label,
.k-form-horizontal .k-form-field > kendo-label,
.k-form-horizontal .k-form-field > .k-form-label {
    margin-right: 10px;
    padding-top: 5px;
    width: 25%;
    text-align: end;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}
.k-form-horizontal .k-form-field .k-label-optional {
    margin: 0;
    align-self: inherit;
}
.k-form-horizontal .k-form-field-wrap {
    max-width: calc((100% - 25%) - 10px);
    flex: 1 1 auto;
}

.k-form [dir='rtl'] .k-label-optional,
.k-rtl .k-form .k-label-optional {
    margin-left: 0;
    margin-right: 6px;
}

.k-form-horizontal [dir='rtl'].k-form-field > .k-label,
.k-form-horizontal [dir='rtl'].k-form-field > kendo-label,
.k-form-horizontal [dir='rtl'].k-form-field > .k-form-label,
.k-form-horizontal [dir='rtl'] .k-form-field > .k-label,
.k-form-horizontal [dir='rtl'] .k-form-field > kendo-label,
.k-form-horizontal [dir='rtl'] .k-form-field > .k-form-label,
.k-rtl .k-form-horizontal.k-form-field > .k-label,
.k-rtl .k-form-horizontal.k-form-field > kendo-label,
.k-rtl .k-form-horizontal.k-form-field > .k-form-label,
.k-rtl .k-form-horizontal .k-form-field > .k-label,
.k-rtl .k-form-horizontal .k-form-field > kendo-label,
.k-rtl .k-form-horizontal .k-form-field > .k-form-label {
    margin-right: 0;
    margin-left: 10px;
}

.k-form-sm .k-form-field,
.k-form-sm .k-form-buttons {
    margin-top: 10px;
}

.k-form-md .k-form-field,
.k-form-md .k-form-buttons {
    margin-top: 14px;
}

.k-form-lg .k-form-field,
.k-form-lg .k-form-buttons {
    margin-top: 18px;
}

.k-form-inline {
    padding: 16px;
}
.k-form-inline fieldset {
    border-width: 1px 0 0;
    border-style: solid;
    margin: 32px 0;
    padding: 0;
}
.k-form-inline fieldset:first-child:first-of-type {
    margin-top: 0;
}
.k-form-inline fieldset:last-child:last-of-type {
    margin-bottom: 0;
}
.k-form-inline legend {
    font-size: 12px;
    text-align: start;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 12px;
    text-transform: uppercase;
    padding: 0 8px 0 0;
    width: auto;
}
.k-form-inline .k-form-field {
    display: flex;
    align-items: flex-start;
    text-align: start;
    margin-bottom: 12px;
}
.k-form-inline .k-form-field > span:not(.k-widget) {
    width: 25%;
    text-align: end;
    line-height: 1.4285714286;
    padding: calc(4px + 1px) 0;
    padding-right: 12px;
    align-self: center;
}
.k-form-inline .k-form-field > input {
    align-self: center;
}
.k-form-inline .k-form-field > input:not(.k-checkbox):not(.k-radio) {
    flex: 1 1 auto;
}
.k-form-inline .k-form-field .k-alert-error {
    font-size: 12px;
    margin-top: 8px;
}
.k-form-inline .k-form-field .k-field-info {
    display: block;
    font-size: 10px;
    line-height: 1;
    margin: 0;
}
.k-form-inline .k-form-field:last-child {
    margin-bottom: 0;
}

.k-edit-form-container {
    width: 400px;
    min-width: 400px;
    border-color: inherit;
    position: relative;
}

.k-popup-edit-form > .k-actions,
.k-popup-edit-form > .k-edit-buttons,
.k-popup-edit-form > .k-action-buttons,
.k-popup-edit-form > .k-columnmenu-actions,
.k-popup-edit-form > .k-form-buttons,
.k-edit-form-container .k-actions,
.k-edit-form-container .k-edit-buttons,
.k-edit-form-container .k-action-buttons,
.k-edit-form-container .k-columnmenu-actions,
.k-edit-form-container .k-form-buttons {
    margin: 16px -16px -16px;
}

.k-edit-label {
    margin: 0 0 16px 0;
    padding: calc(4px + 1px) 0;
    width: 30%;
    line-height: 1.4285714286;
    text-align: end;
    float: left;
    clear: both;
}

.k-edit-field {
    margin: 0 0 16px 0;
    width: 65%;
    float: right;
    clear: right;
    position: relative;
}
.k-edit-field.k-no-editor {
    padding: calc(4px + 1px) 0;
}

.k-edit-field > .k-widget {
    width: 100%;
    box-sizing: border-box;
}
.k-edit-field input[type='radio']:not(.k-radio),
.k-edit-field input[type='checkbox']:not(.k-checkbox) {
    margin-right: 0.4ex;
}
.k-edit-field .k-radio-label,
.k-edit-field .k-checkbox-label {
    margin-right: 16px;
}
.k-edit-field > .k-reset > li + li {
    margin-top: 8px;
}
.k-edit-field .k-reset .k-widget {
    margin: 0 0.4ex 0 1ex;
}

.k-form,
.k-form-inline {
    color: #424242;
}
.k-form fieldset legend,
.k-form-inline fieldset legend {
    color: #373737;
}
.k-form .k-form-legend,
.k-form-inline .k-form-legend {
    border-color: rgba(0, 0, 0, 0.08);
}
.k-form .k-field-info,
.k-form-inline .k-field-info {
    color: #666666;
}
.k-form .k-alert-error,
.k-form-inline .k-alert-error {
    color: #f31700;
}

.k-form-error,
.k-text-error,
.k-form-field-error .k-label {
    color: #f31700;
}

.k-form-separator {
    border-color: rgba(0, 0, 0, 0.08);
}

.k-window {
    border-radius: 0px;
    padding: 0;
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.25;
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    z-index: 10002;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-window.k-window-maximized {
    max-width: 100vw;
    max-height: 100vh;
    box-shadow: none;
}

.k-window-sm {
    width: 300px;
}

.k-window-md {
    width: 800px;
}

.k-window-lg {
    width: 1200px;
}

.k-window-titlebar {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-block: 12px;
    padding-inline: 16px;
    border-width: 0 0 1px;
    border-style: solid;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
}
.k-window-minimized .k-window-titlebar {
    border-width: 0;
}

.k-window-title {
    padding-block: 0.5em;
    padding-inline: 0;
    margin-block: -0.5em;
    margin-inline: 0;
    font-size: 16px;
    line-height: 1.25;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
}
.k-window-title::before {
    content: '​';
}

.k-window-titlebar-actions {
    margin-block: -5em;
    margin-inline: 0;
    margin-inline-end: calc(12px - 16px);
    line-height: 1;
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    align-items: center;
    vertical-align: top;
}

.k-window-titlebar-action {
    flex-shrink: 0;
}

.k-window-content,
.k-prompt-container {
    padding-block: 16px;
    padding-inline: 16px;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: none;
    outline: 0;
    overflow: auto;
    position: relative;
    flex: 1 1 auto;
}

.k-window-content + .k-prompt-container {
    margin-top: -8px;
}

.k-window-content:first-child {
    padding-top: clamp(16px, 12px, 12px);
}

.k-window-content:last-child {
    padding-bottom: clamp(16px, 12px, 12px);
}

.k-window-iframecontent {
    padding: 0;
    overflow: visible;
}
.k-window-iframecontent .k-content-frame {
    vertical-align: top;
    border: 0;
    width: 100%;
    height: 100%;
}

.k-window-actions {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-block: 8px;
    padding-inline: 8px;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: inherit;
    flex: 0 0 auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    overflow: hidden;
}

.k-prompt-container > .k-textarea {
    width: 100%;
}

.k-window .k-resize-n {
    top: 0;
}
.k-window .k-resize-e {
    right: 0;
}
.k-window .k-resize-s {
    bottom: 0;
}
.k-window .k-resize-w {
    left: 0;
}

.k-window {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
    box-shadow: var(--kendo-elevation-8, 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-window:focus,
.k-window.k-focus {
    box-shadow: var(--kendo-elevation-9, 0 32px 34px rgba(0, 0, 0, 0.32), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-window-titlebar {
    border-color: inherit;
    color: #424242;
    background-color: #fafafa;
}

.k-window-primary .k-window-titlebar {
    color: white;
    background-color: #4e1289;
}

.k-window-light .k-window-titlebar {
    color: black;
    background-color: #ebebeb;
}

.k-window-dark .k-window-titlebar {
    color: white;
    background-color: #424242;
}

.k-dialog-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
}
.k-dialog-wrapper .k-dialog {
    position: relative;
}

.k-dialog {
    padding: 0;
    position: fixed;
    box-sizing: border-box;
}
.k-dialog.k-dialog-centered {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}
.k-dialog .k-multiselect {
    width: 100%;
}

.k-dialog-actions {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-block: 8px;
    padding-inline: 8px;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: inherit;
    flex: 0 0 auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 8px;
    overflow: hidden;
}

.k-dialog-titlebar {
    border-color: inherit;
    color: #424242;
    background-color: #fafafa;
}

.k-dialog-primary .k-dialog-titlebar {
    color: white;
    background-color: #4e1289;
}

.k-dialog-light .k-dialog-titlebar {
    color: black;
    background-color: #ebebeb;
}

.k-dialog-dark .k-dialog-titlebar {
    color: white;
    background-color: #424242;
}

.k-pager {
    padding: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: default;
    flex: 0 0 auto;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pager *,
.k-pager *::before,
.k-pager *::after {
    box-sizing: border-box;
}

.k-pager-info,
.k-pager-sizes,
.k-pager-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.k-pager-nav {
    color: inherit;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.k-pager-nav:hover {
    z-index: 2;
}
.k-disabled.k-pager-nav {
    color: inherit;
}

.k-pager-numbers-wrap {
    display: flex;
    flex-direction: row;
    position: relative;
}
.k-pager-numbers-wrap select.k-dropdown-list,
.k-pager-numbers-wrap select.k-dropdown,
.k-pager-numbers-wrap select.k-dropdownlist {
    width: 5em;
}

.k-pager-numbers {
    display: flex;
    flex-direction: row;
}
.k-pager-numbers .k-selected {
    cursor: inherit;
    z-index: 2;
}

.k-pager-input {
    gap: 1ex;
}
.k-pager-input .k-textbox,
.k-pager-input .k-numerictextbox {
    margin-block: 0;
    width: 5em;
}

.k-pager-sizes {
    gap: 1ex;
}
.k-pager-sizes .k-input-inner,
.k-pager-sizes .k-input-value-text {
    text-overflow: clip;
}

.k-pager-info {
    text-align: end;
    justify-content: flex-end;
    flex: 1 1 0%;
    order: 9;
}

.k-pager-refresh {
    margin-inline-start: auto;
    color: inherit;
    order: 10;
}

.k-pager-sm {
    padding-inline: 4px;
    padding-block: 4px;
    gap: 12px;
}
.k-pager-sm .k-pager-numbers-wrap .k-button {
    min-width: calc(1.4285714286em + 4px + 2px);
}
.k-pager-sm .k-pager-numbers-wrap .k-pager-input,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdownlist {
    margin-inline-start: 12px;
    margin-inline-end: 12px;
}
.k-pager-sm .k-pager-sizes .k-dropdown-list,
.k-pager-sm .k-pager-sizes .k-dropdown,
.k-pager-sm .k-pager-sizes .k-dropdownlist,
.k-pager-sm .k-pager-sizes > select {
    width: 5em;
}

.k-pager-md {
    padding-inline: 8px;
    padding-block: 8px;
    gap: 14px;
}
.k-pager-md .k-pager-numbers-wrap .k-button {
    min-width: calc(1.4285714286em + 8px + 2px);
}
.k-pager-md .k-pager-numbers-wrap .k-pager-input,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdownlist {
    margin-inline-start: 14px;
    margin-inline-end: 14px;
}
.k-pager-md .k-pager-sizes .k-dropdown-list,
.k-pager-md .k-pager-sizes .k-dropdown,
.k-pager-md .k-pager-sizes .k-dropdownlist,
.k-pager-md .k-pager-sizes > select {
    width: 5em;
}

.k-pager-lg {
    padding-inline: 10px;
    padding-block: 10px;
    gap: 16px;
}
.k-pager-lg .k-pager-numbers-wrap .k-button {
    min-width: calc(1.5em + 16px + 2px);
}
.k-pager-lg .k-pager-numbers-wrap .k-pager-input,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdownlist {
    margin-inline-start: 16px;
    margin-inline-end: 16px;
}
.k-pager-lg .k-pager-sizes .k-dropdown-list,
.k-pager-lg .k-pager-sizes .k-dropdown,
.k-pager-lg .k-pager-sizes .k-dropdownlist,
.k-pager-lg .k-pager-sizes > select {
    width: 5em;
}

.k-pager {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #fafafa;
}
.k-pager:focus,
.k-pager.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}

.k-skeleton,
.k-placeholder-line {
    display: block;
}

.k-skeleton-text,
.k-placeholder-line {
    border-radius: 4px;
    transform: scale(1, 0.6);
}
.k-skeleton-text:empty::before,
.k-placeholder-line:empty::before {
    content: '​';
}

.k-skeleton-rect {
    border-radius: 0;
}

.k-skeleton-circle {
    border-radius: 9999px;
}

@keyframes k-skeleton-wave {
    0% {
        transform: translateX(-100%);
    }
    60% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(100%);
    }
}
.k-skeleton-wave .k-skeleton,
.k-skeleton-wave .k-placeholder-line,
.k-skeleton-wave.k-skeleton,
.k-skeleton-wave.k-placeholder-line {
    position: relative;
    overflow: hidden;
}
.k-skeleton-wave .k-skeleton::after,
.k-skeleton-wave .k-placeholder-line::after,
.k-skeleton-wave.k-skeleton::after,
.k-skeleton-wave.k-placeholder-line::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    animation: k-skeleton-wave 1.6s linear 0.5s infinite;
}

@keyframes k-skeleton-pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
.k-skeleton-pulse .k-skeleton,
.k-skeleton-pulse .k-placeholder-line,
.k-skeleton-pulse.k-skeleton,
.k-skeleton-pulse.k-placeholder-line {
    animation: k-skeleton-pulse 1.5s ease-in-out 0.5s infinite;
}

.k-skeleton,
.k-placeholder-line {
    background-color: rgba(66, 66, 66, 0.2);
}

.k-skeleton-wave .k-skeleton::after,
.k-skeleton-wave .k-placeholder-line::after,
.k-skeleton-wave.k-skeleton::after,
.k-skeleton-wave.k-placeholder-line::after {
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.04), transparent);
}

.k-table {
    width: 100%;
    max-width: none;
    border-width: 1px;
    border-style: solid;
    text-align: start;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    outline: none;
}

.k-data-table {
    border-width: 1px;
    border-style: solid;
}
.k-data-table .k-table {
    table-layout: fixed;
}

.k-table-thead,
.k-table-tbody,
.k-table-tfoot,
.k-table-row,
.k-table-alt-row {
    border-color: inherit;
    text-align: inherit;
}

.k-table-th,
.k-table-td {
    border-width: 0 0 0px 1px;
    border-style: solid;
    border-color: inherit;
    box-sizing: border-box;
    font-weight: normal;
    text-align: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: table-cell;
    overflow: hidden;
    position: relative;
}
.k-table-th:first-child,
.k-table-td:first-child {
    border-left-width: 0;
}

.k-table-th {
    border-bottom-width: 1px;
}

.k-table-header {
    padding-inline-end: var(--kendo-scrollbar-width);
    border-width: 0 0 1px;
    border-style: solid;
    box-sizing: border-box;
}
.k-table-header .k-table {
    border-width: 0;
}

.k-table-header-wrap {
    margin-right: -1px;
    width: 100%;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: inherit;
    overflow: hidden;
}

.k-table-header > .k-table,
.k-table-header-wrap > .k-table {
    margin-bottom: -1px;
}

.k-table-group-sticky-header {
    flex: none;
}
.k-table-group-sticky-header .k-table-th {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
}

.k-table-list {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: none;
    border-width: 0;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    empty-cells: show;
    list-style: none;
    outline: none;
}
.k-table-list .k-table-row,
.k-table-list .k-table-group-row {
    width: 100%;
    box-sizing: border-box;
    display: table-row;
    position: relative;
}
.k-table-list .k-table-row.k-first {
    border-top: 1px solid currentColor;
}
.k-table-list .k-table-th,
.k-table-list .k-table-td {
    vertical-align: middle;
}
.k-table-list .k-table-group-row::before {
    content: '​';
    padding-inline: 0;
    width: 0;
    display: block;
    overflow: hidden;
}
.k-table-list .k-table-group-row .k-table-th {
    width: 100%;
    border-color: inherit;
    color: inherit;
    background-color: inherit;
    position: absolute;
    top: 0;
}
.k-table-list .k-table-spacer-td {
    padding: 0 !important;
    width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
}
.k-table-list .k-table-group-td {
    padding: 0 !important;
    width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    overflow: visible;
}
.k-table-list .k-table-group-td > span {
    font-size: 0.75em;
    position: absolute;
    top: 0;
    right: 0;
}

.k-virtual-table .k-table-row,
.k-virtual-table .k-table-group-row {
    position: absolute;
    width: 100%;
}

.k-table-scroller {
    position: relative;
    overflow: auto;
}
.k-table-scroller > .k-table {
    border-width: 0;
}

.k-table-footer {
    padding-inline-end: var(--kendo-scrollbar-width);
    border-width: 1px 0 0;
    border-style: solid;
    box-sizing: border-box;
}
.k-table-footer .k-table {
    border-width: 0;
}

.k-table-footer-wrap {
    margin-right: -1px;
    width: 100%;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: inherit;
    overflow: hidden;
}

.k-table-sm {
    font-size: 14px;
    line-height: 1.4285714286;
}

.k-table-sm .k-table-th,
.k-table-sm .k-table-td {
    padding-block: 4px;
    padding-inline: 8px;
}

.k-table-sm .k-table-list .k-table-group-td > span {
    padding-block: 0;
    padding-inline: 4px;
}

.k-table-sm .k-table-list .k-table-group-row::before {
    padding-block: 4px;
    padding-inline: 0;
}

.k-table-md {
    font-size: 14px;
    line-height: 1.4285714286;
}

.k-table-md .k-table-th,
.k-table-md .k-table-td {
    padding-block: 8px;
    padding-inline: 12px;
}

.k-table-md .k-table-list .k-table-group-td > span {
    padding-block: 0;
    padding-inline: 6px;
}

.k-table-md .k-table-list .k-table-group-row::before {
    padding-block: 8px;
    padding-inline: 0;
}

.k-table-lg {
    font-size: 14px;
    line-height: 1.4285714286;
}

.k-table-lg .k-table-th,
.k-table-lg .k-table-td {
    padding-block: 10px;
    padding-inline: 8px;
}

.k-table-lg .k-table-list .k-table-group-td > span {
    padding-block: 0;
    padding-inline: 4px;
}

.k-table-lg .k-table-list .k-table-group-row::before {
    padding-block: 10px;
    padding-inline: 0;
}

.k-rtl.k-table .k-table-th,
.k-rtl.k-table .k-table-td,
.k-rtl .k-table .k-table-th,
.k-rtl .k-table .k-table-td,
[dir='rtl'].k-table .k-table-th,
[dir='rtl'].k-table .k-table-td,
[dir='rtl'] .k-table .k-table-th,
[dir='rtl'] .k-table .k-table-td {
    border-left-width: 0;
    border-right-width: 1px;
}
.k-rtl.k-table .k-table-th:first-child,
.k-rtl.k-table .k-table-td:first-child,
.k-rtl .k-table .k-table-th:first-child,
.k-rtl .k-table .k-table-td:first-child,
[dir='rtl'].k-table .k-table-th:first-child,
[dir='rtl'].k-table .k-table-td:first-child,
[dir='rtl'] .k-table .k-table-th:first-child,
[dir='rtl'] .k-table .k-table-td:first-child {
    border-right-width: 0;
}
.k-rtl.k-table .k-table-header-wrap,
.k-rtl.k-table .k-table-footer-wrap,
.k-rtl .k-table .k-table-header-wrap,
.k-rtl .k-table .k-table-footer-wrap,
[dir='rtl'].k-table .k-table-header-wrap,
[dir='rtl'].k-table .k-table-footer-wrap,
[dir='rtl'] .k-table .k-table-header-wrap,
[dir='rtl'] .k-table .k-table-footer-wrap {
    margin-right: 0;
    margin-left: -1px;
    border-left-width: 1px;
    border-right-width: 0;
}
.k-rtl .k-table-list .k-table-group-td > span,
[dir='rtl'] .k-table-list .k-table-group-td > span {
    left: 0;
    right: auto;
}

.k-table,
.k-data-table {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-table-thead,
.k-table-header,
.k-table-group-sticky-header {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #e0e0e0;
}

.k-table-tfoot,
.k-table-footer {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #e0e0e0;
}

.k-table-group-row {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #e0e0e0;
}

.k-table-group-td > span {
    color: #ffffff;
    background-color: #424242;
}

.k-table-alt-row {
    background-color: rgba(0, 0, 0, 0.04);
}

.k-table-tbody .k-table-row:hover,
.k-table-list .k-table-row:hover,
.k-table-tbody .k-table-row.k-hover,
.k-table-list .k-table-row.k-hover {
    background-color: #ededed;
}

.k-table-tbody .k-table-row.k-focus,
.k-table-tbody .k-table-row:focus,
.k-table-list .k-table-row.k-focus,
.k-table-list .k-table-row:focus,
.k-table-tbody .k-table-td.k-focus,
.k-table-tbody .k-table-td:focus,
.k-table-list .k-table-td.k-focus,
.k-table-list .k-table-td:focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-table-tbody .k-table-row.k-selected > .k-table-td,
.k-table-list .k-table-row.k-selected {
    background-color: rgba(78, 18, 137, 0.25);
}

.k-tabstrip-wrapper {
    padding-block: 0px;
    padding-inline: 0px;
    box-sizing: border-box;
    border-width: 0px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    position: relative;
}

.k-tabstrip {
    border-width: 0;
    border-color: transparent;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    color: inherit;
    background-color: transparent;
    display: flex;
    flex-flow: column nowrap;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-tabstrip-wrapper > .k-tabstrip {
    flex: 1 1 auto;
}

.k-tabstrip-items-wrapper {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    position: relative;
}

.k-tabstrip-items {
    box-sizing: border-box;
    outline: 0;
    display: flex;
    flex-direction: inherit;
    flex-wrap: wrap;
    flex: 1 1 auto;
    gap: 0;
}
.k-tabstrip-items *,
.k-tabstrip-items *::before,
.k-tabstrip-items *::after {
    box-sizing: border-box;
}
.k-tabstrip-items .k-item {
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-items: stretch;
    outline: 0;
}
.k-tabstrip-items .k-tab-on-top {
    z-index: 1;
}
.k-tabstrip-items .k-link {
    padding-block: 6px;
    padding-inline: 12px;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    vertical-align: middle;
    flex: 1 1 auto;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
.k-tabstrip-content,
.k-tabstrip > .k-content {
    margin: 0 !important;
    padding-block: 16px;
    padding-inline: 16px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    display: none;
    overflow: auto;
    flex: 1 1 auto;
    position: relative;
}
.k-tabstrip-content.k-active,
.k-tabstrip > .k-content.k-active {
    display: block;
}
.k-tabstrip-content:focus,
.k-tabstrip-content.k-focus,
.k-tabstrip > .k-content:focus,
.k-tabstrip > .k-content.k-focus {
    outline-width: 1px;
    outline-style: dotted;
    outline-offset: -1px;
}

.k-tabstrip-items .k-loading {
    width: 20%;
    height: 0;
    border: 0;
    border-top: 1px solid transparent;
    border-color: inherit;
    background: none;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.2s linear;
    display: none;
}
.k-tabstrip-items .k-loading.k-complete {
    width: 100%;
    border-top-width: 0;
}

.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-tabstrip-items {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
}
.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-button {
    padding-block: 4px;
    padding-inline: 4px;
    width: auto;
    height: auto;
    flex: none;
    align-self: stretch;
    aspect-ratio: auto;
}
.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-button .k-button-icon {
    min-width: auto;
    min-height: auto;
}

.k-tabstrip-horizontal,
.k-tabstrip-bottom > .k-tabstrip-items-wrapper,
.k-tabstrip-top > .k-tabstrip-items-wrapper {
    flex-direction: row;
}

.k-tabstrip-vertical,
.k-tabstrip-right > .k-tabstrip-items-wrapper,
.k-tabstrip-left > .k-tabstrip-items-wrapper {
    flex-direction: column;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper {
    border-bottom-width: 1px;
}
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-width: 0;
}
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
    margin-bottom: -1px;
    border-bottom-width: 1px;
    border-bottom-color: transparent !important;
}
.k-tabstrip-top > .k-content,
.k-tabstrip-top > .k-tabstrip-content {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-width: 0 !important;
}

.k-tabstrip-bottom > .k-tabstrip-items-wrapper {
    border-top-width: 1px;
}
.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-width: 0;
}
.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item.k-active {
    margin-top: -1px;
    border-top-width: 1px;
    border-top-color: transparent !important;
}
.k-tabstrip-bottom > .k-content,
.k-tabstrip-bottom > .k-tabstrip-content {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-width: 0 !important;
}

.k-tabstrip-left {
    flex-direction: row;
}
.k-tabstrip-left > .k-tabstrip-items-wrapper {
    border-right-width: 1px;
}
.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right-width: 0;
}
.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item.k-active {
    margin-right: -1px;
    border-right-width: 1px;
    border-right-color: transparent !important;
}
.k-tabstrip-left > .k-content,
.k-tabstrip-left > .k-tabstrip-content {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left-width: 0 !important;
}

.k-tabstrip-right {
    flex-direction: row-reverse;
}
.k-tabstrip-right > .k-tabstrip-items-wrapper {
    border-left-width: 1px;
}
.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left-width: 0;
}
.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item.k-active {
    margin-left: -1px;
    border-left-width: 1px;
    border-left-color: transparent !important;
}
.k-tabstrip-right > .k-content,
.k-tabstrip-right > .k-tabstrip-content {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right-width: 0;
}

.k-tabstrip-items-start {
    justify-content: flex-start;
}

.k-tabstrip-items-center {
    justify-content: center;
}

.k-tabstrip-items-end {
    justify-content: flex-end;
}

.k-tabstrip-items-justify {
    justify-content: space-between;
}

.k-tabstrip-items-stretched > * {
    flex: 1 0 0;
}

.k-rtl .k-tabstrip.k-tabstrip-left > .k-tabstrip-items-wrapper,
.k-rtl .k-tabstrip.k-tabstrip-right > .k-tabstrip-items-wrapper,
.k-tabstrip[dir='rtl'].k-tabstrip-left > .k-tabstrip-items-wrapper,
.k-tabstrip[dir='rtl'].k-tabstrip-right > .k-tabstrip-items-wrapper {
    order: 1;
}
.k-rtl .k-tabstrip.k-tabstrip-left > .k-content,
.k-rtl .k-tabstrip.k-tabstrip-right > .k-content,
.k-tabstrip[dir='rtl'].k-tabstrip-left > .k-content,
.k-tabstrip[dir='rtl'].k-tabstrip-right > .k-content {
    order: 0;
}
.k-rtl .k-tabstrip.k-tabstrip-left > .k-tabstrip-content,
.k-rtl .k-tabstrip.k-tabstrip-right > .k-tabstrip-content,
.k-tabstrip[dir='rtl'].k-tabstrip-left > .k-tabstrip-content,
.k-tabstrip[dir='rtl'].k-tabstrip-right > .k-tabstrip-content {
    order: 0;
}

.k-tabstrip-items-wrapper {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
}
.k-tabstrip-items-wrapper .k-item {
    color: #4e1289;
}
.k-tabstrip-items-wrapper .k-item:hover,
.k-tabstrip-items-wrapper .k-item.k-hover {
    color: #420f73;
}
.k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-items-wrapper .k-item.k-active,
.k-tabstrip-items-wrapper .k-item.k-selected {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-tabstrip-items-wrapper .k-item:focus,
.k-tabstrip-items-wrapper .k-item.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-tabstrip-items-wrapper .k-item.k-tabstrip-dragging {
    box-shadow: var(--kendo-elevation-3, 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12));
}
.k-tabstrip-content,
.k-tabstrip > .k-content {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-tabstrip-content:focus,
.k-tabstrip-content.k-focus,
.k-tabstrip > .k-content:focus,
.k-tabstrip > .k-content.k-focus {
    outline-color: #424242;
}

.k-grid {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    display: flex;
    flex-direction: column;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-grid.k-grid-display-block {
    display: block;
}
.k-grid .k-grid-container {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
}
.k-grid .k-grid-aria-root {
    border-color: inherit;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
}
.k-grid .k-table {
    margin: 0;
    width: 100%;
    max-width: none;
    border-width: 0;
    border-color: inherit;
    border-collapse: separate;
    border-spacing: 0;
    box-sizing: content-box;
    empty-cells: show;
    outline: 0;
}
.k-grid .k-grid-header .k-table,
.k-grid .k-grid-content .k-table,
.k-grid .k-grid-content-locked .k-table,
.k-grid .k-grid-footer .k-table {
    table-layout: fixed;
}
.k-grid .k-table-thead,
.k-grid .k-table-tbody,
.k-grid .k-table-tfoot {
    text-align: start;
    border-color: inherit;
}
.k-grid .k-table-row {
    border-color: inherit;
}
.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td {
    border-width: 0;
    border-inline-start-width: 1px;
    border-style: solid;
    border-color: inherit;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    position: static;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: content-box;
}
.k-grid .k-table-th:first-child,
.k-grid td:first-child,
.k-grid .k-table-td:first-child {
    border-inline-start-width: 0;
}
.k-grid .k-table-th:focus,
.k-grid td:focus,
.k-grid .k-table-td:focus {
    outline: none;
}
.k-grid .k-table-th > .k-radio,
.k-grid .k-table-th > .k-radio-wrap,
.k-grid .k-table-th > .k-checkbox,
.k-grid .k-table-th > .k-checkbox-wrap,
.k-grid td > .k-radio,
.k-grid td > .k-radio-wrap,
.k-grid td > .k-checkbox,
.k-grid td > .k-checkbox-wrap,
.k-grid .k-table-td > .k-radio,
.k-grid .k-table-td > .k-radio-wrap,
.k-grid .k-table-td > .k-checkbox,
.k-grid .k-table-td > .k-checkbox-wrap {
    vertical-align: top;
}
.k-grid .k-table-th {
    border-block-end-width: 1px;
    white-space: nowrap;
}
.k-grid td,
.k-grid .k-table-td {
    border-block-end-width: 0px;
    white-space: unset;
    vertical-align: middle;
}
.k-grid a {
    color: inherit;
    text-decoration: none;
}
.k-grid a:hover {
    text-decoration: none;
}
.k-grid .k-grid-header-wrap {
    box-sizing: content-box;
}
.k-grid .k-grid-header {
    border-bottom-width: 1px;
}
.k-grid .k-grid-header .k-table {
    margin-bottom: -1px;
}
.k-grid .k-grid-header.k-grid-draggable-header {
    user-select: none;
    touch-action: none;
}
.k-grid .k-grid-header .k-table-th {
    position: relative;
    vertical-align: bottom;
    cursor: default;
}
.k-grid .k-grid-header .k-table-th:first-child {
    border-inline-start-width: 0;
}
.k-grid .k-grid-header .k-table-th.k-first {
    border-inline-start-width: 1px;
}
.k-grid .k-grid-header .k-table-th > .k-link {
    line-height: inherit;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: 0;
}
.k-grid .k-grid-header .k-table-th > .k-link:focus {
    text-decoration: none;
}
.k-grid .k-grid-header .k-grid-filter,
.k-grid .k-grid-header .k-header-column-menu,
.k-grid .k-grid-header .k-grid-header-menu {
    box-sizing: border-box;
    outline: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    inset-inline-end: 4px;
    z-index: 1;
}
.k-grid .k-grid-header .k-grid-filter:hover,
.k-grid .k-grid-header .k-header-column-menu:hover,
.k-grid .k-grid-header .k-grid-header-menu:hover {
    cursor: pointer;
}
.k-grid .k-grid-header .k-cell-inner > .k-link > .k-sort-icon {
    vertical-align: text-top;
    margin-inline-start: calc(8px - 1px);
}
.k-grid .k-grid-header .k-cell-inner > .k-link > .k-sort-icon .k-icon {
    display: flex;
}
.k-grid .k-grid-header .k-sort-order {
    display: inline-block;
    vertical-align: top;
    height: 16px;
    font-size: 12px;
    margin-top: 2px;
    margin-inline-start: -2px;
}
.k-grid .k-grid-header .k-filterable > .k-cell-inner .k-link {
    padding-inline-end: 0;
}
.k-grid .k-cell-inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: inherit;
    overflow: hidden;
}
.k-grid .k-cell-inner > .k-link {
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: inherit;
    flex: 1;
    overflow: hidden;
}
.k-grid .k-cell-inner > .k-link > .k-sort-icon {
    margin-inline-start: 0;
    display: inline-block;
    flex-shrink: 0;
}
.k-grid .k-cell-inner .k-sort-order {
    flex-shrink: 0;
    line-height: normal;
}
.k-grid .k-cell-inner .k-grid-filter,
.k-grid .k-cell-inner .k-header-column-menu,
.k-grid .k-cell-inner .k-grid-header-menu {
    position: static;
    margin-inline-end: 4px;
}
.k-grid .k-group-col,
.k-grid .k-hierarchy-col {
    padding: 0;
    width: 32px;
}
.k-grid .k-grouping-row p {
    margin: 0;
    display: flex;
    align-items: center;
    align-content: center;
}
.k-grid .k-grouping-row td,
.k-grid .k-grouping-row .k-table-td {
    overflow: visible;
}
.k-grid .k-grouping-row + .k-table-row td,
.k-grid .k-grouping-row + .k-table-row .k-table-td {
    border-top-width: 1px;
}
.k-grid .k-grouping-row .k-group-cell,
.k-grid .k-grouping-row + .k-table-row .k-group-cell {
    border-top-width: 0;
    text-overflow: clip;
}
.k-grid .k-grouping-row .k-icon {
    margin-inline-start: calc(8px - 12px);
    margin-inline-end: 8px;
    text-decoration: none;
}
.k-grid .k-table .k-group-footer td,
.k-grid .k-table .k-group-footer .k-table-td {
    border-style: solid;
    border-width: 1px 0;
}
.k-grid .k-group-footer .k-group-cell + td,
.k-grid .k-group-footer .k-group-cell + .k-table-td {
    border-inline-start-width: 1px;
}
.k-grid .k-hierarchy-cell,
.k-grid .k-drag-cell {
    text-align: center;
    overflow: visible;
}
.k-grid .k-hierarchy-cell > .k-font-icon,
.k-grid .k-drag-cell > .k-font-icon {
    padding-block: 8px;
    padding-inline: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    outline: 0;
}
.k-grid .k-hierarchy-cell + .k-grid-content-sticky {
    border-inline-start-width: 1px;
}
.k-grid .k-detail-row .k-detail-cell {
    border-inline-start-width: 0;
}
.k-grid .k-dirty-cell {
    position: relative;
}
.k-grid .k-dirty-cell.k-edit-cell {
    position: static;
}
.k-grid .k-dirty-cell.k-grid-content-sticky {
    position: sticky;
}
.k-grid .k-dirty {
    border-width: 5px;
    border-color: transparent;
    border-block-start-color: currentColor;
    border-inline-start-color: currentColor;
    inset-inline-start: 0;
    inset-inline-end: auto;
}
.k-grid .k-grid-content-locked + .k-grid-content {
    box-sizing: content-box;
}
.k-grid .k-grid-content-expander {
    position: absolute;
    visibility: hidden;
    height: 1px;
    bottom: 1px;
}
.k-grid .k-grid-filter,
.k-grid .k-header-column-menu,
.k-grid .k-grid-header-menu,
.k-grid .k-hierarchy-cell .k-icon {
    border-width: 0;
}
.k-grid .k-drag-col {
    padding: 0;
    width: 36px;
}
.k-grid .k-drag-cell {
    cursor: move;
}

.k-grid-toolbar {
    border-width: 0 0 1px;
    flex-shrink: 0;
}

.k-grid-toolbar-bottom {
    border-width: 1px 0 0;
}

.k-grouping-header {
    border-width: 0 0 1px;
    border-style: solid;
    border-color: inherit;
    white-space: normal;
    display: flex;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
}
.k-grouping-header::before {
    content: '';
    height: calc(1em + 8px + 2px);
    display: inline-block;
    vertical-align: middle;
}
.k-grouping-header .k-chip-list {
    flex-grow: 0;
    display: contents;
}
.k-grouping-header > .k-grouping-drop-container {
    flex-grow: 1;
    padding-block: 8px;
    margin-block: -8px;
    display: inline-flex;
    align-self: stretch;
    align-items: center;
}

.k-grouping-dropclue {
    width: 12px;
    position: absolute;
    top: 3px;
    box-sizing: content-box;
}
.k-grouping-dropclue::before,
.k-grouping-dropclue::after {
    display: inline-block;
    content: '';
    position: absolute;
}
.k-grouping-dropclue::before {
    border-width: 6px;
    border-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    top: 0;
}
.k-grouping-dropclue::after {
    width: 2px;
    height: calc(100% - 6px);
    top: 6px;
    inset-inline-start: calc(6px - 1px);
}

.k-grid-header-wrap,
.k-grid-footer-wrap {
    margin-inline-end: -1px;
    width: 100%;
    border-width: 0;
    border-inline-end-width: 1px;
    border-style: solid;
    border-color: inherit;
    position: relative;
    overflow: hidden;
}

.k-grid-header-locked + .k-grid-header-wrap.k-auto-scrollable {
    margin-inline-end: 0;
}

.k-grid-header,
.k-grid-footer {
    padding-inline-start: 0;
    padding-inline-end: var(--kendo-scrollbar-width, 17px);
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    flex: 0 0 auto;
}

div.k-grid-header,
div.k-grid-footer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.k-column-resizer {
    width: 0.5em;
    height: 1000%;
    display: block;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    cursor: col-resize;
}

.k-row-resizer {
    display: block;
    height: 2px;
    width: 100%;
    background: none;
}

.k-grid-footer {
    border-top-width: 1px;
}
.k-grid-footer td,
.k-grid-footer .k-table-td {
    height: calc(1.4285714286 * 1em);
}

.k-grid-footer td,
.k-group-footer td,
.k-grouping-row td,
.k-grid-footer .k-table-td,
.k-group-footer .k-table-td,
.k-grouping-row .k-table-td {
    font-weight: bold;
}

.k-grid-filter-popup {
    min-width: 230px;
    max-width: 320px;
}

.k-grid-column-menu-standalone a.k-grid-filter {
    position: initial;
    display: inline-block;
    margin-block: -4px;
    margin-inline: 0;
    padding-block: 4px;
    padding-inline: 8px;
}

.k-filter-row {
    line-height: 1.4285714286;
}
.k-filter-row td,
.k-filter-row .k-table-td,
.k-filter-row .k-table-th {
    border-width: 0;
    border-block-end-width: 1px;
    border-inline-start-width: 1px;
    white-space: nowrap;
}
.k-filter-row td:first-child,
.k-filter-row .k-table-td:first-child {
    border-inline-start-width: 0;
}
.k-filter-row .k-multiselect {
    height: auto;
}

.k-filtercell {
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.k-filtercell > span,
.k-filtercell .k-filtercell-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1 1 auto;
}
.k-filtercell > span > .k-button,
.k-filtercell .k-filtercell-wrapper > .k-button {
    flex: none;
}

.k-grid-content,
.k-grid-content-locked {
    border-color: inherit;
    outline: 0;
}
.k-grid-content .k-table-row:last-child > td,
.k-grid-content .k-table-row:last-child > .k-table-td,
.k-grid-content-locked .k-table-row:last-child > td,
.k-grid-content-locked .k-table-row:last-child > .k-table-td {
    border-bottom-width: 0;
}

.k-grid-content {
    width: 100%;
    min-height: 0;
    overflow: auto;
    overflow-x: auto;
    overflow-y: scroll;
    position: relative;
    flex: 1;
}

.k-virtual-scrollable-wrap {
    height: 100%;
    overflow-y: hidden;
    position: relative;
}

.k-grid .k-edit-cell,
.k-grid .k-command-cell,
.k-grid .k-grid-edit-row td,
.k-grid .k-grid-edit-row .k-table-td {
    text-overflow: clip;
}

.k-grid .k-edit-cell > input:not([type='checkbox']):not([type='radio']),
.k-grid .k-edit-cell > select,
.k-grid .k-edit-cell > .k-widget:not(.k-switch),
.k-grid .k-grid-edit-row td > input:not([type='checkbox']):not([type='radio']),
.k-grid .k-grid-edit-row td > select,
.k-grid .k-grid-edit-row td > .k-widget:not(.k-switch),
.k-grid .k-grid-edit-row .k-table-td > input:not([type='checkbox']):not([type='radio']),
.k-grid .k-grid-edit-row .k-table-td > select,
.k-grid .k-grid-edit-row .k-table-td > .k-widget:not(.k-switch) {
    width: 100%;
    vertical-align: middle;
    box-sizing: border-box;
}
.k-grid .k-edit-cell > .k-radio,
.k-grid .k-edit-cell > .k-checkbox,
.k-grid .k-edit-cell > .k-radio-wrap,
.k-grid .k-edit-cell > .k-checkbox-wrap,
.k-grid .k-grid-edit-row td > .k-radio,
.k-grid .k-grid-edit-row td > .k-checkbox,
.k-grid .k-grid-edit-row td > .k-radio-wrap,
.k-grid .k-grid-edit-row td > .k-checkbox-wrap,
.k-grid .k-grid-edit-row .k-table-td > .k-radio,
.k-grid .k-grid-edit-row .k-table-td > .k-checkbox,
.k-grid .k-grid-edit-row .k-table-td > .k-radio-wrap,
.k-grid .k-grid-edit-row .k-table-td > .k-checkbox-wrap {
    vertical-align: middle;
}

.k-grid .k-command-cell > .k-button {
    margin-inline-end: 8px;
    vertical-align: middle;
}
.k-grid .k-command-cell > .k-button:last-child {
    margin-inline-end: unset;
}

.k-grid > .k-resize-handle,
.k-grid-header .k-resize-handle {
    height: 25px;
    cursor: col-resize;
    position: absolute;
    z-index: 2;
}

.k-selection-aggregates {
    border-width: 1px 0 0;
    border-style: solid;
    line-height: 1.4285714286;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.k-selection-aggregates-item-value {
    font-weight: 700;
}

.k-grid-pager {
    border-width: 1px 0 0;
    border-color: inherit;
    font-size: inherit;
}

.k-grid-pager-top {
    border-width: 0 0 1px;
}

.k-grid-virtual .k-grid-content .k-grid-table-wrap {
    float: left;
    width: 100%;
}
.k-grid-virtual .k-grid-content .k-grid-table {
    position: relative;
    float: left;
    z-index: 1;
}
.k-grid-virtual .k-grid-content > .k-height-container {
    position: relative;
    float: left;
}
.k-grid-virtual .k-grid-content::after {
    content: '';
    display: block;
    clear: both;
}

.k-width-container {
    position: absolute;
    visibility: hidden;
}

.k-width-container div {
    height: 1px;
}

.k-grid-add-row td,
.k-grid-add-row .k-table-td {
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.k-grid-lockedcolumns {
    white-space: nowrap;
}

.k-grid-content,
.k-grid-content-locked {
    white-space: normal;
}

.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked {
    flex: 0 0 auto;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    box-sizing: content-box;
}
.k-grid-content-locked + .k-grid-content.k-auto-scrollable,
.k-grid-footer-locked + .k-grid-content.k-auto-scrollable,
.k-grid-header-locked + .k-grid-content.k-auto-scrollable {
    display: inline-block;
}
.k-grid-content-locked .k-table,
.k-grid-footer-locked .k-table,
.k-grid-header-locked .k-table {
    border-width: 0;
}

.k-grid-content,
.k-grid-footer-wrap,
.k-grid-header-wrap {
    flex: 1 1 auto;
    display: inline-block;
    vertical-align: top;
}
.k-grid-content.k-auto-scrollable,
.k-grid-footer-wrap.k-auto-scrollable,
.k-grid-header-wrap.k-auto-scrollable {
    display: block;
}

.k-grid-header-locked > .k-grid-header-table,
.k-grid-header-wrap > .k-grid-header-table {
    margin-bottom: -1px;
}

.k-grid-header .k-table-th.k-grid-header-sticky,
.k-grid-header .k-filter-row .k-grid-header-sticky,
.k-grid .k-grid-content-sticky,
.k-grid .k-grid-row-sticky,
.k-grid .k-grid-footer-sticky {
    position: sticky;
    z-index: 2;
}
.k-grid-header .k-table-th.k-grid-header-sticky.k-edit-cell,
.k-grid-header .k-filter-row .k-grid-header-sticky.k-edit-cell,
.k-grid .k-grid-content-sticky.k-edit-cell,
.k-grid .k-grid-row-sticky.k-edit-cell,
.k-grid .k-grid-footer-sticky.k-edit-cell {
    overflow: visible;
    z-index: 3;
}

kendo-grid .k-table-row.k-grid-row-sticky {
    border: 0;
    position: static;
    z-index: auto;
}
kendo-grid .k-grid-row-sticky td,
kendo-grid .k-grid-row-sticky .k-table-td {
    border-bottom-width: 1px;
    border-top-width: 1px;
    position: sticky;
    top: inherit;
    bottom: inherit;
    z-index: 2;
}
kendo-grid .k-grid-row-sticky td.k-grid-content-sticky,
kendo-grid .k-grid-row-sticky.k-table-alt-row td.k-grid-content-sticky,
kendo-grid .k-grid-row-sticky .k-table-td.k-grid-content-sticky,
kendo-grid .k-grid-row-sticky.k-table-alt-row .k-table-td.k-grid-content-sticky {
    z-index: 3;
}
kendo-grid .k-grid-header-locked .k-table-th.k-grid-header-sticky:last-child,
kendo-grid .k-grid-content-locked .k-table-row .k-grid-content-sticky:last-child {
    border-inline-end: 0;
}
kendo-grid .k-grid-header .k-grid-column-menu,
kendo-grid .k-grid-header .k-grid-header-menu {
    z-index: 1;
}

.k-grid-content-sticky.k-grid-row-sticky {
    z-index: 3;
}

.k-grid .k-table .k-grid-header-sticky,
.k-grid .k-table .k-grid-content-sticky,
.k-grid .k-table .k-grid-footer-sticky {
    border-inline-end-width: 1px;
}
.k-grid .k-table .k-grid-header-sticky:not([style*='display: none']) + td,
.k-grid .k-table .k-grid-header-sticky:not([style*='display: none']) + .k-table-td,
.k-grid .k-table .k-grid-header-sticky:not([style*='display: none']) + .k-table-th,
.k-grid .k-table .k-grid-content-sticky:not([style*='display: none']) + td,
.k-grid .k-table .k-grid-content-sticky:not([style*='display: none']) + .k-table-td,
.k-grid .k-table .k-grid-content-sticky:not([style*='display: none']) + .k-table-th,
.k-grid .k-table .k-grid-footer-sticky:not([style*='display: none']) + td,
.k-grid .k-table .k-grid-footer-sticky:not([style*='display: none']) + .k-table-td,
.k-grid .k-table .k-grid-footer-sticky:not([style*='display: none']) + .k-table-th {
    border-inline-start-width: 0;
}

.k-grid .k-grid-row-sticky {
    border-bottom-width: 1px;
    border-top-width: 1px;
}

.k-grid-header-sticky.k-table-th.k-grid-no-left-border.k-first {
    border-inline-start-width: 0;
}

.k-grid.k-grid-no-scrollbar .k-grid-header-wrap,
.k-grid.k-grid-no-scrollbar .k-grid-footer-wrap {
    margin: 0;
    border-width: 0;
}
.k-grid.k-grid-no-scrollbar .k-grid-header,
.k-grid.k-grid-no-scrollbar .k-grid-footer {
    padding: 0;
}
.k-grid.k-grid-no-scrollbar .k-grid-content {
    overflow-y: auto;
}

.k-grid-norecords {
    text-align: center;
}

div.k-grid-norecords {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.k-grid-norecords-template {
    box-sizing: border-box;
    margin-block: 0;
    margin-inline: auto;
    width: 20em;
    height: 4em;
    border: 1px solid;
    line-height: 4em;
}

.k-column-title {
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    overflow: hidden;
}

.k-grid-sm .k-table-th {
    padding-block: 4px;
    padding-inline: 8px;
}
.k-grid-sm td,
.k-grid-sm .k-table-td {
    padding-block: 4px;
    padding-inline: 8px;
}
.k-grid-sm .k-grouping-header {
    padding-block: 4px;
    padding-inline: 8px;
    gap: 4px;
}
.k-grid-sm .k-grouping-header::before {
    margin-inline-start: -4px;
}
.k-grid-sm .k-grid-header .k-table-th > .k-link {
    margin-block: -4px;
    margin-inline: -8px;
    padding-block: 4px;
    padding-inline: 8px;
}
.k-grid-sm .k-grid-header .k-grid-filter,
.k-grid-sm .k-grid-header .k-header-column-menu,
.k-grid-sm .k-grid-header .k-grid-header-menu {
    padding: 2px;
    width: calc(1.4285714286em + 4px + 2px);
    height: calc(1.4285714286em + 4px + 2px);
    bottom: calc(4px + calc(1.4285714286 * 1em) / 2 - calc(1.4285714286em + 8px) / 2);
}
.k-grid-sm .k-table-th > .k-cell-inner {
    margin-block: -4px;
    margin-inline: -8px;
}
.k-grid-sm .k-table-th > .k-cell-inner > .k-link {
    padding-block: 4px;
    padding-inline: 8px;
}
.k-grid-sm .k-grouping-row .k-icon {
    margin-inline-start: calc(8px - 8px);
}
.k-grid-sm .k-grouping-dropclue {
    height: calc(calc(1em + 4px + 2px) + 6px);
}
.k-grid-sm .k-hierarchy-cell,
.k-grid-sm .k-drag-cell {
    padding: 0;
}
.k-grid-sm .k-hierarchy-cell > .k-icon,
.k-grid-sm .k-drag-cell > .k-icon {
    padding-block: 4px;
    padding-inline: 0;
}
.k-grid-sm .k-edit-cell,
.k-grid-sm .k-command-cell,
.k-grid-sm .k-grid-edit-row td,
.k-grid-sm .k-grid-edit-row .k-table-td {
    padding-block: 2px;
    padding-inline: 8px;
}
.k-grid-sm .k-filter-row td,
.k-grid-sm .k-filter-row .k-table-td,
.k-grid-sm .k-filter-row .k-table-th {
    padding-block: 6px;
    padding-inline: 6px;
}
.k-grid-sm .k-filtercell > span,
.k-grid-sm .k-filtercell .k-filtercell-wrapper {
    gap: 2px;
}
.k-grid-sm .k-selection-aggregates {
    padding-block: 4px;
    padding-inline: 4px;
}
.k-grid-sm .k-resizer-wrap {
    display: block;
    width: 100%;
    padding-block-start: calc(2px - 2px);
    padding-block-end: 2px;
    position: absolute;
    background: none;
    cursor: row-resize;
    z-index: 2;
}

.k-grid-md .k-table-th {
    padding-block: 8px;
    padding-inline: 12px;
}
.k-grid-md td,
.k-grid-md .k-table-td {
    padding-block: 8px;
    padding-inline: 12px;
}
.k-grid-md .k-grouping-header {
    padding-block: 8px;
    padding-inline: 8px;
    gap: 8px;
}
.k-grid-md .k-grouping-header::before {
    margin-inline-start: -8px;
}
.k-grid-md .k-grid-header .k-table-th > .k-link {
    margin-block: -8px;
    margin-inline: -12px;
    padding-block: 8px;
    padding-inline: 12px;
}
.k-grid-md .k-grid-header .k-grid-filter,
.k-grid-md .k-grid-header .k-header-column-menu,
.k-grid-md .k-grid-header .k-grid-header-menu {
    padding: 4px;
    width: calc(1.4285714286em + 8px + 2px);
    height: calc(1.4285714286em + 8px + 2px);
    bottom: calc(8px + calc(1.4285714286 * 1em) / 2 - calc(1.4285714286em + 8px) / 2);
}
.k-grid-md .k-table-th > .k-cell-inner {
    margin-block: -8px;
    margin-inline: -12px;
}
.k-grid-md .k-table-th > .k-cell-inner > .k-link {
    padding-block: 8px;
    padding-inline: 12px;
}
.k-grid-md .k-grouping-row .k-icon {
    margin-inline-start: calc(8px - 12px);
}
.k-grid-md .k-grouping-dropclue {
    height: calc(calc(1em + 8px + 2px) + 6px);
}
.k-grid-md .k-hierarchy-cell,
.k-grid-md .k-drag-cell {
    padding: 0;
}
.k-grid-md .k-hierarchy-cell > .k-icon,
.k-grid-md .k-drag-cell > .k-icon {
    padding-block: 8px;
    padding-inline: 0;
}
.k-grid-md .k-edit-cell,
.k-grid-md .k-command-cell,
.k-grid-md .k-grid-edit-row td,
.k-grid-md .k-grid-edit-row .k-table-td {
    padding-block: 3px;
    padding-inline: 12px;
}
.k-grid-md .k-filter-row td,
.k-grid-md .k-filter-row .k-table-td,
.k-grid-md .k-filter-row .k-table-th {
    padding-block: 2px;
    padding-inline: 4px;
}
.k-grid-md .k-filtercell > span,
.k-grid-md .k-filtercell .k-filtercell-wrapper {
    gap: 4px;
}
.k-grid-md .k-selection-aggregates {
    padding-block: 8px;
    padding-inline: 8px;
}
.k-grid-md .k-resizer-wrap {
    display: block;
    width: 100%;
    padding-block-start: calc(3px - 2px);
    padding-block-end: 3px;
    position: absolute;
    background: none;
    cursor: row-resize;
    z-index: 2;
}

.k-loading-pdf-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}
.k-loading-pdf-mask > .k-i-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 64px;
}
.k-loading-pdf-mask .k-loading-pdf-progress {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.k-pdf-export .k-loading-pdf-mask {
    display: none;
}

.k-grid-pdf-export-element {
    position: absolute;
    left: -10000px;
    top: -10000px;
}
.k-grid-pdf-export-element .k-filter-row {
    display: none;
}

.k-pdf-export-shadow .k-grid {
    float: left;
    width: auto !important;
}
.k-pdf-export-shadow .k-grid,
.k-pdf-export-shadow .k-grid-content,
.k-pdf-export-shadow .k-grid-content-locked {
    height: auto !important;
    overflow: visible;
}
.k-pdf-export-shadow .k-grid-header-locked + .k-grid-header-wrap,
.k-pdf-export-shadow .k-grid-header-locked + .k-grid-content,
.k-pdf-export-shadow .k-grid-header-locked + .k-grid-footer-wrap {
    width: auto !important;
}
.k-pdf-export-shadow .k-grid-header,
.k-pdf-export-shadow .k-grid[data-role='grid'] .k-grid-footer {
    padding: 0 !important;
}

.k-filter-menu-popup {
    min-width: 230px;
    max-width: 320px;
}

.k-filter-menu {
    box-sizing: border-box;
}

.k-filter-menu-container {
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    gap: 8px;
}
.k-filter-menu-container .k-filter-and {
    width: min-content;
    align-self: start;
}
.k-filter-menu-container .k-actions,
.k-filter-menu-container .k-edit-buttons,
.k-filter-menu-container .k-action-buttons,
.k-filter-menu-container .k-columnmenu-actions,
.k-filter-menu-container .k-form-buttons {
    margin: 0;
    padding: 0;
}
.k-filter-menu-container kendo-numeric-filter-menu,
.k-filter-menu-container kendo-grid-string-filter-menu,
.k-filter-menu-container kendo-grid-date-filter-menu,
.k-filter-menu-container kendo-grid-numeric-filter-menu,
.k-filter-menu-container kendo-grid-filter-menu-input-wrapper,
.k-filter-menu-container kendo-treelist-string-filter-menu,
.k-filter-menu-container kendo-treelist-date-filter-menu,
.k-filter-menu-container kendo-treelist-numeric-filter-menu,
.k-filter-menu-container kendo-treelist-filter-menu-input-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    gap: 8px;
}

.k-filter-menu.k-popup .k-filter-menu-container,
.k-grid-filter-popup.k-popup .k-filter-menu-container {
    min-width: 230px;
    max-width: 320px;
}

.k-popup .k-multicheck-wrap {
    margin: 0;
    padding: 0;
    max-height: 300px;
    white-space: nowrap;
    overflow: auto;
    list-style: none;
}
.k-popup .k-multicheck-wrap .k-item,
.k-popup .k-multicheck-wrap .k-check-all-wrap {
    padding-block: 4px;
    padding-inline: 8px;
    display: flex;
    flex-flow: row nowrap;
}

.k-filter-selected-items {
    font-weight: bold;
    line-height: normal;
    white-space: nowrap;
}

.k-autofitting {
    width: auto !important;
    table-layout: auto !important;
}
.k-autofitting .k-table-th,
.k-autofitting td,
.k-autofitting .k-table-td {
    white-space: nowrap !important;
}
.k-autofitting .k-detail-row {
    display: none !important;
}

.k-column-menu-popup,
.k-grid-columnmenu-popup {
    min-width: 230px;
    box-sizing: border-box;
}
.k-column-menu-popup.k-popup,
.k-grid-columnmenu-popup.k-popup {
    max-width: 320px;
}
.k-column-menu-popup .k-actions,
.k-column-menu-popup .k-edit-buttons,
.k-column-menu-popup .k-action-buttons,
.k-column-menu-popup .k-columnmenu-actions,
.k-column-menu-popup .k-form-buttons,
.k-grid-columnmenu-popup .k-actions,
.k-grid-columnmenu-popup .k-edit-buttons,
.k-grid-columnmenu-popup .k-action-buttons,
.k-grid-columnmenu-popup .k-columnmenu-actions,
.k-grid-columnmenu-popup .k-form-buttons {
    margin: 0;
}

.k-column-menu {
    box-sizing: border-box;
}
.k-column-menu .k-menu:not(.k-context-menu) {
    font-weight: 400;
}
.k-column-menu .k-expander {
    border: 0;
    background: inherit;
}
.k-column-menu .k-expander .k-columnmenu-item {
    display: flex;
    align-items: center;
}

.k-column-menu-tabbed {
    border-radius: 4px 4px 0 0;
}
.k-column-menu-tabbed .k-tabstrip-items {
    margin: -1px -1px 0;
}
.k-column-menu-tabbed .k-tabstrip-items .k-item {
    flex: 1;
}
.k-column-menu-tabbed .k-tabstrip-items .k-link {
    justify-content: center;
}
.k-column-menu-tabbed .k-tabstrip-content {
    padding-inline: 0;
    padding-block: 8px;
    border-width: 0;
}

.k-column-list {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.k-column-chooser-title,
.k-column-list-item {
    padding-block: 4px;
    padding-inline: 8px;
}

.k-column-list-item {
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    position: relative;
}
.k-column-list-item .k-checkbox-label {
    margin: 0;
}
.k-column-list-item:focus {
    outline: none;
}

.k-columns-items-wrap {
    padding-block: 6px;
    padding-inline: 0;
}

.k-columnmenu-item {
    padding-block: 4px;
    padding-inline: 8px;
    outline: 0;
    cursor: pointer;
}
.k-columnmenu-item > .k-icon,
.k-columnmenu-item > .k-expander-indicator {
    margin-inline-end: 4px;
}

.k-columnmenu-item-wrapper + .k-columnmenu-item-wrapper {
    border-top: 1px solid;
    border-top-color: rgba(0, 0, 0, 0.08);
}

.k-columnmenu-item-content {
    overflow: hidden;
}

.k-column-menu-group-header {
    padding-block: 4px;
    padding-inline: 8px;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    user-select: none;
}

.k-column-menu-group-header-text {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    font-size: 12px;
    line-height: 1.4285714286;
    font-weight: bold;
    text-transform: uppercase;
    flex: 1 1 auto;
}

.k-rtl .k-grid-virtual .k-grid-content .k-grid-table-wrap,
.k-rtl .k-grid-virtual .k-grid-content .k-grid-table,
.k-rtl .k-grid-virtual .k-grid-content > .k-height-container,
[dir='rtl'] .k-grid-virtual .k-grid-content .k-grid-table-wrap,
[dir='rtl'] .k-grid-virtual .k-grid-content .k-grid-table,
[dir='rtl'] .k-grid-virtual .k-grid-content > .k-height-container,
.k-rtl.k-grid-virtual .k-grid-content .k-grid-table-wrap,
.k-rtl.k-grid-virtual .k-grid-content .k-grid-table,
.k-rtl.k-grid-virtual .k-grid-content > .k-height-container,
[dir='rtl'].k-grid-virtual .k-grid-content .k-grid-table-wrap,
[dir='rtl'].k-grid-virtual .k-grid-content .k-grid-table,
[dir='rtl'].k-grid-virtual .k-grid-content > .k-height-container {
    float: right;
}

.k-grid-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row > .k-table-th,
.k-filter-row > td,
.k-filter-row > .k-table-td {
    border-color: rgba(0, 0, 0, 0.08);
}

.k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
    color: #424242;
    background-color: #e0e0e0;
}

.k-grid .k-table {
    background-color: inherit;
}

.k-grid-toolbar {
    border-color: inherit;
    box-shadow: none;
}

.k-grid-content {
    background-color: #ffffff;
}

.k-group-footer td,
.k-grouping-row td,
.k-group-footer .k-table-td,
.k-grouping-row .k-table-td,
.k-table-tbody .k-group-cell {
    color: #424242;
    background-color: #fafafa;
}

.k-grouping-dropclue::before {
    border-color: #424242 transparent transparent;
}
.k-grouping-dropclue::after {
    background-color: #424242;
}

.k-grid {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-grid .k-table-row.k-table-alt-row {
    background-color: rgba(0, 0, 0, 0.04);
}
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover,
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
    background-color: #ededed;
}
.k-grid td.k-selected,
.k-grid .k-table-row.k-selected > td,
.k-grid .k-table-td.k-selected,
.k-grid .k-table-row.k-selected > .k-table-td {
    background-color: rgba(78, 18, 137, 0.25);
}
.k-grid .k-table-th:focus,
.k-grid .k-table-th.k-focus,
.k-grid td:focus,
.k-grid td.k-focus,
.k-grid .k-table-td:focus,
.k-grid .k-table-td.k-focus,
.k-grid .k-master-row > td:focus,
.k-grid .k-master-row > td.k-focus,
.k-grid .k-grouping-row > td:focus,
.k-grid .k-grouping-row > td.k-focus,
.k-grid .k-detail-row > td:focus,
.k-grid .k-detail-row > td.k-focus,
.k-grid .k-group-footer > td:focus,
.k-grid .k-group-footer > td.k-focus,
.k-grid .k-master-row > .k-table-td:focus,
.k-grid .k-master-row > .k-table-td.k-focus,
.k-grid .k-grouping-row > .k-table-td:focus,
.k-grid .k-grouping-row > .k-table-td.k-focus,
.k-grid .k-detail-row > .k-table-td:focus,
.k-grid .k-detail-row > .k-table-td.k-focus,
.k-grid .k-group-footer > .k-table-td:focus,
.k-grid .k-group-footer > .k-table-td.k-focus,
.k-grid .k-grid-pager:focus,
.k-grid .k-grid-pager.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-grid .k-grid-filter,
.k-grid .k-header-column-menu,
.k-grid .k-grid-header-menu,
.k-grid .k-hierarchy-cell .k-icon {
    color: #424242;
}
.k-grid .k-grouping-row {
    background-color: #fafafa;
}
.k-grid .k-grouping-row .k-icon {
    color: #424242;
}
.k-grid .k-grouping-row .k-grid-content-sticky {
    border-color: rgba(0, 0, 0, 0.3);
    border-top-color: rgba(0, 0, 0, 0.08);
}
.k-grid .k-grid-header-locked,
.k-grid .k-grid-content-locked,
.k-grid .k-grid-header-locked .k-table-th,
.k-grid .k-grid-content-locked td,
.k-grid .k-grid-content-locked .k-table-td {
    border-color: rgba(0, 0, 0, 0.3);
}
.k-grid .k-grid-content-locked .k-group-footer td,
.k-grid .k-grid-content-locked .k-group-footer .k-table-td,
.k-grid .k-grid-content-locked .k-group-cell {
    border-color: rgba(0, 0, 0, 0.08);
}
.k-grid .k-grid-content-locked .k-grouping-row + .k-table-row td,
.k-grid .k-grid-content-locked .k-grouping-row + .k-table-row .k-table-td {
    border-top-color: rgba(0, 0, 0, 0.08);
}
.k-grid .k-grid-content-locked .k-selected td,
.k-grid .k-grid-content-locked .k-selected .k-table-td {
    background-color: #d3c4e2;
}
.k-grid .k-grid-content-locked .k-selected.k-table-alt-row td,
.k-grid .k-grid-content-locked .k-selected.k-table-alt-row .k-table-td {
    background-color: #ccbedb;
}
.k-grid .k-grid-content-locked .k-selected:hover td,
.k-grid .k-grid-content-locked .k-selected.k-hover td,
.k-grid .k-grid-content-locked .k-selected:hover .k-table-td,
.k-grid .k-grid-content-locked .k-selected.k-hover .k-table-td {
    background-color: #c7b9d5;
}
.k-grid .k-grid-header-locked .k-table-th {
    border-bottom-color: rgba(0, 0, 0, 0.08);
}

col.k-sorted,
.k-table-th.k-sorted {
    background-color: rgba(0, 0, 0, 0.02);
}

.k-grid-header .k-sort-icon,
.k-grid-header .k-sort-order {
    color: #4e1289;
}
.k-grid-header .k-grid-filter:hover,
.k-grid-header .k-header-column-menu:hover,
.k-grid-header .k-grid-header-menu:hover,
.k-grid-header .k-hierarchy-cell .k-icon:hover {
    background-color: #ebebeb;
}
.k-grid-header .k-grid-filter:focus,
.k-grid-header .k-grid-filter.k-focus,
.k-grid-header .k-header-column-menu:focus,
.k-grid-header .k-header-column-menu.k-focus,
.k-grid-header .k-grid-header-menu:focus,
.k-grid-header .k-grid-header-menu.k-focus,
.k-grid-header .k-hierarchy-cell .k-icon:focus,
.k-grid-header .k-hierarchy-cell .k-icon.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.k-grid-header .k-grid-filter.k-active,
.k-grid-header .k-header-column-menu.k-active,
.k-grid-header .k-grid-header-menu.k-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-active {
    color: white;
    background-color: #4e1289;
}
.k-grid-header .k-table-th.k-grid-header-sticky,
.k-grid-header td.k-grid-header-sticky,
.k-grid-header .k-table-td.k-grid-header-sticky,
.k-grid-header .k-grid-header-sticky.k-sorted {
    color: #424242;
    background-color: #e0e0e0;
    border-right-color: rgba(0, 0, 0, 0.3);
    border-left-color: rgba(0, 0, 0, 0.3);
}

.k-grid-footer {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #e0e0e0;
}
.k-grid-footer .k-grid-footer-sticky {
    border-left-color: rgba(0, 0, 0, 0.3);
    border-right-color: rgba(0, 0, 0, 0.3);
    background-color: #e0e0e0;
}

.k-selection-aggregates {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #e0e0e0;
}

.k-master-row .k-grid-content-sticky {
    border-color: rgba(0, 0, 0, 0.3);
    border-top-color: rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
}
.k-master-row .k-grid-row-sticky {
    border-top-color: rgba(0, 0, 0, 0.3);
    border-bottom-color: rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}
.k-master-row.k-table-alt-row .k-grid-content-sticky,
.k-master-row.k-table-alt-row .k-grid-row-sticky {
    background-color: #f6f6f6;
}
.k-master-row.k-table-row.k-selected td.k-grid-content-sticky,
.k-master-row.k-table-row.k-selected .k-table-td.k-grid-row-sticky,
.k-master-row.k-table-row td.k-grid-content-sticky.k-selected,
.k-master-row.k-table-row .k-table-td.k-grid-content-sticky.k-selected {
    background-color: #d3c4e2;
}
.k-master-row.k-selected.k-table-alt-row td.k-grid-content-sticky,
.k-master-row.k-selected.k-table-alt-row .k-table-td.k-grid-row-sticky,
.k-master-row.k-table-alt-row td.k-grid-content-sticky.k-selected,
.k-master-row.k-table-alt-row .k-table-td.k-grid-content-sticky.k-selected {
    background-color: #ccbedb;
}
.k-master-row:hover .k-grid-content-sticky,
.k-master-row:hover .k-grid-row-sticky,
.k-master-row.k-hover .k-grid-content-sticky,
.k-master-row.k-hover .k-grid-row-sticky {
    background-color: #ebebeb;
}
.k-master-row.k-selected:hover td.k-grid-content-sticky,
.k-master-row.k-selected:hover .k-table-td.k-grid-row-sticky,
.k-master-row.k-selected.k-hover td.k-grid-content-sticky,
.k-master-row.k-selected.k-hover .k-table-td.k-grid-row-sticky,
.k-master-row:hover td.k-grid-content-sticky.k-selected,
.k-master-row.k-hover td.k-grid-content-sticky.k-selected,
.k-master-row:hover .k-table-td.k-grid-content-sticky.k-selected,
.k-master-row.k-hover .k-table-td.k-grid-content-sticky.k-selected {
    background-color: #c7b9d5;
}

kendo-grid.k-grid .k-grid-content-sticky {
    border-top-color: rgba(0, 0, 0, 0.08);
    border-left-color: rgba(0, 0, 0, 0.3);
    border-right-color: rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}
kendo-grid.k-grid .k-grid-content-sticky:hover,
kendo-grid.k-grid .k-grid-content-sticky.k-hover {
    background-color: #ebebeb;
}
kendo-grid.k-grid .k-grid-row-sticky td,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td {
    border-top-color: rgba(0, 0, 0, 0.3);
    border-bottom-color: rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}
kendo-grid.k-grid .k-grid-row-sticky:hover td,
kendo-grid.k-grid .k-grid-row-sticky.k-hover td,
kendo-grid.k-grid .k-grid-row-sticky:hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky.k-hover .k-table-td {
    background-color: #ebebeb;
}
kendo-grid.k-grid .k-table-alt-row .k-grid-content-sticky,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row .k-table-td {
    background-color: #f6f6f6;
}
kendo-grid.k-grid .k-table-row.k-selected .k-grid-content-sticky,
kendo-grid.k-grid .k-selected.k-grid-row-sticky td,
kendo-grid.k-grid .k-grid-row-sticky td.k-selected,
kendo-grid.k-grid .k-selected.k-grid-row-sticky .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td.k-selected,
kendo-grid.k-grid .k-selected.k-grid-content-sticky {
    background-color: #d3c4e2;
}
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row .k-grid-content-sticky,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky .k-table-td,
kendo-grid.k-grid .k-table-alt-row .k-selected.k-grid-content-sticky {
    background-color: #ccbedb;
}
kendo-grid.k-grid .k-table-row:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-row.k-hover .k-grid-content-sticky,
kendo-grid.k-grid .k-grid-row-sticky:hover td,
kendo-grid.k-grid .k-grid-row-sticky.k-hover td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row:hover td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row.k-hover td,
kendo-grid.k-grid .k-grid-row-sticky:hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky.k-hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row:hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row.k-hover .k-table-td,
kendo-grid.k-grid .k-table-alt-row:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-alt-row.k-hover .k-grid-content-sticky {
    background-color: #ebebeb;
}
kendo-grid.k-grid .k-table-row.k-selected:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-row.k-selected.k-hover .k-grid-content-sticky,
kendo-grid.k-grid .k-selected.k-grid-row-sticky:hover td,
kendo-grid.k-grid .k-selected.k-grid-row-sticky.k-hover td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky:hover td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky.k-hover td,
kendo-grid.k-grid .k-selected.k-grid-row-sticky:hover .k-table-td,
kendo-grid.k-grid .k-selected.k-grid-row-sticky.k-hover .k-table-td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky:hover .k-table-td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky.k-hover .k-table-td,
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row.k-hover .k-grid-content-sticky,
kendo-grid.k-grid .k-grid-row-sticky:hover td.k-selected,
kendo-grid.k-grid .k-grid-row-sticky.k-hover td.k-selected,
kendo-grid.k-grid .k-grid-row-sticky:hover .k-table-td.k-selected,
kendo-grid.k-grid .k-grid-row-sticky.k-hover .k-table-td.k-selected,
kendo-grid.k-grid .k-table-row:hover .k-grid-content-sticky.k-selected,
kendo-grid.k-grid .k-table-row.k-hover .k-grid-content-sticky.k-selected {
    background-color: #c7b9d5;
}

.k-grouping-row .k-grid-content-sticky {
    background-color: #e0e0e0;
}
.k-grouping-row:hover .k-grid-content-sticky,
.k-grouping-row.k-hover .k-grid-content-sticky {
    background-color: #ebebeb;
}

.k-column-list-item:hover,
.k-columnmenu-item:hover {
    color: #424242;
    background-color: #f0f0f0;
}

.k-column-list-item:focus,
.k-column-list-item.k-focus,
.k-columnmenu-item:focus,
.k-columnmenu-item.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-columnmenu-item.k-selected {
    color: white;
    background-color: #4e1289;
}

.k-column-menu .k-menu:not(.k-context-menu) {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-column-menu .k-menu:not(.k-context-menu) .k-item:hover,
.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-hover {
    color: #424242;
    background-color: #f0f0f0;
}
.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-selected {
    color: white;
    background-color: #4e1289;
}
.k-column-menu .k-menu:not(.k-context-menu) .k-item:focus,
.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-column-menu-tabbed {
    background-color: #fafafa;
}

.k-column-menu-group-header-text {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
}

.k-check-all-wrap {
    border-color: rgba(0, 0, 0, 0.08);
}

.k-grid-norecords-template {
    background-color: #ffffff;
    border-color: rgba(0, 0, 0, 0.08);
}

.k-resizer-wrap.k-hover .k-row-resizer {
    background-color: rgba(0, 0, 0, 0.12);
}
.k-resizer-wrap.k-active .k-row-resizer {
    background-color: #4e1289;
}

.k-loader {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    line-height: 0;
}

.k-loader-canvas {
    display: flex;
    position: relative;
}

.k-loader-segment {
    border-radius: 50%;
    background-color: currentColor;
    position: absolute;
}

.k-loader-sm {
    padding: 2px;
}
.k-loader-sm .k-loader-segment {
    width: 4px;
    height: 4px;
}

.k-loader-md {
    padding: 4px;
}
.k-loader-md .k-loader-segment {
    width: 8px;
    height: 8px;
}

.k-loader-lg {
    padding: 8px;
}
.k-loader-lg .k-loader-segment {
    width: 16px;
    height: 16px;
}

@keyframes pulsing-2-segment {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
.k-loader-pulsing-2 .k-loader-canvas {
    align-items: center;
}
.k-loader-pulsing-2 .k-loader-segment:nth-child(1) {
    left: 0;
    transform: scale(1.5);
    transform-origin: 0 50%;
    animation: pulsing-2-segment 1s ease infinite;
    animation-delay: -0.5s;
}
.k-loader-pulsing-2 .k-loader-segment:nth-child(2) {
    right: 0;
    transform-origin: 100% 50%;
    animation: pulsing-2-segment 1s ease infinite;
}
.k-loader-pulsing-2.k-loader-sm .k-loader-canvas {
    width: 12px;
    height: 6px;
}
.k-loader-pulsing-2.k-loader-md .k-loader-canvas {
    width: 24px;
    height: 12px;
}
.k-loader-pulsing-2.k-loader-lg .k-loader-canvas {
    width: 48px;
    height: 24px;
}

@keyframes spinner-3-segment {
    0% {
        top: 0;
        left: 50%;
    }
    33.3333% {
        top: 100%;
        left: 0;
    }
    66.6666% {
        top: 100%;
        left: 100%;
    }
    100% {
        top: 0;
        left: 50%;
    }
}
.k-loader-spinner-3 .k-loader-segment {
    transform: translate(-50%, -50%);
}
.k-loader-spinner-3 .k-loader-segment:nth-child(1) {
    top: 0;
    left: 50%;
    animation: spinner-3-segment 2s ease infinite;
}
.k-loader-spinner-3 .k-loader-segment:nth-child(2) {
    top: 100%;
    left: 0;
    animation: spinner-3-segment 2s ease infinite;
    animation-delay: -0.6666666667s;
}
.k-loader-spinner-3 .k-loader-segment:nth-child(3) {
    top: 100%;
    left: 100%;
    animation: spinner-3-segment 2s ease infinite;
    animation-delay: -1.3333333333s;
}
.k-loader-spinner-3.k-loader-sm .k-loader-canvas {
    margin: 2px;
    width: calc(16px - 4px);
    height: calc(13.8564064608px - 4px);
}
.k-loader-spinner-3.k-loader-md .k-loader-canvas {
    margin: 4px;
    width: calc(32px - 8px);
    height: calc(27.7128129216px - 8px);
}
.k-loader-spinner-3.k-loader-lg .k-loader-canvas {
    margin: 8px;
    width: calc(64px - 16px);
    height: calc(55.4256258432px - 16px);
}

@keyframes spinner-4-segment-1 {
    0% {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    33.3% {
        top: 0;
        left: 0;
        transform: translateX(0) translateY(0);
    }
    66.6% {
        top: 0;
        left: 0;
        transform: translateX(0) translateY(0);
    }
    100% {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
@keyframes spinner-4-segment-2 {
    0% {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    33.3% {
        top: 0;
        left: 100%;
        transform: translateX(-100%) translateY(0);
    }
    66.6% {
        top: 0;
        left: 100%;
        transform: translateX(-100%) translateY(0);
    }
    100% {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
@keyframes spinner-4-segment-3 {
    0% {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    33.3% {
        top: 100%;
        left: 100%;
        transform: translateX(-100%) translateY(-100%);
    }
    66.6% {
        top: 100%;
        left: 100%;
        transform: translateX(-100%) translateY(-100%);
    }
    100% {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
@keyframes spinner-4-segment-4 {
    0% {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    33.3% {
        top: 100%;
        left: 0;
        transform: translateX(0) translateY(-100%);
    }
    66.6% {
        top: 100%;
        left: 0;
        transform: translateX(0) translateY(-100%);
    }
    100% {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
@keyframes spinner-4 {
    0% {
        transform: rotate(0);
    }
    33.3% {
        transform: rotate(0);
    }
    66.6% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
.k-loader-spinner-4 .k-loader-canvas {
    animation: spinner-4 1.833s ease infinite;
}
.k-loader-spinner-4 .k-loader-segment {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.k-loader-spinner-4 .k-loader-segment:nth-child(1) {
    animation: spinner-4-segment-1 1.833s ease infinite;
}
.k-loader-spinner-4 .k-loader-segment:nth-child(2) {
    animation: spinner-4-segment-2 1.833s ease infinite;
}
.k-loader-spinner-4 .k-loader-segment:nth-child(3) {
    animation: spinner-4-segment-3 1.833s ease infinite;
}
.k-loader-spinner-4 .k-loader-segment:nth-child(4) {
    animation: spinner-4-segment-4 1.833s ease infinite;
}
.k-loader-spinner-4.k-loader-sm .k-loader-canvas {
    width: 16px;
    height: 16px;
}
.k-loader-spinner-4.k-loader-md .k-loader-canvas {
    width: 32px;
    height: 32px;
}
.k-loader-spinner-4.k-loader-lg .k-loader-canvas {
    width: 64px;
    height: 64px;
}

.k-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2001;
}
.k-loader-container .k-loader {
    padding: 0;
}

.k-loader-container,
.k-loader-container-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.k-loader-container-overlay {
    opacity: 0.5;
}

.k-loader-container-inner {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
}

.k-loader-container-panel {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
}

.k-loader-container-sm .k-loader-container-inner {
    padding: 16px;
    gap: 4px;
}
.k-loader-container-sm .k-loader-container-label {
    font-size: 12px;
}

.k-loader-container-md .k-loader-container-inner {
    padding: 20px;
    gap: 8px;
}
.k-loader-container-md .k-loader-container-label {
    font-size: 14px;
}

.k-loader-container-lg .k-loader-container-inner {
    padding: 24px;
    gap: 12px;
}
.k-loader-container-lg .k-loader-container-label {
    font-size: 16px;
}

.k-loader-top .k-loader-container-inner {
    flex-direction: column;
    text-align: center;
}

.k-loader-end .k-loader-container-label {
    order: -1;
}

.k-loading-mask,
.k-loading-image,
.k-loading-color {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
}
.k-loading-mask *,
.k-loading-mask *::before,
.k-loading-mask *::after,
.k-loading-mask::before,
.k-loading-mask::after,
.k-loading-image *,
.k-loading-image *::before,
.k-loading-image *::after,
.k-loading-image::before,
.k-loading-image::after,
.k-loading-color *,
.k-loading-color *::before,
.k-loading-color *::after,
.k-loading-color::before,
.k-loading-color::after {
    box-sizing: border-box;
}

.k-loading-mask {
    z-index: 100;
}
.k-loading-mask.k-opaque .k-loading-color {
    opacity: 1;
}

.k-loading-text {
    text-indent: -4000px;
    text-align: center;
    position: absolute;
    color: currentColor;
}

.k-loading-image {
    z-index: 2;
    color: currentColor;
}

.k-loading-color {
    background-color: #ffffff;
    opacity: 0.3;
}

.k-loader-primary {
    color: #4e1289;
}

.k-loader-secondary {
    color: #656565;
}

.k-loader-tertiary {
    color: #03a9f4;
}

.k-loader-info {
    color: #0058e9;
}

.k-loader-success {
    color: #37b400;
}

.k-loader-warning {
    color: #ffc000;
}

.k-loader-error {
    color: #f31700;
}

.k-loader-dark {
    color: #424242;
}

.k-loader-light {
    color: #ebebeb;
}

.k-loader-inverse {
    color: #424242;
}

.k-loader-container-panel {
    background-color: #ffffff;
    border-color: rgba(0, 0, 0, 0.08);
}

.k-loader-container-inner .k-text-secondary,
.k-loader-container-inner .\!k-text-secondary {
    color: #656565 !important;
}

.k-color-preview {
    width: 100%;
    height: 100%;
    border-width: 1px;
    border-radius: 4px;
    box-sizing: border-box;
    border-style: solid;
    display: inline-flex;
    flex-flow: row nowrap;
    position: relative;
    overflow: hidden;
}
.k-color-preview *,
.k-color-preview *::before,
.k-color-preview *::after {
    box-sizing: border-box;
}

.k-coloreditor-current-color {
    cursor: pointer;
}

.k-icon-color-preview {
    border-width: 0;
    border-radius: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 2px;
}
.k-icon-color-preview .k-color-preview-mask {
    width: calc(16px - 2px);
    height: 2px;
}

.k-color-preview-mask {
    width: 100%;
    height: 100%;
    position: relative;
}

.k-color-preview-mask::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAuSURBVHgBxYyxDQAwDMJIL+YT+DjtzFRliUfLcklqBCRT4eCTxbD6kdL2/LgYXqpvCbs3kBv/AAAAAElFTkSuQmCC');
    background-size: contain;
    background-position: 0 0;
}

.k-no-color .k-color-preview-mask::before {
    content: '';
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' version='1.1'%3e%3cline x1='0' x2='20' y1='0' y2='20' stroke='%23f31700' stroke-width='1'/%3e%3c/svg%3e");
    background-size: 100% 100%;
    background-position: 0 0;
}

.k-color-preview {
    border-color: rgba(0, 0, 0, 0.08);
}
.k-color-preview:hover,
.k-color-preview.k-hover {
    border-color: rgba(0, 0, 0, 0.08);
}

.k-colorpalette {
    border-width: 0;
    box-sizing: border-box;
    outline: 0;
    font-size: 14px;
    font-family: inherit;
    line-height: 0;
    display: inline-flex;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-colorpalette *,
.k-colorpalette *::before,
.k-colorpalette *::after {
    box-sizing: border-box;
}

.k-colorpalette-table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    position: relative;
}

.k-colorpalette-tile {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
}
.k-colorpalette-tile:hover,
.k-colorpalette-tile.k-hover {
    position: relative;
    z-index: 101;
}
.k-colorpalette-tile:focus,
.k-colorpalette-tile.k-focus,
.k-colorpalette-tile.k-selected,
.k-colorpalette-tile.k-selected:hover {
    position: relative;
    z-index: 100;
}

.k-colorpalette-tile:focus,
.k-colorpalette-tile.k-focus {
    box-shadow:
        0 0 3px 1px rgba(0, 0, 0, 0.3),
        inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}
.k-colorpalette-tile:hover,
.k-colorpalette-tile.k-hover {
    box-shadow:
        0 0 3px 1px rgba(0, 0, 0, 0.3),
        inset 0 0 0 1px rgba(255, 255, 255, 0.8);
}
.k-colorpalette-tile.k-selected,
.k-colorpalette-tile.k-selected:hover {
    box-shadow:
        0 1px 3px 1px rgba(0, 0, 0, 0.3),
        inset 0 0 0 1px white;
}

.k-slider {
    border: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    background: none;
    display: inline-flex;
    align-items: center;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-slider *,
.k-slider *::before,
.k-slider *::after {
    box-sizing: border-box;
}
.k-slider .k-label {
    width: auto;
    font-size: 0.92em;
    line-height: 1;
    white-space: nowrap;
    position: absolute;
}
.k-slider .k-tick,
.k-slider .k-slider-track {
    cursor: pointer;
}
.k-slider .k-tick {
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    position: relative;
}
.k-slider.k-disabled .k-tick,
.k-slider.k-disabled .k-slider-track,
.k-slider.k-disabled .k-draghandle {
    cursor: default;
}

.k-slider-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: inherit;
    align-items: inherit;
    gap: inherit;
    position: relative;
}

.k-slider {
    width: min-content;
    height: min-content;
    gap: calc(14px / 2);
}
.k-slider .k-button {
    flex: none;
}
.k-slider .k-slider-track-wrap {
    flex: 1 1 auto;
    display: flex;
    flex-flow: inherit;
    position: relative;
    touch-action: none;
}
.k-slider .k-slider-track-wrap .k-slider-items {
    margin: 0;
    padding: 0;
    list-style: none;
    flex: 1 1 100%;
    display: flex;
    flex-flow: inherit;
    justify-content: space-between;
    user-select: none;
}
.k-slider .k-slider-track-wrap .k-tick {
    flex: 0 0 1px;
}
.k-slider .k-slider-track-wrap .k-tick-horizontal {
    width: 1px;
    min-height: 100%;
}
.k-slider .k-slider-track-wrap .k-tick-vertical {
    width: 100%;
    min-height: 1px;
}
.k-slider .k-slider-track-wrap .k-draghandle {
    position: absolute;
}
.k-slider-horizontal .k-slider-track-wrap {
    height: 26px;
}
.k-slider-horizontal .k-slider-track-wrap .k-slider-track {
    width: 100%;
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle {
    top: 50%;
    transform: translate(-50%, -50%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle:focus,
.k-slider-horizontal .k-slider-track-wrap .k-draghandle:active {
    transform: translate(-50%, -50%) scale(1);
}
.k-slider-horizontal .k-slider-track-wrap .k-slider-selection {
    width: calc((var(--kendo-slider-end, 0) - var(--kendo-slider-start, 0)) * 1%);
    inset-inline-start: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle-start {
    inset-inline-start: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle-end {
    inset-inline-start: calc(var(--kendo-slider-end, 0) * 1%);
}
.k-slider-vertical .k-slider-track-wrap {
    width: 26px;
}
.k-slider-vertical .k-slider-track-wrap .k-slider-track {
    height: 100%;
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle {
    left: 50%;
    transform: translate(-50%, 50%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle:focus,
.k-slider-vertical .k-slider-track-wrap .k-draghandle:active {
    transform: translate(-50%, 50%) scale(1);
}
.k-slider-vertical .k-slider-track-wrap .k-slider-selection {
    bottom: calc(var(--kendo-slider-start, 0) * 1%);
    height: calc((var(--kendo-slider-end, 0) - var(--kendo-slider-start, 0)) * 1%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle-start {
    bottom: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle-end {
    bottom: calc(var(--kendo-slider-end, 0) * 1%);
}

.k-slider-vertical {
    height: 200px;
    flex-flow: column-reverse nowrap;
}
.k-slider-vertical .k-tick {
    text-align: end;
    margin-left: 2px;
}
.k-slider-vertical .k-slider-topleft .k-tick {
    text-align: start;
}
.k-slider-vertical .k-tick {
    background-position: -94px center;
}
.k-slider-vertical .k-slider-topleft .k-tick {
    background-position: -124px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick {
    background-position: -154px center;
}
.k-slider-vertical .k-tick-large {
    display: flex;
    align-items: center;
    background-position: -4px center;
}
.k-slider-vertical .k-slider-topleft .k-tick-large {
    background-position: -34px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large {
    background-position: -64px center;
}
.k-slider-vertical .k-first {
    background-position: -94px 100%;
}
.k-slider-vertical .k-tick-large.k-first {
    background-position: -4px 100%;
}
.k-slider-vertical .k-slider-topleft .k-first {
    background-position: -124px 100%;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-first {
    background-position: -34px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-first {
    background-position: -154px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first {
    background-position: -64px 100%;
}
.k-slider-vertical .k-last {
    background-position: -94px 0;
}
.k-slider-vertical .k-tick-large.k-last {
    background-position: -4px 0;
}
.k-slider-vertical .k-slider-topleft .k-last {
    background-position: -124px 0;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-last {
    background-position: -34px 0;
}
.k-slider-vertical .k-slider-bottomright .k-last {
    background-position: -154px 0;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last {
    background-position: -64px 0;
}
.k-slider-vertical .k-label {
    text-align: start;
    inset-inline-start: 120%;
    top: 50%;
    transform: translateY(-50%);
}
.k-slider-vertical .k-first .k-label {
    top: 100%;
}
.k-slider-vertical .k-last .k-label {
    top: 0;
}
.k-slider-vertical .k-slider-topleft .k-label {
    inset-inline-start: auto;
    inset-inline-end: 120%;
}

.k-slider-horizontal {
    width: 200px;
    flex-flow: row nowrap;
}
.k-slider-horizontal .k-tick {
    background-position: center -92px;
}
.k-slider-horizontal .k-slider-topleft .k-tick {
    background-position: center -122px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick {
    background-position: center -152px;
}
.k-slider-horizontal .k-tick-large {
    background-position: center -2px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large {
    background-position: center -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large {
    background-position: center -62px;
}
.k-slider-horizontal .k-first {
    background-position: 0 -92px;
}
.k-slider-horizontal .k-tick-large.k-first {
    background-position: 0 -2px;
}
.k-slider-horizontal .k-slider-topleft .k-first {
    background-position: 0 -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first {
    background-position: 0 -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-first {
    background-position: 0 -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first {
    background-position: 0 -62px;
}
.k-slider-horizontal .k-last {
    background-position: 100% -92px;
}
.k-slider-horizontal .k-tick-large.k-last {
    background-position: 100% -2px;
}
.k-slider-horizontal .k-slider-topleft .k-last {
    background-position: 100% -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last {
    background-position: 100% -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-last {
    background-position: 100% -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last {
    background-position: 100% -62px;
}
.k-slider-horizontal .k-label {
    inset-inline-start: 50%;
    bottom: -1.2em;
    transform: translateX(-50%);
}
.k-slider-horizontal .k-first .k-label {
    inset-inline-start: 0;
}
.k-slider-horizontal .k-last .k-label {
    inset-inline-start: 100%;
}
.k-slider-horizontal .k-slider-topleft .k-label {
    top: -1.2em;
}

.k-slider-track,
.k-slider-selection {
    margin: 0;
    padding: 0;
    position: absolute;
}
.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection {
    height: 4px;
    left: 0;
    margin-top: -2px;
    top: 50%;
}
.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection {
    bottom: 0;
    left: 50%;
    margin-left: -2px;
    width: 4px;
}

.k-draghandle {
    background-color: transparent;
    background-repeat: no-repeat;
    border-style: solid;
    border-width: 1px;
    outline: 0;
    position: absolute;
    text-align: center;
    text-decoration: none;
    width: 14px;
    height: 14px;
}
.k-slider-transitions.k-slider-horizontal .k-draghandle {
    transition:
        inset-inline-start 0.3s ease-out,
        background-color 0.3s ease-out,
        transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.k-slider-transitions.k-slider-vertical .k-draghandle {
    transition:
        bottom 0.3s ease-out,
        background-color 0.3s ease-out,
        transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.k-draghandle.k-pressed {
    transition: none;
}

.k-slider-transitions.k-slider-horizontal .k-slider-selection {
    transition: width 0.3s ease-out;
}
.k-slider-transitions.k-slider-vertical .k-slider-selection {
    transition: height 0.3s ease-out;
}
.k-slider-selection.k-pressed {
    transition: none;
}

.k-slider.k-readonly .k-button,
.k-slider.k-readonly .k-slider-track,
.k-slider.k-readonly .k-tick,
.k-slider.k-readonly .k-draghandle {
    pointer-events: none;
}

.k-slider-tooltip .k-callout-n,
.k-slider-tooltip .k-callout-s {
    margin-inline-start: -3px;
}
.k-slider-tooltip .k-callout-w,
.k-slider-tooltip .k-callout-e {
    margin-top: -3px;
}

.k-slider-rtl.k-slider-horizontal .k-draghandle,
.k-slider-horizontal.k-slider[dir='rtl'] .k-draghandle,
[dir='rtl'] .k-slider-horizontal.k-slider .k-draghandle,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle {
    transform: translate(50%, -50%);
}
.k-slider-rtl.k-slider-horizontal .k-draghandle:focus,
.k-slider-horizontal.k-slider[dir='rtl'] .k-draghandle:focus,
[dir='rtl'] .k-slider-horizontal.k-slider .k-draghandle:focus,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle:focus,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle:focus,
.k-slider-rtl.k-slider-horizontal .k-draghandle:active,
.k-slider-horizontal.k-slider[dir='rtl'] .k-draghandle:active,
[dir='rtl'] .k-slider-horizontal.k-slider .k-draghandle:active,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle:active,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle:active {
    transform: translate(50%, -50%) scale(1);
}
.k-slider-rtl.k-slider-horizontal .k-label,
.k-slider-horizontal.k-slider[dir='rtl'] .k-label,
[dir='rtl'] .k-slider-horizontal.k-slider .k-label,
.k-slider-horizontal.k-slider.k-rtl .k-label,
.k-rtl .k-slider-horizontal.k-slider .k-label {
    transform: translateX(50%);
}

.k-slider kendo-resize-sensor {
    position: absolute;
}

.k-slider-horizontal .k-slider-wrap:not(.k-slider-buttons) {
    padding-inline-start: calc(14px / 2);
    padding-inline-end: calc(14px / 2);
}

.k-slider-vertical .k-slider-wrap:not(.k-slider-buttons) {
    padding-top: calc(14px / 2);
    padding-bottom: calc(14px / 2);
}

.k-slider {
    color: #424242;
}
.k-slider .k-slider-track,
.k-slider .k-slider-selection {
    border-radius: 4px;
}
.k-slider .k-slider-track {
    background-color: #ebebeb;
}
.k-slider .k-slider-selection {
    background-color: #4e1289;
}
.k-slider .k-draghandle {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
    border-radius: 50%;
}
.k-slider .k-draghandle:hover {
    border-color: #4e1289;
    background-color: #4b1184;
}
.k-slider .k-draghandle:active,
.k-slider .k-draghandle.k-pressed {
    border-color: #451079;
    background-color: #451079;
}
.k-slider .k-draghandle:focus,
.k-slider .k-draghandle.k-focus {
    box-shadow: 0 0 0 2px rgba(78, 18, 137, 0.3);
}
.k-slider.k-focus .k-draghandle {
    box-shadow: 0 0 0 2px rgba(78, 18, 137, 0.3);
}
.k-slider-horizontal .k-tick {
    background-image: url(data:image/gif;base64,R0lGODlhAQC0AIABALi4uAAAACH5BAEAAAEALAAAAAABALQAAAIWjIGJxqzazlux2ovlzND2rAHgSIZWAQA7);
}

.k-slider-vertical .k-tick {
    background-image: url(data:image/gif;base64,R0lGODlhtAABAIABALi4uAAAACH5BAEAAAEALAAAAAC0AAEAAAIWjIGJxqzazlux2ovlzND2rAHgSIZWAQA7);
}

.k-colorgradient {
    border-radius: 4px;
    width: 272px;
    padding-block: 12px;
    padding-inline: 12px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    outline: 0;
    font-size: 14px;
    font-family: inherit;
    line-height: 1.4285714286;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-colorgradient *,
.k-colorgradient *::before,
.k-colorgradient *::after {
    box-sizing: border-box;
}

.k-colorgradient-canvas {
    gap: 12px;
}
.k-colorgradient-canvas .k-hsv-rectangle {
    border-radius: 4px;
    position: relative;
    flex: 1 1 auto;
    user-select: none;
}
.k-colorgradient-canvas .k-hsv-gradient {
    border-radius: 4px;
    height: 180px;
}
.k-colorgradient-canvas .k-hsv-draghandle {
    margin-top: -7px;
    margin-left: -7px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    cursor: pointer;
}
.k-colorgradient-canvas .k-hsv-controls {
    position: relative;
    flex-shrink: 0;
    gap: 12px;
}

.k-colorgradient .k-draghandle {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    border-width: 1px;
    border-style: solid;
    outline-width: 1px;
    outline-style: solid;
    box-sizing: border-box;
}

.k-colorgradient-slider.k-slider-vertical {
    width: 10px;
    height: 180px;
    flex: 0 0 10px;
}
.k-colorgradient-slider.k-slider-vertical .k-slider-track {
    width: 10px;
    border-radius: 10px;
    margin-left: -5px;
}
.k-colorgradient-slider.k-slider-horizontal {
    width: 100%;
    height: 10px;
    flex: 0 0 10px;
}
.k-colorgradient-slider.k-slider-horizontal .k-slider-track {
    height: 10px;
    border-radius: 10px;
    margin-top: -5px;
}
.k-colorgradient-slider.k-alpha-slider .k-slider-track::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 10px;
    position: relative;
    z-index: -1;
}
.k-colorgradient-slider .k-slider-wrap,
.k-colorgradient-slider .k-slider-track-wrap {
    z-index: 1;
}
.k-colorgradient-slider .k-slider-selection {
    display: none;
}
.k-colorgradient-slider .k-draghandle {
    border-width: 3px;
}

.k-colorgradient-inputs {
    gap: 8px;
}
.k-colorgradient-inputs .k-colorgradient-input-label {
    text-transform: uppercase;
}
.k-colorgradient-inputs > .k-vstack,
.k-colorgradient-inputs > .k-vbox {
    gap: 4px;
}
.k-colorgradient-inputs .k-numerictextbox {
    width: 46px;
}
.k-colorgradient-inputs .k-input-inner {
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    text-align: center;
    text-overflow: clip;
}

.k-colorgradient-color-contrast > div {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
}
.k-colorgradient-color-contrast .k-contrast-ratio {
    margin-bottom: 8px;
}
.k-colorgradient-color-contrast .k-contrast-ratio-text {
    font-weight: 700;
}
.k-colorgradient-color-contrast .k-contrast-validation {
    display: inline-flex;
    align-items: center;
    gap: 4px;
}
.k-colorgradient-color-contrast .k-contrast-validation .k-icon {
    vertical-align: middle;
}
.k-colorgradient-color-contrast .k-contrast-validation .k-icon + .k-icon,
.k-colorgradient-color-contrast .k-contrast-validation .k-icon-wrapper-host + .k-icon-wrapper-host .k-icon {
    margin-inline-start: -13px;
}

.k-color-contrast-svg {
    position: absolute;
    top: 0;
    left: 0;
    overflow: visible;
    pointer-events: none;
}

.k-colorgradient {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-colorgradient:focus,
.k-colorgradient.k-focus {
    box-shadow: var(--kendo-elevation-3, 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-colorgradient-canvas .k-hsv-gradient {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), black), linear-gradient(to right, white, rgba(255, 255, 255, 0));
}

.k-colorgradient .k-draghandle {
    border-color: rgba(255, 255, 255, 0.8);
    background-color: transparent;
    outline-color: #000000;
    box-shadow: rgba(0, 0, 0, 0.5);
}
.k-colorgradient .k-draghandle:focus,
.k-colorgradient .k-draghandle.k-focus {
    border-color: #ffffff;
    background-color: transparent;
    outline-color: #000000;
    box-shadow: rgba(0, 0, 0, 0.5);
}
.k-colorgradient .k-draghandle:hover,
.k-colorgradient .k-draghandle.k-hover,
.k-colorgradient .k-draghandle.k-active,
.k-colorgradient .k-draghandle:active {
    border-color: #ffffff;
    background-color: transparent;
    outline-color: #000000;
}

.k-colorgradient-slider.k-hue-slider.k-slider-horizontal .k-slider-track {
    background: linear-gradient(to right, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}
.k-colorgradient-slider.k-hue-slider.k-slider-vertical .k-slider-track {
    background: linear-gradient(to top, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}

.k-colorgradient-slider.k-alpha-slider .k-slider-track::before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAuSURBVHgBxYyxDQAwDMJIL+YT+DjtzFRliUfLcklqBCRT4eCTxbD6kdL2/LgYXqpvCbs3kBv/AAAAAElFTkSuQmCC)
        center repeat;
}

.k-colorgradient-input-label {
    color: #666666;
}

.k-rtl .k-colorgradient .k-hue-slider.k-slider-horizontal .k-slider-track,
[dir='rtl'] .k-colorgradient .k-hue-slider.k-slider-horizontal .k-slider-track {
    background: linear-gradient(to left, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}

.k-coloreditor {
    border-radius: 4px;
    min-width: 272px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-size: 14px;
    font-family: inherit;
    line-height: 1.4285714286;
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
}
.k-coloreditor *,
.k-coloreditor *::before,
.k-coloreditor *::after {
    box-sizing: border-box;
}

.k-coloreditor-header {
    padding: 12px 12px 0;
}

.k-coloreditor-header-actions {
    gap: 8px;
}

.k-coloreditor-preview {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;
    gap: 4px;
    position: relative;
    z-index: 1;
}

.k-coloreditor-preview .k-color-preview {
    width: 32px;
    height: 12px;
}

.k-coloreditor-views {
    min-width: calc(var(--kendo-color-preview-width, 24px) * var(--kendo-color-preview-columns, 10));
    padding-block: 12px;
    padding-inline: 12px;
    gap: 12px;
}
.k-coloreditor-views .k-colorgradient {
    padding: 0;
    width: auto;
    border-width: 0;
}
.k-coloreditor-views .k-colorgradient:focus,
.k-coloreditor-views .k-colorgradient.k-focus {
    outline: 2px dotted;
    outline-offset: 4px;
    border-radius: 0;
}
.k-coloreditor-views .k-colorpalette {
    align-self: center;
}
.k-coloreditor-views .k-colorpalette-tile {
    width: var(--kendo-color-preview-width, 24px);
    height: var(--kendo-color-preview-height, 24px);
}

.k-coloreditor-footer.k-actions,
.k-coloreditor-footer.k-edit-buttons,
.k-coloreditor-footer.k-action-buttons,
.k-coloreditor-footer.k-columnmenu-actions,
.k-coloreditor-footer.k-form-buttons {
    margin: 0;
}

.k-coloreditor {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-coloreditor .k-colorgradient:focus,
.k-coloreditor .k-colorgradient.k-focus {
    box-shadow: none;
    outline-color: rgba(0, 0, 0, 0.3);
}

.k-color-picker,
.k-colorpicker {
    width: min-content;
}

.k-avatar {
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
}
.k-avatar *,
.k-avatar *::before,
.k-avatar *::after {
    box-sizing: border-box;
}

.k-avatar-text,
.k-avatar-initials {
    font-size: 14px;
    font-family: inherit;
    line-height: 1.4285714286;
}

.k-avatar-icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.k-avatar-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.k-avatar-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: top;
}

.k-avatar-sm {
    width: 16px;
    height: 16px;
    flex-basis: 16px;
}

.k-avatar-md {
    width: 32px;
    height: 32px;
    flex-basis: 32px;
}

.k-avatar-lg {
    width: 64px;
    height: 64px;
    flex-basis: 64px;
}

.k-avatar-bordered {
    border-width: 1px;
}

.k-avatar-solid-primary {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}

.k-avatar-solid-secondary {
    border-color: #666666;
    color: white;
    background-color: #666666;
}

.k-avatar-solid-tertiary {
    border-color: #03a9f4;
    color: white;
    background-color: #03a9f4;
}

.k-avatar-solid-info {
    border-color: #0058e9;
    color: white;
    background-color: #0058e9;
}

.k-avatar-solid-success {
    border-color: #37b400;
    color: white;
    background-color: #37b400;
}

.k-avatar-solid-warning {
    border-color: #ffc000;
    color: black;
    background-color: #ffc000;
}

.k-avatar-solid-error {
    border-color: #f31700;
    color: white;
    background-color: #f31700;
}

.k-avatar-solid-dark {
    border-color: #424242;
    color: white;
    background-color: #424242;
}

.k-avatar-solid-light {
    border-color: #ebebeb;
    color: black;
    background-color: #ebebeb;
}

.k-avatar-solid-inverse {
    border-color: #424242;
    color: white;
    background-color: #424242;
}

.k-avatar-outline {
    border-color: currentColor;
}

.k-avatar-outline-primary {
    color: #4e1289;
}

.k-avatar-outline-secondary {
    color: #666666;
}

.k-avatar-outline-tertiary {
    color: #03a9f4;
}

.k-avatar-outline-info {
    color: #0058e9;
}

.k-avatar-outline-success {
    color: #37b400;
}

.k-avatar-outline-warning {
    color: #ffc000;
}

.k-avatar-outline-error {
    color: #f31700;
}

.k-avatar-outline-dark {
    color: #424242;
}

.k-avatar-outline-light {
    color: #ebebeb;
}

.k-avatar-outline-inverse {
    color: #424242;
}

.k-body,
.k-typography {
    font-size: 14px;
    font-family: inherit;
    line-height: 1.4285714286;
    font-weight: 400;
    margin: 0;
}
.k-body p,
.k-typography p {
    margin: 0 0 14px;
}

.k-h1 {
    font-size: 42px;
    font-family: inherit;
    line-height: 54px;
    font-weight: 400;
    margin: 0 0 14px;
}

.k-h2 {
    font-size: 34px;
    font-family: inherit;
    line-height: 46px;
    font-weight: 400;
    margin: 0 0 14px;
}

.k-h3 {
    font-size: 28px;
    font-family: inherit;
    line-height: 42px;
    font-weight: 400;
    margin: 0 0 14px;
}

.k-h4 {
    font-size: 22px;
    font-family: inherit;
    line-height: 32px;
    font-weight: 700;
    margin: 0 0 14px;
}

.k-h5 {
    font-size: 16px;
    font-family: inherit;
    line-height: 26px;
    font-weight: 700;
    margin: 0 0 14px;
}

.k-h6 {
    font-size: 14px;
    font-family: inherit;
    line-height: 20px;
    font-weight: 700;
    margin: 0 0 14px;
}

.k-paragraph {
    font-size: 14px;
    font-family: inherit;
    line-height: 20px;
    font-weight: 400;
    margin: 0 0 14px;
}

.k-pre,
.k-code {
    font-size: 14px;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Roboto Mono', 'Ubuntu Mono', 'Lucida Console', 'Courier New', monospace;
    line-height: 20px;
    font-weight: 400;
    border-style: solid;
    border-width: 1px;
}

.k-pre {
    padding-block: 16px;
    padding-inline: 24px;
    overflow: auto;
}

.k-pre > .k-code {
    padding: 0;
    border-width: 0;
    border-color: transparent;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
}

.k-code {
    padding-block: 0px;
    padding-inline: 4px;
}

.k-display-1 {
    font-size: 84px;
    font-family: inherit;
    line-height: 1.2;
    font-weight: 300;
}

.k-display-2 {
    font-size: 70px;
    font-family: inherit;
    line-height: 1.2;
    font-weight: 300;
}

.k-display-3 {
    font-size: 56px;
    font-family: inherit;
    line-height: 1.2;
    font-weight: 300;
}

.k-display-4 {
    font-size: 42px;
    font-family: inherit;
    line-height: 1.2;
    font-weight: 300;
}

.k-body {
    color: #424242;
    background-color: #ffffff;
}

.k-pre,
.k-code {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #fafafa;
}

kendo-card,
kendo-card-header,
kendo-card-body,
kendo-card-actions,
kendo-card-footer {
    display: block;
}

.k-card {
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-card *,
.k-card *::before,
.k-card *::after {
    box-sizing: border-box;
}

.k-card > .k-card-inner {
    border-radius: calc(6px - 1px);
    display: flex;
    flex-direction: inherit;
    overflow: hidden;
    position: relative;
}

.k-card-horizontal {
    flex-flow: row nowrap;
}

.k-card-vertical {
    flex-flow: column nowrap;
}

.k-card-header {
    padding-block: 12px;
    padding-inline: 16px;
    border-width: 0 0 1px;
    border-style: solid;
    outline: 0;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.k-card-header .k-card-title,
.k-card-header .k-card-subtitle {
    margin-bottom: 0;
}
.k-card-header .k-card-title + .k-card-subtitle,
.k-card-header .k-card-subtitle + .k-card-subtitle {
    margin-top: 8px;
}

.k-card-footer {
    padding-block: 12px;
    padding-inline: 16px;
    border-width: 1px 0 0;
    border-style: solid;
    outline: 0;
    flex-shrink: 0;
}

.k-card-body {
    padding-block: 16px;
    padding-inline: 16px;
    outline: 0;
    flex: 1 1 auto;
}
.k-card-body p {
    margin: 0 0 14px;
}
.k-card-body > .k-last,
.k-card-body > :last-child {
    margin-bottom: 0;
}
.k-card-body > .k-card-actions {
    padding: 0;
}

.k-card-avatar,
.k-card .k-avatar {
    margin-right: 16px;
    width: 45px;
    height: 45px;
    flex-basis: 45px;
}

.k-card-image,
.k-card-media {
    border: 0;
    max-width: 100%;
    flex-shrink: 0;
    overflow: hidden;
}
.k-card-image > img,
.k-card-media > img {
    border: 0;
    max-width: 100%;
}
.k-card-horizontal .k-card-image,
.k-card-horizontal .k-card-media {
    max-width: 100px;
    object-fit: cover;
}

.k-card-title {
    margin: 0 0 8px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 400;
}

.k-card-subtitle {
    margin: 0 0 8px;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
}

.k-card-separator {
    margin: 0;
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    display: block;
    flex: 0 0 auto;
    align-self: stretch;
}

.k-card-separator.k-separator-vertical,
.k-card-horizontal > .k-card-separator {
    border-left-width: 1px;
}

.k-card-separator.k-separator-horizontal,
.k-card-vertical > .k-card-separator {
    border-top-width: 1px;
}

.k-card > .k-hr {
    margin: 0;
    flex: 0 0 auto;
    border-color: inherit;
}

.k-card-actions {
    padding-block: 8px;
    padding-inline: 8px;
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    overflow: hidden;
    display: flex;
    flex-shrink: 0;
    flex-basis: auto;
    gap: 8px;
}

.k-card-actions-start {
    justify-content: flex-start;
}

.k-card-actions-end {
    justify-content: flex-end;
}

.k-card-actions-center {
    justify-content: center;
}

.k-card-actions-horizontal {
    flex-flow: row nowrap;
}

.k-card-actions-vertical {
    flex-flow: column nowrap;
}

.k-card-actions-stretched > * {
    flex: 1 1 auto;
}

.k-card-action {
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    display: inline-flex;
}

.k-card-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    gap: 16px;
    flex: 0 0 auto;
}
.k-card-list .k-card {
    flex: 0 0 auto;
}

.k-card-deck {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    gap: 16px;
    flex: 0 0 auto;
}
.k-card-deck .k-card {
    flex: 0 0 auto;
}

.k-card-deck-scrollwrap {
    padding-bottom: 3px;
    width: 100%;
    box-sizing: content-box;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.k-card-deck-scrollwrap > .k-button {
    border-radius: 0px;
    flex: 0 0 auto;
    position: absolute;
    z-index: 1;
}
.k-card-deck-scrollwrap > .k-button:first-child {
    left: -1px;
}
.k-card-deck-scrollwrap > .k-button:last-child {
    right: -1px;
}
.k-card-deck-scrollwrap > .k-card-deck {
    margin-bottom: -20px;
    padding-bottom: 20px;
    flex: 1 1 auto;
}

.k-card-group {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    flex: 0 0 auto;
}
.k-card-group .k-card {
    border-radius: 0;
    flex: 0 0 auto;
}
.k-card-group .k-card + .k-card {
    margin-inline-start: 1px;
}
.k-card-group .k-card.k-first {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.k-card-group .k-card.k-last {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.k-card-group .k-card.k-only {
    border-radius: 6px;
}

.k-card-with-callout {
    overflow: visible;
}

.k-card-callout {
    margin: 0;
    width: 20px;
    height: 20px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    position: absolute;
}

.k-card-callout.k-callout-n {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.k-card-callout.k-callout-e {
    top: 50%;
    right: 0;
    transform: translate(50%, -50%) rotate(-45deg);
}

.k-card-callout.k-callout-s {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
}

.k-card-callout.k-callout-w {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(45deg);
}

.k-card-flat {
    box-shadow: none;
}

.k-card {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-card .k-card-inner {
    background-color: inherit;
    border-color: inherit;
}
.k-card:focus,
.k-card.k-focus {
    border-color: rgba(0, 0, 0, 0.15);
}
.k-card-actions {
    border-color: rgba(0, 0, 0, 0.08);
}

.k-card-header {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
}

.k-card-footer {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
}

.k-card-separator,
.k-card .k-separator {
    border-color: rgba(0, 0, 0, 0.08);
}

.k-card-subtitle {
    color: #666666;
}

.k-card-primary {
    background-color: #dcd0e7;
    color: #290947;
    border-color: #cdbdde;
}
.k-card-primary .k-card-subtitle {
    color: inherit;
}

.k-card-secondary {
    background-color: #e0e0e0;
    color: #353535;
    border-color: #d4d4d4;
}
.k-card-secondary .k-card-subtitle {
    color: inherit;
}

.k-card-tertiary {
    background-color: #cdeefd;
    color: #02587f;
    border-color: #b8e7fc;
}
.k-card-tertiary .k-card-subtitle {
    color: inherit;
}

.k-card-info {
    background-color: #ccdefb;
    color: #002e79;
    border-color: #b8d0f9;
}
.k-card-info .k-card-subtitle {
    color: inherit;
}

.k-card-success {
    background-color: #d7f0cc;
    color: #1d5e00;
    border-color: #c7eab8;
}
.k-card-success .k-card-subtitle {
    color: inherit;
}

.k-card-warning {
    background-color: #fff2cc;
    color: #856400;
    border-color: #ffedb8;
}
.k-card-warning .k-card-subtitle {
    color: inherit;
}

.k-card-error {
    background-color: #fdd1cc;
    color: #7e0c00;
    border-color: #fcbeb8;
}
.k-card-error .k-card-subtitle {
    color: inherit;
}

.k-card-dark {
    background-color: #d9d9d9;
    color: #222222;
    border-color: #cacaca;
}
.k-card-dark .k-card-subtitle {
    color: inherit;
}

.k-card-light {
    background-color: #fbfbfb;
    color: #7a7a7a;
    border-color: #f9f9f9;
}
.k-card-light .k-card-subtitle {
    color: inherit;
}

.k-card-inverse {
    background-color: #d9d9d9;
    color: #222222;
    border-color: #cacaca;
}
.k-card-inverse .k-card-subtitle {
    color: inherit;
}

.k-card-callout {
    border-color: inherit;
    background-color: inherit;
    box-shadow: inherit;
}

.k-drawer-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
}

kendo-drawer.k-drawer,
kendo-drawer .k-drawer-wrapper {
    transition: none;
}

.k-drawer {
    height: 100%;
    max-width: 100%;
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    transition: all 300ms ease-in-out;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-drawer *,
.k-drawer *::before,
.k-drawer *::after {
    box-sizing: border-box;
}
.k-drawer-mini .k-drawer.k-drawer-start,
.k-drawer-expanded .k-drawer.k-drawer-start,
.k-drawer-left.k-drawer-mini .k-drawer,
.k-drawer-left.k-drawer-expanded .k-drawer {
    border-right-width: 1px;
}
.k-drawer-mini .k-drawer.k-drawer-end,
.k-drawer-expanded .k-drawer.k-drawer-end,
.k-drawer-right.k-drawer-mini .k-drawer,
.k-drawer-right.k-drawer-expanded .k-drawer {
    border-left-width: 1px;
}
.k-drawer.k-drawer-start,
.k-drawer-left .k-drawer {
    top: 0;
    left: 0;
}
.k-drawer.k-drawer-end,
.k-drawer-right .k-drawer {
    top: 0;
    right: 0;
}

.k-drawer-content {
    flex: 1 1 auto;
}

.k-drawer-overlay .k-drawer {
    max-width: 80vw;
    position: fixed;
    z-index: 10002;
    border: 0;
}
.k-drawer-overlay > .k-overlay {
    display: none;
}
.k-drawer-overlay.k-drawer-expanded > .k-overlay {
    display: block;
}

.k-drawer-push {
    display: flex;
    flex-direction: row;
}
.k-drawer-push .k-drawer {
    height: auto;
    position: relative;
    align-self: stretch;
}
.k-drawer-push .k-drawer.k-drawer-end,
.k-drawer-push.k-drawer-right .k-drawer {
    order: 1;
}

.k-drawer-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    transition: all 300ms ease-in-out;
}
.k-drawer-wrapper::-webkit-scrollbar {
    width: 7px;
}
.k-drawer-wrapper::-webkit-scrollbar-thumb {
    border-radius: 20px;
}

.k-drawer-items,
.k-drawer-items ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.k-drawer-item {
    padding-block: 8px;
    padding-inline: 16px;
    outline: 0;
    color: inherit;
    font-size: 16px;
    line-height: 1.5;
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    cursor: pointer;
}
.k-drawer-item > .k-drawer-link {
    margin-block: -8px;
    margin-inline: -16px;
    padding-block: 8px;
    padding-inline: 16px;
    color: inherit;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
    gap: 16px;
}
.k-drawer-item .k-icon {
    flex-shrink: 0;
    padding-inline: 0;
    padding-block: 4px;
    box-sizing: content-box;
}
.k-drawer-item .k-icon.k-i-none {
    margin: 0;
}
.k-drawer-item .k-item-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.k-drawer-item .k-drawer-toggle {
    margin-inline-start: auto;
    cursor: pointer;
    display: flex;
}
.k-drawer-item.k-level-1 {
    padding-inline-start: 32px;
}
.k-drawer-item.k-level-2 {
    padding-inline-start: 48px;
}
.k-drawer-item.k-level-3 {
    padding-inline-start: 64px;
}
.k-drawer-item.k-level-4 {
    padding-inline-start: 80px;
}
.k-drawer-item.k-level-5 {
    padding-inline-start: 96px;
}

.k-drawer-separator {
    padding: 0;
    height: 1px;
}

.k-drawer-mini .k-drawer-wrapper {
    width: calc(2 * 16px + 16px);
}

.k-rtl.k-drawer-mini .k-drawer-start,
.k-rtl .k-drawer-mini .k-drawer-start,
.k-rtl .k-drawer-expanded .k-drawer-start,
.k-rtl.k-drawer-expanded .k-drawer-start,
[dir='rtl'].k-drawer-mini .k-drawer-start,
[dir='rtl'] .k-drawer-mini .k-drawer-start,
[dir='rtl'] .k-drawer-expanded .k-drawer-start,
[dir='rtl'].k-drawer-expanded .k-drawer-start {
    border-left-width: 1px;
    border-right-width: 0;
}
.k-rtl.k-drawer-mini .k-drawer-end,
.k-rtl .k-drawer-mini .k-drawer-end,
.k-rtl.k-drawer-expanded .k-drawer-end .k-drawer-expanded .k-drawer-end,
[dir='rtl'].k-drawer-mini .k-drawer-end,
[dir='rtl'] .k-drawer-mini .k-drawer-end,
[dir='rtl'].k-drawer-expanded .k-drawer-end .k-drawer-expanded .k-drawer-end {
    border-left-width: 0;
    border-right-width: 1px;
}
.k-rtl.k-drawer-overlay .k-drawer-start,
.k-rtl .k-drawer-overlay .k-drawer-start,
[dir='rtl'].k-drawer-overlay .k-drawer-start,
[dir='rtl'] .k-drawer-overlay .k-drawer-start {
    left: auto;
    right: 0;
}
.k-rtl.k-drawer-overlay .k-drawer-end,
.k-rtl .k-drawer-overlay .k-drawer-end,
[dir='rtl'].k-drawer-overlay .k-drawer-end,
[dir='rtl'] .k-drawer-overlay .k-drawer-end {
    left: 0;
    right: auto;
}
.k-rtl .k-drawer-left.k-drawer-push .k-drawer,
[dir='rtl'] .k-drawer-left.k-drawer-push .k-drawer {
    order: 1;
}
.k-rtl .k-drawer-right.k-drawer-push .k-drawer,
[dir='rtl'] .k-drawer-right.k-drawer-push .k-drawer {
    order: 0;
}

.k-drawer {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-drawer-wrapper {
    scrollbar-color: rgba(156, 156, 156, 0.7) #dedede;
}
.k-drawer-wrapper::-webkit-scrollbar-track {
    background: #dedede;
}
.k-drawer-wrapper::-webkit-scrollbar-thumb {
    background: rgba(156, 156, 156, 0.7);
}
.k-drawer-wrapper::-webkit-scrollbar-thumb:hover {
    background: rgb(156, 156, 156);
}

.k-drawer-item:hover,
.k-drawer-item.k-hover {
    color: #424242;
    background-color: #f0f0f0;
}
.k-drawer-item:focus,
.k-drawer-item.k-focus {
    background-color: #ffffff;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-drawer-item:focus:hover,
.k-drawer-item:focus.k-hover,
.k-drawer-item.k-focus:hover,
.k-drawer-item.k-focus.k-hover {
    color: #424242;
    background-color: #f0f0f0;
}
.k-drawer-item.k-selected {
    color: white;
    background-color: #4e1289;
}
.k-drawer-item.k-selected:hover,
.k-drawer-item.k-selected.k-hover {
    color: white;
    background-color: #4b1184;
}

.k-drawer-separator {
    background-color: rgba(0, 0, 0, 0.08);
}

.k-expander {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    overflow: hidden;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    display: flex;
    flex-direction: column;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-expander *,
.k-expander *::before,
.k-expander *::after {
    box-sizing: border-box;
}
.k-rtl .k-expander .k-expander-indicator,
.k-expander.k-rtl .k-expander-indicator,
.k-expander[dir='rtl'] .k-expander-indicator {
    margin-left: 0;
    margin-right: 12px;
}

.k-expander-header {
    padding-block: 12px;
    padding-inline: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.k-expander-spacer {
    flex: 1 1 auto;
}

.k-expander-title {
    text-transform: uppercase;
    font-weight: 500;
}

.k-expander-indicator {
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.k-expander-content {
    padding-block: 16px;
    padding-inline: 16px;
}

.k-expander + .k-expander.k-expanded,
.k-expander.k-expanded + .k-expander {
    margin-top: 8px;
}

.k-expander:not(.k-expanded) + .k-expander:not(.k-expanded) {
    border-top-width: 0;
}

.k-expander {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-expander.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-expander-header {
    color: #424242;
    background-color: transparent;
    outline: none;
}
.k-expander-header:hover,
.k-expander-header.k-hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.k-expander-header.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-expander-title {
    color: #4e1289;
}

.k-expander-sub-title {
    color: #666666;
}

.k-panelbar {
    margin: 0;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    list-style: none;
    display: block;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-panelbar *,
.k-panelbar *::before,
.k-panelbar *::after {
    box-sizing: border-box;
}
.k-panelbar > .k-item,
.k-panelbar > .k-panelbar-header {
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    display: block;
}
.k-panelbar > .k-item > .k-link,
.k-panelbar > .k-panelbar-header > .k-link {
    padding-block: 12px;
    padding-inline: 16px;
    color: inherit;
    background: none;
    text-decoration: none;
    font-weight: 500;
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
    align-content: center;
    position: relative;
    user-select: none;
    cursor: default;
    transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out;
}
.k-panelbar > .k-item + .k-item,
.k-panelbar > .k-panelbar-header + .k-panelbar-header {
    border-top-width: 1px;
}
.k-panelbar .k-group,
.k-panelbar .k-panelbar-group {
    margin: 0;
    padding: 0;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background-color: transparent;
    list-style: none;
}
.k-panelbar .k-group > .k-item,
.k-panelbar .k-panelbar-group > .k-panelbar-item {
    display: block;
}
.k-panelbar .k-group > .k-item > .k-link,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link {
    padding-block: 8px;
    padding-inline: 16px;
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    align-items: center;
    align-content: center;
    position: relative;
    user-select: none;
    cursor: default;
    transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out;
}
.k-panelbar .k-group > .k-item.k-level-1 .k-link,
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link {
    padding-left: 16px;
}
.k-panelbar .k-group > .k-item.k-level-2 .k-link,
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link {
    padding-left: 32px;
}
.k-panelbar .k-group > .k-item.k-level-3 .k-link,
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link {
    padding-left: 48px;
}
.k-panelbar .k-group > .k-item.k-level-4 .k-link,
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link {
    padding-left: 64px;
}
.k-panelbar .k-panelbar-item {
    outline-style: none;
}
.k-panelbar .k-panelbar-content {
    display: flow-root;
}
.k-panelbar .k-panelbar-expand,
.k-panelbar .k-panelbar-collapse,
.k-panelbar .k-panelbar-toggle {
    margin-inline-start: auto;
}
.k-panelbar .k-group .k-panelbar-expand,
.k-panelbar .k-group .k-panelbar-collapse,
.k-panelbar .k-panelbar-group .k-panelbar-toggle {
    margin-inline-end: calc(16px - 16px);
}
.k-rtl .k-panelbar .k-group > .k-item.k-level-1 .k-link,
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link,
.k-panelbar.k-rtl .k-group > .k-item.k-level-1 .k-link,
.k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link,
.k-panelbar[dir='rtl'] .k-group > .k-item.k-level-1 .k-link,
.k-panelbar[dir='rtl'] .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link {
    padding-left: 16px;
    padding-right: 16px;
}
.k-rtl .k-panelbar .k-group > .k-item.k-level-2 .k-link,
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link,
.k-panelbar.k-rtl .k-group > .k-item.k-level-2 .k-link,
.k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link,
.k-panelbar[dir='rtl'] .k-group > .k-item.k-level-2 .k-link,
.k-panelbar[dir='rtl'] .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link {
    padding-left: 16px;
    padding-right: 32px;
}
.k-rtl .k-panelbar .k-group > .k-item.k-level-3 .k-link,
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link,
.k-panelbar.k-rtl .k-group > .k-item.k-level-3 .k-link,
.k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link,
.k-panelbar[dir='rtl'] .k-group > .k-item.k-level-3 .k-link,
.k-panelbar[dir='rtl'] .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link {
    padding-left: 16px;
    padding-right: 48px;
}
.k-rtl .k-panelbar .k-group > .k-item.k-level-4 .k-link,
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link,
.k-panelbar.k-rtl .k-group > .k-item.k-level-4 .k-link,
.k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link,
.k-panelbar[dir='rtl'] .k-group > .k-item.k-level-4 .k-link,
.k-panelbar[dir='rtl'] .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link {
    padding-left: 16px;
    padding-right: 64px;
}

.k-panelbar {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-panelbar > .k-item > .k-link,
.k-panelbar > .k-panelbar-header > .k-link {
    color: #4e1289;
    background-color: #ffffff;
}
.k-panelbar > .k-item > .k-link .k-icon,
.k-panelbar > .k-item > .k-link .k-panelbar-item-icon,
.k-panelbar > .k-panelbar-header > .k-link .k-icon,
.k-panelbar > .k-panelbar-header > .k-link .k-panelbar-item-icon {
    color: #424242;
}
.k-panelbar > .k-item > .k-link:hover,
.k-panelbar > .k-item > .k-link.k-hover,
.k-panelbar > .k-panelbar-header > .k-link:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-hover {
    background-color: whitesmoke;
}
.k-panelbar > .k-item > .k-link:focus,
.k-panelbar > .k-item > .k-link.k-focus,
.k-panelbar > .k-panelbar-header > .k-link:focus,
.k-panelbar > .k-panelbar-header > .k-link.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-panelbar > .k-item > .k-link.k-selected,
.k-panelbar > .k-panelbar-header > .k-link.k-selected {
    color: white;
    background-color: #4e1289;
}
.k-panelbar > .k-item > .k-link.k-selected .k-icon,
.k-panelbar > .k-item > .k-link.k-selected .k-panelbar-item-icon,
.k-panelbar > .k-panelbar-header > .k-link.k-selected .k-icon,
.k-panelbar > .k-panelbar-header > .k-link.k-selected .k-panelbar-item-icon {
    color: inherit;
}
.k-panelbar > .k-item > .k-link.k-selected:hover,
.k-panelbar > .k-item > .k-link.k-selected.k-hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected.k-hover {
    background-color: #48117e;
}
.k-panelbar .k-group > .k-item > .k-link:hover,
.k-panelbar .k-group > .k-item > .k-link.k-hover,
.k-panelbar .k-group > .k-panelbar-item > .k-link:hover,
.k-panelbar .k-group > .k-panelbar-item > .k-link.k-hover,
.k-panelbar .k-panelbar-group > .k-item > .k-link:hover,
.k-panelbar .k-panelbar-group > .k-item > .k-link.k-hover,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link:hover,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-hover {
    background-color: whitesmoke;
}
.k-panelbar .k-group > .k-item > .k-link:focus,
.k-panelbar .k-group > .k-item > .k-link.k-focus,
.k-panelbar .k-group > .k-panelbar-item > .k-link:focus,
.k-panelbar .k-group > .k-panelbar-item > .k-link.k-focus,
.k-panelbar .k-panelbar-group > .k-item > .k-link:focus,
.k-panelbar .k-panelbar-group > .k-item > .k-link.k-focus,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link:focus,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-panelbar .k-group > .k-item > .k-link.k-selected,
.k-panelbar .k-group > .k-panelbar-item > .k-link.k-selected,
.k-panelbar .k-panelbar-group > .k-item > .k-link.k-selected,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected {
    color: white;
    background-color: #4e1289;
}
.k-panelbar .k-group > .k-item > .k-link.k-selected:hover,
.k-panelbar .k-group > .k-item > .k-link.k-selected.k-hover,
.k-panelbar .k-group > .k-panelbar-item > .k-link.k-selected:hover,
.k-panelbar .k-group > .k-panelbar-item > .k-link.k-selected.k-hover,
.k-panelbar .k-panelbar-group > .k-item > .k-link.k-selected:hover,
.k-panelbar .k-panelbar-group > .k-item > .k-link.k-selected.k-hover,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected:hover,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected.k-hover {
    background-color: #48117e;
}
.k-splitter {
    height: 300px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    display: block;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-splitter .k-pane {
    overflow: hidden;
    box-sizing: border-box;
}
.k-splitter .k-scrollable {
    overflow: auto;
}
.k-splitter .k-splitter-resizing {
    overflow: hidden;
}
.k-pane > .k-splitter {
    border-width: 0;
    overflow: hidden;
}
.k-splitter .k-pane-loading {
    position: static;
    top: 50%;
    left: 50%;
}

.k-ghost-splitbar,
.k-splitbar {
    border-style: solid;
    outline: 0;
    position: absolute;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.k-ghost-splitbar .k-collapse-prev,
.k-ghost-splitbar .k-collapse-next,
.k-splitbar .k-collapse-prev,
.k-splitbar .k-collapse-next {
    cursor: pointer;
    padding: 2px;
}

.k-splitbar-draggable-horizontal {
    cursor: col-resize;
}

.k-splitbar-draggable-vertical {
    cursor: row-resize;
}

.k-ghost-splitbar-horizontal,
.k-splitbar-horizontal {
    width: 12px;
    border-width: 0;
    background-repeat: repeat-y;
    flex-direction: column;
    top: 0;
}

.k-ghost-splitbar-vertical,
.k-splitbar-vertical {
    height: 12px;
    border-width: 0;
    background-repeat: repeat-x;
    flex-direction: row;
    left: 0;
}

.k-splitbar-static-horizontal {
    width: 1px;
}

.k-splitbar-static-vertical {
    height: 1px;
}

.k-splitbar-draggable-horizontal .k-resize-handle {
    width: 2px;
    height: 20px;
    position: static;
    z-index: 1;
}

.k-splitbar .k-resize-handle {
    display: none;
    background-color: currentColor;
}

.k-splitbar-draggable-horizontal .k-resize-handle,
.k-splitbar-draggable-vertical .k-resize-handle {
    display: block;
}

.k-splitbar-horizontal .k-collapse-prev {
    margin-bottom: 7px;
}

.k-splitbar-horizontal .k-collapse-next {
    margin-top: 7px;
}

.k-splitbar-vertical .k-collapse-prev {
    margin-right: 7px;
}

.k-splitbar-vertical .k-collapse-next {
    margin-left: 7px;
}

.k-splitbar-draggable-vertical .k-resize-handle {
    width: 20px;
    height: 2px;
    position: static;
    z-index: 1;
}

.k-pane > .k-splitter-overlay {
    opacity: 0;
    position: absolute;
}

.k-splitter-flex {
    display: flex;
    width: 100%;
    height: auto;
}
.k-splitter-flex .k-pane {
    position: relative;
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
    min-height: 0;
    max-height: 100%;
    height: 100%;
}
.k-splitter-flex .k-pane-static {
    flex-grow: 0;
    flex-shrink: 0;
}
.k-splitter-flex .k-pane-flex {
    display: flex;
}
.k-splitter-flex .k-splitbar {
    position: static;
    flex: 0 0 auto;
}
.k-splitter-flex .k-pane.k-hidden,
.k-splitter-flex .k-pane[hidden],
.k-splitter-flex .k-pane-flex.k-hidden,
.k-splitter-flex .k-pane-flex[hidden] {
    flex: 0 1 0% !important;
    overflow: hidden !important;
    display: block !important;
}
.k-splitter-flex.k-splitter-horizontal {
    flex-direction: row;
}
.k-splitter-flex.k-splitter-vertical {
    flex-direction: column;
}

.k-splitter {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-splitbar {
    color: #424242;
    background-color: #fafafa;
}

.k-splitbar:hover,
.k-splitbar.k-hover,
.k-splitbar-horizontal-hover,
.k-splitbar-vertical-hover {
    color: #424242;
    background-color: #f0f0f0;
}

.k-splitbar:focus,
.k-splitbar.k-focus {
    color: white;
    background: #4e1289;
}

.k-ghost-splitbar {
    background-color: #f0f0f0;
}

.k-stepper {
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-size: 14px;
    line-height: 1.4285714286;
    font-family: inherit;
    display: block;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: inherit;
    background: none;
}
.k-stepper *,
.k-stepper *::before,
.k-stepper *::after {
    box-sizing: border-box;
}
.k-stepper .k-step-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    position: relative;
    z-index: 1;
}
.k-stepper .k-step-link {
    outline: none;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.k-stepper .k-step-indicator {
    border-radius: 50%;
    margin: 3px;
    width: 28px;
    height: 28px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    position: relative;
    z-index: 1;
    overflow: visible;
    transition-property: color, background-color, border-color;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
}
.k-stepper .k-step-indicator::after {
    border-radius: 100%;
    content: '';
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    pointer-events: none;
    display: none;
    position: absolute;
    top: calc(-1 * calc(1px + 3px));
    right: calc(-1 * calc(1px + 3px));
    bottom: calc(-1 * calc(1px + 3px));
    left: calc(-1 * calc(1px + 3px));
    z-index: 2;
}
.k-stepper .k-step.k-focus .k-step-indicator::after,
.k-stepper .k-step-link:focus .k-step-indicator::after {
    display: block;
}
.k-stepper .k-step-label {
    max-width: clamp(100%, 10em, 100%);
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.k-stepper .k-step-label:only-child {
    border-radius: 15px;
    padding-block: 5px;
    padding-inline: 12px;
    border-width: 0;
}
.k-stepper .k-step-label .k-step-text {
    max-width: calc(10em - (16px + 4px));
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.k-stepper .k-step-label .k-icon {
    margin-left: 4px;
}
.k-stepper .k-step-current .k-step-label {
    font-weight: bold;
}
.k-stepper .k-step-label-optional {
    flex-basis: 100%;
    font-style: italic;
}
.k-stepper .k-step-disabled,
.k-stepper .k-step.k-disabled {
    opacity: 1;
    pointer-events: none;
}
.k-stepper .k-step-disabled .k-step-link,
.k-stepper .k-step.k-disabled .k-step-link {
    cursor: default;
}
.k-stepper .k-step-disabled .k-step-label-optional,
.k-stepper .k-step.k-disabled .k-step-label-optional {
    color: inherit;
}
.k-stepper .k-progressbar {
    pointer-events: none;
    z-index: 0;
    overflow: visible;
}
.k-stepper .k-progressbar-horizontal {
    grid-row: 1/-1;
}
.k-stepper .k-progressbar-vertical {
    position: absolute;
}

.k-step-list-horizontal {
    flex-direction: row;
    grid-row: 1;
}
.k-step-list-horizontal .k-step {
    flex: 1 0 auto;
    text-align: center;
}
.k-step-list-horizontal .k-step-link {
    margin: auto;
    max-width: 10em;
    flex-direction: column;
}
.k-step-list-horizontal .k-step-label {
    text-align: center;
}
.k-step-list-horizontal .k-step-indicator + .k-step-label {
    margin-top: calc(8px - 3px);
}
.k-step-list-horizontal ~ .k-progressbar {
    width: 100%;
    height: 2px;
    top: calc(((28px + 2 * 2px) / 2) + 2px / 2);
}

.k-step-list-vertical {
    flex-direction: column;
}
.k-step-list-vertical .k-step {
    min-height: calc((28px + 2 * 1px + 2 * 2px) + 20px);
}
.k-step-list-vertical .k-step-label {
    justify-content: flex-start;
}
.k-step-list-vertical .k-step-indicator + .k-step-label {
    margin-left: 12px;
}
.k-step-list-vertical ~ .k-progressbar {
    width: 2px;
    height: 100%;
    min-height: 20px;
    left: calc((28px + 2 * 1px + 2 * 2px) / 2);
}
.k-step-list-vertical .k-step-content {
    height: 0;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.k-step-list-vertical .k-step-current .k-step-content {
    height: auto;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 30px;
    padding-block: 20px;
    padding-inline: 10px;
}

.k-rtl .k-stepper,
.k-stepper[dir='rtl'] {
    direction: rtl;
}
.k-rtl .k-stepper .k-step-label .k-icon,
.k-stepper[dir='rtl'] .k-step-label .k-icon {
    margin-left: 0;
    margin-right: 4px;
}
.k-rtl .k-stepper .k-step-list-vertical .k-step-indicator + .k-step-label,
.k-stepper[dir='rtl'] .k-step-list-vertical .k-step-indicator + .k-step-label {
    margin-left: 0;
    margin-right: 12px;
}
.k-rtl .k-stepper .k-step-list-vertical ~ .k-progressbar,
.k-stepper[dir='rtl'] .k-step-list-vertical ~ .k-progressbar {
    left: auto;
    right: calc((28px + 2 * 1px + 2 * 2px) / 2);
}

.k-stepper {
    color: #424242;
}
.k-stepper .k-step:hover .k-step-label,
.k-stepper .k-step.k-hover .k-step-label,
.k-stepper .k-step.k-step-hover .k-step-label {
    color: #373737;
}
.k-stepper .k-step:hover .k-step-indicator,
.k-stepper .k-step.k-hover .k-step-indicator,
.k-stepper .k-step.k-step-hover .k-step-indicator {
    background-color: #ebebeb;
}
.k-stepper .k-step.k-focus .k-step-label:only-child,
.k-stepper .k-step .k-step-link:focus .k-step-label:only-child {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-stepper .k-step.k-step-disabled .k-step-indicator,
.k-stepper .k-step.k-disabled .k-step-indicator,
.k-stepper .k-step:disabled .k-step-indicator {
    color: #8f8f8f;
}
.k-stepper .k-step.k-step-disabled .k-step-label,
.k-stepper .k-step.k-disabled .k-step-label,
.k-stepper .k-step:disabled .k-step-label {
    color: #8f8f8f;
}
.k-stepper .k-step-indicator {
    border-color: #cccccc;
    color: #424242;
    background-color: #ffffff;
}
.k-stepper .k-step-indicator::after {
    box-shadow: inset 0 0 0 2px #ffffff;
}
.k-stepper .k-step-label:only-child {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-stepper .k-step-done .k-step-indicator {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}
.k-stepper .k-step-done:hover .k-step-indicator,
.k-stepper .k-step-done.k-hover .k-step-indicator,
.k-stepper .k-step-done.k-step-hover .k-step-indicator {
    background-color: #48117e;
}
.k-stepper .k-step-done.k-step-disabled .k-step-indicator,
.k-stepper .k-step-done.k-disabled .k-step-indicator,
.k-stepper .k-step-done:disabled .k-step-indicator {
    border-color: #9571b8;
    color: white;
    background-color: #9571b8;
}
.k-stepper .k-step-current .k-step-indicator {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}
.k-stepper .k-step-current:hover .k-step-indicator,
.k-stepper .k-step-current.k-hover .k-step-indicator,
.k-stepper .k-step-current.k-step-hover .k-step-indicator {
    background-color: #48117e;
}
.k-stepper .k-step-current.k-step-disabled .k-step-indicator,
.k-stepper .k-step-current.k-disabled .k-step-indicator,
.k-stepper .k-step-current:disabled .k-step-indicator {
    border-color: #9571b8;
    color: white;
    background-color: #9571b8;
}
.k-stepper .k-step-success .k-step-label .k-icon {
    color: #37b400;
}
.k-stepper .k-step-error .k-step-label {
    color: #f31700;
}
.k-stepper .k-step-error:hover .k-step-label,
.k-stepper .k-step-error.k-hover .k-step-label,
.k-stepper .k-step-error.k-step-hover .k-step-label {
    color: #f31700;
}
.k-stepper .k-step-label-optional {
    color: #666666;
}
.k-tilelayout {
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    display: grid;
    position: relative;
    padding: 16px 16px;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-tilelayout .k-card {
    box-shadow: none;
    border-width: 1px;
}

.k-tilelayout-item {
    position: relative;
    z-index: 0;
}

.k-layout-item-hint {
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    position: relative;
}
.k-layout-item-hint.k-layout-item-hint-resize {
    z-index: 3;
}

.k-tilelayout-item-header.k-card-header {
    z-index: 0;
}

.k-tilelayout-item-body {
    min-height: 0;
}

.k-tilelayout {
    background-color: #fafafa;
}

.k-layout-item-hint {
    border-color: rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 0.2);
}

.k-notification-group {
    max-height: 100%;
    display: inline-flex;
    flex-flow: column-reverse wrap;
    position: fixed;
    z-index: 1000;
}

.k-notification-container {
    margin-block: 2px;
    margin-inline: 0;
    display: inline-flex;
    vertical-align: top;
}
.k-notification-container-animating {
    overflow: hidden;
}

.k-notification {
    border-radius: 4px;
    padding-block: 4px;
    padding-inline: 8px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 12px;
    line-height: 1.4285714286;
    cursor: default;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-notification *,
.k-notification *::before,
.k-notification *::after {
    box-sizing: border-box;
}
.k-notification > .k-notification-content {
    flex: 1 1 auto;
}

.k-notification-actions {
    flex: none;
    display: flex;
    gap: 4px;
    flex-flow: row nowrap;
    align-items: center;
    align-self: center;
    cursor: pointer;
}

.k-notification-action {
    flex: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-self: center;
}

.k-notification {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
    box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-notification-inverse {
    border-color: #424242;
    color: white;
    background-color: #424242;
}

.k-notification-light {
    border-color: #ebebeb;
    color: black;
    background-color: #ebebeb;
}

.k-notification-dark {
    border-color: #424242;
    color: white;
    background-color: #424242;
}

.k-notification-error {
    border-color: #f31700;
    color: white;
    background-color: #f31700;
}

.k-notification-warning {
    border-color: #ffc000;
    color: black;
    background-color: #ffc000;
}

.k-notification-success {
    border-color: #37b400;
    color: white;
    background-color: #37b400;
}

.k-notification-info {
    border-color: #0058e9;
    color: white;
    background-color: #0058e9;
}

.k-notification-tertiary {
    border-color: #03a9f4;
    color: white;
    background-color: #03a9f4;
}

.k-notification-secondary {
    border-color: #666666;
    color: white;
    background-color: #666666;
}

.k-notification-primary {
    border-color: #4e1289;
    color: white;
    background-color: #4e1289;
}

.k-ripple-target {
    position: relative;
}

.k-ripple {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
}

.k-ripple-blob {
    pointer-events: none;
    position: absolute;
    border-radius: 50%;
    padding: 0;
    transform: translate(-50%, -50%) scale(0);
    transition:
        opacity 100ms linear,
        transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0.1;
    background-color: currentColor;
}
.k-primary .k-ripple-blob {
    opacity: 0.2;
}

.k-ripple-focus::after {
    visibility: visible;
    animation: ripple 600ms ease-out;
}

@keyframes ripple {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    20% {
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1);
    }
}
.k-treelist.k-grid-display-block.k-grid-lockedcolumns {
    display: block;
}
.k-treelist .k-status {
    padding-block: 0.4em;
    padding-inline: 0.6em;
    line-height: 1.6em;
}
.k-treelist .k-status .k-loading {
    vertical-align: baseline;
    margin-right: 5px;
    display: none;
}
.k-treelist tr.k-hidden {
    display: none;
}
.k-treelist .k-treelist-dragging,
.k-treelist .k-treelist-dragging .k-hover {
    cursor: default;
}
.k-treelist .k-drop-hint {
    transform: translate(0, -50%);
}
.k-treelist .k-table-row.k-footer td,
.k-treelist .k-table-row.k-footer-template td,
.k-treelist .k-table-row.k-footer .k-table-td,
.k-treelist .k-table-row.k-footer-template .k-table-td {
    border-width: 1px 0;
    border-style: solid;
    font-weight: bold;
}
.k-treelist .k-table-row.k-footer + .k-table-row.k-footer td,
.k-treelist .k-table-row.k-footer + .k-table-row.k-footer .k-table-td {
    border-top-width: 0;
}

.k-treelist-scrollable > .k-table,
.k-treelist-scrollable .k-grid-header .k-table-row,
.k-treelist-scrollable .k-grid-header .k-table-th {
    background-color: inherit;
}
.k-treelist-scrollable .k-grid-toolbar {
    position: sticky;
    z-index: 3;
    top: 0;
    left: 0;
}
.k-treelist-scrollable .k-grid-header .k-table-th {
    position: sticky;
    z-index: 1;
}
.k-treelist-scrollable .k-grid-header .k-table-th.k-grid-header-sticky {
    z-index: 3;
}
.k-treelist-scrollable thead.k-grid-header {
    padding-right: 0;
}
.k-treelist-scrollable .k-table-row.k-group-footer + .k-table-row.k-group-footer td {
    border-top-width: 0;
}
.k-treelist-scrollable[dir='rtl'] thead.k-grid-header,
.k-rtl .k-treelist-scrollable thead.k-grid-header {
    padding-left: 0;
}

.k-drag-separator {
    display: inline-block;
    border-right: 1px solid;
    height: 1em;
    vertical-align: top;
    margin-block: 0;
    margin-inline: 0.5em;
}

.k-treelist-toggle {
    margin-block: -4px;
    padding: 4px;
    box-sizing: content-box;
    cursor: pointer;
}

.k-treelist tr.k-footer,
.k-treelist tr.k-footer-template {
    background-color: #ededed;
}

.k-external-dropzone {
    height: 220px;
    display: flex;
    flex-flow: row nowrap;
}

.k-dropzone-inner {
    padding-block: 8px;
    padding-inline: 8px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    font-size: 14px;
    font-family: inherit;
    line-height: 1.4285714286;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
}
.k-dropzone-inner .k-dropzone-icon {
    margin-bottom: 24px;
}
.k-dropzone-inner .k-dropzone-hint {
    margin-bottom: 8px;
}
.k-dropzone-inner .k-dropzone-note {
    font-size: 12px;
}

.k-dropzone-inner {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #fafafa;
}
.k-dropzone-inner .k-icon,
.k-dropzone-inner .k-dropzone-icon {
    color: #7e7e7e;
}
.k-dropzone-inner .k-dropzone-note {
    color: #666666;
}

.k-external-dropzone-hover .k-icon,
.k-external-dropzone-hover .k-dropzone-icon {
    color: #4e1289;
}

.k-upload {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-upload *,
.k-upload *::before,
.k-upload *::after {
    box-sizing: border-box;
}
.k-upload .k-upload-button {
    min-width: 7em;
}
.k-upload .k-dropzone,
.k-upload .k-upload-dropzone {
    padding-block: 8px;
    padding-inline: 8px;
    border-width: 0;
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: transparent;
}
.k-upload .k-dropzone .k-dropzone-hint,
.k-upload .k-upload-dropzone .k-dropzone-hint {
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    flex: 1;
}
.k-upload .k-dropzone .k-upload-status,
.k-upload .k-upload-dropzone .k-upload-status {
    font-weight: normal;
    display: flex;
    align-items: center;
    position: relative;
}
.k-upload .k-dropzone .k-upload-status > .k-icon,
.k-upload .k-upload-dropzone .k-upload-status > .k-icon {
    margin-inline-end: 4px;
}
.k-upload .k-dropzone .k-upload-status + .k-dropzone-hint,
.k-upload .k-upload-dropzone .k-upload-status + .k-dropzone-hint {
    display: none;
}
.k-upload .k-dropzone-active .k-upload-status {
    display: none;
}
.k-upload .k-dropzone-active .k-upload-status + .k-dropzone-hint {
    display: block;
}
.k-upload .k-upload-files {
    max-height: 300px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: inherit;
    list-style: none;
}
.k-upload .k-upload-files .k-file-multiple,
.k-upload .k-upload-files .k-file-single {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.k-upload .k-upload-files .k-file-single {
    align-items: center;
}
.k-upload .k-upload-files .k-file-multiple {
    align-items: flex-start;
}
.k-upload .k-upload-files .k-file {
    padding: 8px 8px calc(8px + 2px);
    border-width: 0 0 1px;
    border-style: solid;
    outline: none;
    display: flex;
    align-items: flex-start;
    position: relative;
}
.k-upload .k-upload-files .k-file:last-child {
    border-width: 0;
}
.k-upload .k-upload-files .k-filename {
    margin-left: 16px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}
.k-upload .k-upload-files .k-upload-pct {
    margin-block: 0;
    margin-inline: 4px;
    font-weight: normal;
    vertical-align: middle;
}
.k-upload .k-upload-files ~ .k-clear-selected,
.k-upload .k-upload-files ~ .k-upload-selected {
    margin-top: -4px;
    border-width: 0;
}
.k-upload .k-upload-files ~ .k-upload-selected {
    border-left-width: 1px;
    border-left-style: solid;
    margin-left: -1px;
}
.k-upload .k-upload-files .k-file-name,
.k-upload .k-upload-files .k-file-size,
.k-upload .k-upload-files .k-file-validation-message,
.k-upload .k-upload-files .k-file-summary {
    display: block;
}
.k-upload .k-upload-files .k-file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}
.k-upload .k-upload-files .k-file-size,
.k-upload .k-upload-files .k-file-summary,
.k-upload .k-upload-files .k-file-validation-message {
    font-size: 11px;
    line-height: 1;
}
.k-upload .k-upload-files .k-file-icon-wrapper .k-file-state {
    position: absolute;
}
.k-upload .k-upload-files .k-file-state {
    visibility: hidden;
}
.k-upload .k-upload-files .k-file-info {
    min-height: calc(16px * 2);
    display: block;
    overflow: hidden;
    flex: 1 0 0;
}
.k-upload .k-multiple-files-wrapper,
.k-upload .k-file-single > .k-file-info {
    margin-inline-start: 8px;
    min-height: calc(16px * 2);
    display: block;
    overflow: hidden;
    flex: 1 0 0;
}
.k-upload .k-multiple-files-wrapper .k-file-info {
    margin-bottom: 12px;
    display: block;
}
.k-upload .k-multiple-files-wrapper .k-file-summary {
    margin-top: -4px;
}
.k-upload .k-progressbar {
    --kendo-progressbar-height: 2px;
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 4px;
    width: auto;
}
.k-upload .k-actions,
.k-upload .k-edit-buttons,
.k-upload .k-action-buttons,
.k-upload .k-columnmenu-actions,
.k-upload .k-form-buttons {
    border-top-width: 1px;
}

.k-upload-button-wrap {
    position: relative;
    direction: ltr;
}
.k-upload-button-wrap input {
    font: 170px monospace !important;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
}
.k-upload-button-wrap input[disabled],
.k-upload-button-wrap input.k-disabled {
    visibility: hidden;
}

.k-upload {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}
.k-upload .k-dropzone,
.k-upload .k-upload-dropzone {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #fafafa;
}
.k-upload .k-dropzone.k-hover,
.k-upload .k-upload-dropzone.k-hover {
    background-color: #f0f0f0;
}
.k-upload .k-dropzone .k-dropzone-hint,
.k-upload .k-upload-dropzone .k-dropzone-hint {
    color: #666666;
}
.k-upload .k-upload-files,
.k-upload .k-file {
    border-color: rgba(0, 0, 0, 0.08);
}
.k-upload .k-file.k-focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}
.k-upload .k-file .k-upload-action.k-focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.13);
}
.k-upload .k-upload-status {
    color: #666666;
}
.k-upload .k-file-progress .k-progressbar .k-selected {
    background-color: #0058e9;
}
.k-upload .k-file-success .k-file-validation-message {
    color: #37b400;
}
.k-upload .k-file-success .k-progressbar .k-selected {
    background-color: #37b400;
}
.k-upload .k-file-error .k-file-validation-message {
    color: #f31700;
}
.k-upload .k-file-error .k-progressbar .k-selected {
    background-color: #f31700;
}
.k-upload .k-file-icon-wrapper {
    color: #666666;
    border-color: #666666;
}
.k-upload .k-file-size,
.k-upload .k-file-validation-message,
.k-upload .k-file-summary {
    color: #666666;
}
.k-upload .k-multiple-files-wrapper .k-file-summary {
    color: #424242;
}
.k-upload.k-invalid,
.k-upload.ng-invalid.ng-touched,
.k-upload.ng-invalid.ng-dirty {
    border-color: #f31700;
}
