/**
 * jQuery Schedule v2.2.0
 * https://github.com/Yehzuna/jquery-schedule
 * Thomas BORUSZEWSKI <yehzuna@outlook.com>
 */
.jqs-day-remove, .jqs-day-duplicate, .jqs-period-remove, .jqs-period-duplicate {
  display: none;
  position: absolute;
  height: 10px;
  width: 10px;
  cursor: pointer;
  background-size: 100% 100%;
}

.jqs-day-remove, .jqs-period-remove {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiBoZWlnaHQ9IjI0cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yMi4yNDUsNC4wMTVjMC4zMTMsMC4zMTMsMC4zMTMsMC44MjYsMCwxLjEzOWwtNi4yNzYsNi4yN2MtMC4zMTMsMC4zMTItMC4zMTMsMC44MjYsMCwxLjE0bDYuMjczLDYuMjcyICBjMC4zMTMsMC4zMTMsMC4zMTMsMC44MjYsMCwxLjE0bC0yLjI4NSwyLjI3N2MtMC4zMTQsMC4zMTItMC44MjgsMC4zMTItMS4xNDIsMGwtNi4yNzEtNi4yNzFjLTAuMzEzLTAuMzEzLTAuODI4LTAuMzEzLTEuMTQxLDAgIGwtNi4yNzYsNi4yNjdjLTAuMzEzLDAuMzEzLTAuODI4LDAuMzEzLTEuMTQxLDBsLTIuMjgyLTIuMjhjLTAuMzEzLTAuMzEzLTAuMzEzLTAuODI2LDAtMS4xNGw2LjI3OC02LjI2OSAgYzAuMzEzLTAuMzEyLDAuMzEzLTAuODI2LDAtMS4xNEwxLjcwOSw1LjE0N2MtMC4zMTQtMC4zMTMtMC4zMTQtMC44MjcsMC0xLjE0bDIuMjg0LTIuMjc4QzQuMzA4LDEuNDE3LDQuODIxLDEuNDE3LDUuMTM1LDEuNzMgIEwxMS40MDUsOGMwLjMxNCwwLjMxNCwwLjgyOCwwLjMxNCwxLjE0MSwwLjAwMWw2LjI3Ni02LjI2N2MwLjMxMi0wLjMxMiwwLjgyNi0wLjMxMiwxLjE0MSwwTDIyLjI0NSw0LjAxNXoiLz48L3N2Zz4=");
}

.jqs-day-duplicate, .jqs-period-duplicate {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxwb2x5bGluZSBmaWxsPSJub25lIiBwb2ludHM9IjI5LDIxIDE4LDIxIDE4LDEwICAgIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz48cG9seWdvbiBmaWxsPSJub25lIiBwb2ludHM9IjMwLDQ5IDEsNDkgMSw5ICAgMTgsOSAzMCwyMSAiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS13aWR0aD0iMiIvPjxwb2x5bGluZSBmaWxsPSJub25lIiBwb2ludHM9IjQ4LDEzIDM3LDEzIDM3LDIgICAiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS13aWR0aD0iMiIvPjxwb2x5Z29uIHBvaW50cz0iMzcuNDE0LDAgMTksMCAxOSw2IDIxLDggMjEsMiAzNi41ODYsMiA0OCwxMy40MTQgNDgsNDAgMzMsNDAgMzMsNDIgNTAsNDIgNTAsMTIuNTg2ICIvPjwvc3ZnPg==");
}

.jqs {
  position: relative;
  padding: 40px 20px 20px 60px;
  overflow-y: scroll;
  font-family: system, -apple-system, Roboto, Helvetica Neue, Arial, Helvetica, Geneva, sans-serif;
  box-sizing: border-box;
}
.jqs-mode-edit .jqs-period {
  cursor: pointer;
}
.jqs-mode-edit .ui-draggable-dragging {
  cursor: move;
}
.jqs-mode-5 .jqs-table td, .jqs-mode-5 .jqs-grid-day {
  width: 20%;
}
.jqs-table {
  position: relative;
  z-index: 10;
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: content-box;
}
.jqs-table td,
.jqs-table th {
  padding: 0;
}
.jqs-table td {
  position: relative;
  width: 14.28571%;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.jqs-grid {
  position: absolute;
  top: 40px;
  left: 60px;
  right: 20px;
  box-sizing: content-box;
}
.jqs-grid-head {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  display: flex;
}
.jqs-grid-day {
  position: relative;
  width: 14.28571%;
  padding: 8px 0 4px 0;
  text-align: center;
  font-size: 12px;
}
.jqs-grid-day:hover .jqs-day-remove,
.jqs-grid-day:hover .jqs-day-duplicate, .jqs-grid-day-buttons .jqs-day-remove,
.jqs-grid-day-buttons .jqs-day-duplicate {
  display: block;
}
.jqs-grid-line {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid transparent;
  position: relative;
  height: 38px;
  box-sizing: content-box;
}
.jqs-grid-line:last-child {
  height: 0;
  border-bottom: none;
}
.jqs-grid-line:before {
  content: "";
  position: absolute;
  top: 50%;
  height: 1px;
  width: 100%;
  background: #eee;
}
.jqs-grid-hour {
  position: absolute;
  top: -7px;
  left: -50px;
  display: block;
  width: 42px;
  font-size: 12px;
  text-align: right;
}
.jqs-day {
  width: 100%;
  height: 960px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.jqs-day-remove {
  bottom: 20px;
  right: 2px;
}
.jqs-day-duplicate {
  bottom: 4px;
  right: 2px;
}
.jqs-period {
  position: absolute;
  width: 100%;
  min-height: 10px;
}
.jqs-period:hover .jqs-period-remove, .jqs-period:hover .jqs-period-duplicate {
  display: block;
}
.jqs-period-15:hover .jqs-period-remove, .jqs-period-15:hover .jqs-period-duplicate, .jqs-period-15 .jqs-period-time, .jqs-period-15 .jqs-period-title {
  display: none;
}
.jqs-period-30 .jqs-period-title {
  display: none;
}
.jqs-period-time, .jqs-period-title {
  display: block;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -0.5px;
  word-break: break-all;
  overflow: hidden;
}
.jqs-period-time {
  font-weight: 700;
  line-height: 16px;
}
.jqs-period-container {
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  padding: 0 3px;
  border-radius: 3px;
  box-sizing: border-box;
}
.jqs-period-helper {
  position: absolute;
  width: 100%;
  min-height: 10px;
}
.jqs-period-helper:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background-color: rgba(50, 50, 50, 0.1);
}
.jqs-period-helper-time {
  padding: 2px 0 0 5px;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: #666;
}
.jqs-period-remove {
  bottom: 2px;
  right: 2px;
}
.jqs-period-duplicate {
  bottom: 2px;
  right: 16px;
}
.jqs-options {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 16px;
}
.jqs-options-time {
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: #000;
}
.jqs-options-title {
  width: 220px;
  font-size: 11px;
  border: none;
  border-bottom: 1px solid #333;
  margin-bottom: 16px;
  margin-right: 36px;
}
.jqs-options-color {
  display: inline-block;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  height: 25px;
  font-size: 11px;
  margin-bottom: 16px;
  margin-right: 4px;
  cursor: pointer;
}
.jqs-options-duplicate, .jqs-options-remove {
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #f5f5f5;
  color: #444;
  font-weight: 700;
  font-size: 11px;
}
.jqs-options-duplicate:hover, .jqs-options-remove:hover {
  background: #e5e5e5;
  color: #333;
}
.jqs-options-close {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.jqs-options-close:before, .jqs-options-close:after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 2px;
  left: 4px;
  background: #000;
}
.jqs-options-close:before {
  transform: rotate(45deg);
}
.jqs-options-close:after {
  transform: rotate(-45deg);
}
