$c-primary: #4e1289;
$c-secondary: #ff8f00;

$c-white: #ffffff;
$c-black: #000000;

$c-disabled: #808080;
$c-disabled-bg: #dadada;

$c-background: #f8f8f8;
$c-background-layout: #ffffff;
$c-background-menu: #e0e0e0; //#eeeeee;
$c-background-menu-active: #c2c2c2; //#e0e0e0;

$c-navigation: $c-primary;
$c-submenu-bottom: $c-black; //#656565;

$c-text: $c-black; //#656565;
$c-text-primary: #ffffff;
$c-text-secondary: #ffffff;

$c-shadow-sm: 0px 2px 4px rgba(0, 0, 0, 0.25);
$c-border-radius: 5px;
$c-border-radius-xl: 10px;

//$c-grey-light: #f6f6f6;

$c-txt-success: #009200;
$c-txt-error: #a52a2a;

$c-indicator-true: #00ff00;
$c-indicator-false: #ff0000;

$c-separator: #b9b9b9;
$content-frame: #e0e0e0;

$k-grid-toolbar: #ffffff;
$k-grouping-header: #e0e0e0;
$k-grid-header: #e0e0e0;
$k-grid-pager: #e0e0e0;

$k-grid-content-min-height: 42px;
$k-grid-content-max-height: 300px;

$k-panelbar-k-item: #e0e0e0;
$k-panelbar-k-item-k-state-expanded: #e0e0e0;

/* KendoUI variables */
$base-theme: Default;
$skin-name: cue;
$swatch-name: Default;
$border-radius: 5px;
$accent: $c-primary;
$info: #3e80ed;
$success: #5ec232;
$warning: #fdce3e;
$error: #ff0f1b;
$text-color: #656565;
$bg-color: #ffffff;
$base-text: #656565;
$base-bg: #f6f6f6;
$hovered-text: #656565;
$hovered-bg: #ededed;
$selected-text: #ffffff;
$selected-bg: $c-primary;
$series-a: $c-primary;
$series-b: #ffd246;
$series-c: #78d237;
$series-d: #28b4c8;
$series-e: #2d73f5;
$series-f: #aa46be;

$accent: $c-primary;
$dialog-titlebar-bg: $c-primary;
$dialog-titlebar-border: $c-primary;

$accent-contrast: white;
$selected-text: white;
$base-text: $c-text;
//$base-bg: red; // grid header, footer, text-background, button background atd.
$base-border: #adadad;

$grid-alt-bg: #f7f7f7;

$c-input-p-y: 4px;
$c-input-p-x: 8px;

@mixin app-name-font() {
  font-family: 'Basic Sans', sans-serif;
  font-weight: 700;
  font-size: 1.3125rem;
}
