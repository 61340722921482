@use 'sass:map';

$spacing: (
  'px': 1px,
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 0.75rem,
  '4': 1rem,
  '5': 1.25rem,
  '6': 1.5rem,
  '8': 2rem,
  '10': 2.5rem,
);

// TODO - replace with tailwind ??

@each $key, $value in $spacing {
  .h-#{$key} {
    height: $value;
  }

  // .w-#{$key} {
  //   width: $value;
  // }

  .m-#{$key} {
    margin: $value;
  }

  .p-#{$key} {
    padding: $value;
  }

  .mb-#{$key} {
    margin-bottom: $value;
  }

  .mt-#{$key} {
    margin-top: $value;
  }

  .ml-#{$key} {
    margin-left: $value;
  }

  .mr-#{$key} {
    margin-right: $value;
  }

  .mx-#{$key} {
    margin-left: $value;
    margin-right: $value;
  }

  .my-#{$key} {
    margin-top: $value;
    margin-bottom: $value;
  }

  // .pb-#{$key} {
  //   padding-bottom: $value;
  // }

  // .pt-#{$key} {
  //   padding-top: $value;
  // }

  // .pl-#{$key} {
  //   padding-left: $value;
  // }

  // .pr-#{$key} {
  //   padding-right: $value;
  // }

  // .px-#{$key} {
  //   padding-left: $value;
  //   padding-right: $value;
  // }

  // .py-#{$key} {
  //   padding-top: $value;
  //   padding-bottom: $value;
  // }

  .gap-#{$key} {
    gap: $value;
  }

  // .gap-y-#{$key} {
  //   row-gap: $value;
  // }

  // .gap-x-#{$key} {
  //   column-gap: $value;
  // }
}
