@use 'theme.scss' as theme;

@use 'utility.scss';

@use 'grid/main';

@use 'variables' as var;
@use '_kendoOverrides';

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

@import './grid/variables/main';
$tab-width-offset: 45px;
$tab-left-offset: 29px;
$tab-margins: 15px;
$tabs-height-offset: 145px;
$tabs-height-offset-header: 40px;

@font-face {
  font-family: 'CueArrowFont';
  src: url('/assets/fonts/CueArrowFont.ttf') format('truetype');
}

@font-face {
  font-family: 'Basic Sans';
  src:
    local('Basic Sans'),
    url('/assets/fonts/basic-sans-700.woff2') format('woff2'),
    url('/assets/fonts/basic-sans-700.woff') format('woff');
  font-weight: 700;
}

* {
  font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Verdana, sans-serif;
  //color: $c-text;
}

.tab-error {
  font-size: 15px !important;
  margin-left: 0.25rem;
}

::-ms-reveal {
  display: none;
}

*:focus {
  outline: none !important;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: left;
}

hr {
  border: 0.5px solid var.$c-separator;
}

p,
span,
div,
h1,
h2,
h3,
h4,
h5 {
  color: var.$c-text;
  text-align: left;
  font-size: 13px;
}
.description {
  padding-top: 2px;
  font-weight: lighter;
  margin-bottom: 10px;
  display: inline-block;
}

.experimental {
  color: orangered;
  font-weight: bolder;
  size: 20px;
}
a {
  color: var.$c-primary;
  &:hover {
    color: darken(var.$c-primary, 5%);
  }
}

.bold {
  font-weight: bold;
}

hr {
  border-color: rgb(231, 231, 231);
}

.icon {
  max-width: 22px;
  max-height: 22px;
  text-align: center;
  vertical-align: middle;
}

.iconText {
  margin: 0 4px 0 0;
  max-width: 22px;
  max-height: 22px;
  text-align: center;
  vertical-align: middle;
}

.buttonIcon {
  padding: 2px;
  flex-shrink: 0;
}

.k-tooltip-title,
.k-tooltip-content {
  color: white;
}

#pageToolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  overflow: hidden;
  color: var.$c-black;
  margin-bottom: 15px;

  .heading {
    font-size: 25px;
    font-weight: 500;
    @media #{$breakpoint-lg-down} {
      font-size: 20px;
    }
  }

  .k-button-text {
    @media #{$breakpoint-lg-down} {
      font-size: 10px;
    }
  }
}

.toolbarRight {
  display: flex;
  align-items: center;
  gap: 10px;
}

.topBarLeft {
  display: flex;
  align-items: center;
  gap: 10px;
  @media #{$breakpoint-xs-only} {
    //flex-direction: column;
    //align-items: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.topBarRight {
  position: relative;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  flex-shrink: initial;
  @media #{$breakpoint-xs-only} {
    flex-wrap: nowrap;
    &.dropdownShown {
      display: block;
      white-space: nowrap;
      .k-button:not(#hamburgerBtn) {
        margin-right: 5px;
      }
    }
  }

  > .k-button {
    @media #{$breakpoint-xs-only} {
      transition: none;
    }
  }
}

.topBarButtons {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  @media #{$breakpoint-sm-down} {
    display: none;
    position: fixed;
    right: 15px;
    flex-direction: column;
    margin-top: 5px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
    z-index: -1;
    top: 100px;
    &.active {
      display: flex;
      z-index: 13;
      .k-button:not(#hamburgerBtn) {
        margin-right: 0px;
      }
    }
  }
}

.toolbarBtn {
  border-radius: var.$c-border-radius !important;

  &.k-state-active {
    outline: none !important;
    cursor: default;
    pointer-events: none !important;
    opacity: 0.6;
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
  }
}

#pageTabs {
  overflow: hidden;
}

#pageContent {
  overflow: auto;
  position: absolute;
  top: 40px;
  bottom: 0;
  height: auto;
}

.k-tabstrip-items-wrapper {
  border-color: transparent !important;
}

.k-tabstrip-items {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.k-tabstrip {
  width: 100%;
}

.page-title {
  font-size: 25px;
  font-weight: 500;
  position: relative;
  top: -4px;
  @media #{$breakpoint-xs-only} {
    font-size: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
}

.contentActions {
  margin-bottom: 15px;
}

.content-container {
  padding: 15px;
  overflow: auto;
  //position: absolute;
  //top: 95px;
  //bottom: 0;
  height: auto;
  max-height: calc(100% - 80px);
  //width: calc(100% - #{$tab-width-offset} * 2);
  background: var.$c-white;
  border-radius: var.$c-border-radius-xl;
  //left: $tab-left-offset;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);

  @media #{$breakpoint-lg-down} {
    padding: 10px 8px;
  }
}

.error {
  text-align: right;
  font-size: 0.85em;
  color: var.$c-txt-error;
}

.k-tabstrip > .k-content {
  display: initial;
  background: transparent !important;
  border: none !important;
  border-color: transparent !important;
  height: calc(100vh - #{$tabs-height-offset} - #{$tabs-height-offset-header});
  z-index: 5;
  top: -5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}
.k-tabstrip-top > .k-tabstrip-items {
  border-color: transparent !important;
  padding: 5px;
}
.k-tabstrip-items .k-link {
  padding: 6px 12px 10px;
  font-weight: 500;
}
.k-tabstrip-items .k-item.k-active,
.k-tabstrip-items .k-item.k-select {
  color: var.$c-black;
  transition: all 1s ease;
  border-color: transparent !important;
  border-radius: var.$c-border-radius-xl var.$c-border-radius-xl 0 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
  background-color: var.$c-white;
  margin-bottom: 0 !important;
  border-bottom-width: 0 !important;
}

.k-button .k-button-text {
  color: var.$c-white !important;
  display: flex;
  align-items: center;
}

.k-button {
  padding: 0 12px;
  border: none;
  border-color: transparent !important;
  box-shadow: var.$c-shadow-sm;
  background: var.$c-primary !important;
  color: var.$c-white !important;
  font-weight: 500;
  font-size: 13px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  outline: none;
  transition: all 0.2s ease;
  height: 30px;
  border-radius: var.$c-border-radius !important;
  &:hover {
    background: darken(var.$c-primary, 5%) !important;
    color: var.$c-white !important;
  }
  &.buttonIcon {
    width: 30px;
    padding: 0 !important;
    .k-grid & {
      @media #{$breakpoint-lg-down} {
        width: 20px;
        height: 20px;

        .icon {
          max-width: 18px;
          max-height: 18px;
        }
      }
    }
  }
  a {
    &:hover {
      color: inherit;
    }
  }

  @media #{$breakpoint-sm-only} {
    padding: 0 10px;
  }
}

.form-group {
  position: relative;
  margin-bottom: 5px;
}

input,
.k-input,
.k-textbox {
  background: var.$c-white;
  border: none;
  border-color: transparent;
  outline: none;
  border-radius: var.$c-border-radius;
  transition: all 0.25s ease;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35) !important;
  color: var.$c-black !important;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  &.ng-invalid:not(.ng-untouched) {
    color: var.$c-white !important;
    background-color: var.$error;
  }
}
input.k-textbox {
  padding-inline: var.$c-input-p-x;
}

.k-dropdown-wrap {
  display: flex;
  max-width: 400px;
}
.k-dropdown-wrap,
.k-numeric-wrap,
.k-picker-wrap {
  background: var.$c-white !important;
  border: none !important;
  border-color: transparent !important;
  outline: none !important;
  border-radius: var.$c-border-radius !important;
  transition: all 0.25s ease;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35) !important;
  color: var.$c-black !important;
  height: 30px;
  width: 100%;
  .k-input {
    box-shadow: none !important;
  }
}
.k-select {
  background: transparent !important;
}
label >  span {
  font-size: 12px;
  margin-bottom: 5px;
}
.k-textarea {
  background: var.$c-white;
  border: none;
  border-color: transparent;
  outline: none;
  border-radius: var.$c-border-radius;
  transition: all 0.25s ease;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35) !important;
  color: var.$c-black !important;
  height: 80px;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  &.ng-invalid:not(.ng-untouched) {
    color: var.$c-white !important;
    background-color: var.$c-secondary;
  }
}
.k-form-field {
  span.error {
    font-size: 20px;
    position: relative;
    top: 6px;
    padding-left: 5px;
    padding-right: 5px;
    height: 6px;
    line-height: 6px;
    margin-right: 5px;
  }
  div.error {
    font-size: 13px;
    margin-top: 4px;
    font-weight: 500;
    text-align: left;
  }
}

.k-grid {
  border-radius: var.$c-border-radius;
  th {
    font-weight: 500;
  }
  label {
    margin-top: 0 !important;
  }
  .k-filtercell-wrapper {
    align-items: center;
  }
}

.k-dialog-buttongroup .k-button {
  height: 40px !important;
}

/* GLOBAL STYLES */
input[type='checkbox'] {
  width: 20px !important;
  height: 20px;
  background: var.$c-white;
  color: var.$c-white !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35);
  border: none;
  transition: all 0.25s ease;
  outline: none;
  border-radius: var.$c-border-radius;
  &:checked {
    color: var.$c-white !important;
    background-color: var.$c-primary;
  }
  &:hover:not(:checked) {
    background-color: lighten(var.$c-primary, 35%);
  }
}

kendo-progressbar {
  margin-top: 8px;
  .k-state-selected {
    background: var.$c-primary !important;
    box-shadow: 0px 1px 4px rgb(0, 0, 0, 0.35);
  }
}

kendo-grid-filter-cell-operators kendo-dropdownlist {
  position: relative;
  top: -4px;
}

.errorWrapper {
  font-weight: bold;
  color: var.$c-txt-error;
  margin: 5px 0;
}

::selection {
  color: var.$c-white !important;
  background: var.$c-primary !important;
}

// LEaflet

.my-user-icon {
  border: 0px !important;
  fill: red;
  &.selected {
    fill: red;
  }

  &.poi-selected {
    fill: orange;
  }
}

.my-custom-icon {
  border: 0px !important;

  &.selected {
    fill: red;
  }
  &.poi-selected {
    fill: orange;
  }
}

.leaflet-pm-icon-resource {
  background-image: url('/assets/img/navigation/resource-icon.png');

  &.selected {
    fill: red;
  }
  &.poi-selected {
    fill: orange;
  }
}

.leaflet-pm-icon-area {
  background-image: url('/assets/img/navigation/area-icon.png');
}

.leaflet-pm-icon-navigation {
  background-image: url('/assets/img/navigation/navigation-icon.png');
}

.leaflet-pm-icon-navigate {
  background-image: url('/assets/img/navigation/navigate-icon.png');
}

.leaflet-pm-icon-measure {
  background-image: url('/assets/img/navigation/area-icon.png');
}

.leaflet-pm-icon-copy {
  background-image: url('/assets/img/navigation/copy.svg');
}

.ql-editor {
  //overflow: visible;
  min-height: 200px;
  max-height: 500px;
  p,
  ol,
  ul,
  pre,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 0px;
    padding-bottom: 10px;
    margin: 0;
  }
}

.ql-divider {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yMDZfMzc0OCkiPg0KPHBhdGggZD0iTTIzIDEwLjk5OTdIMUMwLjQ0NzcxNSAxMC45OTk3IDAgMTEuNDQ3NCAwIDExLjk5OTdDMCAxMi41NTIgMC40NDc3MTUgMTIuOTk5NyAxIDEyLjk5OTdIMjNDMjMuNTUyMyAxMi45OTk3IDI0IDEyLjU1MiAyNCAxMS45OTk3QzI0IDExLjQ0NzQgMjMuNTUyMyAxMC45OTk3IDIzIDEwLjk5OTdaIiBmaWxsPSIjMzMyNzJCIi8+DQo8L2c+DQo8ZGVmcz4NCjxjbGlwUGF0aCBpZD0iY2xpcDBfMjA2XzM3NDgiPg0KPHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJ3aGl0ZSIvPg0KPC9jbGlwUGF0aD4NCjwvZGVmcz4NCjwvc3ZnPg0K') !important;
}

div[style*='left: -'] {
  &.ql-tooltip {
    left: unset !important;
  }
}

quill-editor {
  display: block;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgb(0, 0, 0, 0.35);
  .ql-container {
    border: none;
    border-radius: 0 0 5px 5px;
  }
  .ql-toolbar {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px 5px 0 0;

    .k-tabstrip-items-wrapper + .k-tabstrip-content & {
      border-radius: 0;
    }
  }
}

.input-bottom-description {
  margin-top: 6px;
  color: #6c6c6c;
}

.layoutPopup {
  width: 800px;
  img {
    width: 100%;
  }
}

/**
These styles are repeated many times in many components, so its easier to just have them here
*/
.pageGrid {
  .k-grid-content {
    min-height: var.$k-grid-content-min-height;
    overflow-x: auto;
  }
  .k-header.k-grid-toolbar {
    background-color: var.$k-grid-toolbar;
    border-top-style: none;
    padding: 4px;
  }
  .k-grouping-header {
    background-color: var.$k-grouping-header;
  }
  .k-grid-pager {
    background-color: var.$k-grid-pager;
    padding: 4px;
  }
  td {
    padding: 2px 4px;
    @media #{$breakpoint-lg-down} {
      font-size: 12px;
    }
  }
  th {
    padding: 6px 4px;
    @media #{$breakpoint-lg-down} {
      font-size: 12px;
    }
  }
  &.hasMobileDropdown {
    .k-grid-toolbar {
      align-items: start;
    }
  }
  @media #{$breakpoint-lg-down} {
    .k-column-title,
    .k-grid-pager,
    .k-input-inner {
      font-size: 12px;
    }
  }
}

/* Styles for index html */
.landing-screen {
  display: flex;
  height: 100dvh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  @include var.app-name-font();
  font-size: 4rem;
  & img {
    width: 8rem;
    height: 8rem;
  }
}

.row {
  div[class^='col'] {
    box-sizing: border-box;
    @media #{$breakpoint-xsm-down} {
      width: 50%;
      padding: 1rem;
    }
    @media #{$breakpoint-xs-only} {
      width: 100%;
      padding: 5px 0;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 20px;
  @media #{$breakpoint-xsm-only} {
    grid-template-columns: 1fr 1fr;
  }
  @media #{$breakpoint-xs-only} {
    grid-template-columns: 1fr;
  }
  &--4 {
    @media #{$breakpoint-md-up} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

.grid-col {
  margin-bottom: 20px;
  @media #{$breakpoint-xs-only} {
    margin-bottom: 10px;
  }

  &--line {
    width: calc(100% / 3 - 10px);
    @media #{$breakpoint-xsm-only} {
      width: calc(100% / 2 - 10px);
    }
    @media #{$breakpoint-xs-only} {
      width: 100%;
    }
  }
}

.acc-myForm {
  margin: auto;
  max-width: 500px;
}

.acc-linkWrapper {
  margin: auto;
  max-width: 500px;
}

.acc-btnWrapper {
  text-align: center;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.instructions-bellow {
  margin: 0 0 20px;
  font-style: italic;
  font-weight: normal;
}

.tabs-w-tiny-padding {
  .k-tabstrip-items {
    padding-top: 2px;
    padding-left: 3px;
  }
}

.tabs-w-padding {
  .k-tabstrip-items {
    padding: 5px 5px 0;
  }
  .k-tabstrip-items-wrapper {
    + .k-tabstrip-content {
      margin-top: -3px !important;
    }
  }
}
